import React from "react";
//Material
import FilterListIcon from "@material-ui/icons/FilterList";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import Divider from "@material-ui/core/Divider";
//stateless
import { ButtonTag } from "stateless/button";
const { log } = console;
function FilterShow(props) {
  const { data_expand = [], onRemove } = props;
  const { data_expand_single = [], RemoveTagSingle } = props;
  return (
    <>
      <Box display="flex" flexWrap="wrap">
        {data_expand?.map((el, idx) => (
          <React.Fragment key={idx}>
            <Box style={{ margin: "0 5px 5px 0" }}>
              <ButtonTag
                height="35px"
                clickCancel={() =>
                  onRemove(Object.keys(el["name"])[0], el["value"])
                }
              >
                {Object.keys(el["name"])}:{el["value"]}
              </ButtonTag>
            </Box>
          </React.Fragment>
        ))}
        {data_expand_single?.map((el, idx) => (
          <React.Fragment key={idx}>
            <Box style={{ margin: "0 5px 5px 0" }}>
              <ButtonTag
                height="35px"
                clickCancel={() => RemoveTagSingle(el["name"])}
              >
                {el["name"]}:{el["value"]}
              </ButtonTag>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </>
  );
}

export default FilterShow;
