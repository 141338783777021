import React, { useState, useEffect, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { common } from "utils";
//Material
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
//Component
import { BaseContainer } from "stateless/base-container";
import Button from "@material-ui/core/Button";
import FormInput from "stateless/hook-controls/input";
import FormCheckBox from "stateless/hook-controls/checkbox";
import ComboJobType from "stateless/selection-control/combo/job-type";
import { Modal } from "stateless/modal";
import { ReactTable } from "stateless/react-table";
//Function
import { setDataToFormHookV2, isEmptyStr } from "utils/common-function";
import { getMasterColumns } from "./columns/job-master";
import { buildParameter } from "./parameter";
import * as alertDialog from "utils/alert";
import * as api from "./api";
// Context

function JobMaster() {
  //Context

  //State
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [DataList, setDataList] = useState([]);
  const [DataDetail, setDataDetail] = useState({});

  //Event
  const onOpenAddModal = () => {
    setOpenModal(true);
    setEditMode(false);
    setDataDetail(null);
  };

  const onOpenEditModal = () => {
    setOpenModal(true);
    setEditMode(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  //Function
  const Edit = (Detail) => {
    onOpenEditModal();
    setDataDetail(Detail);
  };

  const Delete = async (id) => {
    let param = {
      JOB_GUID: id,
    };

    let checkdel = await api.CheckDeleteJobMaster(param);
    if (checkdel > 0) {
      return;
    }

    let res = await alertDialog.ConfirmDelete();
    if (!res) return;

    let api_res = await api.DeleteJobMaster(param);
    if (!api_res) return;
    fetch();
    alertDialog.del_success();
  };

  const withLoading = async (promise_function) => {
    setIsLoading(true);
    let result = await common.to_raw(promise_function());
    setIsLoading(false);
    return result;
  };

  const Import = async () => {
    let res = await withLoading(async () => api.SyncJopMasterFromTalend());
    if (res) {
      fetch();
      alertDialog.inform_success();
    }
  };

  //---------------------------
  const fetch = async () => {
    let res = await api.GetListJobMaster();
    setDataList(res);
  };
  useEffect(() => {
    fetch();
  }, []);

  //---------------------------
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  const tablecomponent = () => {
    return (
      <>
        {/* <Box m={2} className="btnstyle">
          <Button variant="contained" onClick={Import} disabled={isLoading}>
            Import
          </Button>
        </Box> */}
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  };

  return (
    <>
      {isLoading ? <LinearProgress /> : null}
      <BaseContainer>
        <Typography style={{ marginLeft: "5px" }}>Job Master</Typography>
        <ReactTable
          keyField="JOB_GUID"
          data={DataList}
          columns={getMasterColumns(Edit, Delete)}
          headcomponent={tablecomponent}
        />
        <DataModal
          editmode={editMode ? 1 : 0}
          open={openModal}
          onClose={onCloseModal}
          search={fetch}
          datadetail={DataDetail}
        />
      </BaseContainer>
    </>
  );
}

function DataModal(props) {
  const { onClose } = props;
  const { open, editmode } = props;
  const { datadetail, search = () => {}, ...otherprops } = props;
  const method = useForm();
  const { setValue, getValues, setError, clearErrors, errors } = method;

  //Event

  //Effect
  useEffect(() => {
    if (!open) return;
    if (editmode) {
      let data = setDataToFormHookV2(datadetail);
      setTimeout(() =>
        data?.forEach(({ name, value }) => setValue(name, value))
      );
    } else {
      setTimeout(() => setValue("ACTIVE", true));
    }
  }, [datadetail, setValue, open, editmode]);

  const SaveDetail = async () => {
    const ID = editmode ? datadetail.JOB_GUID : getValues().JOB_GUID;
    let param = buildParameter.SaveDetail(ID, getValues());

    clearErrors("ORIGIN");

    if (datadetail.ORIGIN !== getValues().ORIGIN) {
      let checkdup = await api.CheckDulplicateOriginByJobID(param);
      if (checkdup > 0) {
        setError("ORIGIN", {
          type: "manual",
          message: "The Origin is Duplicate.",
        });
        return;
      }
    }

    let res = await api.SaveJobMaster(param);
    if (res) {
      search();
      onClose();
      alertDialog.inform_success();
    }
  };

  //Style
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
  }));
  const classes = useStyles();

  // Props
  const inputProps = {
    readOnly: editmode ? true : false,
  };

  return (
    <div>
      <Modal
        {...otherprops}
        disableEnforceFocus
        maxWidth="md"
        onSave={SaveDetail}
        title={editmode ? "Edit Job Master" : "Add Job Master"}
      >
        <FormProvider {...method}>
          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box m={1}>
                  <FormInput
                    name="JOB_ID"
                    label="Job ID"
                    variant={editmode ? "filled" : ""}
                    required={editmode ? false : true}
                    InputProps={inputProps}
                    errorobj={errors}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={1} mb={1.5}>
                  <FormInput name="ORIGIN" label="Origin" errorobj={errors} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={1}>
                  <FormInput
                    name="JOB_NAME"
                    label="Job Name"
                    errorobj={errors}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={1}>
                  <ComboJobType required name="JOB_TYPE_ID" label="Job Type" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={1} mb={5}>
                  <FormInput
                    name="PARAMETERS"
                    label="Parameters"
                    multiline
                    rows={3}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <FormCheckBox key="ACTIVE" name="ACTIVE" label="Active" />
                </Box>
              </Grid>
            </Grid>
          </div>
        </FormProvider>
      </Modal>
    </div>
  );
}
export default JobMaster;
