import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
  Controller,
} from "react-hook-form";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
//Material
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
//component
import { BaseContainer } from "stateless/base-container";
import { BoxTemplate1 } from "stateless/Box";
import { ReactTable } from "stateless/react-table";
import { Button, ButtonSearchTextBox } from "stateless/button";
import { Modal } from "stateless/modal";
import { SearchTextBox } from "stateless/hook-controls/input";
import { SearchResultCard } from "apps/data-catalog/stateless-datacatalog/card";
import { FilterBrowse, FilterShow } from "stateless/filterbar";
import PopupDataProperty from "../screen-data-object/popup-data-property";
//Context
import { CaptionFieldContext } from "apps/data-catalog/caption-field-context/caption-field-context";
//utils
import {
  titleCase,
  isEmptyArray,
  isEmptyObj,
  isEmptyStr,
} from "utils/common-function";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import axios from "axios";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
import propsResolver from "react-bootstrap-table-next/lib/src/props-resolver";
import { height } from "@mui/system";
import { ToDateText, ToDateTimeText } from "utils/date";
import { SettingsVoice } from "@material-ui/icons";
import { Pagination } from "@mui/material";
import {
  readFile,
  GetTemplate,
  colToNumber,
  numberToCol,
} from "apps/main/function-api/useExcel";
const { log } = console;
//api
export const api_SearchAll = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/SearchAll`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
//const
const field_lst = [
  "DomainName",
  "SubDomainName",
  "ObjectName",
  "ObjectType",
  "ColumnName",
  "Description",
  "BusinessGlossary",
];
const defaultValues = {
  DomainName: [],
  SubDomainName: [],
  ObjectName: [],
  ObjectType:[],
  ColumnName: [],
  BusinessGlossary: [],
  Description: [],
};
const defaultCount = {
  Count: 0,
  PageCount: 0,
};
function Browse() {
  const { state } = useContext(CaptionFieldContext); //field
  const [openDetail, setOpenDetail] = useState(false); //control Property dialog
  const [selectedData, setSelectedData] = useState({}); //control Property dialog
  const [filter, setFilter] = useState(defaultValues); //filter data (capsule)
  const [result, setResult] = useState([]); //Data for show in browse
  const [result_count, setResult_Count] = useState(defaultCount); // For Paging information
  const [page, setPage] = useState(1); // for paging component
  const [isLoad, setIsLoad] = useState(false);
  const [searchParameter, setSearchParameter] = useState({}); //จำค่า parameter ไว้ใช้ส่งตอน paging
  const [open, setOpen] = React.useState(false); //Downloading CSV
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const methods = useForm({
    defaultValues: defaultValues,
  });
  const { getValues, setValue, reset, register, control } = methods;
  function ClickView(Data) {
    setOpenDetail(true);
    setSelectedData(Data);
  }
  async function ClickURLForSearch(param) {
    setValue("FullText", param);
    Search();
  }
  const handleChange = (event, value) => {
    setPage(value);
    Search({ ...searchParameter, Page: value });
  };
  async function Search(param) {
    if (isEmptyObj(param)) {
      param = { ...getValues(), ...filter, PageSize: 10 };
      setPage(1);
    }
    setSearchParameter((prev) => ({ ...prev, ...param }));
    setIsLoad(true);
    Clear();
    let [res] = await api_SearchAll(param);
    setIsLoad(false);
    if (res === undefined) res = {};
    setResult(res["Data"]);
    setResult_Count({ Count: res["Count"], PageCount: res["PageCount"] });
  }
  function getFilterValue(name, value) {
    setFilter((prev) => ({ ...prev, [name]: value }));
  }
  function ClearFilterValue() {
    setFilter(defaultValues);
  }
  function expand_arr() {
    let data_arr = common.objectWithoutKey({ ...filter }, "FullText");
    return common.expand_array(data_arr);
  }
  function RemoveTag(name, value) {
    setFilter((prev) => ({
      ...prev,
      [name]: prev[name].filter((el) => el !== value),
    }));
  }
  function Clear() {
    //setResult([])
  }
  async function Export(params) {
    let field = state.FieldDictionary;
    field = field.filter(x=>x.table_name ==='csv_data_property' || x.table_name ==='csv_data_object'|| x.table_name ==='csv_version' )
    field = field.filter((x) => !isEmptyStr(x.excel_col));
    field = field.map((x) => ({
      ...x,
      excel_col_num: colToNumber(x.excel_col),
    }));
    field = field.sort((a, b) =>
      a.excel_col_num > b.excel_col_num
        ? 1
        : b.excel_col_num > a.excel_col_num
        ? -1
        : 0
    );
    handleToggle();
    let [res, err] = await api_SearchAll({
      ...searchParameter,
      isExport: true,
    });
    if (err) {
      handleClose();
      return;
    }
    let field_property = field.filter(
      (x) => x.table_name === "csv_data_property"
    );
    let field_object = field.filter(
      (x) =>
        x.table_name === "csv_data_object" 
    );
    let field_version = field.filter(
      (x) =>
        x.table_name === "csv_version" 
    );
    let new_data = res.Data.map((el) => {
      let data_object = {};
      let data_property = {};
      let version ={}
      field_property?.forEach(
        (prop) =>{
          data_property = {
            ...data_property,
            [prop.excel_col]: el.data_property[prop.field] || "",
          }
        }
      );
      field_object?.forEach(
        (prop) =>
          (data_object = {
            ...data_object,
            [prop.excel_col]: el.data_object[prop.field] || "",
          })
      );
      field_version?.forEach(
        (prop) =>
          (version = {
            ...version,
            [prop.excel_col]: el.data_version[prop.field] || "",
          })
      );
      let merge = { ...data_property,...data_object,...version};
      return merge;
    });
    const ws = XLSX.utils.json_to_sheet(new_data);
    ChangeHeader(ws,[...field_property,...field_object,...field_version]);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob(["\uFEFF"+csv], { type: "text/csv; charset=utf-8" });
    saveAs(blob, `export_data.csv`);
    handleClose();
  }
  function ChangeHeader(ws,field) {
    for (var i =0; i < field.length; i++) {
      let cell = `${field[i].excel_col}1`;
      if (ws[cell] === undefined) ws[cell] = { t: "s", v: "" };
      ws[cell].w = field[i].display;

    }
  }
  return (
    <>
      <FormProvider {...methods}>
        <BaseContainer p={0}>
          <Box display="flex" p={0} flexDirection="column">
            <Box
              display="flex"
              height={"50px"}
              width={"100%"}
              justifyContent="center"
              position={"absolute"}
              top={"140"}
              left={"0"}
              pl={"10"}
              pr={"10"}
            >
              <SearchTextBox
                name="FullText"
                onClear={() => {
                  setValue("FullText", "");
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    Search();
                  }
                }}
              />
              <Box style={{ zIndex: 0, marginLeft: "-40" }}>
                {" "}
                <ButtonSearchTextBox onClick={() => Search()} />
              </Box>
            </Box>
            <Box width={"100%"} mb={"10"} display="flex">
              <FilterBrowse
                getFilterValue={getFilterValue}
                field={field_lst}
                data={filter}
                clear={ClearFilterValue}
              />
            </Box>
            <Box width={"100%"} mb={"10"} display="flex" alignItems="center">
              <Box color={"#969696"} mr={2}>
                <Typography>
                  {`Search Results : `}
                  {isLoad
                    ? "Loading..."
                    : result_count["Count"] > 0
                    ? `${result_count["Count"]} results`
                    : `No results...`}
                </Typography>
                <Button onClick={Export} disabled={!result_count["Count"] > 0}>
                  Download CSV
                </Button>
              </Box>
              <Box>
                <FilterShow data={expand_arr()} onRemove={RemoveTag} />
              </Box>
            </Box>
            <Box display={"flex"} flexGrow={1} justifyContent="center">
              <Box display={result_count["PageCount"] > 1 ? "" : "none"}>
                {" "}
                <Pagination
                  count={result_count["PageCount"]}
                  page={page}
                  onChange={handleChange}
                  size="large"
                />
              </Box>
            </Box>

            <Box>
              {result?.map((el, idx) => (
                <SearchResultCard
                  key={idx}
                  data={el}
                  highlight = {searchParameter.FullText}
                  clickView={ClickView}
                  clickURL={ClickURLForSearch}
                ></SearchResultCard>
              ))}
            </Box>
          </Box>
          <Box display={"flex"} flexGrow={1} justifyContent="center">
            <Box display={result_count["PageCount"] > 1 ? "" : "none"}>
              {" "}
              <Pagination
                count={result_count["PageCount"]}
                page={page}
                onChange={handleChange}
                size="large"
              />
            </Box>
          </Box>
          <PopupDataProperty
            open={openDetail}
            highlight = {searchParameter.FullText}
            onClose={() => setOpenDetail(false)}
            data={selectedData}
          />
        </BaseContainer>
      </FormProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Browse;
