import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { GridToolbar } from "@mui/x-data-grid";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { KKPModal } from "stateless/modal/modal2";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { isEmptyStr } from "utils/common-function";
import { GetDataFrom64 } from "utils/file";
import { DynamicFilterList } from "../stateless/dynamic-filter/filter_list";
import { useDynamicFilter } from "../stateless/dynamic-filter/useDynamicFilter";

export const api_ReadFileTemplate = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/ReadTemplateFile`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
function useTemplateDynamic({ Data }) {
  const [DataRow, setDataRow] = useState([]);
  const [DataDataColumn, setDataColumn] = useState([]);
  const state = { DataRow, DataDataColumn };
  const GetTableDataList = useCallback(async (params) => {
    let [res, err] = await api_ReadFileTemplate(params);
    if (err) return;
    if (isEmptyStr(res)) {
      setDataRow([]);
      setDataColumn([]);
      return;
    }
    let row = GetDataFrom64(res);
    row = row.map((el, idx) => ({ id: idx + 1, ...el }));
    setDataRow(row);
    let col = Object.getOwnPropertyNames(row[0]);
    col.shift();
    setDataColumn(col);
  }, []);
  const actions = {
    GetTableDataList,
  };

  return { state, actions };
}
export function ViewTemplateDynamicDialog({ controlDialog }) {
  const { isOpen, Data } = controlDialog;
  const controlDynamicFilter = useDynamicFilter();
  const { state, actions, formMethods } = useTemplateDynamic({ Data });
  const { GetTableDataList, download } = actions;

  useEffect(() => {
    if (!isOpen) return;
    GetTableDataList({
      ...Data,
      dynamicFilter: controlDynamicFilter.filterData,
    });
  }, [isOpen, GetTableDataList, Data, controlDynamicFilter.filterData]);

  return (
    <>
      <FormProvider {...formMethods}>
        <KKPModal control={controlDialog} title="View Template File">
          <Typography>Template Name : {Data?.template_name}</Typography>
          <Box my={1}>
            <DynamicFilterList {...controlDynamicFilter} />
          </Box>
          <Box mt={2}></Box>
          <Table
            data={state.DataRow}
            col={state.DataDataColumn}
            download={download}
          />
        </KKPModal>
      </FormProvider>
    </>
  );
}
function Table({ data, col, download }) {
  const columns = [];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={data || []}
      rowHeight={40}
      customColumn={{
        all: col,
        custom_col: columns,
        date: ["data_date"],
        date_time: ["upload_datetime", "adjust_datetime"],
      }}
      getRowId={(row) => row.id}
      components={{
        //Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {},
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
