import React, { useCallback, useContext, useEffect } from "react";

import { useForm, FormProvider, useFormContext } from "react-hook-form";
// Util
import { common } from "utils";
//Material
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
//Component
import { ReactTable } from "stateless/react-table";
import { BaseContainer } from "stateless/base-container";
import { MaterialUIDatePickers } from "stateless/hook-controls/datepicker/datepicker";
import ComboBackToDateRange from "stateless/selection-control/combo/backto-date-range";
import CheckboxJobtype from "stateless/selection-control/checkbox/jobtype";
import CheckboxJobStatus from "stateless/selection-control/checkbox/jobstatus";
import { useEnablesignal } from "stateless/selection-control/checkbox/enablesignal";
import ModalPlanDetail from "apps/job-monitoring/job-execution-plan/modal/modal-plan-detail";
import ModalJobHistory from "../job-execution-plan/modal/modal-job-history";
//Context
import { JobMonitoringContext } from "./job-monitoring-context";
import { LoadingContext, withLoadingContext } from "context/loading-context";
//Functions
import { getExecutionPlanColumns } from "./columns";
import { GenerateFormDate } from "../job-execution-plan/api";
import { url, method } from "./signalr";
import { useSignalR } from "../function-api/useSignalR";
import { checkDateRange } from "utils/date";
import { useSelectRowOpenModal } from "apps/job-monitoring/function-api/useSelectRowOpenModal";
const JobMonitoring = () => {
  const { actions, state } = useContext(JobMonitoringContext);
  const { actions: load_actions, state: load_state } =
    useContext(LoadingContext);
  const methods = useForm({
    defaultValues: state.CriteriaSearch,
  });

  // For Plan Detail
  const {
    isOpen: isOpenDetail,
    close: closeDetail,
    Data: dataDetail,
    handleClickRow: clickDetail,
  } = useSelectRowOpenModal();

  // For Job Detail
  const {
    isOpen: isOpenJobDetail,
    close: closeJobDetail,
    Data: jobDetail,
    handleClickRow: clickJobDetail,
  } = useSelectRowOpenModal();

  const { getValues } = methods;
  const { component, isChecked: disableSignal } = useEnablesignal();
  const filterdata = useCallback(() => {
    let param = getValues();
    actions.filtering_joblist(param);
  }, [getValues, actions]);
  const Search = useCallback(async () => {
    let data = getValues();
    let param = {
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
    };

    load_actions.withLoading(() => actions.getJobList(param));
  }, [getValues, actions, load_actions]);
  useEffect(() => {
    Search();
  }, [Search]);
  useEffect(() => {
    filterdata();
  }, [state.ExecutedJobList, filterdata]);
  const signalRHandler = useCallback(
    (data) => {
      actions.updateJobList(data);
    },
    [actions]
  );

  //function
  useSignalR(url, method, signalRHandler, disableSignal);
  const rowStyle = (row, rowIndex) => {
    return { backgroundColor: row.STATUS_COLOR };
  };

  const tablecomponent = () => {
    return (
      <>
        <Box p={1} display="flex" className="btnstyle">
          {component(disableSignal ? Search : () => {})}
          <Button variant="contained" onClick={Search}>
            Refresh
          </Button>
        </Box>
      </>
    );
  };

  const viewJobDetail = async (row) => {
    clickJobDetail(row);
  };
  const viewHistory = async (row) => {
    clickDetail(row);
  };
  return (
    <div>
      <BaseContainer>
        {load_state?.isLoading ? (
          <div className="lineLoading">
            <LinearProgress />
          </div>
        ) : null}
        <FormProvider {...methods}>
          <div>
            <Criteria />
          </div>
        </FormProvider>
        <ReactTable
          keyField="HISTORY_ID"
          data={state.FilterExecutedJobList || []}
          columns={getExecutionPlanColumns(viewJobDetail, viewHistory)}
          rowStyle={rowStyle}
          headcomponent={tablecomponent}
          exportCsv
        />
      </BaseContainer>
      <ModalPlanDetail
        open={isOpenDetail}
        onClose={closeDetail}
        data={dataDetail}
      />
      <ModalJobHistory
        open={isOpenJobDetail}
        onClose={closeJobDetail}
        data={jobDetail}
      />
    </div>
  );
};
const Criteria = () => {
  const { actions, state } = useContext(JobMonitoringContext);
  const methods = useFormContext();
  const { actions: load_actions, state: load_state } =
    useContext(LoadingContext);
  const { getValues, clearErrors, setError } = methods;
  const Search = () => {
    let data = getValues();
    let param = {
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
    };

    // Call Api
    load_actions.withLoading(() => actions.getJobList(param));
  };
  const filterdata = (e) => {
    let param = getValues();
    common.reCheckbox(getValues(), e);
    actions.filtering_joblist(param);
  };
  const dateChange = (e) => {
    clearErrors();
    let valid = checkDateRange(getValues().dateFrom, getValues().dateTo);
    if (!valid) {
      setError("dateFrom", {
        type: "manual",
        //message: `Invalid date range`,
      });
      setError("dateTo", {
        type: "manual",
        message: `Invalid date range`,
      });
      return;
    }
    actions.SetCriteriaSearch(getValues());
    Search();
  };
  return (
    <>
      <Box display="flex" flexWrap="wrap" className="criteriaStyle">
        <Box
          flexBasis={"165px"}
          flexGrow={1}
          p={1}
          flexDirection="column"
          justifyContent="space-around"
          className="boxCriteria"
        >
          <Box pb={1}>
            {" "}
            <MaterialUIDatePickers
              name="dateFrom"
              label="From"
              onChange={dateChange}
            />
          </Box>

          <Box>
            <MaterialUIDatePickers
              name="dateTo"
              label="To"
              onChange={dateChange}
              minDate={state.CriteriaSearch.dateFrom}
            />
          </Box>
        </Box>

        <Box p={1} flexGrow={1} flexBasis={"250px"} className="boxCriteria">
          <CheckboxJobStatus name="JOB_STATUS_ID" onChange={filterdata} />
        </Box>
        <Box p={1} flexGrow={1} flexBasis={"300px"} className="boxCriteria">
          <CheckboxJobtype name="JOB_TYPE_ID" onChange={filterdata} />
        </Box>
      </Box>
    </>
  );
};
export default withLoadingContext(JobMonitoring);
