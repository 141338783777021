import axios from "axios";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
//Master
export const GetListSystemConfigByGroup = async (param) => {
  let url = `${API_URL.Job}/api/master/GetListSystemConfigByGroup`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const SaveSystemConfig = async (param) => {
  let url = `${API_URL.Job}/api/master/SaveSystemConfig`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const GetListOriginConfig = async (param) => {
  let url = `${API_URL.Job}/api/master/GetListOriginConfig`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const SaveOriginConfig = async (param) => {
    let url = `${API_URL.Job}/api/master/SaveOriginConfig`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
  };

export const DeleteOriginConfig = async (param) => {
    let url = `${API_URL.Job}/api/master/DeleteOriginConfig`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
  };

export const GetServiceConfiguration = async (param) => {
  let url = `${API_URL.Job}/api/jobedge/GetServiceConfiguration`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const SetServiceConfiguration = async (param) => {
  let url = `${API_URL.Job}/api/jobedge/SetServiceConfiguration`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const ClearCache = async (param) => {
  let url = `${API_URL.Job}/api/Execution/ClearCache`;
  let { error } = await common.to(axios.post(url, param));
  return error
};