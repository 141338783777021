import React, { useState, useEffect } from "react";
//Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//Component
import { ReactTable } from "../../../../stateless/react-table";
import { Modal } from "../../../../stateless/modal";
import ModalJobHistory from "./modal-job-history";
import ModalPlanHistory from "./modal-plan-history";
import ModalDependencies from "./modal-dependencies"
import { getColumPlanDetail } from "../columns/popup-plan-detail";
import * as alertDialog from "utils/alert";
//Function
import * as api from "../api";
import { buildParameter } from "../parameter";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import { titleCase } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";

let { log: c } = console;
function ModalPlanDetail(props) {
    const [resultData, setData] = useState([]);
    const { open, onClose, data } = props;

    // For Dependencies
    const {
        isOpen,
        close,
        Data,
        handleClickRow: clickViewDependency,
    } = useSelectRowOpenModal();

    // For Job Detail
    const {
        isOpen: isOpenJobDetail,
        close: closeJobDetail,
        Data: jobDetail,
        handleClickRow: clickJobDetail,
    } = useSelectRowOpenModal();

    // For Plan History
    const {
        isOpen: isOpenPlanHistory,
        close: closePlanHistory,
        Data: planHistory,
        handleClickRow: clickPlanHistory,
    } = useSelectRowOpenModal();
    useEffect(() => {
        if (!open) return;
        (async () => {
            let res = await api.GetPlanDetail({
                PLAN_ID: data?.PLAN_ID,
                INSTANCE_ID: data.INSTANCE_ID,
            });
            if (!res) return;
            setData(res);
        })();
    }, [open, data]);
    async function dependencies(params) {
        let rowdata = await api.GetDependencyChainOfThisPlanDisplay(
            buildParameter.GetPlanDependenciesChain(data)
        );
        clickViewDependency(rowdata);
    }
    async function forcerun() {
        if (data.INSTANCE_ID === undefined)
            return;

        let confirm = await alertDialog.ConfirmCommon(
            "Confirm force run and bypass all dependencies?",
            "yes",
            "no"
        );
        if (!confirm)
            return;
        let param = buildParameter.BypassDependencyParam(data?.INSTANCE_ID, data?.STATUS_ID);
        let res = await api.BypassDependency(param);
        let txt = `Force run success.`;
        if (res === false)
            txt = `Force run failed.`;
        await alertDialog.Inform(txt);
        if (res === true)
            onClose();
    }
    const viewJobDetail = async (row) => {
        clickJobDetail(row);
    };
    const viewPlanHistory = async (row) => {
        clickPlanHistory(resultData);
    };

    const useStyles = makeStyles((theme) => ({
        heading: {
            fontSize: theme.typography.pxToRem(16),
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.text.secondary,
        },
    }));
    const classes = useStyles();
    const captionText = () => {
        return `Plan Detail` + (data?.INSTANCE_ID === undefined ? '' : ' of Result Dates: ' + ToDateText(data?.RESULT_DATE));
    }

    return (
        <>
            <Modal {...props} title={captionText()} hiddenSave maxWidth="xl" className="headerModalPlanDetail">
                <Box p={1} className="boxTableDetail">
                    <Table className="tableDetail">
                        <colgroup>
                            <col style={{ width: "150px" }} />
                            <col style={{ width: "auto" }} />
                        </colgroup>
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row" className="tdHeaderRow">
                                    <Typography className={classes.heading}>
                                        {titleCase("Plan")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.secondaryHeading}>
                                        : {resultData?.PLAN_TYPE_TEXT} - {resultData?.PLAN_ID} - {resultData?.PLAN_NAME}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row" className="tdHeaderRow">
                                    <Typography className={classes.heading}>
                                        {titleCase("OWNER")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.secondaryHeading}>
                                        : {resultData?.OWNER}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row" className="tdHeaderRow">
                                    <Typography className={classes.heading}>
                                        {titleCase("SCHEDULE")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.secondaryHeading}>
                                        : {resultData?.SCHEDULE_TEXT}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row" className="tdHeaderRow">
                                    <Typography className={classes.heading}>
                                        {titleCase("VALIDATION_KEY")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.secondaryHeading}>
                                        : {resultData?.VALIDATION_KEY}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row" className="tdHeaderRow">
                                    <Typography className={classes.heading}>
                                        {titleCase("Execution Status")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.secondaryHeading}>
                                        : {
                                            ToDateTimeText(resultData?.STATUS_DATETIME) + ', ' +
                                            resultData?.EXECUTION_TYPE + ', ' +
                                            resultData?.STATUS_TEXT
                                        }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row" className="tdHeaderRow">
                                    <Typography className={classes.heading}>
                                        {titleCase("TAGS")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.secondaryHeading}>
                                        : {resultData?.TAGS || ""}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                <Box display="block" bgcolor="background.paper">
                    <ReactTable
                        keyField="JOB_GUID"
                        data={resultData.JobDetails || []}
                        columns={getColumPlanDetail(viewJobDetail)}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-end" className="btnstyle">
                    {data?.STATUS_ID === 2 || data?.STATUS_ID === 103 ?
                        <Button variant="contained" onClick={forcerun} className="btn-large">
                            Force Run
                        </Button>
                        : null}
                    <Button variant="contained" onClick={dependencies} className="btn-large">
                        View Dependencies
                    </Button>
                    <Button variant="contained" onClick={viewPlanHistory} className="btn-large">
                        View Execution History
                    </Button>
                </Box>
            </Modal>
            <ModalDependencies
                open={isOpen}
                onClose={close}
                data={Data}
            />
            <ModalJobHistory
                open={isOpenJobDetail}
                onClose={closeJobDetail}
                data={jobDetail}
            />
            <ModalPlanHistory
                open={isOpenPlanHistory}
                onClose={closePlanHistory}
                data={planHistory}
            />
        </>
    );
}

export default ModalPlanDetail;
