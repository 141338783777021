import axios from "axios";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { useOpenDialog2 } from "apps/main/function-api/useOpenDialog";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { getToday } from "utils/date";
import { inform_success, inform_error, ConfirmCommon } from "utils/alert";
import { isEmptyStr } from "utils/common-function";

export const api_GetTargetTableList = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/GetTargetTableList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_DeleteTargetTable = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/DeleteTargetTable`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export function useTargetTable({ filter }) {
  const [TargetTableList, setTargetTableList] = useState([]);
  const formMethods = useForm({ defaultValues: { datadate: getToday() } });

  //state here
  const controlDialogDetail = useSelectRowOpenModal();
  const controlDialogTemplate = useOpenDialog2();

  const state = { TargetTableList, controlDialogDetail, controlDialogTemplate };
  const GetTargetTableList = useCallback(async () => {
    //call api
    let param = {
      group_users: filter.GroupUser,
      group_reports: filter.GroupReport,
    };
    let [res, err] = await api_GetTargetTableList(param);
    if (err) return;
    setTargetTableList(res);
  }, [filter]);
  useEffect(() => {
    GetTargetTableList();
  }, [GetTargetTableList]);
  //function here

  const actions = {
    addRow: async () => {
      controlDialogDetail.handleClickRow();
    },
    viewRow: async (row) => {
      controlDialogDetail.handleClickRow(row);
    },
    deleteRow: async (row) => {
      if (!(await ConfirmCommon("Confirm Delete"))) return;
      let [res, err] = await api_DeleteTargetTable({
        table_id: row.table_id,
      });
      if (err) return;
      if (res > 0) {
        inform_success("Deleted.");
        GetTargetTableList();
      } else {
        inform_error("Failed to delete");
      }
    },
    GetTargetTableList,
  };
  return {
    state,
    actions,
    formMethods,
  };
}
