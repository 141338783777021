import { Dialog, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { styleIconLink } from "utils/style";
export function KKPModal(props) {
  const { control: control_modal, children, title,maxWidth="lg", ...rest } = props;
  const {
    isOpen,
    close,
    open,
    onClose,
    toggle,
    Data,
    setData,
    handleClickRow,
  } = control_modal;
  return (
    <Dialog
      onClose={close}
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogContent dividers>
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="flex start"
          mb={2}
        ></Box>
        <Box name="header" display="flex">
          {title} <Box flexGrow={1}   />
          <CloseIcon sx={styleIconLink} onClick={close}/>
        </Box>
        <Box m={2}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
}
