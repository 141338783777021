import React, { useEffect, useState,useCallback } from "react";
import FormSelect from "../../hook-controls/select";
import { GetBuList } from "../../../module/master/api";
function ComboGroupBu(props) {
  const [Data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      let data = await GetBuList();
      const cbo= data&&data.map(({ GROUP_BU: label, GROUP_BU: id }) =>({ label, id }));
      setData(cbo);
    })();
  }, [setData]);

  return (
    <>
      <FormSelect {...props} options={Data} />
    </>
  );
}

export default ComboGroupBu;