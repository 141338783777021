import { titleCase } from "utils/common-function";
import { styleTextLink } from "utils/style";
export const getColumnsImport = (viewDetail = () => {}) => {
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      //viewDetail(row);
    },
  };
  const columns = [
    {
      dataField: "index",
      text: titleCase("No."),
    },
    {
      dataField: "StreamName",
      text: titleCase("STREAM_NAME"),
     // style: styleTextLink,
    },
    {
      dataField: "ORIGIN",
      text: titleCase("ORIGIN"),
    },
    {
      dataField: "Action",
      text: titleCase("Action"),
    },
    {
      dataField: "Status",
      text: titleCase("Status"),
    },
    // {
    //   dataField: "Status",
    //   text: titleCase("Status"),
    // },
    {
      dataField: "Message",
      text: titleCase("Message"),
    },
  ];
  var foundIndex = columns.findIndex((x) => x.dataField === "StreamName");
  columns[foundIndex] = { ...columns[foundIndex], events: onClickCol };
  return columns;
};
