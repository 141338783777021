// import { MenuContextProvider } from "../apps/main/portal/menu-context";
// //Context Entire App
// export default function MainProvider({ children }) {
//   return <MenuContextProvider>{children}</MenuContextProvider>;
// }

import { UserContextProvider } from "apps/job-monitoring/user/user-context";
import { MenuContextProvider } from "apps/main/portal/menu-context";
import { JobMonitoringContextProvider } from "apps/job-monitoring/job-monitoring/job-monitoring-context";
import { ExecutionPlanContextProvider } from "apps/job-monitoring/job-execution-plan/job-execution-plan-context";
import { CriteriaContextProvider } from "apps/job-monitoring/job-execution-plan/criteria-context";
import { CaptionFieldContextProvider } from "apps/data-catalog/caption-field-context/caption-field-context";
export default function MainProvider({ children }) {
  return (
    <UserContextProvider>
      <MenuContextProvider>
        <JobMonitoringContextProvider>
          <ExecutionPlanContextProvider>
            <CriteriaContextProvider>
              <CaptionFieldContextProvider>
                {children}
              </CaptionFieldContextProvider>
            </CriteriaContextProvider>
          </ExecutionPlanContextProvider>
        </JobMonitoringContextProvider>
      </MenuContextProvider>
    </UserContextProvider>
  );
}
