import {
  createContext,
  useEffect,
  useState,
  useContext,
  useReducer,
} from "react";
import moment from "moment";
import { getMenu, GetModulesHelper } from "./api";
import { MergeArrayByKey, isEmptyArray } from "utils/common-function";
import { protect_route } from "./../../../template/ProtectedRoutes";
const getActions = (dispatch) => {
  return {
    GetMenuList: async (param) => {
      let data = await getMenu();
      if (data) {
        data.forEach((el) =>
          el.ModuleGroups.forEach((el) => {
            let menu = MergeArrayByKey(
              el.Modules,
              "MODULE_NAME",
              protect_route,
              "ScreenName"
            );
            el.Modules = menu;
          })
        );
        dispatch({ type: action_type.SetMenuData, data });
      }
    },
    getHelperLink: (HelperName, HelperList) => {
      let res = HelperList?.find((el) => el.HELPER_NAME === HelperName)
        ?.HELPER_LINK;
      return res;
    },
    GetHelperList: async () => {
      let data = await GetModulesHelper();
      if (data) {
        dispatch({ type: action_type.SetHelperList, data });
      }
    },
  };
};
//State
const initial_state = {
  MenuList: [],
  HelperList: [],
};
//1.Context
export const MenuContext = createContext({
  state: initial_state,
  actions: getActions(),
});
//2.Provider
export const MenuContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initial_state);
  const [actions] = useState(getActions(dispatch));
  //Method//
  return (
    <MenuContext.Provider value={{ state: state, actions: actions }}>
      {children}
    </MenuContext.Provider>
  );
};
export function withContext(Component) {
  return function (...props) {
    return (
      <MenuContextProvider>
        <Component {...props} />
      </MenuContextProvider>
    );
  };
}
//3.Reducer
export const action_type = {
  SetMenuData: "SetMenuData",
  SetHelperList: "SetHelperList",
};
const Reducer = (state = initial_state, action) => {
  switch (action.type) {
    case action_type.SetMenuData:
      return {
        ...state,
        MenuList: action.data,
      };
    case action_type.SetHelperList:
      return {
        ...state,
        HelperList: action.data,
      };
    default:
      break;
  }
  return state;
};
