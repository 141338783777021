import React, { useState, useEffect } from "react";
//Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//Component
import { ReactTable } from "../../../../stateless/react-table";
import { Modal } from "../../../../stateless/modal";
import ModalTalend from "./modal-job-talend";
//Function
import * as api from "../api";
import { buildParameter } from "../parameter";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import { titleCase } from "utils/common-function";
import { getColumJobHistory } from "../columns/popup-job-history";
import { styleBookmarkRow } from "utils/style";
import { el } from "date-fns/locale";

let { log: c } = console;
function ModalJobHistory(props) {
  const [resultData, setData] = useState([]);
  const { open, data } = props;
  const {
    isOpen: isOpenLog,
    close: closeLog,
    Data: dataParam,
    handleClickRow: clickViewLog,
  } = useSelectRowOpenModal();
  useEffect(() => {
    if (!open) return;
    (async () => {
      let res = await api.GetJobHistory({
        PLAN_ID: data?.PLAN_ID,
        JOB_GUID: data?.JOB_GUID,
        ITEM_ID: data?.ITEM_ID,
      });
      if (!res) return;
      res.History = res.History?.map(el=>({...el,ORIGIN:res.ORIGIN,JOB_ID:res.JOB_ID,JOB_GUID:res.JOB_GUID,JOB_NAME:res.JOB_NAME}))
      setData(res);
    })();
  }, [open, data]);

  const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
  }));
  const classes = useStyles();
  const rowStyle = (row, rowIndex) => {
    if (row.HISTORY_ID === data.HISTORY_ID) return styleBookmarkRow;
  };
  return (
    <>
      <Modal
        {...props}
        title={`Job Execution History`}
        hiddenSave
        maxWidth="md"
        className="headerModalJobHistoryDetail"
      >
        <Box p={1} className="boxTableDetail">
          <Table className="tableDetail">
            <colgroup>
              <col style={{ width: "150px" }} />
              <col style={{ width: "auto" }} />
            </colgroup>
            <TableBody>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("Job")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {resultData?.JOB_ID} - {resultData?.JOB_NAME}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("ORIGIN")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {resultData?.ORIGIN}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("JOB_TYPE")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {resultData?.TYPE_TEXT}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("PARAMETERS")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {resultData?.PARAMETERS}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box display="block" bgcolor="background.paper">
          <ReactTable
            rowStyle={rowStyle}
            keyField="HISTORY_ID"
            data={resultData.History || []}
            columns={getColumJobHistory(clickViewLog)}
          />
        </Box>
      </Modal>
      <ModalTalend open={isOpenLog} onClose={closeLog} data={dataParam} />
    </>
  );
}
export default ModalJobHistory;
