import { GetUser } from "utils/sessions";
import { GenerateFormDate } from "./api";
import { isEmptyStr } from "utils/common-function";
import { el } from "date-fns/locale";
import { ApiDateTimeFormat, ToDateText } from "utils/date";
import moment from "moment";
export const buildParameter = {
  GetExecutionPlanList: (formdata) => {
    let param = {
      DateFrom: moment(ToDateText(formdata.DateFrom)).format(ApiDateTimeFormat),
      DateTo: moment(ToDateText(formdata.DateFrom))
        .add(1, "day")
        .format(ApiDateTimeFormat),
    };
    return formdata.isChecked ? param : {};
  },

  GetPlanHistoryList: (formdata) => {
    let param = {
      dateFrom: formdata.dateFrom,
      dateTo: formdata.dateTo,
    };
    return param;
  },
  GetReprocessList: (formdata) => {
    let param = {
      dateFrom: formdata.dateFrom,
      dateTo: formdata.dateTo,
    };
    return param;
  },
  GetSBXPlanJobDetailList: (PlanDetail) => {
    return { SESSION_ID: PlanDetail?.SESSION_ID, PLAN_ID: PlanDetail?.PLAN_ID };
  },
  AddJobItems: (PlanDetail, JobList = []) => {
    return JobList?.map((el) => {
      return {
        SESSION_ID: PlanDetail.SESSION_ID,
        SESSION_OWNER: GetUser(),
        JOB_GUID: el.JOB_GUID,
      };
    });
  },
  AddPlanDependencies: (PlanDetail, PlanList = []) => {
    return PlanList?.map((el) => {
      return {
        SESSION_ID: PlanDetail.SESSION_ID,
        DEPENDS_ON_PLAN_ID: el.PLAN_ID,
      };
    });
  },
  SavePlanSchedule: (
    PlanDetail,
    ScheduleSpecDetail,
    boolScheduleDetail,
    formdata
  ) => {
    let param = {
      SESSION_ID: PlanDetail.SESSION_ID,
      SESSION_OWNER: GetUser(),
      Schedule: {
        ...ScheduleSpecDetail,
        SCHEDULE_SPEC_ID: PlanDetail.PLAN_SCHEDULE_ID,
        ...formdata,
        ...boolScheduleDetail,
        SCHEDULE_TYPE_ID: formdata.SCHEDULE_TYPE_ID,
        ONETIME_DATETIME: isEmptyStr(formdata.ONETIME_DATETIME)
          ? null
          : formdata.ONETIME_DATETIME,
        TIME_DATA: formdata.TIME_DATA,
        INTERVAL_MINUTES:
          formdata.isInterval && formdata.SCHEDULE_TYPE_ID !== 1
            ? parseInt(formdata.INTERVAL_MINUTES, 10)
            : null,
        INTERVAL_ENDTIME:
          formdata.isInterval && formdata.SCHEDULE_TYPE_ID !== 1
            ? formdata.INTERVAL_ENDTIME
            : null,
      },
    };
    return param;
  },

  SavePlanJobDetailSbx: (PlanDetail, JobDetail) => {
    JobDetail = {
      ...JobDetail,
      RETRY_COUNT: parseInt(JobDetail.RETRY_COUNT, 10),
      RETRY_WAIT: parseInt(JobDetail.RETRY_WAIT, 10),
    };
    return { ...PlanDetail, JobDetail: JobDetail };
  },
  GetPlanDependencies: (PlanDetail) => {
    let param = {
      SESSION_ID: PlanDetail.SESSION_ID,
      PLAN_ID: PlanDetail.PLAN_ID,
    };
    return param;
  },
  GetPlanDependenciesChain: (PlanDetail) => {
    let param = {
      PLAN_ID: PlanDetail.PLAN_ID,
      SESSION_ID: PlanDetail.SESSION_ID,
      RESULT_DATE: PlanDetail.RESULT_DATE,
    };
    return param;
  },
  SaveDataDate: (PlanDetail, formdata) => {
    let param = {
      ...PlanDetail,
      ...formdata,
      SESSION_ID: PlanDetail.SESSION_ID,
      DEPENDS_ON_PLAN_ID: PlanDetail.DEPENDS_ON_PLAN_ID,
      DEP_DATADATE_TYPE_ID: parseInt(formdata.DEP_DATADATE_TYPE_ID, 10),
      DEP_DATADATE_VALUE: parseInt(formdata.DEP_DATADATE_VALUE, 10) || 0,
    };
    return param;
  },
  RemovePlanDependencies: (PlanDetail, planid) => {
    let param = {
      SESSION_ID: PlanDetail.SESSION_ID,
      DEPENDS_ON_PLAN_ID: planid,
    };
    return param;
  },
  UpdateJobSequenceSbxParam: (PlanDetail = {}, JobTemplst = []) => {
    let ids = JobTemplst.map((el) => el.ITEM_ID);
    let param = {
      SESSION_ID: PlanDetail.SESSION_ID,
      SESSION_OWNER: GetUser(),
      ITEM_IDS: ids,
    };
    return param;
  },
  LoadReprocessPlanSetParam: (his_id, dtls) => {
    return {
      TARGET_HISTORY_ID: his_id,
      DependCalculateInfo: dtls,
    };
  },
  SaveReprocess: (header, formdata, schedule, Details, SelectedDetail) => {
    let dtls = Details?.filter((el) => SelectedDetail?.includes(el.PLAN_ID));
    dtls = dtls.map((el, idx) => ({
      REPROCESS_PLAN_ID: "",
      ITEM_ID: "",
      PLAN_ID: el.PLAN_ID + "",
      POSITION: el.POSITION + 0,
      PREVIOUS_EXECUTION_P_ID: el.PREVIOUS_EXECUTION_P_ID,
      PREVIOUS_EXECUTED_DATE: el.PREVIOUS_EXECUTED_DATE,
      PREVIOUS_STATUS_ID: el.PREVIOUS_STATUS_ID,
      PREVIOUS_RESULT_DATE: el.PREVIOUS_RESULT_DATE,
      FORCE_RESULT_DATE: el.FORCE_RESULT_DATE,
      EXECUTION_P_ID: "",
    }));

    let data = {
      Header: {
        ...formdata,
        EXCLUSIVE_RUN: true,
        CREATED_BY: GetUser(),
      },
      Schedule: {
        SCHEDULE_TYPE_ID: schedule.SCHEDULE_TYPE_ID,
        ONETIME_DATETIME: schedule.ONETIME_DATETIME,
      },
      Details: dtls,
    };
    return data;
  },
  GetSbxReprocessParam: (header) => {
    return { Reprocess_Id: header?.REPROCESS_PLAN_ID, User: GetUser() };
  },
  SubmitReprocessParam: (header, dtls) => {
    return {
      Reprocess_Id: header?.REPROCESS_PLAN_ID,
      User: GetUser(),
      InstanceIds: dtls?.map((el) => el.INSTANCE_ID),
    };
  },
  GetDailyPlanStatusList: (formdata) => {
    let param1 = {
      ScheduleDateFrom: formdata.ScheduledateFrom,
      ScheduleDateTo: formdata.ScheduledateTo,
    };
    let param2 = {
      ResultDateFrom: formdata.ResultdateFrom,
      ResultDateTo: formdata.ResultdateTo,
    };
    let mergeparam = formdata.choice === "1" ? param1 : param2;
    return mergeparam;
  },
  GetSelfServiceStatusList: (formdata) => {
    let param1 = {
      ScheduleDateFrom: formdata.ScheduledateFrom,
      ScheduleDateTo: formdata.ScheduledateTo,
    };
    let param2 = {
      ResultDateFrom: formdata.ResultdateFrom,
      ResultDateTo: formdata.ResultdateTo,
    };
    let mergeparam = formdata.choice === "1" ? param1 : param2;
    mergeparam = {...mergeparam,Owner :formdata.Owner}
    return mergeparam;
  },
  HoldPlans: (formdata, selected_lst) => {
    return {
      Hold: formdata.hold === "1",
      PlanIds: selected_lst?.map((el) => el.PLAN_ID),
      InstanceIds: selected_lst?.map((el) => el.INSTANCE_ID),
      Mix: selected_lst?.map((el) => ({
        INSTANCE_ID: el.INSTANCE_ID,
        PLAN_ID: el.PLAN_ID,
      })),
    };
  },
  InvalidatePlanParam: (formdata, ids) => {
    return {
      Invalid: formdata.Invalid === "1",
      InstanceIds: ids,
      IncludeImpacted: formdata.IncludeImpacted,
    };
  },
  SuccessPlanParam: (ids) => {
    return {
      InstanceIds: ids,
    };
  },
  CancelRunningPlanParam: (ids) => {
    return {
      InstanceIds: ids,
    };
  },
  BypassDependencyParam: (id, statusId) => {
    return {
      INSTANCE_ID: id,
      STATUS_ID: statusId,
    };
  },
  GetReprocessImpactedPlansParam: (header, Include0 = false) => {
    return {
      SESSION_ID: header.SESSION_ID,
      REPROCESS_PLAN_ID: header.REPROCESS_PLAN_ID,
      IncludePosition0: Include0,
    };
  },
  GetReprocessDependencyPlansParam: (header, Include0 = false) => {
    return {
      SESSION_ID: header.SESSION_ID,
      REPROCESS_PLAN_ID: header.REPROCESS_PLAN_ID,
      IncludePosition0: Include0,
    };
  },
  SaveReprocessDependencyPlansParam: (
    REPROCESS_PLAN_ID,
    Detail,
    selected,
    Include0 = false
  ) => {
    let res = Detail.map((el) => ({
      REPROCESS_PLAN_ID: REPROCESS_PLAN_ID,
      PLAN_ID: el.PLAN_ID,
      CHECKED: selected.includes(el.PLAN_ID),
    }));
    return res;
  },
  GetImpactedPlansParam: (instances = [], include0 = false) => {
    return { IncludePosition0: include0, InstanceIds: instances };
  },
  EditReprocess: (Reprocessdata) => {
    return {
      REPROCESS_PLAN_ID: Reprocessdata?.REPROCESS_PLAN_ID,
      SESSION_OWNER: GetUser(),
    };
  },
  GetSelfServiceExecutionList: (formdata) => {
    let param = {
      DateFrom: moment(ToDateText(formdata.DateFrom)).format(ApiDateTimeFormat),
      DateTo: moment(ToDateText(formdata.DateFrom))
        .add(1, "day")
        .format(ApiDateTimeFormat),
      Owner: formdata.Owner,
    };
    return formdata.isChecked ? param : { Owner: formdata.Owner };
  },
  
};
