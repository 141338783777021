import React from "react";
import PropTypes from "prop-types";
import "./button.css";
import MaterialButton from "@mui/material/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CancelIcon from "@material-ui/icons/Cancel";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { styled } from "@mui/material/styles";
/**
 * Primary UI component for user interaction
 */
export const Button = (props) => {
  return <MaterialButton variant="contained" size="small" {...props} />;
};
export const ButtonSearchTextBox = ({ long = false, ...props }) => {
  return (
    <MaterialButton
      variant="contained"
      style={{
        borderRadius: "50px",
        width: long ? "350px" : "150px",
        height: "100%",
        backgroundColor: "#594f74",
        color: "#fff",
      }}
      {...props}
    >
      {props.children || "Search"}
    </MaterialButton>
  );
};
export const ButtonView = (props) => {
  return (
    <MaterialButton
      variant="contained"
      style={{
        borderRadius: "5px",
        width: "80px",
        backgroundColor: "#594f74",
        color: "#fff",
      }}
      {...props}
    >
      <VisibilityIcon />
      {"View"}
    </MaterialButton>
  );
};
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#594f74",
  },
}));
export const ButtonDropdown = (props) => {
  const { children, count } = props;
  return (
    <MaterialButton
      variant="contained"
      style={{
        borderRadius: "50px",
        height: "100%",
        backgroundColor: "#fff",
        color: "#594f74",
      }}
      {...props}
    >
      <Box display={"flex"}>
        <Box mr={3} display={count ? "flex" : "none"}>
          <StyledBadge badgeContent={count} color="primary"></StyledBadge>
        </Box>
        <Box>{children}</Box>
        <Box>
          <ArrowDropDownIcon></ArrowDropDownIcon>
        </Box>
      </Box>
    </MaterialButton>
  );
};
export const ButtonLink = (props) => {
  const { children, count, ...rest } = props;
  return (
    <>
      <Box display={"flex"}>
        <Box>
          <Link href="#" {...rest}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="span"
              style={{ color: "#4E24BD" }}
            >
              {children}
            </Typography>
          </Link>
        </Box>
        <Box ml={2} display={count ? "flex" : "none"}>
          <StyledBadge badgeContent={count} color="primary">     </StyledBadge>
        </Box>
        <Box></Box>
      </Box>
    </>
  );
};
export const ButtonTag = (props) => {
  const { clickCancel, children, ...rest } = props;
  return (
    <MaterialButton
      variant="contained"
      style={{
        borderRadius: "50px",
        height: "100%",
        backgroundColor: "#988EB3",
        color: "#fff",
        cursor: "default",
      }}
      {...rest}
    >
      <Box display={"flex"}>
        <Box>{children}</Box>
        <Box ml={1}>
          <CancelIcon onClick={clickCancel}></CancelIcon>
        </Box>
      </Box>
    </MaterialButton>
  );
};
export const FooterButtonInbound = ({ children, ...props }) => (
  <MaterialButton
    {...props}
    variant="outlined"
    sx={{
      color: "#fff",
      borderRadius: "50px",
      borderColor: "#fff",
    }}
  >
    {children}
  </MaterialButton>
);
export const PurpleButton = ({ children, ...props }) => (
  <MaterialButton
    {...props}
    variant="outlined"
    sx={{
      bckgroundColor: "##695D89E5",
      borderRadius: "10px",
    }}
  >
    {children}
  </MaterialButton>
);
Button.propTypes = {
  onClick: PropTypes.func,
};
export default Button;
