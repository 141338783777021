import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import { FormProvider } from "react-hook-form";
import InboundCriteriaBox from "stateless/Inbound/Inbound-criteria-box";
import DataDateBox from "stateless/Inbound/datadate-box";
import { BaseContainer } from "stateless/base-container";
import { useReconcile } from "./function";
import { PurpleButton } from "stateless/button";
import { FilterManual, FilterShow } from "stateless/filterbar";
import useFilterbar from "stateless/filterbar/useFilterbar";
import { ToDateRangeText, ToDateText } from "utils/date";
import {
  GridFooterContainer,
  GridPagination,
  GridToolbar,
} from "@mui/x-data-grid";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { SelectTypeReconcile } from "stateless/selection-control/combo/inbound/select-type-reconcile";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
import { SelectReconcileReport } from "stateless/selection-control/combo/inbound/select-reconcile-report";
import { DynamicFilterList } from "../stateless/dynamic-filter/filter_list";
import { useDynamicFilter } from "../stateless/dynamic-filter/useDynamicFilter";
import { SelectReportGroupReconcile } from "stateless/selection-control/combo/inbound/select-report-group-reconcile";
import { SelectAreaReconcile } from "stateless/selection-control/combo/inbound/select-area-reconcile";
import { titleCase } from "utils/common-function";
import { getFormattedItemList } from "utils/componentUtils";

const filter_field_list = ["GroupUser", "Module", "GroupReport"];
const filter_field_silgle_list = ["DataDate"];
const formatter = [
  {
    field: "DataDate",
    handler: (val) => {
      return ToDateRangeText(ToDateText(val.from), ToDateText(val.to));
    },
  },
];
const default_filter = {
  GroupUser: [],
  Module: [],
  GroupReport: [],
};
function Index() {
  const controlFilter = useFilterbar({
    fieldList: filter_field_list,
    defaultValues: default_filter,
    fieldSingle: filter_field_silgle_list,
    Formatter: formatter,
  });
  const controlDynamicFilter = useDynamicFilter();
  const methods = useReconcile({
    filter: controlFilter.data,
    dynamicFilter: controlDynamicFilter.filterData,
  });
  const { actions, state, formMethods } = methods;
  const {
    w_reconcile_area,
    w_reconcile_report_group,
    w_reconcile_type,
    w_reconcile,
  } = state;
  let param_relate_select_box = {
    area_name:w_reconcile_area,
    report_group:w_reconcile_report_group,
    type_name:w_reconcile_type
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <InboundCriteriaBox>
          <DataDateBox onChange={actions.getReconcileResultList} dateHighlight={state.ReconcileDateList}/>
          <Box mx={1}></Box>
          <Box>
            <SelectAreaReconcile
              name="area_name"
              label="Area"
              sx={{ backgroundColor: "#fff", width: 200 }}
            />
          </Box>
          <Box mx={1}></Box>
          <Box>
            <SelectReportGroupReconcile
              name="report_group"
              label="Report Group"
              param = {param_relate_select_box}
              sx={{ backgroundColor: "#fff", width: 200 }}
            />
          </Box>
          <Box mx={1}></Box>
          
          <Box>
            <SelectReconcileReport
              label="Report"
              name="reconcile"
              param = {param_relate_select_box}
              sx={{ backgroundColor: "#fff", width: 200 }}
            />
          </Box>
        </InboundCriteriaBox>
        <BaseContainer>
          <Box display={"flex"}>
            <FilterManual
              {...controlFilter}
              groupReport
              //Search={() => GetUploadManualList()}
            />
          </Box>
          <Box my={1}>
            <FilterShow {...controlFilter} />
          </Box>
          <Box my={1}>
            <DynamicFilterList
              {...controlDynamicFilter}
              table_info={useMemo(
                () => ({ table_type: "reconcile", table_name: w_reconcile }),
                [w_reconcile]
              )}
            />
          </Box>
          <Table methods={methods} />
        </BaseContainer>
      </FormProvider>
    </>
  );
}

function Table({ methods }) {
  const { viewError, download, downloadMultiple } = methods.actions;
  const { ReconcileResultList, ReconcileColumnList } = methods.state;
  const columns = [
    {
      field: "id",
      headerName: "RN",
      minWidth: 100,
      valueGetter: (params) => {
        const index = ReconcileResultList.findIndex(
          (row) => row.id === params.id
        );
        return index + 1;
      },
    },
    
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={ReconcileResultList}
      customColumn={{
        all: ReconcileColumnList || ["id"],
        //custom_col: columns,
        date: ["edw_data_dt", "data_date"],
      }}
      getRowId={(row) => row.id}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "DOWNLOAD (.CSV)",
          disabled: false,
          buttonclick: download,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}

export default Index;
