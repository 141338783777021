import { titleCase } from "utils/common-function";

export const getColumPlanDetail = () => {
  const columns = [
    {
      dataField: "JOB_ID",
      text: titleCase("JOB_ID"),
      sort: true,
    },
    {
      dataField: "JOB_NAME",
      text: titleCase("JOB_NAME"),
      sort: true,
    },
    {
      dataField: "ORIGIN",
      text: titleCase("ORIGIN"),
      sort: true,
    }
  ];
  return columns;
};
