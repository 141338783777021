import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { GetUser } from "utils/sessions";
import { isEmptyStr, titleCase } from "utils/common-function";
import { ToDateTimeText, ToDateText } from "utils/date";
import { styleTextLink } from "utils/style";
export const getExecutionPlanColumns = (
  editPlan,
  deletePlan,
  viewDetail = () => {},
  executeplan = () => {}
) => {
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      viewDetail(row);
    },
  };
  let columns = [
    {
      dataField: "OP1",
      text: "",
      sort: false,
      csvExport: false,
      // events: onClickCol,
      formatter: (cell, row) => {
        return (
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            ml={1}
            mr={1}
          >
            <>
              <PlayArrowIcon
                color="action"
                //className={classes.icon}
                onClick={() => {
                  executeplan(row);
                }}
              />
              {row.SESSION_OWNER?.toUpperCase() === GetUser()?.toUpperCase() ||
              isEmptyStr(row.SESSION_OWNER) ? (
                <>
                  <EditIcon
                    color="action"
                    //className={classes.icon}
                    onClick={() => {
                      editPlan(row.PLAN_ID);
                    }}
                  />

                  <DeleteIcon
                    color="action"
                    //className={classes.icon}
                    onClick={() => {
                      deletePlan(row.PLAN_ID);
                    }}
                  />
                </>
              ) : null}
            </>
          </Box>
        );
      },
    },
    {
      dataField: "PLAN_ID",
      text: titleCase("PLAN_ID"),
      sort: true,
      //
    },

    {
      dataField: "PLAN_NAME",
      text: titleCase("PLAN_NAME"),
      sort: true,
      // events: onClickCol,
      style: styleTextLink,
    },
    {
      dataField: "OWNER",
      text: titleCase("OWNER"),
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "TYPE_TEXT",
      text: titleCase("Plan Type"),
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "STATUS_TEXT",
      text: titleCase("Plan Status"),
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "SCHEDULE_TEXT",
      text: titleCase("Schedule"),
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "DATADATE_TYPE_TEXT",
      text: titleCase("Datadate Type"),
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "LAST_FINISHED",
      text: "Last Finished",
      sort: true,
      // events: onClickCol,
      formatter: (cell, row) => {
        return <>{ToDateTimeText(row.LAST_FINISHED)}</>;
      },
      csvFormatter: (col) => ToDateTimeText(col),
    },
    {
      dataField: "NEXT_EXECUTED",
      text: "Scheduled Date",
      sort: true,
      // events: onClickCol,
      formatter: (cell, row) => {
        return <>{ToDateTimeText(row.NEXT_EXECUTED)}</>;
      },
    },
    {
      dataField: "NEXT_RESULT_DATE",
      text: "Next Result Date",
      sort: true,
      // events: onClickCol,
      formatter: (cell, row) => {
        return <>{ToDateText(row.NEXT_RESULT_DATE)}</>;
      },
    },
    {
      dataField: "ENABLED",
      text: "Enabled",
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "TAGS",
      text: "TAGS",
      hidden: true,
      // events: onClickCol,
    },
  ];
  var foundIndex = columns.findIndex((x) => x.dataField === "PLAN_NAME");
  columns[foundIndex] = { ...columns[foundIndex], events: onClickCol };
  return columns;
};
