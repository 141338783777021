import { List, ListItem } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
export const getFormattedItemList = (param) => {
    if (!param.value) {
      return null;
    }
    const arrays = param.value.split(",");
    const formattedGroups = arrays.map((item) => (
      <ListItem key={item.trim()}>
        <CircleIcon sx={{ fontSize: 6, marginRight: "5px" }} /> {item.trim()}
      </ListItem>
    ));
  
    return <List>{formattedGroups}</List>;
  };