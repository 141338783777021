import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

//Material
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
//Component
import FormInput from "stateless/hook-controls/input";
import FormCheckBox from "stateless/hook-controls/checkbox";
import { SelectJobMaster } from "stateless/selection-control/combo/select-job-master";
import { SelectOriginList } from "stateless/selection-control/combo/select-origin";
import { Modal } from "stateless/modal";
//Function
import { GetUser } from "utils/sessions";
import * as alertDialog from "utils/alert";
import * as api from "../../../job-master/api";
// Utils
import {
  isEmptyStr,
  isEmptyObj,
  setDataToFormHookV2,
} from "utils/common-function";
// Context

function SelfServiceJobMaster({ controlDialog, refreshList }, props) {
  const [mode, setMode] = useState();
  const { isOpen, Data, close } = controlDialog;

  const method = useForm({
    defaultValues: {},
  });
  const { setValue, getValues, setError, clearErrors, errors, reset } = method;

  const editMode = mode === "Edit";

  const buildParameter = {
    SaveDetail: (JOB_ID, JOB_GUID, formdata) => {
      let param = {
        JOB_ID: JOB_ID,
        ORIGIN: formdata.ORIGIN,
        JOB_GUID: JOB_GUID,
        JOB_NAME: formdata.JOB_NAME,
        JOB_TYPE_ID: formdata.JOB_TYPE_ID,
        PARAMETERS: formdata.PARAMETERS,
        ACTIVE: formdata.ACTIVE,
        SESSION_OWNER: GetUser(),
      };
      return param;
    },
  };

  //Effect
  useEffect(() => {
    if (!isOpen) return;
    reset();
    if (!isEmptyObj(Data)) {
      setMode("Edit");
    } else {
      setMode("Add");
      setValue("ACTIVE", true);
    }
  }, [isOpen, Data, reset, setValue]);

  useEffect(() => {
    if (isEmptyObj(Data)) return;
    let hook = setDataToFormHookV2(Data);
    hook?.forEach(({ name, value }) => setValue(name, value));
  }, [setValue, Data]);

  const SaveDetail = async () => {
    const JOB_ID = editMode ? Data.JOB_ID : 0;
    const JOB_GUID = editMode ? Data.JOB_GUID : null;
    let param = buildParameter.SaveDetail(JOB_ID, JOB_GUID, getValues());

    clearErrors("ORIGIN");
    clearErrors("JOB_NAME");
    clearErrors("JOB_TYPE_ID");

    if (isEmptyStr(param.ORIGIN)) {
      setError("ORIGIN", {
        type: "manual",
        message: "The Origin field is required",
      });
      return;
    }

    if (isEmptyStr(param.JOB_NAME)) {
      setError("JOB_NAME", {
        type: "manual",
        message: "The Job Name field is required",
      });
      return;
    }

    if (isEmptyObj(param.JOB_TYPE_ID)) {
      setError("JOB_TYPE_ID", {
        type: "manual",
        message: "The Job type field is required",
      });
      return;
    }

    let checkdup = await api.CheckDulplicateOriginByJobID(param);
    if (checkdup > 0) {
      setError("ORIGIN", {
        type: "manual",
        message: "The Origin is Duplicate.",
      });
      return;
    }

    let res = await api.SaveJobMaster(param);
    if (res) {
      refreshList();
      close();
      alertDialog.inform_success();
    }
  };

  //Style
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
  }));
  const classes = useStyles();

  // Props
  const inputProps = {
    readOnly: editMode ? true : false,
  };

  return (
    <>
      <FormProvider {...method}>
        <Modal
          {...props}
          disableEnforceFocus
          open={isOpen}
          onClose={close}
          maxWidth="md"
          onSave={SaveDetail}
          title={`${mode} Job Master`}
        >
          <div className={classes.root}>
            <Grid container spacing={1}>
              {editMode && (
                <Grid item xs={12}>
                  <Box m={1}>
                    <FormInput
                      name="JOB_ID"
                      label="Job ID"
                      variant={"filled"}
                      required={false}
                      InputProps={inputProps}
                      errorobj={errors}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box m={1} mb={1.5}>
                  <SelectOriginList required name="ORIGIN" label="Origin" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={1}>
                  <FormInput
                    name="JOB_NAME"
                    label="Job Name"
                    variant={editMode ? "filled" : ""}
                    required={editMode ? false : true}
                    InputProps={inputProps}
                    errorobj={errors}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={1}>
                  <SelectJobMaster
                    required
                    name="JOB_TYPE_ID"
                    label="Job Type"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={1} mb={5}>
                  <FormInput
                    name="PARAMETERS"
                    label="Parameters"
                    multiline
                    rows={3}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <FormCheckBox key="ACTIVE" name="ACTIVE" label="Active" />
                </Box>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </FormProvider>
    </>
  );
}
export default SelfServiceJobMaster;
