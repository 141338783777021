import { styleTextLink } from "utils/style";
import { ToDateTimeText } from "utils/date";
export const getExecutionPlanColumns = (
  viewJobDetail = () => {},
  viewPlanDetail = () => {}
) => {
  const onClickColJob = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      viewJobDetail(row);
    },
  };
  const onClickColPlan = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      viewPlanDetail(row);
    },
  };
  const columns = [
    {
      dataField: "STATUS_DATETIME",
      text: "Status DateTime",
      sort: true,
      // events: onClickCol,
      formatter: (cell, row) => {
        return <>{ToDateTimeText(row.STATUS_DATETIME)}</>;
      },
    },
    {
      dataField: "STATUS_TEXT",
      text: "Job Status",
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "JOB_NAME",
      text: "Job Name",
      sort: true,
      // events: onClickCol,
      style: styleTextLink,
    },
    {
      dataField: "ORIGIN",
      text: "Job Origin",
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "JOB_ID",
      text: "Job Id",
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "TYPE_TEXT",
      text: "Job Type",
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "EXECUTION_ID",
      text: "Job Execution Id",
      sort: true,
      style: { minWidth: "300px" },
      // events: onClickCol,
    },
    {
      dataField: "PARAMETERS",
      text: "Parameters",
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "MESSAGE",
      text: "Message",
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "PLAN_ID",
      text: "Plan Id",
      sort: true,
      // events: onClickCol,
      style: styleTextLink,
    },
    {
      dataField: "EXECUTION_P_ID",
      text: "Plan Execution Id",
      sort: true,
      // events: onClickCol,
    },
  ];

  var foundIndexJOBName = columns.findIndex((x) => x.dataField === "JOB_NAME");
  columns[foundIndexJOBName] = {
    ...columns[foundIndexJOBName],
    events: onClickColJob,
  };

  var foundIndexPlanName = columns.findIndex(
    (x) => x.dataField === "PLAN_ID"
  );
  columns[foundIndexPlanName] = {
    ...columns[foundIndexPlanName],
    events: onClickColPlan,
  };

  return columns;
};
