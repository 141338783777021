//Material
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
//Function
import { GetUser } from "utils/sessions";
import { isEmptyStr, titleCase } from "utils/common-function";
import { formatDate, formatDateTime } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import { styleTextLink } from "utils/style";
export const getColumn = (clickViewPlan = () => { }, clickEdit = () => { },editMode=false) => {
    let columns = [
        // {
        //     isDummyField: true,
        //     dataField: "",
        //     text: "",
        //     sort: false,
        //     // events: onClickCol,
        //     formatter: (cell, row, rowIndex) => {
        //         if(editMode) return
        //         return (
        //             <>
        //                 <EditIcon
        //                     onClick={() => {
        //                         clickEdit(row);
        //                     }}
        //                 />
        //             </>
        //         );
        //     },
        // },
        {
            dataField: "FORCE_RESULT_DATE",
            text: titleCase("FORCE_RESULT_DATE"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateText(row.FORCE_RESULT_DATE)}</>;
            },
        },
        {
            dataField: "PLAN_NAME",
            text: titleCase("PLAN_NAME"),
            sort: true,
            style: styleTextLink,
            events: {
                onClick: async (e, column, columnIndex, row, rowIndex) => {
                    clickViewPlan(row);
                },
            },
        },
        {
            dataField: "RESULT_DATE",
            text: titleCase("RESULT_DATE"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateText(row.RESULT_DATE)}</>;
            },
        },
        {
            dataField: "STATUS_TEXT",
            text: titleCase("LAST STATUS"),
            sort: true,
        },
        {
            dataField: "LAST_EXECUTION_TYPE",
            text: titleCase("LAST EXECUTION TYPE"),
            sort: true,
        },
        {
            dataField: "LAST_EXECUTED",
            text: titleCase("Last Executed"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateTimeText(row.LAST_EXECUTED)}</>;
            },
        },
        {
            dataField: "LAST_FINISHED",
            text: titleCase("LAST_FINISHED"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateTimeText(row.LAST_FINISHED)}</>;
            },
        },
        {
            dataField: "VALIDATION_KEY",
            text: titleCase("Process Name"),
            sort: true,
        },
        {
            dataField: "MESSAGE",
            text: titleCase("MESSAGE"),
            sort: true,
        },
    ];
    return columns;
};