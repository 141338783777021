import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Button } from "stateless/button";
// material
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  typography: {
    color: "#594f74",
  },
  paper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    height: "44px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));

export default function CustomizedInputBase(props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    label,
    buttonText = "View",
    defaultValue = "",
    onClick = () => {},
    ...rest
  } = props;
  const { name } = rest;

  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Box sx={{ justifyContent: "center", flex: "0 0 auto" }} mr={1}>
          <Typography variant="caption" className={classes.typography}>
            <b>{label} :</b>
          </Typography>
        </Box>
        <Paper className={classes.paper}>
          <Controller
            control={control}
            defaultValue={defaultValue}
            {...rest}
            render={({ field }) => {
              return (
                <InputBase
                  readOnly
                  {...field}
                  {...rest}
                  className={classes.input}
                  onClick={onClick}
                  inputProps={{
                    "aria-label": label,
                    style: { cursor: "pointer" },
                  }}
                />
              );
            }}
          />
          <Button
            variant="outlined"
            onClick={onClick}
            style={{
              color: "#ffffff",
              backgroundColor: "#594f74",
              borderColor: "#594f74",
            }}
            startIcon={<VisibilityRoundedIcon style={{ color: "#ffffff" }} />}
          >
            {buttonText}
          </Button>
        </Paper>
      </Box>
    </>
  );
}
