import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { isEmptyArray } from "utils/common-function";
import { ToDateText, ToDateTextNoDash, getToday } from "utils/date";
import { DownloadFileCsv, DownloadFileExcel } from "utils/file";
const { log } = console;
export const api_GetValidationList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetValidationList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetValidationDetailList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetValidationDetailList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetValidationColumnlList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetValidationColumnlList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetValidationDateList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetValidationDateList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function useValidation({ filter }) {
  const controlSelect = useState([]);
  const [selectionModel, setSelectionModel] = controlSelect;
  const [ValidationList, setValidationList] = useState([]);
  const [ValidationDateList, setValidationDateList] = useState([]);
  const formMethods = useForm({ defaultValues: { datadate: getToday() } });
  const { getValues, control } = formMethods;
  const w_is_error = useWatch({ control, name: "is_error" });
  //state here
  const controlDialogError = useSelectRowOpenModal();
  const state = { ValidationList, controlDialogError,ValidationDateList };
  const getValidationList = useCallback(async () => {
    //call api
    let param = {
      data_dt: getValues().datadate,
      group_users: filter.GroupUser,
      modules: filter.Module,
      is_error: w_is_error,
    };
    let [res, err] = await api_GetValidationList(param);
    if (err) return;
    if(!isEmptyArray(res))
    {
      res = res.map((el,idx)=>({...el,id:idx}))
    }
    setValidationList(res);
  }, [getValues, filter, w_is_error]);

  useEffect(() => {
    getValidationList();
  }, [getValidationList]);
  useEffect(() => {
    (async ()=>{
      let [res, err] = await api_GetValidationDateList();
      if (err) return;
      setValidationDateList(res.map((el)=>ToDateText(el.data_dt)))
    })()
  }, [])
  
  //function here
  const download = async (row) => {
    let [res, err] = await api_GetValidationDetailList({
      table_name: row.validation_desciption,
      data_dt: row.data_dt,
      case_id: row.case_id,
    });
    if (isEmptyArray(res)) return;
    let file_name = `${row.validation_desciption}_${
      row.case_id
    }_${ToDateTextNoDash(row.data_dt)}.xlsx`;
    DownloadFileExcel(res, file_name);
  };
  const actions = {
    viewError: async (row) => {
      controlDialogError.handleClickRow(row);
    },
    download,
    downloadMultiple: async () => {
      let rows = ValidationList.filter((el) =>
        selectionModel.includes(el.template_name)
      );
      for (let index = 0; index < rows.length; index++) {
        const row = rows[index];
        download(row);
      }
    },
    getValidationList,
  };
  return {
    state,
    actions,
    formMethods,
    controlSelect,
  };
}
export function useValidationDetail({ Data }) {
  const [ValidationDetailList, setValidationDetailList] = useState([]);
  const [ValidationDetailColumn, setValidationDetailColumn] = useState([]);
  const GetValidationDetailList = useCallback(async (params) => {
    let [rescol, errcol] = await api_GetValidationColumnlList({
      table_name: params.validation_desciption,
    });
    if (errcol) return;
    setValidationDetailColumn(rescol.map((el) => el.COLUMN_NAME));
    let [res, err] = await api_GetValidationDetailList(params);
    if (err) return;
    setValidationDetailList(res);
  }, []);
  const state = { ValidationDetailList, ValidationDetailColumn };
  const actions = {
    GetValidationDetailList,
    download: () => {
      let file_name = `${Data.REPORT_NM}_${
        Data.VALIDATION_RULE
      }_${ToDateTextNoDash(Data.DATA_DT)}.xlsx`;
      DownloadFileExcel(ValidationDetailList, file_name);
    },
  };
  return { state, actions };
}
