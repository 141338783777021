import "date-fns";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import DateFnsUtils from "@date-io/date-fns";
import { useMaterialDate } from "./useMaterialDate";
import { dateTimeMuiformat } from "utils/date";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
export function MaterialUIDateTimePickers(props) {
  const { name, label, onChange = () => {}, inputVariant = "outlined" } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { handleDateTimeChange, values } = useMaterialDate(name);
  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { ref, onChange, value, ...rest } }) => (
          <>
            <KeyboardDateTimePicker
              {...rest}
              label={label}
              autoOk
              ampm={false}
              size="small"
              style={{ height: "39px", marginTop: "5px" }}
              inputVariant={inputVariant}
              format={dateTimeMuiformat}
              value={values}
              onChange={(e) => {
                onChange(e);
                handleDateTimeChange(e);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              inputRef={ref}
              error={isError}
              helperText={errorMessage}
            />
          </>
        )}
      />
    </MuiPickersUtilsProvider>
  );
}
