import React, { useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import { DropzoneDialog } from "material-ui-dropzone";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
//Component
import { Modal } from "stateless/modal";
import { Button } from "stateless/button";
import { ReactTable } from "stateless/react-table";
import { CMDataGrid } from "stateless/data-grid";
//Utils
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal.js";
import { common } from "../../../utils";
import { API_URL } from "../../../utils/api-utils";
import { MuiDateTimeToApi } from "utils/date";
import { titleCase, isEmptyArray, isEmptyStr } from "utils/common-function";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import { ToDateText, ToDateTimeText } from "utils/date";
import { CreateMuiColumnObj } from "utils/mui-table";
import { CaptionFieldContext } from "../caption-field-context/caption-field-context";
import { TemplateID } from "../utils/constants";
import { SortDetailTemplate2 } from "../utils/function";

const { log } = console;
//Const
const db_col = [
  "pdm_column_name",
  "pdm_datatype",
  "pdm_key",
  "pdm_field_definition",
  "pdm_sample",
  "kde",
  "possible_value",
  "reserve1st",
  "reserve2nd",
  "reserve3th",
  "reserve_date",
  "pdm_remark",
  "src_schema",
  "src_table_name_file_name",
  "src_alias",
  "src_column",
  "src_datatype",
  "src_remark",
  "trans_mapping_ref_name",
  "trans_business_rule",
  "trans_join_condition",
  "trans_remark",
  "update_inf_updated_date",
  "update_inf_updated_by",
  "update_inf_review",
  "update_inf_remark",
  "is_union",
  "union_desc",
];
const db_col_template2 = [
  "fm_sn",
  "format_type",
  "fm_format",
  "fm_fieldname_source",
  "fm_nullable",
  "fm_remarks",
  "fm_description",
  "fm_data_type",
  "fm_sample",
  "fm_pk",
  
];
const date_col = ["update_inf_updated_date", "reserve_date"];
const LoadDetail = async (data) => {
  let url = `${API_URL.Report}/api/Report/LoadDataPropertyList`;
  let { result } = await common.to(axios.post(url, data));
  if (result && result.data) return result.data;
};
function PopupDetail(props) {
  const { data, open } = props;
  const history = useHistory();
  const context = useContext(CaptionFieldContext);

  let db_col_dict = context.state.FieldDictionary?.filter(
    (el) => el.table_name === "detail_template2"
  ).map((el) => el.field);
  const [Data, setData] = useState([]);
  let db_col_dynamic = db_col;
  if (data?.template_id === TemplateID.template2){
    db_col_dynamic = db_col_template2;
  }
    

  useEffect(() => {
    if (!open) return;
    (async () => {
      let res = await LoadDetail(data);
      if(data?.template_id === TemplateID.template2){
        res = SortDetailTemplate2(res)
      }
      setData(res);
    })();
  }, [data, open]);
  const col = useMemo(() => {
    let custom_col = [
      {
        field: "is_union",
        type: "boolean",
      },
    ];
    return CreateMuiColumnObj(db_col_dynamic, [], custom_col);
  }, [db_col_dynamic]);
  return (
    <>
      <Modal maxWidth="md" title="Data Property List" hiddenSave {...props}>
        {/* <Button onClick={toggle}>Download</Button> */}
        <CMDataGrid
          rows={Data || []}
          getRowId={(row) => row.data_property_id}
          customColumn={col}
        />
      </Modal>
    </>
  );
}

export default PopupDetail;
