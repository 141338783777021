import axios from "axios";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
//Master
export const GetListRoleMaster = async (param) => {
  let url = `${API_URL.Lobby}/api/Portal/GetListRoleMaster`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const DeleteRole = async (param) => {
  let url = `${API_URL.Lobby}/api/Portal/DeleteRole`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const SaveRole = async (param) => {
  let url = `${API_URL.Lobby}/api/Portal/SaveRole`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const GetListModuleMaster = async (param) => {
  let url = `${API_URL.Lobby}/api/Portal/GetListModuleMaster`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
