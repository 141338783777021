import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
//Material
import FilterListIcon from "@material-ui/icons/FilterList";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
//stateless
import {
  ButtonDropdown,
  ButtonLink,
  ButtonSearchTextBox,
} from "stateless/button";
import {
  PopupManualTemplate,
  PopupManualStatus,
} from "stateless/selection-control/popup-checkbox";
//function
import {
  useOpenDialog,
  useOpenDialog2,
} from "apps/main/function-api/useOpenDialog";
import { padding } from "@mui/system";
import { isEmptyObj } from "utils/common-function";
import { PopupCheckboxInboundGroupUser } from "stateless/popup-checkbox/group_user";
import { PopupCheckboxInboundModule } from "stateless/popup-checkbox/module";
import { PopupCheckboxInboundGroupReport } from "stateless/popup-checkbox/group_report";
const { log } = console;
function FilterBrowse(props) {
  const { getFilterValue, clear, field = [], data } = props;
  const { groupUser = false, module = false, groupReport = false } = props;
  const { fieldsingle, dataSingle, getsingleFilterValue } = props;
  const { Search } = props;
  const control_PopupCheckboxInboundGroupUser = useOpenDialog2();
  const control_PopupCheckboxInboundModule = useOpenDialog2();
  const control_PopupCheckboxInboundGroupReport = useOpenDialog2();
  function checkHasFilter() {
    let has = false;
    for (let index = 0; index < field.length; index++) {
      if (data[field[index]]?.length > 0) {
        has = true;
        break;
      }
    }
    if (!isEmptyObj(dataSingle)) has = true;
    return has;
  }
  return (
    <>
      <Box display="flex" alignItems="center" name="filterbox">
        <Box display="flex" name="categorybox" justifyContent={"center"} mr={1}>
          <Box mr={1}>
            <FilterListIcon />
          </Box>
          <Box mr={1}>
            <Typography>Category</Typography>
          </Box>
          <Box>{checkHasFilter() ? <CancelIcon onClick={clear} /> : null}</Box>
        </Box>
        <Box className="lineVertical"></Box>
        {groupUser ? (
          <Box ml={1}>
            <ButtonDropdown
              count={data[field[0]]?.length}
              onClick={control_PopupCheckboxInboundGroupUser.clickOpen}
            >
              Group User
            </ButtonDropdown>
          </Box>
        ) : null}
        {module ? (
          <Box ml={1}>
            <ButtonDropdown
              count={data[field[1]]?.length}
              onClick={control_PopupCheckboxInboundModule.clickOpen}
            >
              Module
            </ButtonDropdown>
          </Box>
        ) : null}
        {groupReport ? (
          <Box ml={1}>
            <ButtonDropdown
              count={data[field[2]]?.length}
              onClick={control_PopupCheckboxInboundGroupReport.clickOpen}
            >
              Group Report
            </ButtonDropdown>
          </Box>
        ) : null}
      </Box>
      <PopupCheckboxInboundGroupUser
        {...control_PopupCheckboxInboundGroupUser}
        defaultSelected={data[field[0]]}
        name={field[0]}
        retriveData={(p_data) => {
          getFilterValue(field[0], p_data[field[0]]);
          control_PopupCheckboxInboundGroupUser.onClose();
        }}
      />
      <PopupCheckboxInboundModule
        {...control_PopupCheckboxInboundModule}
        defaultSelected={data[field[1]]}
        name={field[1]}
        retriveData={(p_data) => {
          getFilterValue(field[1], p_data[field[1]]);
          control_PopupCheckboxInboundModule.onClose();
        }}
      />
      <PopupCheckboxInboundGroupReport
        {...control_PopupCheckboxInboundGroupReport}
        defaultSelected={data[field[2]]}
        name={field[2]}
        retriveData={(p_data) => {
          getFilterValue(field[2], p_data[field[2]]);
          control_PopupCheckboxInboundGroupReport.onClose();
        }}
      />
    </>
  );
}
FilterBrowse.propTypes = {
  getFilterValue: PropTypes.func,
  field: PropTypes.array,
  clear: PropTypes.func,
};
export default FilterBrowse;
