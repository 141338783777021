import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
// Util
import { common } from "utils";
//Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//Component
import { Modal } from "../../../../stateless/modal";
import { ReactTable } from "../../../../stateless/react-table";
import { getColumPlanDetail } from "../columns/popup-plan-detail";
//Function
import { ToDateText, ToDateTimeText } from "utils/date";
import {
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyArray,
  titleCase,
} from "utils/common-function";
import * as alertDialog from "utils/alert";
//
function ModalViewPlanDetail(props) {
  const [ planData, setPlanData] = useState([]);
  const [ planDetailData, setDetailData] = useState([]);
  const { open, onClose, data } = props;

  useEffect(() => {
      if (!open) return;
      setPlanData(data?.Plan?.tnx_execution_plan);
      setDetailData(data?.Plan?.list_tnx_execution_plan_job_detail)
  }, [open, data]);

  const useStyles = makeStyles((theme) => ({
      heading: {
          fontSize: theme.typography.pxToRem(16),
      },
      secondaryHeading: {
          fontSize: theme.typography.pxToRem(16),
          color: theme.palette.text.secondary,
      },
  }));
  const classes = useStyles();
  const captionText = () => {
      return `Plan Detail`;
  }

  return (
      <>
          <Modal {...props} title={captionText()} hiddenSave maxWidth="md">
              <Box p={1} className="boxTableDetail">
                  <Table className="tableDetail">
                      <colgroup>
                          <col style={{ width: "150px" }} />
                          <col style={{ width: "auto" }} />
                      </colgroup>
                      <TableBody>
                          <TableRow>
                              <TableCell scope="row" className="tdHeaderRow">
                                  <Typography className={classes.heading}>
                                      {titleCase("Plan")}
                                  </Typography>
                              </TableCell>
                              <TableCell>
                                  <Typography className={classes.secondaryHeading}>
                                      : {planData?.PLAN_ID} - {planData?.PLAN_NAME}
                                  </Typography>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell scope="row" className="tdHeaderRow">
                                  <Typography className={classes.heading}>
                                      {titleCase("OWNER")}
                                  </Typography>
                              </TableCell>
                              <TableCell>
                                  <Typography className={classes.secondaryHeading}>
                                      : {planData?.OWNER}
                                  </Typography>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell scope="row" className="tdHeaderRow">
                                  <Typography className={classes.heading}>
                                      {titleCase("VALIDATION_KEY")}
                                  </Typography>
                              </TableCell>
                              <TableCell>
                                  <Typography className={classes.secondaryHeading}>
                                      : {planData?.VALIDATION_KEY}
                                  </Typography>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell scope="row" className="tdHeaderRow">
                                  <Typography className={classes.heading}>
                                      {titleCase("TAGS")}
                                  </Typography>
                              </TableCell>
                              <TableCell>
                                  <Typography className={classes.secondaryHeading}>
                                      : {planData?.TAGS || ""}
                                  </Typography>
                              </TableCell>
                          </TableRow>
                      </TableBody>
                  </Table>
              </Box>
              <Box display="block" bgcolor="background.paper">
                  <ReactTable
                      keyField="JOB_GUID"
                      data={planDetailData || []}
                      columns={getColumPlanDetail()}
                  />
              </Box>
          </Modal>
      </>
  );
}
export default ModalViewPlanDetail;
