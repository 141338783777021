import {
  createContext,
  useEffect,
  useState,
  useContext,
  useReducer,
} from "react";
import { update_array } from "utils/common-function";
import * as api from "../../api";
import { filterPlan } from "../../reducer";
const getActions = (dispatch) => {
  return {
    GetSelfServiceStatusList: async (param) => {
      let data = await api.GetSelfServiceStatusList(param);
      if (data) {
        dispatch({ type: action_type.SetSelfServiceStatusList, data });
      }
    },
    UpdateSelfServiceStatusList: async (data) => {
      if (data) {
        dispatch({ type: action_type.UpdateSelfServiceStatusList, data });
      }
    },
    FilteringSelfServiceStatus: async (param) => {
      dispatch({ type: action_type.FilteringSelfServiceStatus, param });
    },
  };
};
//State
const initial_state = {
  SelfServiceStatusList: [],
  SelfServiceStatusFilterList: [],
};
//1.Context
export const SelfServiceStatusContext = createContext({
  state: initial_state,
  actions: getActions(),
});
//2.Provider
export const SelfServiceStatusContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initial_state);
  const [actions] = useState(getActions(dispatch));
  //Method//
  return (
    <SelfServiceStatusContext.Provider value={{ state: state, actions: actions }}>
      {children}
    </SelfServiceStatusContext.Provider>
  );
};
export function withContext(Component) {
  return function (...props) {
    return (
      <SelfServiceStatusContextProvider>
        <Component {...props} />
      </SelfServiceStatusContextProvider>
    );
  };
}
//3.Reducer
export const action_type = {
  SetSelfServiceStatusList: "SelfServiceStatus_SetSelfServiceStatusList",
  UpdateSelfServiceStatusList: "SelfServiceStatus_UpdateSelfServiceStatusList",
  FilteringSelfServiceStatus: "SelfServiceStatus_FilteringSelfServiceStatus",
};
const Reducer = (state = initial_state, action) => {
  switch (action.type) {
    case action_type.SetSelfServiceStatusList:
      return {
        ...state,
        SelfServiceStatusList: action.data,
      };
    case action_type.UpdateSelfServiceStatusList:
      let newdata = update_array(
        state.SelfServiceStatusList,
        action.data,
        "INSTANCE_ID"
      );
      return { ...state, SelfServiceStatusList: newdata };
    case action_type.FilteringSelfServiceStatus:
      let filterd = filterPlan(state.SelfServiceStatusList, action.param);
      return {
        ...state,
        SelfServiceStatusFilterList: filterd,
      };
    default:
      break;
  }
  return state;
};
