import { Chip, List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";
import ArticleIcon from "@mui/icons-material/Article";
import { isEmptyArray } from "utils/common-function";
import FileSaver from "file-saver";
import moment from "moment";
import { Modal } from "stateless/modal";
export function ListFile({ FileList, RemoveFile }) {
  return (
    <List sx={style} component="nav" aria-label="mailbox folders">
      {FileList?.map((el) => (
        <ListItem key={el.name}>
          <ListItemText primary={el.name} />{" "}
          <DeleteIcon onClick={() => RemoveFile(el.name)} />
        </ListItem>
      ))}
    </List>
  );
}
export function ListFileImportResult({ ImportResult, view }) {
  return (
    <List sx={style} component="nav" aria-label="mailbox folders">
      {ImportResult?.map((el) => (
        <ListItem key={el.FileName}>
          <FileResult result={el} view={view} />
        </ListItem>
      ))}
    </List>
  );
}
function FileResult({ result, view }) {
  const style = result?.Fail ? { color: "red" } : { color: "green" };
  return (
    <>
      <ListItemText primary={result?.FileName} sx={style} />
      {result?.Fail ? (
        <Chip label="Fail" color="error" variant="outlined" />
      ) : (
        <Chip label="Success" color="success" variant="outlined" />
      )}
      <Box mr={1}></Box>
      <ArticleIcon
        sx={style}
        onClick={() => {
          view(result);
        }}
      />
    </>
  );
}
export function DialogImportResultDetail(props) {
    const { onClose, Data, ...rest } = props;
    return (
      <Modal
        title={Data?.FileName}
        SaveText="Download"
        onSave={() => {
          DownloadImportInformation(Data);
        }}
        maxWidth="sm"
        {...rest}
        onClose={onClose}
      >
        <ul>
          {Data?.Message.map((el, idx) => (
            <li key={idx}>{el}</li>
          ))}
        </ul>
      </Modal>
    );
  }
export function DownloadImportInformation(res) {
    if (isEmptyArray(res?.Message)) return;
    var file = new File(
      [res.Message?.join("\r\n")],
      `Import Data Object Information ${res.FileName} ${moment().format(
        "yyyyMMDDhhmmss"
      )}.txt`,
      {
        type: "text/plain;charset=utf-8",
      }
    );
    FileSaver.saveAs(file);
  }
const style = {
  width: "100%",
  //maxWidth: 360,
  bgcolor: "background.paper",
};
