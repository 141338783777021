import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useOpenDialog2 } from "apps/main/function-api/useOpenDialog";
//Material
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
//Component
import { SelectRoles } from "stateless/selection-control/combo/select-roles";
import { SelectUsers } from "stateless/selection-control/combo/select-users";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
import { PurpleButton } from "stateless/button";
import { Modal } from "stateless/modal";
//utils
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { inform_success, inform_error, ConfirmCommon } from "utils/alert";
import {
  titleCase,
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyObj,
  isEmptyArray,
} from "utils/common-function";

export function UserApproverDialog(props) {
  const {
    addApprover = () => {},
    name,
    approverList,
    onClose,
    clickOpen,
    toggle,
    ...rest
  } = props;

  const methods = useForm();
  const { getValues, reset, errors, control, clearErrors, setError } = methods;

  async function save() {
    clearErrors("user_id");

    let param = getValues();
    
    if (isEmptyStr(param.user_id)) {
      setError("user_id", {
        type: "manual",
        message: "Please select user.",
      });
      return;
    }
    addApprover(param);
    close();
  }

  function close() {
    reset();
    onClose();
  }

  const footer = (
    <>
      <Box display="flex" flexgrow={1} justifyContent={"flex-end"}>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveAltIcon />}
            onClick={save}
          >
            Add
          </Button>
        </Box>
        <Box m={1}>
          <Button variant="outlined" onClick={close}>
            Close
          </Button>
        </Box>
      </Box>
    </>
  );

  const w_role_id = useWatch({ control, name: "role_id" });
  let param_role = {
    role_id: w_role_id,
    user_id_list: approverList,
  };

  if (!props.open) return null;

  return (
    <>
      <Modal
        maxWidth="sm"
        onClose={close}
        title={titleCase(name)}
        hiddenSave
        plain
        hideFooterDivder
        ComponentFooter={footer}
        {...rest}
      >
        <FormProvider {...methods}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <SelectRoles name="role_id" label={titleCase("Roles")} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <SelectUsers
                  name="user_id"
                  label={titleCase("Users")}
                  param={param_role}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </Modal>
    </>
  );
}
