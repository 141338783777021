import Box from "@material-ui/core/Box";
//Material

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
//Function
import { GetUser } from "utils/sessions";
import { isEmptyStr, titleCase } from "utils/common-function";
import { formatDate, formatDateTime } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import { PLANSTATUS } from "../constants";
import { styleTextLink } from "utils/style";
export const getColumn = (
    clickView = () => { },
    editPlan = () => { },
    deletePlan = () => { }
) => {
    let columns = [
        {
            dataField: "OP1",
            text: "",
            sort: false,
            csvExport: false,
            // events: onClickCol,
            formatter: (cell, row) => {
                if (row.STATUS_ID !== PLANSTATUS.Idle) return <Box component="div" display="flex" justifyContent="center" width="50px"></Box>;
                return (
                    <Box component="div" display="flex" justifyContent="center" >
                        <>
                            {row.SESSION_OWNER?.toUpperCase() === GetUser()?.toUpperCase() ||
                                isEmptyStr(row.SESSION_OWNER) ? (
                                    <>
                                        <EditIcon
                                            color="action"
                                            //className={classes.icon}
                                            onClick={() => {
                                                editPlan(row);
                                            }}
                                        />

                                        <DeleteIcon
                                            color="action"
                                            //className={classes.icon}
                                            onClick={() => {
                                                deletePlan(row);
                                            }}
                                        />
                                    </>
                                ) : null}
                        </>
                    </Box>
                );
            },
        },
        {
            dataField: "CREATED_DATE",
            text: titleCase("CREATED_DATE"),
            sort: true,
            // events: onClickCol,
            formatter: (cell, row) => {
                return <>{ToDateText(row.CREATED_DATE)}</>;
            },
        },
        {
            dataField: "REPROCESS_NOTE",
            text: titleCase("REPROCESS_NOTE"),
            sort: true,
            style: styleTextLink,
            events: {
                onClick: async (e, column, columnIndex, row, rowIndex) => {
                    clickView(row.REPROCESS_PLAN_ID);
                },
            },
        },
        {
            dataField: "ACTUAL_START_DATETIME",
            text: titleCase("ACTUAL_START_DATETIME"),
            sort: true,
            formatter: (cell, row) => {
                return <>{ToDateTimeText(row.ACTUAL_START_DATETIME)}</>;
            },
        },
        {
            dataField: "STATUS_TEXT",
            text: titleCase("STATUS"),
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "MESSAGE",
            text: titleCase("MESSAGE"),
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "REPROCESS_BY",
            text: titleCase("REPROCESS_BY"),
            sort: true,
            // events: onClickCol,
        },
    ];
    return columns;
};
