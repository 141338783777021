import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
  Controller,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
//Material
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import EditIcon from "@material-ui/icons/Edit";
import { DataGrid } from "@mui/x-data-grid";
import Link from "@mui/material/Link";
//component
import { BaseContainer } from "stateless/base-container";
import { Button } from "stateless/button";
import FormInput from "stateless/hook-controls/input";
import SelectStatus from "stateless/selection-control/combo/datalist-status";
import PopupImport from "../screen-manage-object/popup-import";
import PopupImportProperty from "./popup-import-data-property";

import { CMDataGrid } from "stateless/data-grid";
import PopupSBXProperty from "./popup-data-sbx-property";
import { SubmitInformation } from "../utils/function";
//context

//utils
import { isEmptyArray, isEmptyStr, titleCase } from "utils/common-function";
import { inform_success, ConfirmCommon, Inform } from "utils/alert";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import axios from "axios";
import { API_URL } from "../../../utils/api-utils";
import { common } from "../../../utils";
import { ToDateText } from "utils/date";
import { useParams } from "react-router";
import { FlashOnRounded } from "@material-ui/icons";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal.js";
import { useOpenDialog } from "apps/main/function-api/useOpenDialog";
import { styleTextLink } from "utils/style";
//const

//api
export const LoadSbxDataPropertyGroupingList = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/LoadSbxDataPropertyGroupingList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const CancelDataProperty = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/CancelDataProperty`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_PublishMultipleDataProperty = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/PublishMultipleDataProperty`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
const { log } = console;
const db_col = [
  "status_text",
  "session_owner",
  "sheet_name",
  "pdm_schema",
  "pdm_table_name_file_name",
  "original_filename",
  "imported_timestamp",
];
const date_time_col =[
  "imported_timestamp",
]
//
//
//SCREEN
//
//
function Component() {
  const methods = useForm();
  const [selectionModel, setSelectionModel] = useState([]);
  const [SbxPropertyList, setSbxObjList] = useState([]);
  const { getValues, setValue } = methods;
  const { isOpen, close, handleClickRow, Data } = useSelectRowOpenModal();
  const [importPropertyOpen, Close, Open] = useOpenDialog();
  const custom_col = [
    {
      field: "pdm_table_name_file_name",
      headerName: "Data Object",
      renderCell: (params) => (
        <Link onClick={() => handleClickRow(params["row"])} href="#">
          {params.value}
        </Link>
      ),
    },
  ];

  const GetDataList = useCallback(async () => {
    let param = getValues();
    let [data, err] = await LoadSbxDataPropertyGroupingList(param);
    if (err) return;
    setSbxObjList(data);
  }, [getValues]);
  useEffect(() => {
    GetDataList();
  }, [GetDataList]);
  async function Publish() {
    if (isEmptyArray(selectionModel)) return;
    if (!(await ConfirmCommon("Confirm Publish"))) return;
    let param = { sbx_ids: selectionModel };
    let [res, err] = await api_PublishMultipleDataProperty(param);
    if (err) return;
    GetDataList();
    let selected_row = res.map((el) => el["status_text"]);
    SubmitInformation(selected_row);
  }
  function clickImport() {
    Open();
  }
  async function Delete() {
    if (isEmptyArray(selectionModel)) return;
    if (!(await ConfirmCommon("Confirm Delete"))) return;
    let param = selectionModel.map((el) => ({ sbx_id: el }));
    let [res, err] = await CancelDataProperty(param);
    if (err) return;
    GetDataList();
    inform_success("Deleted.");
  }
  return (
    <div>
      <BaseContainer>
        <FormProvider {...methods}></FormProvider>
        <Box my={1} display="flex" justifyContent={"flex-end"}>
          <Button onClick={clickImport}>Import Property</Button>
          <Box mx={1}></Box>
          <Button onClick={Publish}>Submit Property</Button>
          <Box mx={1}></Box>
          <Button onClick={Delete}>Delete Property (Draft)</Button>
        </Box>
        <CMDataGrid
          rows={SbxPropertyList || []}
          getRowId={(row) => row.sbx_id}
          customColumn={{ all: db_col, date_time:date_time_col,custom_col: custom_col }}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </BaseContainer>
      <PopupImportProperty
        open={importPropertyOpen}
        onClose={Close}
        success={GetDataList}
      />
      <PopupSBXProperty open={isOpen} onClose={close} data={Data} />
    </div>
  );
}

export default Component;
