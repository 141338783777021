import React, { useEffect, useState } from "react";
import axios from "axios";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { ButtonDropdown } from "stateless/button";
import { useCallback } from "react";
import { useOpenDialog2 } from "apps/main/function-api/useOpenDialog";
import { PopupDynamic } from "./dialog_filter";
export const api_GetFilterColumnList = async (param) => {
  let url = `${API_URL.Inbound}/api/Master/GetFilterColumnList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export function DynamicFilterList({
  getFilterValue,
  table_info,
  filterData,
  RefreshCurrentFilter,
}) {
  const [columnList, setcolumnList] = useState([]);
  const GetColumnDynamicList = useCallback(async () => {
    if (!table_info) return;
    let param = table_info;
    let [res, err] = await api_GetFilterColumnList(param);
    if (err) return;
    setcolumnList(res);
    RefreshCurrentFilter(res.map((el) => el.column_filter));
  }, [table_info, RefreshCurrentFilter]);
  useEffect(() => {
    GetColumnDynamicList();
  }, [GetColumnDynamicList]);

  return (
    <Box display="flex" alignItems="center" name="filterbox">
      {columnList?.map((el) => (
        <SingleFilter
          key={el.id}
          tableInfo={el}
          getFilterValue={getFilterValue}
          filterData={filterData}
        />
      ))}
    </Box>
  );
}
function SingleFilter({ tableInfo, getFilterValue, filterData }) {
  const control_Popup = useOpenDialog2();
  return (
    <>
      <Box ml={1}>
        <ButtonDropdown
          count={
            filterData?.hasOwnProperty(tableInfo.column_filter)
              ? filterData[tableInfo.column_filter].length
              : 0
          }
          onClick={control_Popup.clickOpen}
        >
          {tableInfo.column_filter}
        </ButtonDropdown>
      </Box>
      <PopupDynamic
        {...control_Popup}
        tableInfo={tableInfo}
        defaultSelected={
          filterData?.hasOwnProperty(tableInfo.column_filter)
            ? filterData[tableInfo.column_filter]
            : []
        }
        name={tableInfo.column_filter}
        retriveData={(p_data) => {
          getFilterValue(
            tableInfo.column_filter,
            p_data[tableInfo.column_filter]
          );
          //(data.column_filter, p_data[data.column_filter]);
          control_Popup.onClose();
        }}
      />
    </>
  );
}
