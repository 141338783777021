import React, { usesEffect } from "react";
import { RouteLayout, RouteLayout2 } from "./../template/RouteLayout";
//main / sample
import { Portal, PortalApps } from "apps/main/portal";
import { ExampleHookForm } from "apps/main/example";
//apps
import {
  DataObjectList,
  AddEditDataObject,
  Browse,
  SandBoxDataObject,
  SandBoxDataProperty,
} from "apps/data-catalog";
import {
  WebPortal,
  JobExecutionPlan,
  AddExecutionPlan,
  PlanHistory,
  Reprocess,
  ReprocessList,
  DailyPlanStatus,
  GroupExecution,
  GroupExecutionManagement,
  SelfServiceExecution,
  SelfServiceStatus,
  SelfServiceReprocess,
  AddSelfServiceExecution,
} from "apps/job-monitoring";
import { SystemConfig } from "apps/job-monitoring/system-config";
import { UserMaintenance } from "apps/job-monitoring/user-maintenace";
import { RoleMaintenance } from "apps/job-monitoring/role-maintenance";
import { JobTypeMaster } from "apps/job-monitoring/job-type-master";
import { ImportExport } from "apps/job-monitoring/import-export";
import { ImportExportPlan } from "apps/job-monitoring/import-export-plan";
import { JobMaster } from "apps/job-monitoring/job-master";
import { Dashboard } from "apps/job-monitoring/dashboard";
import { JobMonitoring } from "apps/job-monitoring/job-monitoring";
import {
  InboundAdjust,
  InboundApprove,
  InboundExport,
  InboundReconcile,
  InboundUpload,
  InboundValidation,
  ReconcileReportMaintenace,
  TargetTableMaintenace,
  TableFilterMaintenance,
} from "apps/inbound";

import { BuMaintenance } from "apps/job-monitoring/group-bu"
//public
const public_route = [
  {
    path: "/",
    component: PortalApps,
  },
  {
    path: "/test-form",
    component: ExampleHookForm,
  },
];
export const protect_route = [
  {
    path: "/JobMonitoring",
    component: Portal,
    ScreenName: "EDP Automation Job Controller and Monitoring", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "EDP Automation Job Controller and Monitoring",
  },
  {
    path: "/JobMonitoring/Job-Execution-Plan",
    component: JobExecutionPlan,
    ScreenName: "Execution Plan",
    HELPER_NAME: "Execution Plan",
  },
  {
    path: "/JobMonitoring/Execution-Plan",
    component: AddExecutionPlan,
    ScreenName: "Add Job ExecutionPlan",
    HELPER_NAME: "EditExecutionPlan",
  },
  {
    path: "/JobMonitoring/Daily-Plan-status",
    component: DailyPlanStatus,
    ScreenName: "Daily Plan Status",
    HELPER_NAME: "DailyPlanStatus",
  },
  {
    path: "/JobMonitoring/Job-Monitoring",
    component: JobMonitoring,
    ScreenName: "Job Status History", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "JobStatusHistory",
  },
  {
    path: "/JobMonitoring/Execution-Plan-History",
    component: PlanHistory,
    ScreenName: "Plan Status History",
    HELPER_NAME: "PlanStatusHistory",
  },
  {
    path: "/JobMonitoring/System-Config",
    component: SystemConfig,
    ScreenName: "System Configuration",
    HELPER_NAME: "SystemConfiguration",
  },
  {
    path: "/JobMonitoring/Reprocess",
    component: Reprocess,
    ScreenName: "Reprocess",
    HELPER_NAME: "Reprocess",
  },
  {
    path: "/JobMonitoring/GroupExecutionManagement",
    component: GroupExecutionManagement,
    ScreenName: "Group Execution Management",
    HELPER_NAME: "Group Execution Management",
  },
  {
    path: "/JobMonitoring/GroupExecution",
    component: GroupExecution,
    ScreenName: "Group Execution",
    HELPER_NAME: "Group Execution",
  },
  {
    path: "/JobMonitoring/ReprocessList",
    component: ReprocessList,
    ScreenName: "Reprocess List",
    HELPER_NAME: "ReprocessList",
  },
  {
    path: "/JobMonitoring/Jop-Type-Master",
    component: JobTypeMaster,
    ScreenName: "Job Type Master",
    HELPER_NAME: "JobTypeMaster",
  },
  {
    path: "/JobMonitoring/Import-Export",
    component: ImportExport,
    ScreenName: "Import Export",
    HELPER_NAME: "ImportExport",
  },
  {
    path: "/JobMonitoring/Job-Master",
    component: JobMaster,
    ScreenName: "Job Master",
    HELPER_NAME: "JobMaster",
  },
  {
    path: "/JobMonitoring/Import-Export-Plan",
    component: ImportExportPlan,
    ScreenName: "Import Export Plan",
    HELPER_NAME: "ImportExportPlan",
  },

  {
    path: "/JobMonitoring/Dashboard",
    component: Dashboard,
    ScreenName: "Dashboard",
    HELPER_NAME: "Dashboard",
  },
  {
    path: "/JobMonitoring/Self-Service-Execution",
    component: SelfServiceExecution,
    ScreenName: "Self Service Execution",
    HELPER_NAME: "Self Service Execution",
  },
  {
    path: "/JobMonitoring/Self-Service-Execution-Edit",
    component: AddSelfServiceExecution,
    ScreenName: "Add Self Service Execution",
    HELPER_NAME: "Add Self Service Execution",
  },
  {
    path: "/JobMonitoring/Self-Service-Status",
    component: SelfServiceStatus,
    ScreenName: "Self Service Status",
    HELPER_NAME: "Self Service Status",
  },
  {
    path: "/JobMonitoring/BuMaintenance",
    component: BuMaintenance,
    ScreenName: "Group BU Maintenance",
    HELPER_NAME: "Group BU Maintenance",
  },
  {
    path: "/JobMonitoring/Self-Service-Reprocess",
    component: SelfServiceReprocess,
    ScreenName: "Self Service Reprocess",
    HELPER_NAME: "Self Service Reprocess",
  },
  {
    path: "/DataCatalog",
    component: Portal,
    ScreenName: "DataCatalog", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "DataCatalog",
  },
  {
    path: "/DataCatalog/View",
    component: DataObjectList,
    ScreenName: "Data Object List", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Data Object List",
  },
  {
    path: "/DataCatalog/sbx/data-object",
    component: SandBoxDataObject,
    ScreenName: "Manage Data Object", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Manage Data Object",
  },
  {
    path: "/DataCatalog/sbx/data-property",
    component: SandBoxDataProperty,
    ScreenName: "Manage Data Property", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Manage Data Property",
  },
  {
    path: "/DataCatalog/Edit",
    component: AddEditDataObject,
    ScreenName: "Manage Draft Data", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Manage Draft Data",
  },
  {
    path: "/DataCatalog/Browse",
    component: Browse,
    ScreenName: "Browse", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Browse",
  },
  {
    path: "/SystemConfig",
    component: Portal,
    ScreenName: "System Config",
    HELPER_NAME: "System Config",
  },
  {
    path: "/SystemConfig/User-Maintenance",
    component: UserMaintenance,
    ScreenName: "User Maintenance",
    HELPER_NAME: "UserMaintenance",
  },
  {
    path: "/SystemConfig/Role-Maintenance",
    component: RoleMaintenance,
    ScreenName: "Role Maintenance",
    HELPER_NAME: "RoleMaintenance",
  },
  {
    path: "/Inbound",
    component: Portal,
    ScreenName: "Inbound", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Inbound",
  },
  {
    path: "/Inbound/Validation",
    component: InboundValidation,
    ScreenName: "Validation", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Validation",
  },
  {
    path: "/Inbound/ReconcileSource",
    component: InboundReconcile,
    ScreenName: "Reconcile Source", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Reconcile Source",
  },
  {
    path: "/Inbound/Upload",
    component: InboundUpload,
    ScreenName: "Upload CSV", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Upload CSV",
  },
  {
    path: "/Inbound/Adjustment",
    component: InboundAdjust,
    ScreenName: "Adjustment", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Adjustment",
  },
  {
    path: "/Inbound/Export",
    component: InboundExport,
    ScreenName: "Export", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Export",
  },
  {
    path: "/Inbound/Approve",
    component: InboundApprove,
    ScreenName: "Approve", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Approve",
  },
  {
    path: "/Inbound/ReconcileReportMaintenace",
    component: ReconcileReportMaintenace,
    ScreenName: "Reconcile Report Maintenance", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Reconcile Report Maintenance",
  },
  {
    path: "/Inbound/TargetTableMaintenace",
    component: TargetTableMaintenace,
    ScreenName: "Target Table Maintenance", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Target Table Maintenance",
  },
  {
    path: "/Inbound/TableFilterMaintenance",
    component: TableFilterMaintenance,
    ScreenName: "Filter Report Table Maintenance", //ScreenName Must Equal Module Name in SqlDB
    HELPER_NAME: "Filter Report Table Maintenance",
  },
];

export const ProtectedRoutes = () => {
  //if(!CheckToken()){
  //        history.replace('/Login')
  //            let urlSaml = `${API_URL.Authen}/auth/Login?returnUrl=/`;
  //            window.location.replace(urlSaml);
  //    return null;
  //}
  return (
    <>
      {public_route.map((el, idx) => (
        <RouteLayout key={idx} exact path={el.path} component={el.component} />
      ))}
      {protect_route?.map((el, idx) => (
        <RouteLayout2
          key={idx}
          exact
          path={el.path}
          component={el.component}
          ScreenName={el.ScreenName}
          helper={el.HELPER_NAME}
        />
      ))}
    </>
  );
};
ProtectedRoutes.route = Object.values(
  public_route.concat(protect_route).map((el) => el.path)
);
