import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { useOpenDialog2 } from "apps/main/function-api/useOpenDialog";
import { TargetTableTemplateDialog } from "./dialog-target-table-template";
import { TargetTableApproverDialog } from "./dialog-target-table-approver";
import { ViewTemplateDynamicDialog } from "./dialog-template-view";
//Material
import { GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@material-ui/icons/Save";
//Component
import FormInput from "stateless/hook-controls/input";
import { InputButtonBox } from "stateless/hook-controls/input";
import { PurpleButton } from "stateless/button";
import { SelectReportGroupMaster } from "stateless/selection-control/combo/inbound/select-report-group-master";
import { CheckboxInboundGroupUserList } from "stateless/selection-control/checkbox/inbound/group_user";
import { KKPModal } from "stateless/modal/modal2";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
//utils
import { DownloadFromBase64 } from "utils/file";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { inform_success, inform_error, ConfirmCommon } from "utils/alert";
import {
  titleCase,
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyObj,
  isEmptyArray,
} from "utils/common-function";

export const api_GetMstTemplateList = async (param) => {
  let url = `${API_URL.Inbound}/api/Master/GetMstTemplateList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_GetTargetTableDataData = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/GetTargetTableData`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_SaveTargetTable = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/SaveTargetTable`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_DeleteTemplate = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/DeleteTemplate`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_ReadFileTemplate = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/ReadTemplateFile`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

function useTargetTableData({ Data }) {
  const formMethods = useForm({
    defaultValues: { group_user: [] },
  });
  const { getValues, setError, clearErrors } = formMethods;

  //state here
  const controlDialogDetail = useSelectRowOpenModal();
  const controlDialogTemplateFile = useOpenDialog2();
  const controlDialogApprover = useOpenDialog2();
  const [DataRow, setDataRow] = useState([]);
  const [MstTemplateList, setMstTemplateList] = useState([]);
  const [TemplateList, setTemplateList] = useState([]);
  const [ApproverList, setApproverList] = useState([]);
  const state = {
    DataRow,
    TemplateList,
    ApproverList,
    controlDialogDetail,
    controlDialogTemplateFile,
    controlDialogApprover,
  };

  const GetMstTemplateList = useCallback(async () => {
    let [res, err] = await api_GetMstTemplateList();
    if (err) return;
    setMstTemplateList(res);
  }, []);

  const GetTargetTableData = useCallback(async (params) => {
    let [res, err] = await api_GetTargetTableDataData(params);
    if (err) return;
    setDataRow(res);
  }, []);

  function GetTemplateId(templateName) {
    if (isEmptyObj(MstTemplateList)) return null;
    const foundItem = MstTemplateList.find(
      (item) => item.template_name === templateName
    );
    return foundItem ? foundItem.template_id : null;
  }

  async function SaveTargetTable(fetchData, close) {
    clearErrors("table_name");
    clearErrors("area_name");
    clearErrors("report_group");

    let param = getValues();
    param.templat_list = TemplateList;
    param.approver_list = ApproverList;

    if (isEmptyStr(param.table_name)) {
      setError("table_name", {
        type: "manual",
        message: "The Table name field is required",
      });
      return;
    }

    if (isEmptyStr(param.area_name)) {
      setError("area_name", {
        type: "manual",
        message: "The Area field is required",
      });
      return;
    }

    if (isEmptyStr(param.report_group)) {
      setError("report_group", {
        type: "manual",
        message: "The Group Report field is required",
      });
      return;
    }

    if (isEmptyArray(param.approver_list)) {
      await inform_error("Please Add Approver.", true);
      return;
    }

    if (isEmptyArray(param.group_user)) {
      await inform_error("Please Select Group User.", true);
      return;
    }

    if (isEmptyArray(param.templat_list)) {
      await inform_error("Please Add Template File.", true);
      return;
    }

    if (!(await ConfirmCommon("Confirm Save?"))) return;
    let [res, err] = await api_SaveTargetTable(param);
    if (err) return;
    await inform_success("Save Complete.");
    fetchData();
    close();
  }

  function deleteTemplate(row) {
    setTemplateList(
      TemplateList.filter((item) => item.template_id !== row.template_id)
    );
  }

  async function addApprover(p_data) {
    const existing =
      ApproverList &&
      ApproverList.find((item) => item.username === p_data.user_id);
    if (existing) {
      await inform_error("User already exists.", true);
      return;
    }

    const newData = [
      ...ApproverList,
      {
        table_id: Data && Data.table_id !== null ? Data.table_id : 0,
        username: p_data.user_id,
      },
    ];
    setApproverList(newData);
  }

  function deleteApprover(row) {
    setApproverList(
      ApproverList.filter((item) => item.username !== row.username)
    );
  }

  async function download(row) {
    let params = {
      template_name: row.template_name,
    };
    let [res, err] = await api_ReadFileTemplate(params);
    if (err) return;
    if (isEmptyStr(res)) {
      return;
    }
    var filename = params.template_name + ".csv";
    DownloadFromBase64(res, filename);
  }

  const actions = {
    setTemplateList,
    setApproverList,
    GetMstTemplateList,
    GetTargetTableData,
    viewTmplate: async (row) => {
      controlDialogDetail.handleClickRow(row);
    },
    GetTemplateId,
    SaveTargetTable,
    deleteTemplate,
    addApprover,
    deleteApprover,
    download,
  };
  return { state, actions, formMethods };
}
export function TargetTableDialog({ controlDialog, methods }) {
  const { isOpen, Data, close } = controlDialog;
  const { state, actions, formMethods } = useTargetTableData({ Data });
  const { setValue, reset, errors, control } = formMethods;

  const { TemplateList, ApproverList } = state;
  const {
    setTemplateList,
    setApproverList,
    GetMstTemplateList,
    GetTargetTableData,
  } = actions;
  const { GetTargetTableList } = methods.actions;

  useEffect(() => {
    if (!isOpen) return;
    reset();
    GetMstTemplateList();
    setTemplateList([]);
    setApproverList([]);
    if (!isEmptyObj(Data)) {
      GetTargetTableData(Data);
    }
  }, [
    isOpen,
    GetMstTemplateList,
    GetTargetTableData,
    Data,
    setValue,
    reset,
    setTemplateList,
    setApproverList,
  ]);

  useEffect(() => {
    if (isEmptyObj(state.DataRow)) return;
    let hook = setDataToFormHookV2(state.DataRow);
    hook?.forEach(({ name, value }) => setValue(name, value));
    if (isEmptyObj(state.DataRow.templat_list)) return;
    setTemplateList(state.DataRow.templat_list);
    if (isEmptyObj(state.DataRow.approver_list)) return;
    setApproverList(state.DataRow.approver_list);
  }, [setValue, state.DataRow, setTemplateList, setApproverList]);

  useEffect(() => {
    var number_of_approvers = ApproverList !== null ? ApproverList.length : 0;
    setValue("number_of_approvers", number_of_approvers);
  }, [ApproverList, setValue]);

  return (
    <>
      <FormProvider {...formMethods}>
        <KKPModal control={controlDialog} title={`Table Detail`} maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box>
                    <FormInput
                      name="table_name"
                      label={titleCase("table_name")}
                      required={true}
                      errorobj={errors}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <FormInput
                      name="area_name"
                      label={titleCase("Area")}
                      required={true}
                      errorobj={errors}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <SelectReportGroupMaster
                      name="report_group"
                      label={titleCase("Group Report")}
                      required={true}
                      errorobj={errors}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Grid item xs={12}>
                <Box>
                  <InputButtonBox
                    name="number_of_approvers"
                    label={titleCase("approver")}
                    onClick={() => {
                      state.controlDialogApprover.clickOpen();
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CheckboxInboundGroupUserList
                showTitle
                name={"group_user"}
                control={control}
                setValue={setValue}
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Table
              data={TemplateList}
              actions={actions}
              addTemplateFile={state.controlDialogTemplateFile.clickOpen}
            />
          </Box>
          <Box
            mt={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() =>
                  actions.SaveTargetTable(GetTargetTableList, close)
                }
              >
                Save
              </Button>
            </Box>
            <Box m={1}>
              <Button variant="outlined" onClick={close}>
                Close
              </Button>
            </Box>
          </Box>
          <ViewTemplateDynamicDialog
            controlDialog={state.controlDialogDetail}
          />
          <TargetTableTemplateDialog
            {...state.controlDialogTemplateFile}
            disableEnforceFocus
            name={"templat_list"}
            defaultSelected={
              isEmptyArray(TemplateList)
                ? []
                : TemplateList.map((el) => el.template_name)
            }
            retrieveData={(p_data) => {
              let templat_list = p_data.templat_list.map((templateName) => {
                return {
                  template_id: actions.GetTemplateId(templateName),
                  template_name: templateName,
                };
              });
              setTemplateList(templat_list);
              state.controlDialogTemplateFile.onClose();
            }}
          />
          <TargetTableApproverDialog
            {...state.controlDialogApprover}
            disableEnforceFocus
            name={"approver_list"}
            dataTable={ApproverList}
            addApprover={actions.addApprover}
            deleteApprover={actions.deleteApprover}
          />
        </KKPModal>
      </FormProvider>
    </>
  );
}

function Table({ data, actions, addTemplateFile }) {
  const { viewTmplate, deleteTemplate, download } = actions;
  const columns = [
    {
      field: "template_name",
      headerName: "TEMPLATE FILE NAME",
      sortable: true,
    },
    {
      field: "action",
      headerName: "DETAIL",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => viewTmplate(params.row)}>
            View
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "delete",
      headerName: "DELETE TEMPLATE",
      align: "center",
      minWidth: 150,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => deleteTemplate(params.row)}>
            Delete
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "download",
      headerName: "DOWNLOAD",
      align: "center",
      minWidth: 150,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => download(params.row)}>
            Download (.CSV)
          </PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      maxHeight="450px"
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={data || []}
      rowHeight={40}
      customColumn={{
        all: db_col,
        custom_col: columns,
        date: [],
        date_time: [],
      }}
      getRowId={(row) => row.template_id}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "Add New Template File",
          buttonclick: addTemplateFile,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col = ["template_name"];
