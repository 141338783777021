import React, { useState, useEffect } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
//Material
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
//Component
import { BaseContainer } from "stateless/base-container";
import { BoxTemplate1 } from "stateless/Box";
import FormInput from "stateless/hook-controls/input";
import FormCheckBox from "stateless/hook-controls/checkbox";
import CheckboxRole from "stateless/selection-control/checkbox/role";
import { Modal } from "stateless/modal";
import { ReactTable } from "stateless/react-table";
//Function
import { setDataToFormHookV2, isEmptyStr } from "utils/common-function";
import { getMaintenanceColumns } from "./columns/user-maintenance";
import * as alertDialog from "utils/alert";
import { buildParameter } from "./parameter";
import { checkDuplicateUser, GetListUserMaintenance } from "./api";
import { DeleteUser } from "./api";
import { SaveUser } from "./api";
import ComboGroupBu from "stateless/selection-control/combo/select-bu";

function UserMaintenance() {
  //Context

  //State
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [DataList, setDataList] = useState([]);
  const [DataDetail, setDataDetail] = useState({});

  //Event
  const onOpenAddModal = () => {
    setOpenModal(true);
    setEditMode(false);
    setDataDetail(null);
  };
  const onOpenEditModal = () => {
    setOpenModal(true);
    setEditMode(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  //Function
  const Edit = (Detail) => {
    onOpenEditModal();
    setDataDetail(Detail);
  };

  const Delete = async (id) => {
    let res = await alertDialog.ConfirmDelete();
    if (!res) return;

    let param = {
      ID: id,
    };
    let api_res = await DeleteUser(param);
    if (!api_res) return;
    fetch();
    alertDialog.del_success();
  };

  //--------
  const fetch = async () => {
    let res = await GetListUserMaintenance();
    setDataList(res);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      <BaseContainer>
        <BoxTemplate1 title="User Maintenance" add onClickAdd={onOpenAddModal}>
          <ReactTable
            keyField="ID"
            data={DataList}
            columns={getMaintenanceColumns(Edit, Delete)}
          />
        </BoxTemplate1>
        <DataModal
          editmode={editMode ? 1 : 0}
          open={openModal}
          onClose={onCloseModal}
          search={fetch}
          datadetail={DataDetail}
        />
      </BaseContainer>
    </div>
  );
}

function DataModal(props) {
  const { onClose } = props;
  const { open, editmode } = props;
  const { datadetail, search = () => { }, ...otherprops } = props;
  const method = useForm();
  const { setValue, getValues, setError, clearErrors, errors, control } =
    method;

  const CurrentLocalUser = useWatch({
    control,
    name: "LOCAL_USER",
    defaultValue: false,
  });

  //Effect
  useEffect(() => {
    if (!open) return;
    if (editmode) {
      let data = setDataToFormHookV2(datadetail);
      setTimeout(() =>
        data?.forEach(({ name, value }) => setValue(name, value))
      );
    } else {
      setTimeout(() => setValue("ACTIVE", true));
    }
  }, [datadetail, setValue, open, editmode]);

  const SaveDetail = async () => {
    clearErrors("USER_ID");
    clearErrors("PASSWORD");
    clearErrors("CONFIRM_PASSWORD");

    const IS_LOCAL_USER = getValues().LOCAL_USER;
    const USER_ID = getValues().USER_ID;
    const PASSWORD = getValues().PASSWORD;
    const CONFIRM_PASSWORD = getValues().CONFIRM_PASSWORD;

    if (isEmptyStr(USER_ID)) {
      setError("USER_ID", {
        type: "manual",
        message: "The User ID field is required",
      });
      return;
    }

    if (IS_LOCAL_USER) {
      if (isEmptyStr(PASSWORD)) {
        setError("PASSWORD", {
          type: "manual",
          message: "The Password field is required",
        });
        return;
      }

      if (isEmptyStr(CONFIRM_PASSWORD)) {
        setError("CONFIRM_PASSWORD", {
          type: "manual",
          message: "The Confirm Password field is required",
        });
        return;
      }

      if (PASSWORD !== CONFIRM_PASSWORD) {
        setError("CONFIRM_PASSWORD", {
          type: "manual",
          message: "The Password and Password Confirmation fields do not match",
        });
        return;
      }
    }

    const ID = editmode ? datadetail.ID : null;
    let param = buildParameter.SaveDetail(ID, getValues());

    let checkdup = editmode ? 0 : await checkDuplicateUser(param);
    if (checkdup > 0) {
      setError("USER_ID", {
        type: "manual",
        message: "The User ID is Duplicate.",
      });
      return;
    }

    let res = await SaveUser(param);
    if (res) {
      search();
      onClose();
      alertDialog.inform_success();
    }
  };

  return (
    <div>
      <Modal
        {...otherprops}
        onSave={SaveDetail}
        title={editmode ? "Edit User" : "Add User"}
      >
        <FormProvider {...method}>
          <Box m={3}>
            <FormInput
              variant={editmode ? "filled" : ""}
              InputProps={editmode ? { readOnly: true } : null}
              required
              name="USER_ID"
              label="User ID"
              errorobj={errors}
            />
          </Box>
          <Box m={3}>
            <CheckboxRole name="ListRole" />
          </Box>
          <Box m={3}>
            <ComboGroupBu required={false} name="GROUP_BU" label="BU" />
          </Box>
          <Box
            m={3} ml={3}
            style={{ border: "1px solid #c4c4c4", borderRadius: "4px" }}
          >
            <Box m={1} ml={4.5}>
              <FormCheckBox
                key="LOCAL_USER"
                name="LOCAL_USER"
                label="Local User"
                errorobj={errors}
              />
            </Box>
            <Box
              m={3}
              flexGrow={1}
              display={CurrentLocalUser === true ? "flex" : "none"}
              style={{ margin: "0 24px 5px 24px" }}
            >
              <FormInput
                required
                name="PASSWORD"
                label="Password"
                type="password"
                errorobj={errors}
              />
            </Box>
            <Box
              m={3}
              flexGrow={1}
              display={CurrentLocalUser === true ? "flex" : "none"}
              style={{ margin: "0 24px 15px 24px" }}
            >
              <FormInput
                required
                name="CONFIRM_PASSWORD"
                label="Confirm Password"
                type="password"
                errorobj={errors}
              />
            </Box>
          </Box>
          <Box mt={3} ml={4.5} flexGrow={1} style={{ marginTop: "0" }}>
            <FormCheckBox
              key="ACTIVE"
              name="ACTIVE"
              label="Active"
              errorobj={errors}
            />
          </Box>
        </FormProvider>
      </Modal>
    </div>
  );
}

export default UserMaintenance;
