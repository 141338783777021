import { GetUser } from "utils/sessions";

export const buildParameter = {
  SaveDetail: (id, formdata, listModule) => {
    const arrModule = [];

    const findModuleID = (ModuleName) => {
      var ID = "";
      listModule.forEach((m) => {
        m.Modules?.forEach((item) => {
          if (item.MODULE_NAME === ModuleName) {
            ID = item.ID;
            return;
          }
        });
      });
      return ID;
    };

    Object.keys(formdata).forEach((key) =>
      typeof formdata[key] === "boolean"
        ? formdata[key] && key !== "ACTIVE"
          ? arrModule.push({
              ID: findModuleID(key),
              MODULE_NAME: key,
            })
          : null
        : null
    );

    let param = {
      ROLE_ID: id,
      ROLE_DESC: formdata.ROLE_DESC,
      LIST_MODULES: arrModule,
      ACTIVE: formdata.ACTIVE,
      SESSION_OWNER: GetUser(),
    };
    return param;
  },
};
