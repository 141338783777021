import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
//Material
import FilterListIcon from "@material-ui/icons/FilterList";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
//stateless
import { ButtonDropdown,ButtonLink } from "stateless/button";
import {
  PopupDomainName,
  PopupObjectName,
  PopupColumnName,
  PopupSearchMore,
  PopupSubDomainName,
  PopupObjectType
} from "stateless/selection-control/popup-checkbox";
//function
import { useOpenDialog } from "apps/main/function-api/useOpenDialog";
import { padding } from "@mui/system";
const { log } = console;
function FilterBrowse(props) {
  const { getFilterValue, clear, field = [], data } = props;
  const [popupDomain, domainOnClose, domainOnOpen] = useOpenDialog();
  const [popupSubDomain, subDomainOnClose, subDomainOnOpen] = useOpenDialog();
  const [popupObject, objectOnClose, objectOnOpen] = useOpenDialog();
  const [popupObjectType, objectTypeOnClose, objectTypeOnOpen] = useOpenDialog();
  const [popupColumn, columnOnClose, columnOnOpen] = useOpenDialog();
  const [
    popupSearchMore,
    SearchMoreOnClose,
    SearchMoreOnOpen,
  ] = useOpenDialog();
  function checkHasFilter() {
    let has = false;
    for (let index = 0; index < field.length; index++) {
      if (data[field[index]]?.length > 0) {
        has = true;
        break;
      }
    }
    return has;
  }
  return (
    <>
      <Box display="flex" width="100%">
        <Box display="flex" name="categorybox" justifyContent={"center"} mr={1}>
          <Box mr={1}>
            <FilterListIcon />
          </Box>
          <Box mr={1}>
            <Typography>Category</Typography>
          </Box>

          <Box>{checkHasFilter() ? <CancelIcon onClick={clear} /> : null}</Box>
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          style={{ height: "100%", border: "1px solid #000" }}
        />
        <Box ml={1}>
          <ButtonDropdown count={data[field[0]]?.length} onClick={domainOnOpen}>
            Data Domain Name
          </ButtonDropdown>
        </Box>
        <Box ml={1}>
          <ButtonDropdown count={data[field[1]]?.length} onClick={subDomainOnOpen}>
            Data Sub Domain Name
          </ButtonDropdown>
        </Box>
        <Box ml={1}>
          <ButtonDropdown count={data[field[2]]?.length} onClick={objectOnOpen}>
            Data Object/Table Name
          </ButtonDropdown>
        </Box>
        <Box ml={1} mr={1}>
          <ButtonDropdown count={data[field[3]]?.length} onClick={objectTypeOnOpen}>
            Data Object Type
          </ButtonDropdown>
        </Box>
        <Box ml={1} mr={1}>
          <ButtonDropdown count={data[field[4]]?.length} onClick={columnOnOpen}>
            Column Name
          </ButtonDropdown>
        </Box>
        <Box style={{marginLeft:"auto",padding:"8px 16px"}}>
          <ButtonLink onClick={SearchMoreOnOpen} count={data[field[5]]?.length+data[field[6]]?.length}>Search More</ButtonLink>
        </Box>
      </Box>
      <PopupDomainName
        open={popupDomain}
        onClose={domainOnClose}
        defaultSelected={data[field[0]]}
        name={field[0]}
        retriveData={(p_data) => {
          getFilterValue(field[0], p_data[field[0]]);
          domainOnClose();
        }}
      />
      <PopupSubDomainName
        open={popupSubDomain}
        onClose={subDomainOnClose}
        defaultSelected={data[field[1]]}
        name={field[1]}
        retriveData={(p_data) => {
          getFilterValue(field[1], p_data[field[1]]);
          subDomainOnClose();
        }}
      />
      <PopupObjectName
        open={popupObject}
        onClose={objectOnClose}
        defaultSelected={data[field[2]]}
        name={field[2]}
        retriveData={(p_data) => {
          getFilterValue(field[2], p_data[field[2]]);
          objectOnClose();
        }}
      />
      <PopupObjectType
        open={popupObjectType}
        onClose={objectTypeOnClose}
        defaultSelected={data[field[3]]}
        name={field[3]}
        retriveData={(p_data) => {
          getFilterValue(field[3], p_data[field[3]]);
          objectTypeOnClose();
        }}
      />
      <PopupColumnName
        open={popupColumn}
        onClose={columnOnClose}
        defaultSelected={data[field[4]]}
        name={field[4]}
        retriveData={(p_data) => {
          getFilterValue(field[4], p_data[field[4]]);
          columnOnClose();
        }}
      />
      <PopupSearchMore
        open={popupSearchMore}
        onClose={SearchMoreOnClose}
        defaultSelected={useMemo(() => ({
          [field[5]]: data[field[5]],
          [field[6]]: data[field[6]],
        }),[field,data])}
        name1={field[5]}
        name2={field[6]}
        retriveData={(p_data) => {
          getFilterValue(field[5], p_data[field[5]]);
          getFilterValue(field[6], p_data[field[6]]);
          SearchMoreOnClose();
        }}
      />
    </>
  );
}
FilterBrowse.propTypes = {
  getFilterValue: PropTypes.func,
  field: PropTypes.array,
  clear: PropTypes.func,
};
export default FilterBrowse;
