import { createContext,useState } from "react";

export const UserContext = createContext(null);
const useUser = ()=>{
const [User, setUser] = useState({me:"Tong"})
 return {User,setUser}  
}


export const UserContextProvider = ({children}) =>{
    return(
        <UserContext.Provider value = {useUser()}>
            {children}
        </UserContext.Provider>
    )
}