import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { DropzoneDialog } from "material-ui-dropzone";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
//Materials
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
//Component
import { Modal } from "stateless/modal";
import { Button } from "stateless/button";
import { CMDataGrid } from "stateless/data-grid";
//Utils
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { MuiDateTimeToApi } from "utils/date";
import { titleCase, isEmptyArray, isEmptyStr } from "utils/common-function";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import { CreateMuiColumnObj } from "utils/mui-table";
const { log } = console;
//Const

const LoadDataObjectEditingList = async (param) => {
  let url = `${API_URL.Report}/api/Report/LoadDataObjectEditingList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const CreateDataObject = async (param) => {
  let url = `${API_URL.Report}/api/Report/CreateDataObject`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
const db_col = [
  "action",
  "sheet_name",
  "domain",
  "sub_domain",
  "schema_name",
  "data_object_table_name",
  "imported_by",
  "imported_timestamp",
  "session_owner",
  "sbx_created_timestamp",
  "sbx_updated_timestamp",
];
const date_col = [];
const datetime_col = ["imported_timestamp", "sbx_updated_timestamp"];
function PopupEdit(props) {
  const { data, open } = props;
  const history = useHistory();
  const [Data, setData] = useState([]);

  const col = useMemo(() => {
    function handleClickRow(params) {
      history.push({
        pathname: "/DataCatalog/Edit",
        state: { sbx_id: params.sbx_id },
      });
    }
    let custom_col = [
      {
        field: "action",
        headerName: "Edit Draft",
        align: "center",
        minWidth: 50,
        flex: 0,
        sortable: false,
        renderCell: (params) => (
          <Box component="div" display="flex" justifyContent="center">
            <EditIcon
              color="action"
              //className={classes.icon}
              onClick={() => {
                handleClickRow(params.row);
              }}
            />
          </Box>
        ),
      }
    ];
    return CreateMuiColumnObj(db_col, date_col, datetime_col, custom_col);
  }, [history]);
  useEffect(() => {
    if (!open) return;
    (async () => {
      let [res, err] = await LoadDataObjectEditingList(data);
      if (err) return;
      setData(res);
    })();
  }, [data, history, open]);
  async function Edit() {
    let [res, err] = await CreateDataObject({
      data_object_id: data.data_object_id,
    });
    if (err) return;
    history.push({
      pathname: "/DataCatalog/Edit",
      state: { sbx_id: res.sbx_id },
    });
  }
  return (
    <>
      <Modal
        hiddenSave
        maxWidth="md"
        title="Data Object Editing List (Edit Draft Data)"
        {...props}
      >
        <Box my={1} display="flex" justifyContent={"flex-end"}>
          <Button onClick={Edit}>Create New Draft</Button>
        </Box>
        <CMDataGrid
          rows={Data || []}
          getRowId={(row) => row.sbx_id}
          customColumn={col}
        />
      </Modal>
    </>
  );
}

export default PopupEdit;
