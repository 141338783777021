import axios from "axios";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
//Master

export const GetListJobMaster = async (param) => {
  let url = `${API_URL.Job}/api/master/GetListJobMaster`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const SaveJobMaster = async (param) => {
  let url = `${API_URL.Job}/api/master/SaveJobMaster`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const DeleteJobMaster = async (param) => {
  let url = `${API_URL.Job}/api/master/DeleteJobMaster`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const CheckDeleteJobMaster = async (param) => {
  let url = `${API_URL.Job}/api/master/CheckDeleteJobMaster`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};


export const CheckDulplicateOriginByJobID = async (param) => {
  let url = `${API_URL.Job}/api/master/CheckDulplicateOriginByJobID`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const SyncJopMasterFromTalend = async (param) => {
  let url = `${API_URL.Job}/api/master/SyncJopMasterFromTalend`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
