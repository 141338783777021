import React, { useState, useEffect, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
//Materail
import { MaterialUIDateTimePickers } from "../../../stateless/hook-controls/datepicker/datetimepicker";
import { MaterialUITimePickers } from "../../../stateless/hook-controls/datepicker/timepicker";
import Box from "@material-ui/core/Box";
import { Divider } from "@material-ui/core";
//Context
import { ExecutionPlanContext } from "./job-execution-plan-context";
//Component
import FormInput, {
  RegexTextField,
} from "../../../stateless/hook-controls/input";
//Function
import { humanize, titleCase } from "utils/common-function";
import * as CONS from "./constants";
export const Weekly = [
  { name: "SUN" },
  { name: "MON" },
  { name: "TUE" },
  { name: "WED" },
  { name: "THU" },
  { name: "FRI" },
  { name: "SAT" },
];
export const getHourly = () => {
  let arr = [];
  for (let index = 0; index <= 23; index++) {
    arr.push({
      name: `HOUR${index.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`,
      label: `${index.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}:00`,
    });
  }
  return arr;
};
export const getMonthly = () => {
  let arr = [];
  for (let index = 1; index <= 31; index++) {
    arr.push({
      name: `DAY${index.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`,
    });
  }
  arr.push({ name: "END_MONTH_DAY" });
  return arr;
};
export const Monthly2 = [
  { name: "M_1ST_SUN" },
  { name: "M_1ST_MON" },
  { name: "M_1ST_TUE" },
  { name: "M_1ST_WED" },
  { name: "M_1ST_THU" },
  { name: "M_1ST_FRI" },
  { name: "M_1ST_SAT" },
  { name: "M_2ND_SUN" },
  { name: "M_2ND_MON" },
  { name: "M_2ND_TUE" },
  { name: "M_2ND_WED" },
  { name: "M_2ND_THU" },
  { name: "M_2ND_FRI" },
  { name: "M_2ND_SAT" },
  { name: "M_3RD_SUN" },
  { name: "M_3RD_MON" },
  { name: "M_3RD_TUE" },
  { name: "M_3RD_WED" },
  { name: "M_3RD_THU" },
  { name: "M_3RD_FRI" },
  { name: "M_3RD_SAT" },
  { name: "M_4TH_SUN" },
  { name: "M_4TH_MON" },
  { name: "M_4TH_TUE" },
  { name: "M_4TH_WED" },
  { name: "M_4TH_THU" },
  { name: "M_4TH_FRI" },
  { name: "M_4TH_SAT" },
  { name: "M_5TH_SUN" },
  { name: "M_5TH_MON" },
  { name: "M_5TH_TUE" },
  { name: "M_5TH_WED" },
  { name: "M_5TH_THU" },
  { name: "M_5TH_FRI" },
  { name: "M_5TH_SAT" },
];

export const getYearly = () => {
  let arr = [];
  for (let index = 1; index <= 12; index++) {
    arr.push({
      name: `MONTH${index.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`,
    });
  }
  return arr;
};

export const hours = getHourly();
export const days = getMonthly();
export const months = getYearly();
export const SchduleSpec = ({
  CurrentType,
  isRerun,
  interval,
  isSpecificDay,
}) => {
  const { register, errors, control, setValue } = useFormContext();
  const isInterval = useWatch({
    control,
    name: "isInterval", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: false, // default value before the render
  });
  const Cur_Time = useWatch({
    control,
    name: "TIME_DATA", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    //defaultValue: null, // default value before the render
  });
  const Cur_DATETIME = useWatch({
    control,
    name: "ONETIME_DATETIME", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    //defaultValue: null, // default value before the render
  });
  const IS_Specific_Day = useWatch({
    control,
    name: "IS_Specific_Day", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: true, // default value before the render
  });
  useEffect(() => {
    register("isInterval");
    register("TIME_DATA");
    register("ONETIME_DATETIME");
  }, [register]);
  useEffect(() => {
    if (interval) setValue("isInterval", true);
  }, [interval, setValue]);
  useEffect(() => {
    setValue("IS_Specific_Day", isSpecificDay);
  }, [isSpecificDay, setValue]);
  const handleNameChange = (evt) => {
    const newName = evt.target.value.replace(/[^0-9,:\s]/g, "");
    setValue("TIME_DATA", newName);
  };
  function Time() {
    const time = () => {
      return (
        <>
          <Box
            display="flex"
            minWidth="230px"
            id="TimeBox"
            style={{ paddingBottom: "5px" }}
          >
            <Box display={CurrentType !== CONS.ONETIME ? "none" : "flex"}>
              <MaterialUIDateTimePickers
                name="ONETIME_DATETIME"
                label="Time"
                value={Cur_DATETIME}
              />
            </Box>

            <Box
              mb={1}
              display={
                ![
                  CONS.DAILY,
                  CONS.WEEKLY,
                  CONS.MONTHLY,
                  CONS.YEARLY,
                  CONS.QUARTERLY,
                ].includes(CurrentType)
                  ? "none"
                  : "flex"
              }
            >
              <FormInput
                name="TIME_DATA"
                label="Times"
                onChange={handleNameChange}
                value={Cur_Time}
              />
              <Box m={1}  flexGrow={1} display={![CONS.DAILY].includes(CurrentType) ? "none" : "flex"} width={200}>
              <Box
                
                pl={1}
                style={{ paddingLeft: "10px", marginTop: "5px" }}
              >
                {/* <FormCheckbox name="ENABLED" label="Enable"></FormCheckbox> */}
                <input
                  type="checkbox"
                  //ref={register}
                  {...register("IS_Specific_Day")}
                  name="IS_Specific_Day"
                />{" "}
                Specify date
              </Box>{" "}
            </Box>
            </Box>
          </Box>
        </>
      );
    };
    return (
      <>
        <Box display="flex" pt={1} pb={1} style={{ padding: "0" }}>
          {time()}
        </Box>
      </>
    );
  }
  function Hourly() {
    return (
      <>
        <Box
          display={
            ![CONS.HOURY_WEEKDAY, CONS.HOURY_MONTHDAY].includes(CurrentType)
              ? "none"
              : "flex"
          }
          flexWrap="wrap"
        >
          {hours?.map((el) => (
            <Box justifyContent="center" p={1} key={el.name}>
              <input
                type="checkbox"
                //ref={register}
                {...register(el.name)}
                name={el.name}
              />{" "}
              {titleCase(el.label)}
            </Box>
          ))}
          <Box flexBasis={"100%"} pb={1} pt={1}>
            <FormInput
              type="number"
              name="HOUR_MINUTE"
              label={titleCase("HOUR_MINUTE")}
              errorobj={errors}
            />
          </Box>
        </Box>
        <Divider />
      </>
    );
  }
  function Weekdays() {
    return (
      <>
        <Box
          display={
            ![CONS.WEEKLY, CONS.HOURY_WEEKDAY].includes(CurrentType)
              ? "none"
              : "flex"
          }
          flexWrap="wrap"
        >
          {Weekly?.map((el) => (
            <Box justifyContent="center" p={1} key={el.name} width={200}>
              <input
                type="checkbox"
                //ref={register}
                {...register(el.name)}
                name={el.name}
                label={humanize(el.name)}
              />{" "}
              {titleCase(el.name)}
            </Box>
          ))}
        </Box>
      </>
    );
  }
  function Days() {
    let showday =
      (IS_Specific_Day && CurrentType === CONS.DAILY) ||
      [CONS.MONTHLY, CONS.YEARLY, CONS.HOURY_MONTHDAY, CONS.QUARTERLY].includes(
        CurrentType
      );
    let showday2 =
      [CONS.MONTHLY].includes(CurrentType);
    return (
      <>
        <Box display={!showday ? "none" : "flex"} flexDirection="column">
          <Box display="flex" flexWrap="wrap">
            {days?.map((el) => (
              <Box justifyContent="center" p={1} key={el.name}>
                <input
                  type="checkbox"
                  //ref={register}
                  {...register(el.name)}
                  name={el.name}
                  label={humanize(el.name)}
                />{" "}
                {titleCase(el.name)}
              </Box>
            ))}
          </Box>

          <Divider />
          <Box display={!showday2 ? "none" : "flex"} flexWrap="wrap">
            <Box display="flex" flexWrap="wrap">
              <Box justifyContent="center" p={1} width={75}>
                {" "}
                First:
              </Box>
              {Monthly2?.slice(0, 7).map((el, idx) => (
                <Box justifyContent="center" p={1} key={el.name}>
                  <input
                    type="checkbox"
                    //ref={register}
                    {...register(el.name)}
                    name={el.name}
                    label={titleCase(el.name)}
                  />{" "}
                  {titleCase(el.name?.split("_").slice(-1)[0])}
                </Box>
              ))}
            </Box>
            <Divider />
            <Box display="flex" flexWrap="wrap">
              <Box justifyContent="center" p={1} width={75}>
                {" "}
                Second:
              </Box>
              {Monthly2?.slice(7, 14).map((el, idx) => (
                <Box justifyContent="center" p={1} key={el.name}>
                  <input
                    type="checkbox"
                    //ref={register}
                    {...register(el.name)}
                    name={el.name}
                    label={titleCase(el.name)}
                  />{" "}
                  {titleCase(el.name?.split("_").slice(-1)[0])}
                </Box>
              ))}
            </Box>
            <Divider />
            <Box display="flex" flexWrap="wrap">
              <Box justifyContent="center" p={1} width={75}>
                {" "}
                Third:
              </Box>
              {Monthly2?.slice(14, 21).map((el, idx) => (
                <Box justifyContent="center" p={1} key={el.name}>
                  <input
                    type="checkbox"
                    //ref={register}
                    {...register(el.name)}
                    name={el.name}
                    label={titleCase(el.name)}
                  />{" "}
                  {titleCase(el.name?.split("_").slice(-1)[0])}
                </Box>
              ))}
            </Box>
            <Divider />
            <Box display="flex" flexWrap="wrap">
              <Box justifyContent="center" p={1} width={75}>
                {" "}
                Forth:
              </Box>
              {Monthly2?.slice(21, 28).map((el, idx) => (
                <Box justifyContent="center" p={1} key={el.name}>
                  <input
                    type="checkbox"
                    //ref={register}
                    {...register(el.name)}
                    name={el.name}
                    label={titleCase(el.name)}
                  />{" "}
                  {titleCase(el.name?.split("_").slice(-1)[0])}
                </Box>
              ))}
            </Box>
            <Divider />
            <Box display="flex" flexWrap="wrap">
              <Box justifyContent="center" p={1} width={75}>
                {" "}
                Last:
              </Box>
              {Monthly2?.slice(28, 35).map((el, idx) => (
                <Box justifyContent="center" p={1} key={el.name}>
                  <input
                    type="checkbox"
                    //ref={register}
                    {...register(el.name)}
                    name={el.name}
                    label={titleCase(el.name)}
                  />{" "}
                  {titleCase(el.name?.split("_").slice(-1)[0])}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  function Month() {
    return (
      <>
        <Divider />
        <Box
          display={
            ![CONS.YEARLY, CONS.QUARTERLY].includes(CurrentType)
              ? "none"
              : "flex"
          }
          flexWrap="wrap"
        >
          {months?.map((el) => (
            <Box justifyContent="center" p={1} key={el.name}>
              <input
                type="checkbox"
                //ref={register}
                {...register(el.name)}
                name={el.name}
                label={titleCase(el.name)}
              />{" "}
              {titleCase(el.name)}
            </Box>
          ))}
        </Box>
      </>
    );
  }
  return (
    <>
      {/* <Grid container>
        <Grid item sm={12}>
          {Time()}
        </Grid>
        <Grid item>{Weekdays()}</Grid>
        <Grid item>{Days()}</Grid>
        <Grid item>{Month()}</Grid>
      </Grid> */}
      <Box display="flex" flexDirection="column" mt={1}>
        {Time()}
        {/* {isRerun ? null : Hourly()} */}
        {isRerun ? null : Weekdays()}
        {isRerun ? null : Days()}
        {isRerun ? null : Month()}
      </Box>
    </>
  );
};
