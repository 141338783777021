import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
  Controller,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
//Material
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import EditIcon from "@material-ui/icons/Edit";
//component
import { BaseContainer } from "stateless/base-container";
import { Button } from "stateless/button";
import FormInput from "stateless/hook-controls/input";
import SelectStatus from "stateless/selection-control/combo/datalist-status";
import PopupImport from "./popup-import";
import { useOpenDialog } from "apps/main/function-api/useOpenDialog";
import { CMDataGrid } from "stateless/data-grid";
import { SubmitInformation } from "../utils/function";
import PopupImport2 from "./popup-import template2";
import PopupDetail from './popup-template2-data-property'
//context

//utils
import { isEmptyArray, isEmptyStr, titleCase } from "utils/common-function";
import { inform_success, ConfirmCommon } from "utils/alert";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import axios from "axios";
import { API_URL } from "../../../utils/api-utils";
import { common } from "../../../utils";
import { TemplateID } from "../utils/constants";
import { SelectTemplate } from "../stateless-datacatalog/select";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { Link } from "@mui/material";
//api
export const LoadSbxDataObjectList = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/LoadSbxDataObjectList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_PublishMultipleDataObject = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/PublishMultipleDataObjectAndProperty`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_CancelDataObject = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/CancelDataObjectAndProperty`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function ObjectTemplate2(params) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [importOpen, Close, Open] = useOpenDialog();
  const history = useHistory();
  const [SbxObjList, setSbxObjList] = useState([]);
  const { isOpen, close, handleClickRow, Data } = useSelectRowOpenModal();
  const GetDataList = useCallback(async () => {
    let param = { template_id: TemplateID.template2 };
    let [data, err] = await LoadSbxDataObjectList(param);
    if (err) return;
    setSbxObjList(data);
  }, []);
  function clickImport() {
    Open();
  }
  useEffect(() => {
    GetDataList();
  }, [GetDataList]);
  async function Publish() {
    if (isEmptyArray(selectionModel)) return;
    if (!(await ConfirmCommon("Confirm Publish"))) return;
    let param = { sbx_ids: selectionModel };
    let [res, err] = await api_PublishMultipleDataObject(param);
    if (err) return;
    GetDataList();
    let selected_row = res.map((el) => el["status_text"]);
    SubmitInformation(selected_row);
  }
  async function Delete() {
    if (isEmptyArray(selectionModel)) return;
    if (!(await ConfirmCommon("Confirm Delete"))) return;
    let param = selectionModel.map((el) => ({ sbx_id: el }));
    let [res, err] = await api_CancelDataObject(param);
    if (err) return;
    GetDataList();
    inform_success("Deleted.");
  }
  const col = useMemo(() => {
    // function handleClickRow(params) {
    //   history.push({
    //     pathname: "/DataCatalog/Edit",
    //     state: { sbx_id: params.sbx_id },
    //   });
    // }
    let custom_col = [
      // {
      //   field: "action",
      //   headerName: "Edit",
      //   align: "center",
      //   minWidth: 50,
      //   flex: 0,
      //   sortable: false,
      //   renderCell: (params) => (
      //     <Box component="div" display="flex" justifyContent="center">
      //       <EditIcon
      //         color="action"
      //         //className={classes.icon}
      //         onClick={() => {
      //           handleClickRow(params.row);
      //         }}
      //       />
      //     </Box>
      //   ),
      // },
      {
        field: "sbx_created_timestamp",
        headerName: "Created Date",
      },
      {
        field: "sbx_updated_timestamp",
        headerName: "Updated Date",
      },
      {
        field: "data_object_table_name",
        headerName: "Data Object",
        renderCell: (params) => (
          <Link onClick={() => handleClickRow(params["row"])} href="#">
            {params.value}
          </Link>
        ),
      },
    ];
    return {
      all: db_col,
      date: date_col,
      date_time: datetime_col,
      custom_col: custom_col,
    };
  }, [handleClickRow]);
  return (
    <>
      <Box my={1} display="flex" justifyContent={"flex-end"}>
        <Box style={{ width: "150" }} mr={1}>
          <SelectTemplate name="Template" />
        </Box>
        <Button onClick={clickImport}>Import Data</Button>
        <Box mx={1}></Box>
        <Button onClick={Publish}>Submit Data</Button>
        <Box mx={1}></Box>
        <Button onClick={Delete}>Delete Data (Draft)</Button>
      </Box>
      <CMDataGrid
        rows={SbxObjList || []}
        getRowId={(row) => row.sbx_id}
        customColumn={col}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
      <PopupImport2 open={importOpen} onClose={Close} success={GetDataList} />
      <PopupDetail
        open={isOpen}
        onClose={close}
        data={Data}
      />
    </>
  );
}
const db_col = [
  "status_text",
  "session_owner",
  "edit_type",
  "sheet_name",
  "schema_name",
  "data_object_table_name",
  "filename_tablename",
  "source_system",
  "source_path",
  "mode_of_transfer",
  "file_type",
  "desitnation",
  "frequency",
  "delivery_time",
  "availability_nature",
  "extraction_criteria",
  "sheet_name_1",
  "remarks",
  "imported_by",
  "imported_timestamp",
  "sbx_created_timestamp",
  "sbx_updated_timestamp",
  "edit_status",
];
const date_col = [
  "registration_date",
  "last_update_date",
  "status_update_date",
];
const datetime_col = [
  "imported_timestamp",
  "sbx_created_timestamp",
  "sbx_updated_timestamp",
];
