import React, { useEffect, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
//Material
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
//Component
import { SearchTextBoxFull } from "stateless/hook-controls/input";
import { Modal } from "stateless/modal";
import { CheckboxTemplateList } from "stateless/selection-control/checkbox/inbound/template";
//utils
import { titleCase } from "utils/common-function";

export function TargetTableTemplateDialog(props) {
  const {
    retrieveData = () => {},
    defaultSelected,
    name,
    tableInfo,
    onClose,
    clickOpen,
    toggle,
    ...rest
  } = props;

  const methods = useForm({ defaultValues: { [name]: [] } });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
  } = methods;

  useEffect(() => {
    setValue(name, defaultSelected);
  }, [defaultSelected, setValue, name]);

  const footer = (
    <>
      <Box display="flex" flexgrow={1} justifyContent={"flex-end"}>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveAltIcon />}
            onClick={() => {
              retrieveData(getValues());
              reset();
            }}
          >
            Confirm
          </Button>
        </Box>
        <Box m={1}>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </>
  );

  const watch_data = useWatch({ control, name: "Fulltext" });
  if (!props.open) return null;

  return (
    <>
      <Modal
        maxWidth="md"
        title={titleCase("Add Template File")}
        hiddenSave
        plain
        hideFooterDivder
        ComponentFooter={footer}
        {...rest}
      >
        <FormProvider {...methods}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" flexgrow={1} justifyContent={"center"} mb={2}>
                <Box flexgrow={1} name="SearchTextBox">
                  <SearchTextBoxFull
                    name="Fulltext"
                    onClear={() => setValue("Fulltext", "")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CheckboxTemplateList
                name={name}
                control={control}
                filter={watch_data}
                setValue={setValue}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </Modal>
    </>
  );
}
