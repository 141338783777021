import React, { useState, useEffect } from "react";
//Material
import Box from "@material-ui/core/Box";
//Component
import { ReactTable } from "../../../../stateless/react-table";
import { Modal } from "../../../../stateless/modal";
import { getColumReprocessDetail } from "../columns/popup-reprocess-detail";
import ModalPlanDetail from "../modal/modal-plan-detail";
//Function
import * as api from "../api";
import { isEmptyStr } from "utils/common-function";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";

function ReprocessDetails(props) {
    const [DataList, setDataList] = useState([]);
    const { open, id } = props;
    const {
        isOpen: isOpenDetail,
        close: closeDetail,
        Data: dataDetail,
        handleClickRow: clickDetail,
    } = useSelectRowOpenModal();
    const viewHistory = async (row) => {
        clickDetail(row);
    };
    useEffect(() => {
        if (!open) return;
        (async () => {
            if (isEmptyStr(id)) return;
            let res = await api.GetReprocessPlanDetailList({ REPROCESS_PLAN_ID: id });
            res && setDataList(res);
        })();
    }, [open, id]);
    return (
        <>
            <Modal {...props} title={`Reprocess Detail`} hiddenSave maxWidth="xl" className="headerModalReprocessHistoryDetail">
                <Box display="block" bgcolor="background.paper">
                    <ReactTable
                        keyField="ITEM_ID"
                        data={DataList}
                        columns={getColumReprocessDetail(viewHistory)}
                    />
                </Box>
            </Modal>
            <ModalPlanDetail
                open={isOpenDetail}
                onClose={closeDetail}
                data={dataDetail}
            />
        </>
    );
}

export default ReprocessDetails;
