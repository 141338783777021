import axios from "axios";
import moment from "moment";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { GetUser } from "utils/sessions";
import {
  isValidDateISOString,
  isEmptyObj,
  isEmptyArray,
  isEmptyStr,
} from "utils/common-function";
import { inform_error, inform_success } from "utils/alert";
import { getToday } from "utils/date";
import { DownloadFromBase64 } from "utils/file";
const { log } = console;
export const api_GetUploadAdjustmentsList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetUploadAdjustmentsList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetUploadDetailList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetUploadDetailList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetUploadErrorList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetUploadErrorList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_UploadAdjustFile = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/UploadAdjustFile`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_CheckUploadAdjustFile = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/CheckUploadAdjustFile`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetAdjustmentHistoryList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetAdjustmentHistoryList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_ReadFile = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/ReadFileAdjust`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function useUploadAdjust({ filter }) {
  const controlSelect = useState([]);
  const [selectionModel] = controlSelect;
  const [UploadAdjustList, setUploadAdjustList] = useState([]);
  const formMethods = useForm({
    defaultValues: { datadate: getToday() },
  });
  const { getValues } = formMethods;
  //state here
  const controlDialogError = useSelectRowOpenModal();
  const controlDialogDetail = useSelectRowOpenModal();
  const controlDialogHistory = useSelectRowOpenModal();
  const state = {
    UploadAdjustList,
    controlDialogError,
    controlDialogDetail,
    controlDialogHistory,
  };
  const getUploadAdjustmentsList = useCallback(async () => {
    //call api
    let param = {
      DATADATE: getValues().datadate,
      group_users: filter.GroupUser,
    };
    let [res, err] = await api_GetUploadAdjustmentsList(param);
    if (err) return;
    setUploadAdjustList(res);
  }, [getValues, filter]);
  useEffect(() => {
    getUploadAdjustmentsList();
  }, [getUploadAdjustmentsList]);

  async function uploadAdjustment(file) {
    if (isEmptyObj(file) || isEmptyArray(file)) return;

    // let param = getValues();
    // var datadate = param.datadate;
    // if (!isValidDateISOString(param.datadate))
    //   datadate = param.datadate.toISOString();

    let form = new FormData();
    form.append("FILE", file);

    let [res, err] = await api_UploadAdjustFile(form);
    if (err) return;
    if (!isEmptyArray(res)) {
      inform_error(res.join("\n"), true);
      return 
    }
    inform_success('Upload complete')
    getUploadAdjustmentsList();
  }

  async function checkUpload(row) {
    let param = {
      id: row.id,
      TEMPLATE_NAME: row.TEMPLATE_NAME,
      UPLOAD_ID: row.UPLOAD_ID,
    };
    let [res, err] = await api_CheckUploadAdjustFile(param);
    if (err) return;

    if (res) getUploadAdjustmentsList();
  }

  //function here
  const download = async (row) => {
    let [res, err] = await api_ReadFile(row);
    if (err) return;
    if (isEmptyStr(res)) return;
    DownloadFromBase64(res, row.filename);
  };
  const downloadMultiple = async (row) => {
    let rows = UploadAdjustList.filter((el) => selectionModel.includes(el.template_name));
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      download(row);
    }
  };
  const actions = {
    checkUpload,
    viewError: async (row) => {
      controlDialogError.handleClickRow(row);
    },
    viewDetail: async (row) => {
      controlDialogDetail.handleClickRow(row);
    },
    viewHistory: async (row) => {
      controlDialogHistory.handleClickRow(row);
    },
    download,
    downloadMultiple,
    uploadAdjustment,
    getUploadAdjustmentsList,
  };
  return {
    state,
    actions,
    formMethods,
    controlSelect,
  };
}

export function useUploadError() {
  const [UploadErrorList, setUploadErrorList] = useState([]);
  const GetUploadErrorList = useCallback(async (params) => {
    let [res, err] = await api_GetUploadErrorList({
      template_name: params.TEMPLATE_NAME,
      EDW_DATA_DT: params.EDW_DATA_DT,
    });
    if (err) return;
    setUploadErrorList(res);
  }, []);
  const state = { UploadErrorList };
  const actions = {
    GetUploadErrorList,
  };
  return { state, actions };
}
export function useUploadDetail() {
  const [UploadDetailList, setUploadDetailList] = useState([]);
  const GetUploadDetailList = useCallback(async (params) => {
    let [res, err] = await api_GetUploadDetailList({
      UPLOAD_ID: params.UPLOAD_ID,
    });
    if (err) return;
    setUploadDetailList(res);
  }, []);
  const state = { UploadDetailList };
  const actions = {
    GetUploadDetailList,
  };
  return { state, actions };
}
