import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridFooterContainer, GridPagination } from "@mui/x-data-grid";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { FooterButtonInbound } from "stateless/button";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
import { KKPModal } from "stateless/modal/modal2";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { isEmptyArray } from "utils/common-function";
import { getToday } from "utils/date";

export const api_GetAdjustmentHistoryList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetAdjustmentHistoryList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetAdjustmentHistoryListByTable = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetAdjustmentHistoryListByTable`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
function useViewAdjustHistory() {
  const [DataRow, setDataRow] = useState([]);
  const state = { DataRow };
  const GetAdjustmentHistoryList = useCallback(async (params) => {
    let [res, err] = await api_GetAdjustmentHistoryList(params);
    if (err) return;
    setDataRow(res);
    if (isEmptyArray(res)) return;
  }, []);
  const GetAdjustmentHistoryListByTable = useCallback(async (params) => {
    let [res, err] = await api_GetAdjustmentHistoryListByTable(params);
    if (err) return;
    setDataRow(res);
    if (isEmptyArray(res)) return;
  }, []);
  const actions = {
    GetAdjustmentHistoryList,
    GetAdjustmentHistoryListByTable,
  };

  return { state, actions };
}
export function ViewAdjustHistoryDialog({ controlDialog, isTableView }) {
  const { isOpen, Data } = controlDialog;
  const { state, actions, formMethods } = useViewAdjustHistory();
  const {
    GetAdjustmentHistoryList,
    GetAdjustmentHistoryListByTable,
  } = actions;
  useEffect(() => {
    if (!isOpen) return;
    if (isTableView) {
      GetAdjustmentHistoryListByTable(Data);
    } else {
      GetAdjustmentHistoryList(Data);
    }
  }, [
    isOpen,
    isTableView,
    GetAdjustmentHistoryList,
    GetAdjustmentHistoryListByTable,
    Data,
  ]);

  return (
    <>
      <FormProvider {...formMethods}>
        <KKPModal control={controlDialog} title="Adjust History List">
          <Typography>Template Name : {Data?.template_name}</Typography>
          <Box mt={2}></Box>
          <Table data={state.DataRow} />
        </KKPModal>
      </FormProvider>
    </>
  );
}
function Table({ data }) {
  const columns = [];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={data || []}
      rowHeight={40}
      customColumn={{
        all: db_col,
        custom_col: columns,
        date: ["data_date"],
        date_time: ["upload_datetime", "adjust_datetime"],
      }}
      getRowId={(row) => row.id}
      components={{
        //Footer: CustomInboundFooterOneButtonComponent,
        //Toolbar: GridToolbar,
      }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col = [
  "adjust_on_round",
  "filename",
  "upload_datetime",
  "upload_by",
  "check_result",
  "check_datetime",
  "adjust_datetime",
  "adjust_by",
  "adjust_result",
  "status",
  "last_modified_date",
];
