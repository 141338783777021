import { titleCase } from "utils/common-function";
import { styleTextLink } from "utils/style";
export const getColumnsImport = (viewDetail = () => {}) => {
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      viewDetail(row);
    },
  };
  const columns = [
    {
      dataField: "index",
      text: titleCase("No."),
    },
    {
      dataField: "PLAN_NAME",
      text: titleCase("PLAN_NAME"),
      style: styleTextLink,
    },
    {
      dataField: "ORIGIN_UNIQUE",
      text: titleCase("ORIGIN"),
    },
    {
      dataField: "Status",
      text: titleCase("Status"),
    },
    {
      dataField: "Message",
      text: titleCase("Message"),
    },
  ];
  var foundIndex = columns.findIndex((x) => x.dataField === "PLAN_NAME");
  columns[foundIndex] = { ...columns[foundIndex], events: onClickCol };
  return columns;
};
