import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
// Util
import { common } from "utils";
//Material
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
//Component
import { ReactTable } from "stateless/react-table";
import { BaseContainer } from "stateless/base-container";
import CheckboxPlanType from "stateless/selection-control/checkbox/plantype";
import CheckboxPlanStatus from "stateless/selection-control/checkbox/planstatus";
import { Modal } from "stateless/modal";
import { MaterialUIDatePickers } from "stateless/hook-controls/datepicker/datepicker";
import ModalPlanDetail from "../../modal/modal-plan-detail";
import ModalDeploy from "../../modal/modal-deploy-plan";
import ModalExecute from "../../modal/modal-execute-plan";
//Context
import { ExecutionPlanContext } from "../../job-execution-plan-context";
import { LoadingContext, withLoadingContext } from "context/loading-context";

//Function
import { getColumPlanHistory } from "../../columns/popup-execution-plan-history";
import { getExecutionPlanColumns } from "../../columns/execution-plan";
import { GetExecutionPlanHistoryList, CheckCurrentEditedPlan } from "../../api";
import * as alertDialog from "utils/alert";

import { buildParameter } from "../../parameter";
import {
  url,
  methodRemovePlan,
  methodOnPlanStatusChanged,
} from "../../signalr";
import { useSignalR } from "../../../function-api/useSignalR";
import { GetUser } from "utils/sessions";
import { useSelectRowOpenModal } from "../../../function-api/useSelectRowOpenModal.js";
import { SelectOwnerList } from "stateless/selection-control/combo/owner-list";
const SelfServiceExecution = (props) => {
  const context = useContext(ExecutionPlanContext);
  const methods = useForm({
    defaultValues: context.state.CriteriaSearch,
  });
  const { actions } = context;
  const Loading_context = useContext(LoadingContext);
  const { actions: load_actions } = Loading_context;
  const { getValues, register } = methods;
  const history = useHistory();
  const {
    isOpen: isOpenDetail,
    close: closeDetail,
    Data: dataDetail,
    handleClickRow: clickDetail,
  } = useSelectRowOpenModal();
  const {
    isOpen: isOpenDeploy,
    close: closeDeploy,
    handleClickRow: clickDeploy,
  } = useSelectRowOpenModal();
  const {
    isOpen: isOpenExecute,
    close: closeExecute,
    Data: dataExecute,
    handleClickRow: clickExecute,
  } = useSelectRowOpenModal();
  //Callback
  const Search = useCallback(async () => {
    let paramsearch = buildParameter.GetSelfServiceExecutionList(getValues());
    load_actions.withLoading(() =>
      actions.GetSelfServiceExecutionList(paramsearch)
    );
  }, [getValues, actions, load_actions]);
  const filterdata = useCallback(() => {
    let param = getValues();
    actions.FilteringExecutionPlan(param);
  }, [actions, getValues]);
  const signalRUpdateCallback = useCallback(
    (data) => {
      actions.UpdatePlanList(data);
    },
    [actions]
  );
  const signalRRemoveCallback = useCallback(
    (data) => {
      actions.RemovePlanList(data);
    },
    [actions]
  );
  const DeployLogCallback = useCallback((data) => {
    console.log(data);
  }, []);
  useEffect(() => {
    filterdata();
  }, [context.state.ExecutionPlanList, filterdata]);
  useEffect(() => {
    register("isChecked");
  });
  useSignalR(url, methodOnPlanStatusChanged, signalRUpdateCallback);
  useSignalR(url, methodRemovePlan, signalRRemoveCallback);
  //useSignalR(url, methodLogDeployPlan,DeployLogCallback);
  useEffect(() => {
    const fetch = async () => {
      if (await CheckCurrentEditedPlan({ SESSION_OWNER: GetUser() })) {
        let res1 = alertDialog.Informcurrentedit();
        let res2 = actions.GetCurrentEditedSelfServicePlan({
          SESSION_OWNER: GetUser(),
        });
        await Promise.all([res1, res2]);
        Search();
        history.push("/JobMonitoring/Self-Service-Execution-Edit");
        return;
      }
      Search();
    };
    fetch();
  }, [actions, history, Search]);

  const editPlan = async (id) => {
    let param = {
      PLAN_ID: id,
      SESSION_OWNER: GetUser(),
    };
    await load_actions.withLoading(async () =>
      context.actions.GetSelfServicePlanDetail(param)
    );
    history.push({
      pathname: "/JobMonitoring/Self-Service-Execution-Edit",
    });
  };
  const deletePlan = async (id) => {
    let res = await alertDialog.ConfirmDelete();
    if (!res) return;

    let param = {
      PLAN_ID: id,
    };
    let api_res = await load_actions.withLoading(() =>
      context.actions.DeleteExecutionPlan(param)
    );
    if (!api_res) return;
    alertDialog.del_success();
    let paramsearch = buildParameter.GetExecutionPlanList(getValues());
    await load_actions.withLoading(() =>
      context.actions.GetExecutionPlanList(paramsearch)
    );
  };
  const viewHistory = async (row) => {
    clickDetail(row);
  };
  const ExecutePlan = async (row) => {
    clickExecute(row);
  };
  const columns = getExecutionPlanColumns(
    editPlan,
    deletePlan,
    viewHistory,
    ExecutePlan
  );
  const rowStyle = (row, rowIndex) => {
    return { backgroundColor: row.STATUS_COLOR };
  };
  const CreatePlan = async () => {
    let param = { SESSION_OWNER: GetUser() };
    await load_actions.withLoading(async () =>
      context.actions.CreateSelfServicePlan(param)
    );
    history.push({
      pathname: "/JobMonitoring/Self-Service-Execution-Edit",
    });
  };

  const tablecomponent = () => {
    return (
      <>
        <Box m={2} className="btnstyle">
          <Button variant="contained" onClick={Search}>
            Refresh
          </Button>
        </Box>
        <Box m={2} className="btnstyle">
          <Button variant="contained" onClick={CreatePlan}>
            New Plan
          </Button>
        </Box>
        {/* <Box m={2} className="btnstyle">
          <Button variant="contained" onClick={clickDeploy}>
            Deploy
          </Button>
        </Box> */}
      </>
    );
  };
  return (
    <>
      {Loading_context.state?.isLoading ? (
        <div className="lineLoading">
          <LinearProgress />
        </div>
      ) : null}
      <BaseContainer>
        <FormProvider {...methods}>
          <div>
            <Criteria />
          </div>
        </FormProvider>
        <Typography style={{ marginLeft: "5px" }}>
          Execution Plan List
        </Typography>

        <ReactTable
          data={context.state.FilterExecutionPlanList}
          columns={columns}
          keyField="PLAN_ID"
          rowStyle={rowStyle}
          headcomponent={tablecomponent}
          exportCsv
        />
        <ModalPlanDetail
          open={isOpenDetail}
          onClose={closeDetail}
          data={dataDetail}
        />
        <ModalExecute
          open={isOpenExecute}
          onClose={closeExecute}
          data={dataExecute}
        />
        <ModalDeploy open={isOpenDeploy} onClose={closeDeploy} />
      </BaseContainer>
    </>
  );
};

const Criteria = () => {
  const context = useContext(ExecutionPlanContext);
  const Loading_context = useContext(LoadingContext);
  const { actions: load_actions } = Loading_context;
  const methods = useFormContext();
  const { getValues, setValue, control } = methods;
  const CurrentisChecked = useWatch({
    control,
    name: "isChecked", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: false, // default value before the render
  });
  const Search = (checked = null) => {
    let data =
      checked !== null ? { ...getValues(), isChecked: checked } : getValues();
    let param = buildParameter.GetSelfServiceExecutionList(data);
    // Call Api
    load_actions.withLoading(() =>
      context.actions.GetSelfServiceExecutionList(param)
    );
  };
  useEffect(() => {
    setValue("isChecked", context.state.CriteriaSearch.isChecked);
  }, [setValue, context.state.CriteriaSearch.isChecked]);

  const filterdata = (e) => {
    let param = getValues();
    common.reCheckbox(getValues(), e);
    load_actions.withLoading(() =>
      context.actions.FilteringExecutionPlan(param)
    );
    context.actions.SetCriteriaSearch(param);
  };
  const dateChange = (e) => {
    context.actions.SetCriteriaSearch(getValues());
    Search();
  };
  const ownerChange = (e) => {
    setValue(e.target.name, e.target.value);
    context.actions.SetCriteriaSearch({
      ...getValues(),
      Owner: e.target.value,
    });
    Search();
  };
  return (
    <>
      <Box display="flex" flexWrap="wrap" className="criteriaStyle">
        <Box p={1} flexGrow={1} flexBasis={"250px"} className="boxCriteria">
          <CheckboxPlanType
            name="EXECUTION_PLAN_TYPE_ID"
            onChange={filterdata}
          />
          <Box p={1} flexBasis={"250px"} className="boxCriteria" display="flex">
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={CurrentisChecked}
                    onChange={(e) => {
                      context.actions.SetCriteriaSearch({
                        ...getValues(),
                        isChecked: e.target.checked,
                      });
                      Search(e.target.checked);
                      //setisChecked(e.target.checked);
                      //setValue("isChecked",e.target.checked)
                    }}
                    name="isChecked"
                    color="primary"
                  />
                }
                label="Scheduled Date"
              />
            </Box>
            <Box flexGrow={1}>
              <MaterialUIDatePickers
                name="DateFrom"
                label="date"
                disabled={!CurrentisChecked}
                onChange={dateChange}
              />
            </Box>
          </Box>
        </Box>

        <Box p={1} flexGrow={1} flexBasis={"430px"} className="boxCriteria">
          <CheckboxPlanStatus name="PLAN_STATUS_ID" onChange={filterdata} />
        </Box>
      </Box>
      {/* <Box width={"200"}>
        Owner :{" "}
        <SelectOwnerList name="Owner" hasall={true} onChange={ownerChange} />
      </Box> */}
    </>
  );
};
const HistoryModal = ({ planid, ...props }) => {
  const [HistoryList, setHistoryList] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      let res = await GetExecutionPlanHistoryList({ PLAN_ID: planid });
      setHistoryList(res);
    };
    if (!props.open) return;
    fetch();
  }, [props.open, planid]);
  return (
    <>
      <Modal
        {...props}
        title={`History Plan : ${HistoryList[0]?.PLAN_NAME || ""}`}
        hiddenSave
      >
        <Box display="block" bgcolor="background.paper">
          <ReactTable
            keyField="HISTORY_ID"
            data={HistoryList}
            columns={getColumPlanHistory()}
          />
        </Box>
      </Modal>
    </>
  );
};
export default withLoadingContext(SelfServiceExecution);
