import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
// Util
import { common } from "utils";
//Material
import Box from "@material-ui/core/Box";
import { DropzoneDialog } from "material-ui-dropzone";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
//Component
import { Modal } from "../../../../stateless/modal";
import FormInput from "../../../../stateless/hook-controls/input";
import { ReactTable } from "../../../../stateless/react-table";
import { getColumnsImport } from "../columns/popup-import-export";
import ModalOrigin from "./modal-origin";
import ModalViewPlanDetail from "./modal-plan-detail";
//Function
import { useSelectItem } from "../../../../stateless/react-table/useSelectItem";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import {
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyArray,
  titleCase,
} from "utils/common-function";
import * as alertDialog from "utils/alert";
import * as api from "../api";
//
function ModalImport(props) {
  const { open } = props;

  // state
  const methods = useForm();
  const [openUpload, setOpenUpload] = useState(false);
  const [openOrigin, setOpenOrigintModal] = useState(false);
  const [file, setfile] = useState([]);
  const [jsonFile, setJsonFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { handleOnSelect, handleOnSelectAll, SelectedItems } = useSelectItem(
    "PLAN_NAME"
  );
  // --------------------------------------------
  useEffect(() => {
    if (!open) return null;
    setJsonFile(null);
    setfile(null);
  }, [open]);

  // Function
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const withLoading = async (promise_function) => {
    setIsLoading(true);
    let result = await common.to_raw(promise_function());
    setIsLoading(false);
    return result;
  };

  async function upload(files = []) {
    try {
      var reader = new FileReader();
      reader.onload = function (event) {
        setJsonFile(JSON.parse(event.target.result));
      };
      reader.readAsText(files[0]);
      setfile(files[0]);
    } catch (e) {
      console.error(e);
    }
  }

  const Import = async () => {
    // console.log(jsonFile)
    let form = new FormData();
    // form.append("file", file);
    form.append("list_plan_upload", JSON.stringify(jsonFile));

    let res = await withLoading(async () => api.ImportPlan(form));
    if (res) {
      alertDialog.inform_success();
      setJsonFile(res);
    }
  };

  // For Plan Detail
  const {
    isOpen: isOpenPlanDetail,
    close: closePlanDetail,
    Data: planDetail,
    handleClickRow: clickPlanDetail,
  } = useSelectRowOpenModal();
  const viewPlanDetail = async (row) => {
    clickPlanDetail(row);
  };

  // Event
  const onOpenSetOrginModal = () => {
    if (isEmptyArray(SelectedItems)) return;
    setOpenOrigintModal(true);
  };
  const onCloseOriginModal = () => {
    setOpenOrigintModal(false);
  };

  // Backdrop
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  const tablecomponent = () => {
    return (
      <>
        <Box m={2} className="btnstyle">
          <Button variant="contained" onClick={onOpenSetOrginModal}>
            Set Origin
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      <Modal
        {...props}
        title={`Import Plan`}
        onSave={Import}
        SaveText={`Submit`}
        maxWidth="lg"
      >
        <FormProvider {...methods}>
          <Box display="block">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" className="update-field" m={2}>
                  <FormInput
                    disabled
                    name="FILE_NAME"
                    label="Source File"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={file?.path || ""}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenUpload(true);
                    }}
                  >
                    Browse
                  </Button>
                </Box>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ReactTable
                  keyField="PLAN_GUID"
                  headcomponent={tablecomponent}
                  clickToSelect
                  handleOnSelect={handleOnSelect}
                  handleOnSelectAll={handleOnSelectAll}
                  data={
                    jsonFile?.map((el, index) => ({
                      ...el,
                      PLAN_GUID: el.Plan.tnx_execution_plan.PLAN_GUID,
                      PLAN_NAME: el.Plan.tnx_execution_plan.PLAN_NAME,
                      ORIGIN_UNIQUE: [
                        ...new Set(
                          el.Plan.list_tnx_execution_plan_job_detail.map(data => data.ORIGIN)
                        ),
                      ].join(", "),
                      Status: el.ImportResult?.ReturnStatus,
                      Message: el.ImportResult?.Message,
                      index: ++index,
                    })) || []
                  }
                  columns={getColumnsImport(viewPlanDetail)}
                />
              </Grid>
            </Grid>
          </Box>
          {isLoading ? <br /> : null}
          {isLoading ? <LinearProgress /> : null}
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DropzoneDialog
            acceptedFiles={[".json, application/json"]}
            cancelButtonText={"cancel"}
            filesLimit={1}
            submitButtonText={"submit"}
            maxFileSize={30000000}
            open={openUpload}
            onClose={() => setOpenUpload(false)}
            onSave={(files) => {
              upload(files);
              setOpenUpload(false);
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
          />
        </FormProvider>
      </Modal>
      <ModalOrigin
        open={openOrigin}
        onClose={onCloseOriginModal}
        JsonFile={jsonFile}
        selectedItems={SelectedItems}
      />
      <ModalViewPlanDetail
        open={isOpenPlanDetail}
        onClose={closePlanDetail}
        data={planDetail}
      />
    </>
  );
}
export default ModalImport;
