import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
  Controller,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
//Material
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import EditIcon from "@material-ui/icons/Edit";
//component
import { BaseContainer } from "stateless/base-container";
import { Button } from "stateless/button";
import FormInput from "stateless/hook-controls/input";
import SelectStatus from "stateless/selection-control/combo/datalist-status";
import PopupImport from "./popup-import";
import { useOpenDialog } from "apps/main/function-api/useOpenDialog";
import { CMDataGrid } from "stateless/data-grid";
import { SubmitInformation } from "../utils/function";
import PopupImport2 from "./popup-import template2";
//context

//utils
import { isEmptyArray, isEmptyStr, titleCase } from "utils/common-function";
import { inform_success, ConfirmCommon } from "utils/alert";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import axios from "axios";
import { API_URL } from "../../../utils/api-utils";
import { common } from "../../../utils";
import { ToDateText } from "utils/date";
import { useParams } from "react-router";
import { FlashOnRounded } from "@material-ui/icons";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal.js";
import { styleTextLink } from "utils/style";
import { ObjectTemplate2 } from "./template2";
import { TemplateID } from "../utils/constants";
import { SelectTemplate } from "../stateless-datacatalog/select";

//const

//api
export const LoadSbxDataObjectList = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/LoadSbxDataObjectList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_PublishMultipleDataObject = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/PublishMultipleDataObject`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_CancelDataObject = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/CancelDataObject`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const UpdateStatus = async (param = { Dataset_Ids: [], Status: "" }) => {
  let url = `${API_URL.Report}/api/Report/UpdateStatus`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const CancelDraftIndex = async (param = { session_ids: [] }) => {
  let url = `${API_URL.Report}/api/Report/CancelDraftIndex`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const CreateDataObject = async (param) => {
  let url = `${API_URL.Report}/api/Report/CreateDataObject`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
const { log } = console;
const db_col = [
  "status_text",
  "session_owner",
  "edit_type",
  "action",
  "sheet_name",
  "domain",
  "sub_domain",
  "schema_name",
  "data_object_table_name",
  "description",
  "business_glossary",
  "data_specialist_name",
  "data_object_type",
  "data_source",
  "data_storage",
  "data_update_frequency",
  "data_privacy_exist",
  "keyword_for_search",
  "registration_date",
  "last_update_date",
  "author_name",
  "status",
  "status_update_date",
  "imported_by",
  "imported_timestamp",
  "sbx_created_timestamp",
  "sbx_updated_timestamp",
  "edit_status",
];
const date_col = [
  "registration_date",
  "last_update_date",
  "status_update_date",
];
const datetime_col = [
  "imported_timestamp",
  "sbx_created_timestamp",
  "sbx_updated_timestamp",
];
//
//
//SCREEN
//
//
function DataObjectList() {
  const methods = useForm({ defaultValues: { Template: "1" } });
  const { control } = methods;
  const template_id = useWatch({ control, name: "Template" });
  return (
    <>
      <BaseContainer>
        <FormProvider {...methods}>
          {template_id === TemplateID.template1 && <ObjectTemplate1 />}
          {template_id === TemplateID.template2 && <ObjectTemplate2 />}
        </FormProvider>
      </BaseContainer>
    </>
  );
}
function ObjectTemplate1() {
  const history = useHistory();
  const location = useLocation();
  const methods = useFormContext();
  const [importOpen, Close, Open] = useOpenDialog();
  const [selectionModel, setSelectionModel] = useState([]);
  const [SbxObjList, setSbxObjList] = useState([]);
  const { getValues, setValue } = methods;
  const GetDataList = useCallback(async () => {
    let param = { ...getValues(), template_id: TemplateID.template1 };
    let [data, err] = await LoadSbxDataObjectList(param);
    if (err) return;
    setSbxObjList(data);
  }, [getValues]);
  useEffect(() => {
    GetDataList();
  }, [GetDataList]);

  const col = useMemo(() => {
    function handleClickRow(params) {
      history.push({
        pathname: "/DataCatalog/Edit",
        state: { sbx_id: params.sbx_id },
      });
    }
    let custom_col = [
      {
        field: "action",
        headerName: "Edit",
        align: "center",
        minWidth: 50,
        flex: 0,
        sortable: false,
        renderCell: (params) => (
          <Box component="div" display="flex" justifyContent="center">
            <EditIcon
              color="action"
              //className={classes.icon}
              onClick={() => {
                handleClickRow(params.row);
              }}
            />
          </Box>
        ),
      },
      {
        field: "data_privacy_exist",
        type: "boolean",
      },
      {
        field: "domain",
        headerName: "Data Domain",
      },
      {
        field: "sub_domain",
        headerName: "Data Sub Domain",
      },
      {
        field: "sbx_created_timestamp",
        headerName: "Created Date",
      },
      {
        field: "sbx_updated_timestamp",
        headerName: "Updated Date",
      },
    ];
    return {
      all: db_col,
      date: date_col,
      date_time: datetime_col,
      custom_col: custom_col,
    };
  }, [history]);
  //
  async function Publish() {
    if (isEmptyArray(selectionModel)) return;
    if (!(await ConfirmCommon("Confirm Publish"))) return;
    let param = { sbx_ids: selectionModel };
    let [res, err] = await api_PublishMultipleDataObject(param);
    if (err) return;
    GetDataList();
    let selected_row = res.map((el) => el["status_text"]);
    SubmitInformation(selected_row);
  }
  async function Delete() {
    if (isEmptyArray(selectionModel)) return;
    if (!(await ConfirmCommon("Confirm Delete"))) return;
    let param = selectionModel.map((el) => ({ sbx_id: el }));
    let [res, err] = await api_CancelDataObject(param);
    if (err) return;
    GetDataList();
    inform_success("Deleted.");
  }
  function clickImport() {
    Open();
  }
  async function clickCreate() {
    let [res, err] = await CreateDataObject({});
    if (err) return;
    history.push({
      pathname: "/DataCatalog/Edit",
      state: { sbx_id: res.sbx_id },
    });
  }
  return (
    <>
      <Box my={1} display="flex" justifyContent={"flex-end"}>
        <Box style={{ width: "150" }} mr={1}>
          <SelectTemplate name="Template" />
        </Box>
        <Button onClick={clickCreate}>Create Data Object (Draft)</Button>
        <Box mx={1}></Box>
        <Button onClick={clickImport}>Import Data Object</Button>
        <Box mx={1}></Box>
        <Button onClick={Publish}>Submit Data Object</Button>
        <Box mx={1}></Box>
        <Button onClick={Delete}>Delete Data Object (Draft)</Button>
      </Box>
      <CMDataGrid
        rows={SbxObjList || []}
        getRowId={(row) => row.sbx_id}
        customColumn={col}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
      <PopupImport open={importOpen} onClose={Close} success={GetDataList} />
      
    </>
  );
}

export default DataObjectList;
