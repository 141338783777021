import React, { useState } from "react";
import PropTypes from "prop-types";

export function useOpenDialog() {
  const [Open, setOpen] = useState(false);
  return [
    Open,
    () => {
      setOpen(false);
    },
    () => {
      setOpen(true);
    },
    () => {
      setOpen((prev) => !prev);
    },
  ];
}
export function useOpenDialog2() {
  const [open, setOpen] = useState(false);
  return {
    open,
    onClose: () => {
      setOpen(false);
    },
    clickOpen: () => {
      //Open
      setOpen(true);
    },
    toggle: () => {
      //Toggle
      setOpen((prev) => !prev);
    },
  };
}
useOpenDialog.propTypes = {};
