import axios from "axios";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
//Master
export const GetBuGroupList = async (param) => {
  let url = `${API_URL.Job}/api/Master/GetBuGroupList`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const DeleteBu = async (param) => {
  let url = `${API_URL.Job}/api/Master/DeleteBu`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const SaveBu = async (param) => {
  let url = `${API_URL.Job}/api/Master/SaveBu`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const checkDuplicateBu = async (param) => {
  let url = `${API_URL.Job}/api/Master/checkDuplicateBu`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const GetUserList = async (param) => {
  let url = `${API_URL.Job}/api/Master/GetUserList`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};