import React from "react";
import "./modal.css";
//Material
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
//Component

//Context

//Function

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, cleanUp, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box display="flex">
        <Box>
          <Typography variant="h6">{children}</Typography>
        </Box>
        <Box flexGrow={1}></Box>
        <Box justifyContent="flex-end" mr={3}>
          {cleanUp ? (
            <Link href="#" underline="none" variant="caption" onClick={cleanUp}>
              Clean Up
            </Link>
          ) : null}
        </Box>
        <Box>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
export default function Modal({ children, ...props }) {
  const { cleanUp, ...rest } = props;
  const {
    open,
    onClose,
    onSave,
    CloseText = "Close",
    SaveText = "Save",
    className,
    title,
    hiddenSave = false,
    plain = false,
    hideFooterDivder = false,
    ComponentFooter,
    ...otherprops
  } = rest;
  const Footer = () => {
    return (
      <>
        {hiddenSave ? null : (
          <Button onClick={onSave} color="primary">
            {SaveText}
          </Button>
        )}

        <Button onClick={onClose} color="primary">
          {CloseText}
        </Button>
      </>
    );
  };
  return (
    <>
      <Dialog
        {...otherprops}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={onClose}
        fullWidth
        className="main-dialog"
        id="main-dialog"
      >
        <DialogTitle
          id="customized-dialog-title"
          className={className}
          style={{
            backgroundColor: plain ? "#fff" : "#3b8acf",
            color: plain ? "#594f74" : "#fff",
          }}
          cleanUp={cleanUp}
          onClose={onClose}
        >
          {title}
        </DialogTitle>
        <Box>
          <DialogContent dividers={!hideFooterDivder}>{children}</DialogContent>
        </Box>
        <DialogActions className="btnDialog">
          {hideFooterDivder ? null : <Footer />}
        </DialogActions>
        <Box p={ComponentFooter ? 2 : 0}>{ComponentFooter}</Box>
      </Dialog>
    </>
  );
}
