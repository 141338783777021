import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { humanize, titleCase } from "utils/common-function";

export const getExecutionDependencyColumns = (editPlan, deletePlan) => {
    const columns = [
        {
            dataField: "OP1",
            text: "",
            sort: false,

            // events: onClickCol,
            formatter: (cell, row) => {
                return (
                    <Box component="div" display="flex" justifyContent="center">
                        <>
                            <EditIcon
                                color="action"
                                //className={classes.icon}
                                onClick={() => {
                                    editPlan(row);
                                }}
                            />
                            <DeleteIcon
                                color="action"
                                //className={classes.icon}
                                onClick={() => {
                                    deletePlan(row.DEPENDS_ON_PLAN_ID);
                                }}
                            />
                        </>
                    </Box>
                );
            },
        },
        {
            dataField: "PLAN_ID",
            text: titleCase("PLAN_ID"),
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "PLAN_NAME",
            text: titleCase("PLAN_NAME"),
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "TYPE_TEXT",
            text: "Plan Type",
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "DEP_DATADATE_TYPE_NAME",
            text: "DataDate Type",
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "DEP_DATADATE_VALUE",
            text: "DataDate Value",
            sort: true,
            formatter: (cell, row) => {
                if(row.DEP_DATADATE_TYPE_ID ===1) return row.DEP_DATADATE_VALUE
            }
            // events: onClickCol,
        },
        {
            dataField: "ENABLED",
            text: titleCase("ENABLED"),
            sort: true,
            // events: onClickCol,
        },
    ];
    return columns;
};
