import React, { useEffect } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
  Controller,
} from "react-hook-form";
//materail
import Box from "@material-ui/core/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Input from "@material-ui/core/Input";
//stateless
import { Modal } from "stateless/modal";
import { SearchTextBoxFull } from "stateless/hook-controls/input";
import { ButtonSearchTextBox } from "stateless/button";
import {  FilterShow } from "stateless/filterbar";
//utils
import { common } from "utils";
function PopupSearchMore(props) {
  const { retriveData = () => {}, defaultSelected, name1,name2, ...rest } = props;
  const methods = useForm({ defaultValues: { [name1]: [],[name2]:[] } });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    register,
  } = methods;
  useEffect(() => {
    setValue(name1, defaultSelected[name1]);
    setValue(name2, defaultSelected[name2]);
  }, [defaultSelected, setValue, name1,name2]);
  function clean() {
    reset();
  }
  function AddList(p_name, value,arrname) {
    
    setValue(p_name, "");
    setValue(arrname, [...watch_data[arrname], value]);
  }
  function expand_arr() {
    let data_arr = {[name1]:watch_data[name1],[name2]:watch_data[name2]}
    return common.expand_array(data_arr);
  }
  function RemoveTag(name, value) {
    setValue(
      name,
      watch_data[name].filter((el) => el !== value)
    );
  }
  const footer = (
    <>
    
      <Box display="flex" flexgrow={1} justifyContent={"center"}>
        <ButtonSearchTextBox
          long
          onClick={() => {
            retriveData(getValues());
            reset();
          }}
        >
          Confirm
        </ButtonSearchTextBox>
      </Box>
    </>
  );
  const watch_data = useWatch({ control });
  return (
    <>

      <Modal
        maxWidth="sm"
        title={`Data Object Name`}
        hiddenSave
        plain
        hideFooterDivder
        ComponentFooter={footer}
        cleanUp={clean}
        {...rest}
      >
        <FormProvider {...methods}>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexgrow={1}
              justifyContent={"center"}
              mb={2}
              flexDirection="column"
              alignItems={"center"}
            >
              <Box flexgrow={1} name="SearchTextBox" mb={1}>
                <SearchTextBoxFull
                  name="txtDescription"
                  onClear={() => setValue("txtDescription", "")}
                  placeholder="Search By Description"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13)
                      AddList(e.target.name, e.target.value,name1);
                  }}
                />
              </Box>
              <Box flexgrow={1} name="SearchTextBox">
                <SearchTextBoxFull
                  name="txtBusiness"
                  onClear={() => setValue("txtBusiness", "")}
                  placeholder="Search By Business Glossary"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13)
                      AddList(e.target.name, e.target.value,name2);
                  }}
                />
              </Box>
            </Box>
            <Box><FilterShow data={expand_arr()} onRemove={RemoveTag} /></Box>
          </Box>
        </FormProvider>
      </Modal>
    </>
  );
}
const getOpObj = (option) => {
  if (!option.title) option = top100Films.find((op) => op.title === option);
  return option;
};

const top100Films = [
  { title: "Search by Business Glossary:", year: 1994 },
  { title: "Search by Description:", year: 1972 },
];
export default PopupSearchMore;
