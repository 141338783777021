import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import FormSelect from "stateless/hook-controls/select";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
export const api_GetData = async (param) => {
  let url = `${API_URL.Job}/api/Master/GetUserMasterList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function SelectUsers({ param, ...props }) {
  const [Data, setData] = useState([]);
  let name = props.name;
  const { control, setValue } = useFormContext();
  const w_value = useWatch({ control, name: name });
  useEffect(() => {
    (async () => {
      let [res, err] = await api_GetData(param);
      if (err) return;
      const cbo =
        res && res.map(({ user_id: label, user_id: id }) => ({ label, id }));
      let found = cbo.find((el) => el.id === w_value);
      if (!found) setValue(name, undefined);
      setData(cbo);
    })();
  }, [param, w_value, name, setValue]);
  return (
    <>
      <FormSelect {...props} options={Data} />
    </>
  );
}
