import { createContext, useReducer, useState } from "react";
import {
  getExecutionPlanList,
  GetSelfServiceExecutionList,
  CreateExecutionPlan,
  GetSelfServiceJobMasterList,
} from "./api";
import * as api from "./api";
import * as master_api from "../master/api";
import { ExecutionPlanReducer, action_type, initial_state } from "./reducer";
import { buildParameter } from "./parameter";
import { HookisvalidDate } from "utils/date";
const getActions = (dispatch) => {
  const actions = {
    //Plan History
    GetPlanHistoryList: async (param) => {
      let data = await api.GetExecutionPlanHistoryList(param);
      if (data) {
        dispatch({ type: action_type.AssignPlanHistoryList, data });
      }
    },
    AddPlanHistoryList: async (newlist) => {
      dispatch({ type: action_type.AddPlanHistoryList, data: newlist });
    },
    UpdatePlanHistoryList: async (newlist) => {
      dispatch({ type: action_type.UpdatePlanHistoryList, data: newlist });
    },
    FilteringExecutionPlanHistory: async (param) => {
      dispatch({ type: action_type.UpdateFilterPlanHistoryList, param });
    },
    SetCriteriaSearchHistory: (param) => {
      dispatch({ type: action_type.UpdateCriteriaHistory, param });
    },
    //Manipulate with ExecutionPlanList
    GetExecutionPlanList: async (param) => {
      if (!HookisvalidDate(param, ["dateTo"])) return;
      let data = await getExecutionPlanList(param);
      if (data) {
        dispatch({ type: action_type.ASSIGN_ExecutionPlanList, data });
      }
    },
    UpdatePlanList: async (newlist) => {
      dispatch({ type: action_type.Update_ExecutionPlanList, data: newlist });
    },
    RemovePlanList: async (removelist) => {
      dispatch({
        type: action_type.Remove_ExecutionPlanList,
        data: removelist,
      });
    },
    FilteringExecutionPlan: async (param) => {
      dispatch({ type: action_type.Filter_ExecutionPlanList, param });
    },
    DeleteExecutionPlan: async (param) => {
      return await api.DeleteExecutionPlan(param);
    },
    SetCriteriaSearch: (param) => {
      dispatch({ type: action_type.UpdateCriteria, param });
    },
    //GetExecutionDetail
    CreatePlan: async (param) => {
      let data = await CreateExecutionPlan(param);
      if (data) {
        data = {
          ...data,
          SCHEDULE_TYPE_ID: data.PlanSchedule?.SCHEDULE_TYPE_ID,
        };
        dispatch({ type: action_type.UPDATE_ExecutionPlanDetail, data });
        actions.GetSBXPlanJobDetailList(
          buildParameter.GetSBXPlanJobDetailList(data)
        );
        actions.GetDependencyOfThisPlanSbx(
          buildParameter.GetPlanDependencies(data)
        );
        actions.GetDependencyChainOfThisPlanDisplay(
          buildParameter.GetPlanDependenciesChain(data)
        );
        return true;
      } else return false;
    },
    GetExecutionPlanDetail: async (param) => {
      let data = await api.GetExecutionPlanDetail(param);
      if (data) {
        //data ={...data,SCHEDULE_TYPE_ID:data.PlanSchedule.SCHEDULE_TYPE_ID}
        dispatch({ type: action_type.UPDATE_ExecutionPlanDetail, data });
        actions.GetSBXPlanJobDetailList(
          buildParameter.GetSBXPlanJobDetailList(data)
        );
        actions.GetDependencyOfThisPlanSbx(
          buildParameter.GetPlanDependencies(data)
        );
        actions.GetDependencyChainOfThisPlanDisplay(
          buildParameter.GetPlanDependenciesChain(data)
        );
        return true;
      }
    },
    GetCurrentEditedPlan: async (param) => {
      let data = await api.GetCurrentEditedPlan(param);
      if (data) {
        //data ={...data,SCHEDULE_TYPE_ID:data.PlanSchedule.SCHEDULE_TYPE_ID}
        dispatch({ type: action_type.UPDATE_ExecutionPlanDetail, data });
        actions.GetSBXPlanJobDetailList(
          buildParameter.GetSBXPlanJobDetailList(data)
        );
        actions.GetDependencyOfThisPlanSbx(
          buildParameter.GetPlanDependencies(data)
        );
        actions.GetDependencyChainOfThisPlanDisplay(
          buildParameter.GetPlanDependenciesChain(data)
        );
        return true;
      }
    },
    //Main Operation with SBX
    CancelEditExecutionPlan: async (param) => {
      let data = await api.CancelEditExecutionPlan(param);
      if (data > 0) {
        dispatch({ type: action_type.CLEAR_SCREEN });
        return true;
      }
    },
    SubmitExecutionPlan: async (param) => {
      let data = await api.SubmitExecutionPlan(param);
      if (data) {
        dispatch({ type: action_type.CLEAR_SCREEN });
        return true;
      }
    },
    //Manipulate with Detail
    SavePlanName: async (param) => {
      let data = await api.SavePlanNameSbx(param);
      if (data) {
        return true; //dispatch({ type: action_type.UPDATE_ExecutionPlanDetail, data });
      }
    },
    SavePlanScheduleSbx: async (param) => {
      await api.SavePlanScheduleSbx(param);
      actions.GetExecutionPlanDetail(param);
    },
    //Manipulate with JobList
    GetSBXPlanJobDetailList: async (param) => {
      let data = await api.GetSBXPlanJobDetailList(param);
      if (data) {
        //data ={...data,SCHEDULE_TYPE_ID:data.PlanSchedule.SCHEDULE_TYPE_ID}
        dispatch({ type: action_type.UPDATE_JobItemList, data });
        return;
      }
    },
    AddJobToPlanSbx: async (JobList = []) => {
      for (let index = 0; index < JobList.length; index++) {
        await api.AddJobToPlanSbx(JobList[index]);
      }
    },
    MoveUp: (ITEM_ID) => {
      dispatch({ type: action_type.MOVEUP_JobItemList, param: ITEM_ID });
    },
    MoveDown: (ITEM_ID) => {
      dispatch({ type: action_type.MOVEDOWN_JobItemList, param: ITEM_ID });
    },
    RevertSequence: () => {
      dispatch({ type: action_type.REVERT_JobItemList });
    },
    UpdateJobItemsSequence: async (param) => {
      return api.UpdateJobSequenceSbx(param);
    },
    DeleteJob: async (param) => {
      return await api.DeleteJob(param);
    },
    //Manipulate with dependency plan
    GetPlanDependenciesMasterList: async (param) => {
      let data = await api.getExecutionPlanList(param);
      if (data) {
        dispatch({ type: action_type.UPDATE_ExecutionPlanMasterList, data });
      }
    },
    GetDependencyOfThisPlanSbx: async (param) => {
      let data = await api.GetDependencyOfThisPlanSbx(param);
      if (data) {
        dispatch({ type: action_type.UPDATE_DENPENDENCIES_LIST, data });
        return;
      }
    },
    GetDependencyChainOfThisPlanDisplay: async (param) => {
      let data = await api.GetDependencyChainOfThisPlanDisplay(param);
      if (data) {
        data = data.map((el, i) => ({ ...el, running_no: i }));
        dispatch({ type: action_type.UPDATE_DependenciesChainList, data });
        return;
      }
    },
    AddDependencyOfThisPlanSbx: async (PlanList = []) => {
      for (let index = 0; index < PlanList.length; index++) {
        let data = await api.AddDependencyOfThisPlanSbx(PlanList[index]);
        if (index === PlanList.length - 1 && data) {
          actions.GetDependencyOfThisPlanSbx(
            buildParameter.GetPlanDependencies(data)
          );
        }
      }
    },
    UpdateDepencyDetail: async (param) => {
      let data = await api.UpdateDepencyDetail(param);
      if (data) {
        dispatch({ type: action_type.UPDATE_JobItemList, data });
        return;
      }
    },
    RemoveDependency: async (param) => {
      return await api.RemoveDependency(param);
    },
    //Reprocess
    GetReprocessPlanList: async (param) => {
      let data = await api.GetReprocessPlanList(param);
      if (data) {
        dispatch({ type: action_type.ASSIGN_ReprocessList, data });
      }
    },
    SetCriteriaSearchReprocess: (param) => {
      dispatch({ type: action_type.UpdateCriteriaReprocess, param });
    },
    //Group
    SetCriteriaSearchGroupExecution: (param) => {
      dispatch({ type: action_type.UpdateCriteriaGroupExecution, param });
    },
    //Master Job
    GetJobList: async () => {
      let data = await master_api.GetJobMasterList();
      if (data) {
        //data ={...data,SCHEDULE_TYPE_ID:data.PlanSchedule.SCHEDULE_TYPE_ID}
        dispatch({ type: action_type.UPDATE_JobList, data });
        return;
      }
    },
    FilteringJobList: (param) => {
      dispatch({ type: action_type.UPDATE_FilterJobList, param });
    },
    FilteringPlanList: async (param) => {
      dispatch({
        type: action_type.UPDATE_FilterExecutionPlanMasterList,
        param,
      });
    },
    // Self Service
    CreateSelfServicePlan: async (param) => {
      let data = await CreateExecutionPlan(param);
      if (data) {
        data = {
          ...data,
          SCHEDULE_TYPE_ID: data.PlanSchedule?.SCHEDULE_TYPE_ID,
        };
        dispatch({ type: action_type.UPDATE_ExecutionPlanDetail, data });
        actions.GetSBXPlanJobDetailList(
          buildParameter.GetSBXPlanJobDetailList(data)
        );
        actions.GetDependencyOfThisPlanSbx(
          buildParameter.GetPlanDependencies(data)
        );
        actions.GetSelfServiceDependencyChainOfThisPlanDisplay(
          buildParameter.GetPlanDependenciesChain(data)
        );
        return true;
      } else return false;
    },
    GetSelfServicePlanDetail: async (param) => {
      let data = await api.GetExecutionPlanDetail(param);
      if (data) {
        //data ={...data,SCHEDULE_TYPE_ID:data.PlanSchedule.SCHEDULE_TYPE_ID}
        dispatch({ type: action_type.UPDATE_ExecutionPlanDetail, data });
        actions.GetSBXPlanJobDetailList(
          buildParameter.GetSBXPlanJobDetailList(data)
        );
        actions.GetDependencyOfThisPlanSbx(
          buildParameter.GetPlanDependencies(data)
        );
        actions.GetSelfServiceDependencyChainOfThisPlanDisplay(
          buildParameter.GetPlanDependenciesChain(data)
        );
        return true;
      }
    },
    GetCurrentEditedSelfServicePlan: async (param) => {
      let data = await api.GetCurrentEditedPlan(param);
      if (data) {
        //data ={...data,SCHEDULE_TYPE_ID:data.PlanSchedule.SCHEDULE_TYPE_ID}
        dispatch({ type: action_type.UPDATE_ExecutionPlanDetail, data });
        actions.GetSBXPlanJobDetailList(
          buildParameter.GetSBXPlanJobDetailList(data)
        );
        actions.GetDependencyOfThisPlanSbx(
          buildParameter.GetPlanDependencies(data)
        );
        actions.GetSelfServiceDependencyChainOfThisPlanDisplay(
          buildParameter.GetPlanDependenciesChain(data)
        );
        return true;
      }
    },
    GetSelfServiceExecutionList: async (param) => {
      if (!HookisvalidDate(param, ["dateTo"])) return;
      let data = await GetSelfServiceExecutionList(param);
      if (data) {
        dispatch({ type: action_type.ASSIGN_ExecutionPlanList, data });
      }
    },
    GetSelfServiceJobMasterList: async () => {
      let data = await GetSelfServiceJobMasterList();
      if (data) {
        dispatch({ type: action_type.UPDATE_JobList, data });
        return;
      }
    },
    GetSelfServiceDependenciesPlanList: async (param) => {
      let data = await api.GetSelfServiceDependenciesPlanList(param);
      if (data) {
        dispatch({ type: action_type.UPDATE_ExecutionPlanMasterList, data });
      }
    },
    GetSelfServiceDependencyChainOfThisPlanDisplay: async (param) => {
      let data = await api.GetSelfServiceDependencyChainOfThisPlanDisplay(
        param
      );
      if (data) {
        data = data.map((el, i) => ({ ...el, running_no: i }));
        dispatch({ type: action_type.UPDATE_DependenciesChainList, data });
        return;
      }
    },
    SaveSelfServicePlanNameSbx: async (param) => {
      let data = await api.SaveSelfServicePlanNameSbx(param);
      if (data) {
        return true;
      }
    },
    CancelEditSelfServicePlan: async (param) => {
      let data = await api.CancelEditSelfServicePlan(param);
      if (data > 0) {
        dispatch({ type: action_type.CLEAR_SCREEN });
        return true;
      }
    },
    SubmitSelfServicePlan: async (param) => {
      let data = await api.SubmitSelfServicePlan(param);
      if (data) {
        dispatch({ type: action_type.CLEAR_SCREEN });
        return true;
      }
    },
    SaveSelfServicePlanScheduleSbx: async (param) => {
      await api.SaveSelfServicePlanScheduleSbx(param);
      actions.GetExecutionPlanDetail(param);
    },
  };
  return actions;
};

//1.Context
export const ExecutionPlanContext = createContext({
  state: initial_state,
  actions: getActions(),
});
//2.Provider
export const ExecutionPlanContextProvider = ({ children }) => {
  const [ExecutionState, dispatch] = useReducer(
    ExecutionPlanReducer,
    initial_state
  );
  const [actions] = useState(getActions(dispatch));
  //Method//
  return (
    <ExecutionPlanContext.Provider
      value={{
        state: ExecutionState,
        actions: actions,
        //actions: { ...initial_method, ...actions },
      }}
    >
      {children}
    </ExecutionPlanContext.Provider>
  );
};
