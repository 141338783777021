import { Badge, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { MaterialUIDatePickers } from "stateless/hook-controls/datepicker/datepicker";
import { DateGetDay, MuiDateTimeToApi, ToDateText } from "utils/date";

function DataDateBox({ onChange, dateHighlight }) {
  function rederdate(day, selectedDate, isInCurrentMonth, dayComponent) {
    //const date = makeJSDateObject(day); // skip this step, it is required to support date libs
    const isSelected =
      dateHighlight?.includes(ToDateText(day)) && isInCurrentMonth;
    return (
      <Badge badgeContent={isSelected ? "🟢" : undefined}>{dayComponent}</Badge>
    );
  }
  return (
    <Box display={"flex"}>
      <Box width={100} mt={1} mr={1}>
        {" "}
        <Typography>Data Date :</Typography>
      </Box>{" "}
      <Box>
        <MaterialUIDatePickers
          name="datadate"
          onChange={onChange}
          renderDay={rederdate}
        />
      </Box>
    </Box>
  );
}

export default DataDateBox;
