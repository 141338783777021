import { formatMs } from "@material-ui/core";
import { GetUser } from "utils/sessions";

export const buildParameter = {
  SaveDetail: (id, formdata) => {
    let param = {
      JOB_GUID: id,
      ORIGIN: formdata.ORIGIN,
      JOB_ID: formdata.JOB_ID,
      JOB_NAME : formdata.JOB_NAME,
      JOB_TYPE_ID : formdata.JOB_TYPE_ID,
      PARAMETERS : formdata.PARAMETERS,
      ACTIVE : formdata.ACTIVE,
      SESSION_OWNER: GetUser(),
    };
    return param;
  },
};
