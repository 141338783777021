import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  useOpenDialog,
  useOpenDialog2,
} from "apps/main/function-api/useOpenDialog";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { ViewTemplateDynamicDialog } from "./dialog-template-view";
//Material
import { GridToolbar } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { DropzoneDialog } from "material-ui-dropzone";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
//Component
import { PurpleButton } from "stateless/button";
import { Modal } from "stateless/modal";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
//utils
import { DownloadFromBase64 } from "utils/file";
import { titleCase } from "utils/common-function";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
import { isEmptyStr, isEmptyObj, isEmptyArray } from "utils/common-function";
import { inform_error, inform_success, ConfirmCommon } from "utils/alert";

export const api_GetMstTemplateList = async (param) => {
  let url = `${API_URL.Inbound}/api/Master/GetMstTemplateList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_UploadTemplateFile = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/UploadTemplateFile`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_DeleteTemplate = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/DeleteTemplate`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_ReadFileTemplate = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/ReadTemplateFile`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export function useTemplateMaster() {
  const [TemplateList, setTemplateList] = useState([]);
  const formMethods = useForm();

  //state here
  const controlDialogDetail = useSelectRowOpenModal();
  const controlDialogAddNewTemplate = useOpenDialog2();
  const state = {
    TemplateList,
    controlDialogDetail,
    controlDialogAddNewTemplate,
  };

  const GetMstTemplateList = useCallback(async () => {
    //call api
    let [res, err] = await api_GetMstTemplateList();
    if (err) return;
    setTemplateList(res);
  }, []);

  //viewDetail,

  async function viewTmplate(row) {
    controlDialogDetail.handleClickRow(row);
  }

  async function deleteTemplate(row) {
    if (!(await ConfirmCommon("Confirm Delete"))) return;
    let [res, err] = await api_DeleteTemplate({
      template_id: row.template_id,
      template_name: row.template_name,
    });
    if (err) return;
    if (res.status_id === 21) {
      inform_success("Deleted.");
      GetMstTemplateList();
    } else {
      inform_error(res.message, true);
    }
  }

  async function uploadTemplateFile(file) {
    if (isEmptyObj(file) || isEmptyArray(file)) return;

    let form = new FormData();
    form.append("FILE", file);

    let [res, err] = await api_UploadTemplateFile(form);
    if (err) return;
    if (!isEmptyArray(res)) {
      inform_error(res.join("\n"), true);
      return;
    }

    inform_success("Upload complete");
    GetMstTemplateList();
  }

  async function download(row) {
    let params = {
      template_name: row.template_name,
    };
    let [res, err] = await api_ReadFileTemplate(params);
    if (err) return;
    if (isEmptyStr(res)) {
      return;
    }
    var filename = params.template_name + ".csv";
    DownloadFromBase64(res, filename);
  }

  useEffect(() => {
    GetMstTemplateList();
  }, [GetMstTemplateList]);
  //function here

  const actions = {
    GetMstTemplateList,
    viewTmplate,
    deleteTemplate,
    uploadTemplateFile,
    download,
  };
  return {
    state,
    actions,
    formMethods,
  };
}

export function TemplateMasterDialog(props) {
  const { onClose, clickOpen, toggle, ...rest } = props;

  const methods = useTemplateMaster();
  const { formMethods } = methods;
  const { TemplateList, controlDialogAddNewTemplate, controlDialogDetail } =
    methods.state;

  if (!props.open) return null;

  return (
    <>
      <Modal
        maxWidth="md"
        title={titleCase("Template List")}
        onClose={onClose}
        hiddenSave
        plain
        hideFooterDivder
        {...rest}
      >
        <FormProvider {...formMethods}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Table
                  data={TemplateList}
                  addTemplate={controlDialogAddNewTemplate.clickOpen}
                  actions={methods.actions}
                  onClose={onClose}
                />
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
        <AddTemplateMasterDialog
          {...controlDialogAddNewTemplate}
          disableEnforceFocus
          name={"template_file"}
          methods={methods}
        />
        <ViewTemplateDynamicDialog controlDialog={controlDialogDetail} />
      </Modal>
    </>
  );
}
function Table({ data, addTemplate, actions }) {
  const { viewTmplate, deleteTemplate, download } = actions;
  const columns = [
    {
      field: "view",
      headerName: "VIEW",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => viewTmplate(params.row)}>
            View
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "delete",
      headerName: "DELETE",
      align: "center",
      minWidth: 150,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => deleteTemplate(params.row)}>
            Delete
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "download",
      headerName: "DOWNLOAD",
      align: "center",
      minWidth: 150,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => download(params.row)}>
            Download (.CSV)
          </PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={data || []}
      rowHeight={40}
      customColumn={{
        all: db_col,
        custom_col: columns,
        date: [],
        date_time: [],
      }}
      getRowId={(row) => row.template_id}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "Add New Template",
          buttonclick: addTemplate,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col = ["template_name"];

function AddTemplateMasterDialog(props) {
  const { name, onClose, clickOpen, toggle,methods, ...rest } = props;
  const { actions, formMethods } = methods;
  const { setValue, reset, errors, control } = formMethods;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    typography: {
      color: "#594f74",
    },
    paper: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
      height: "44px",
    },
  }));
  const classes = useStyles();
  const [uploadIsOpen, uploadOnClose, uploadOnOpen] = useOpenDialog();
  const [file, setFile] = useState([]);

  async function upload(files = []) {
    try {
      if (files.length > 0) {
        setFile(files[0]);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const footer = (
    <>
      <Box display="flex" flexgrow={1} justifyContent={"flex-end"}>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DriveFolderUploadIcon />}
            onClick={() => {
              actions.uploadTemplateFile(file);
              onClose();
            }}
          >
            Upload
          </Button>
        </Box>
        <Box m={1}>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </>
  );

  useEffect(() => {
    if (!props.open) return;
    reset();
    setFile([]);
  }, [props.open, reset, setFile]);

  return (
    <>
      <Modal
        maxWidth="sm"
        title={titleCase("Add New Template File")}
        onClose={onClose}
        hiddenSave
        plain
        hideFooterDivder
        ComponentFooter={footer}
        {...rest}
      >
        <FormProvider {...formMethods}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box className={classes.root}>
                <Box sx={{ justifyContent: "center", flex: "0 0 auto" }} mr={1}>
                  <Typography variant="body2" className={classes.typography}>
                    <b>TEMPLATE FILE :</b>
                  </Typography>
                </Box>
                <Paper className={classes.paper}>
                  <InputBase
                    readOnly
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Choose File"
                    inputProps={{
                      "aria-label": "choose file",
                      style: { cursor: "pointer" },
                    }}
                    onClick={uploadOnOpen}
                    value={file?.name || ""}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="upload"
                    onClick={uploadOnOpen}
                  >
                    <DriveFolderUploadIcon />
                  </IconButton>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
        <DropzoneDialog
          key={"uploadfile"}
          acceptedFiles={[".csv"]}
          cancelButtonText={"cancel"}
          filesLimit={1}
          submitButtonText={"submit"}
          maxFileSize={30000000}
          open={uploadIsOpen}
          onClose={uploadOnClose}
          onSave={(files) => {
            upload(files);
            uploadOnClose();
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
      </Modal>
    </>
  );
}
