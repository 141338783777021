import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridFooterContainer, GridPagination } from "@mui/x-data-grid";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { FooterButtonInbound } from "stateless/button";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
import { KKPModal } from "stateless/modal/modal2";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { isEmptyArray, isEmptyStr } from "utils/common-function";
import { getToday } from "utils/date";
import { DownloadFromBase64, GetDataFrom64 } from "utils/file";

export const api_ReadFile = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/ReadFileAdjust`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
function useViewAdjustFile() {
  const [DataRow, setDataRow] = useState([]);
  const [DataDataColumn, setDataColumn] = useState([]);
  const state = { DataRow, DataDataColumn };
  const GetTableDataList = useCallback(async (params) => {

    let [res, err] = await api_ReadFile(params);
    if (err) return;

    if (isEmptyStr(res)) {
      setDataRow([]);
      setDataColumn([])
      return;
    }
    let row = GetDataFrom64(res);
    row = row.map((el, idx) => ({ ...el, id: idx }));
    setDataRow(row);
    let col = Object.getOwnPropertyNames(row[0]);
    col.shift();
    setDataColumn(col);
  }, []);
  async function download(params) {
    let [res, err] = await api_ReadFile(params);
    if (err) return;
    if (isEmptyStr(res)) {
      return;
    }
    DownloadFromBase64(res, params.filename);
  }
  const actions = {
    GetTableDataList,
    download,
  };
  return { state, actions };
}
export function ViewAdjustFileDialog({ controlDialog }) {
  const { isOpen, Data } = controlDialog;
  const { state, actions, formMethods } = useViewAdjustFile();
  const { GetTableDataList, download } = actions;
  useEffect(() => {
    if (!isOpen) return;
    GetTableDataList(Data);
  }, [isOpen, GetTableDataList, Data]);

  return (
    <>
      <FormProvider {...formMethods}>
        <KKPModal control={controlDialog} title="Adjust Detail">
          <Typography>Template : {Data?.template_name}</Typography>
          <Box mt={2}></Box>
          <Typography>File name : {Data?.filename}</Typography>
          <Box mt={2}></Box>
          <Table
            data={state.DataRow}
            col={state.DataDataColumn}
            download={() => download(Data)}
          />
        </KKPModal>
      </FormProvider>
    </>
  );
}
function Table({ data, col, download }) {
  const columns = [];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={data || []}
      rowHeight={40}
      customColumn={{
        all: col,
        custom_col: columns,
        date: ["data_date"],
        date_time: ["upload_datetime", "adjust_datetime"],
      }}
      getRowId={(row) => row.id}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        //Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "DOWNLOAD (.CSV)",
          disabled: false,
          buttonclick: download,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
