import {
  createContext,
  useEffect,
  useState,
  useContext,
  useReducer,
} from "react";
import { update_array } from "utils/common-function";
import * as api from "../api";
import { filterPlan } from "../reducer";
const getActions = (dispatch) => {
  return {
    GetDailyPlanList: async (param) => {
      let data = await api.GetDailyPlanList(param);
      if (data) {
        dispatch({ type: action_type.SetDailyPlanList, data });
      }
    },
    UpdateDailyPlanList: async (data) => {
      if (data) {
        dispatch({ type: action_type.UpdateDailyPlanList, data });
      }
    },
    FilteringDailyPlan: async (param) => {
      dispatch({ type: action_type.FilteringDailyPlan, param });
    },
  };
};
//State
const initial_state = {
  DailyPlanList: [],
  DailyPlanFilterList: [],
};
//1.Context
export const DailyPlanContext = createContext({
  state: initial_state,
  actions: getActions(),
});
//2.Provider
export const DailyPlanContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initial_state);
  const [actions] = useState(getActions(dispatch));
  //Method//
  return (
    <DailyPlanContext.Provider value={{ state: state, actions: actions }}>
      {children}
    </DailyPlanContext.Provider>
  );
};
export function withContext(Component) {
  return function (...props) {
    return (
      <DailyPlanContextProvider>
        <Component {...props} />
      </DailyPlanContextProvider>
    );
  };
}
//3.Reducer
export const action_type = {
  SetDailyPlanList: "DailyPlan_SetDailyPlanList",
  UpdateDailyPlanList: "DailyPlan_UpdateDailyPlanList",
  FilteringDailyPlan: "DailyPlan_FilteringDailyPlan",
};
const Reducer = (state = initial_state, action) => {
  switch (action.type) {
    case action_type.SetDailyPlanList:
      return {
        ...state,
        DailyPlanList: action.data,
      };
    case action_type.UpdateDailyPlanList:
      let newdata = update_array(
        state.DailyPlanList,
        action.data,
        "INSTANCE_ID"
      );
      return { ...state, DailyPlanList: newdata };
    case action_type.FilteringDailyPlan:
      let filterd = filterPlan(state.DailyPlanList, action.param);
      return {
        ...state,
        DailyPlanFilterList: filterd,
      };
    default:
      break;
  }
  return state;
};
