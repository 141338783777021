import { isEmptyArray, isEmptyStr,titleCase } from "utils/common-function";

export function mapping_caption(text,FieldDictionary) {
  
  if (isEmptyStr(text)||isEmptyArray(FieldDictionary)) return text;
  let caption = FieldDictionary.find((el) => el.field === text)?.display;
  if (caption === undefined||isEmptyStr(caption)) return titleCase(text);
  return caption;
}

