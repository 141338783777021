import React, { useEffect, useState, useCallback } from "react";
import FormSelect from "../../hook-controls/select";
import { GetRunModeList } from "../../../module/master/api";
function ComboRunMode(props) {
  const [Data, setData] = useState([]);
  const GetList = useCallback(async () => {
    let data = await GetRunModeList();
    const cbo =
      data && data.map(({ runmode: label, runmode: id }) => ({ label, id }));
    setData(cbo);
  }, [setData]);
  useEffect(() => {
    GetList();
  }, [GetList]);

  return (
    <>
      <FormSelect {...props} options={Data} />
    </>
  );
}

export default ComboRunMode;
