import React, { useEffect, useContext, useCallback } from "react";
import { ReactTable } from "../../../../stateless/react-table";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
// Util
import { common } from "utils";
//Material
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
//Component
import { BaseContainer } from "../../../../stateless/base-container";
import { MaterialUIDatePickers } from "../../../../stateless/hook-controls/datepicker/datepicker";
import CheckboxExecutionType from "../../../../stateless/selection-control/checkbox/execution-type";
import CheckboxPlanStatus from "../../../../stateless/selection-control/checkbox/planstatus";
import { useEnablesignal } from "../../../../stateless/selection-control/checkbox/enablesignal";
import ModalPlanDetail from "../modal/modal-plan-detail";
//Context
import { ExecutionPlanContext } from "../job-execution-plan-context";
import {
  LoadingContext,
  withLoadingContext,
} from "../../../../context/loading-context";
//Function
import { getExecutionPlanHistoryColumns } from "../columns/execution-plan-history";
import { buildParameter } from "../parameter";
import { GetUser } from "utils/sessions";
import { ConfirmCommon, Informcurrentedit } from "utils/alert";
import { checkDateRange } from "utils/date";
import { CheckCurrentEditedPlan } from "../api";
import { url, methodOnPlanStatusChanged } from "../signalr";
import { useSignalR } from "../../function-api/useSignalR";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
function ExecutionPlanHistory() {
  //Context
  const { actions: load_actions, state: load_state } =
    useContext(LoadingContext);
  const context = useContext(ExecutionPlanContext);
  const { state, actions } = context;
  const Search = () => {
    let param = buildParameter.GetPlanHistoryList(methods.getValues());
    // Call Api
    load_actions.withLoading(() => context.actions.GetPlanHistoryList(param));
  };
  //API
  const methods = useForm({
    defaultValues: context.state.CriteriaSearchHistory,
  });
  const { getValues } = methods;
  const history = useHistory();
  const { component, isChecked: disableSignal } = useEnablesignal();
  //State
  const {
    isOpen,
    close,
    Data,
    handleClickRow: clickView,
  } = useSelectRowOpenModal();
  //Effect
  useEffect(() => {
    (async () => {
      if (await CheckCurrentEditedPlan({ SESSION_OWNER: GetUser() })) {
        let res1 = Informcurrentedit();
        let res2 = actions.GetCurrentEditedPlan({ SESSION_OWNER: GetUser() });
        await Promise.all([res1, res2]);
        history.push("/JobMonitoring/Execution-Plan");
      }
      let paramsearch = buildParameter.GetPlanHistoryList(getValues());
      load_actions.withLoading(() => actions.GetPlanHistoryList(paramsearch));
    })();
  }, [actions, history, getValues, load_actions]);
  const signalRHandler = useCallback(
    (data) => {
      actions.AddPlanHistoryList(data);
    },
    [actions]
  );
  //function
  useSignalR(url, methodOnPlanStatusChanged, signalRHandler, disableSignal);
  //const
  const columns = getExecutionPlanHistoryColumns(clickView);
  const rowStyle = (row, rowIndex) => {
    return { backgroundColor: row.STATUS_COLOR };
  };
  const tablecomponent = () => {
    return (
      <>
        <Box m={2} display="flex" className="btnstyle">
          {component(disableSignal ? Search : () => {})}
          <Button variant="contained" onClick={Search}>
            Refresh
          </Button>
        </Box>
      </>
    );
  };
  return (
    <>
      {load_state?.isLoading ? <LinearProgress /> : null}

      <BaseContainer>
        <FormProvider {...methods}>
          <Criteria />
        </FormProvider>
        <ReactTable
          data={state.FilterExecutionPlanHistoryList}
          columns={columns}
          keyField="HISTORY_ID"
          rowStyle={rowStyle}
          headcomponent={tablecomponent}
          exportCsv
        />
      </BaseContainer>
      <ModalPlanDetail open={isOpen} data={Data} onClose={close} />
    </>
  );
}
const Criteria = () => {
  const context = useContext(ExecutionPlanContext);
  const Loadingcontext = useContext(LoadingContext);
  const methods = useFormContext();
  const { handleSubmit, getValues, setValue, clearErrors, setError } = methods;
  const Search = () => {
    let param = buildParameter.GetPlanHistoryList(getValues());
    // Call Api
    Loadingcontext.actions.withLoading(() =>
      context.actions.GetPlanHistoryList(param)
    );
  };
  const filterdata = (e) => {
    let param = getValues();
    common.reCheckbox(getValues(), e);
    Loadingcontext.actions.withLoading(() =>
      context.actions.FilteringExecutionPlanHistory(param)
    );
    context.actions.SetCriteriaSearchHistory(param);
  };
  const dateChange = (e) => {
    clearErrors();
    let valid = checkDateRange(getValues().dateFrom, getValues().dateTo);
    context.actions.SetCriteriaSearchHistory(getValues());
    if (!valid) {
      setError("dateFrom", {
        type: "manual",
        //message: `Invalid date range`,
      });
      setError("dateTo", {
        type: "manual",
        message: `Invalid date range`,
      });
      return;
    }
    Search();
  };
  return (
    <>
      <Box display="flex" flexWrap="wrap" className="criteriaStyle">
        <Box
          flexBasis={"165px"}
          flexGrow={1}
          p={1}
          flexDirection="column"
          justifyContent="space-around"
          className="boxCriteria"
        >
          <Box pb={1}>
            {" "}
            <MaterialUIDatePickers
              name="dateFrom"
              label="From"
              onChange={dateChange}
            />
          </Box>

          <Box>
            <MaterialUIDatePickers
              name="dateTo"
              label="To"
              onChange={dateChange}
              minDate={context.state.CriteriaSearchHistory.dateFrom}
            />
          </Box>
        </Box>

        <Box p={1} flexGrow={1} flexBasis={"250px"} className="boxCriteria">
          <CheckboxExecutionType
            name="EXECUTION_TYPE_ID"
            onChange={filterdata}
          />
        </Box>
        <Box p={1} flexGrow={1} flexBasis={"430px"} className="boxCriteria">
          <CheckboxPlanStatus name="PLAN_STATUS_ID" onChange={filterdata} />
        </Box>
        {/* <Box p={1} flexGrow={1} maxWidth={400}>
          
        </Box> */}
      </Box>
    </>
  );
};
export default withLoadingContext(ExecutionPlanHistory);
