import Box from "@material-ui/core/Box";
//Material

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
//Function
import { GetUser } from "utils/sessions";
import { isEmptyStr, titleCase } from "utils/common-function";
import { formatDate, formatDateTime } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import { PLANSTATUS } from "../constants";
import { styleTextLink } from "utils/style";
export const getColumn = (
    clickView = () => { },
    editPlan = () => { },
    deletePlan = () => { }
) => {
    let columns = [
        {
            dataField: "group_name",
            text: titleCase("group_name"),
            sort: true,
            // events: onClickCol,
            style: styleTextLink,
            events: {
                onClick: async (e, column, columnIndex, row, rowIndex) => {
                    clickView(row);
                },
            },
        },
        {
            dataField: "invoked_datetime",
            text: titleCase("invoked_datetime"),
            sort: true,
            // events: onClickCol,
            formatter: (cell, row) => {
                return <>{ToDateTimeText(row.invoked_datetime)}</>;
            },
        },
        {
            dataField: "invoked_by",
            text: titleCase("invoked_by"),
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "forced_result_date",
            text: titleCase("forced_result_date"),
            sort: true,
            // events: onClickCol,
            formatter: (cell, row) => {
                return <>{ToDateText(row.forced_result_date)}</>;
            },
        },
        {
            dataField: "actual_result_date",
            text: titleCase("actual_result_date"),
            sort: true,
            // events: onClickCol,
            formatter: (cell, row) => {
                return <>{ToDateText(row.actual_result_date)}</>;
            },
        },
        {
            dataField: "run_mode",
            text: titleCase("run_mode"),
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "status_text",
            text: titleCase("status_text"),
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "message",
            text: titleCase("message"),
            sort: true,
            // events: onClickCol,
        },
        
       
    ];
    return columns;
};
