import { Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { KKPModal } from "stateless/modal/modal2";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { getToday } from "utils/date";

const { CMDataGrid2 } = require("stateless/data-grid/basic");
export const api_GetApproveHistoryList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetApproveHistoryList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
function useViewApproveHistory() {
  const [DataRow, setDataRow] = useState([]);

  const state = { DataRow };
  const GetApproveHistoryList = useCallback(async (params) => {
    let [res, err] = await api_GetApproveHistoryList(params);
    if (err) return;
    setDataRow(res);
  }, []);
  function download(params) {}
  const actions = {
    GetApproveHistoryList,
    download,
  };

  return { state, actions };
}
export function ApproveHistoryDetailDialog({ controlDialog }) {
  const { isOpen, Data } = controlDialog;
  const { state, actions, formMethods } = useViewApproveHistory();
  const { GetApproveHistoryList } = actions;
  useEffect(() => {
    if (!isOpen) return;
    GetApproveHistoryList(Data);
  }, [isOpen, GetApproveHistoryList, Data]);

  return (
    <>
      <FormProvider {...formMethods}>
        <KKPModal control={controlDialog} title="Approve History List">
          <Typography>Table Name : {Data?.table_name}</Typography>

          <Box mt={2}></Box>
          <TableAdjustHistory
            data={state.DataRow}
          />
        </KKPModal>
      </FormProvider>
    </>
  );
}
function TableAdjustHistory({ data, col, download }) {
  const columns = [];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={data || []}
      rowHeight={50}
      customColumn={{
        all: db_col_history,
        custom_col: columns,
        date: ["data_date"],
        date_time: ["created_date"],
      }}
      getRowId={(row) => row.history_id}
      components={
        {
          //Footer: CustomFooterOneButtonComponent,
          //Toolbar: GridToolbar,
        }
      }
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col_history = [
  "approve_round",
  "created_by",
  "created_date",
];
