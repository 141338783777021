import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import InboundCriteriaBox from "stateless/Inbound/Inbound-criteria-box";
import DataDateBox from "stateless/Inbound/datadate-box";
import useFilterbar from "stateless/filterbar/useFilterbar";
import { ToDateRangeText, ToDateText } from "utils/date";
import { useAdjust, useAdjustDetail } from "./function";
import { BaseContainer } from "stateless/base-container";
import { Box } from "@mui/system";
import { FilterManual, FilterShow } from "stateless/filterbar";
import { Button, Typography } from "@mui/material";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { isEmptyArray } from "utils/common-function";
import {
  GridFooterContainer,
  GridPagination,
  GridToolbar,
} from "@mui/x-data-grid";
import { FooterButtonInbound, PurpleButton } from "stateless/button";
import { KKPModal } from "stateless/modal/modal2";
import FormCheckBox from "stateless/hook-controls/checkbox";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { ViewAdjustHistoryDialog } from "../stateless/dialog/dialog-adjust-history";
import { ViewAdjustFileDialog } from "../stateless/dialog/dialog-adjust-file-view";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
const filter_field_list = ["GroupUser", "Status"];
const filter_field_silgle_list = ["DataDate"];
const formatter = [
  {
    field: "DataDate",
    handler: (val) => {
      return ToDateRangeText(ToDateText(val.from), ToDateText(val.to));
    },
  },
];
const default_filter = {
  GroupUser: [],
};
function Index() {
  const controlFilter = useFilterbar({
    fieldList: filter_field_list,
    defaultValues: default_filter,
    fieldSingle: filter_field_silgle_list,
    Formatter: formatter,
  });
  const methods = useAdjust({ filter: controlFilter.data });
  const { actions, state, formMethods } = methods;
  return (
    <>
      <FormProvider {...formMethods}>
        <InboundCriteriaBox>
          <DataDateBox onChange={actions.GetAdjustmentList} />
        </InboundCriteriaBox>
        <BaseContainer>
          <Box display={"flex"}>
            <FilterManual
              {...controlFilter}
              groupUser
              //Search={() => GetUploadManualList()}
            />
            <Box display={"flex"} pr={3}></Box>
          </Box>
          <Box my={1}>
            <FilterShow {...controlFilter} />
          </Box>
          <Table methods={methods} />
        </BaseContainer>
      </FormProvider>
      <ViewAdjustFileDialog controlDialog={state.controlDialogDetail} />
      <ViewAdjustHistoryDialog
        controlDialog={methods.state.controlDialogHistory}
        methods={methods}
      />
    </>
  );
}
function Table({ methods }) {
  const { viewDetail, adjust, adjustMultiple,viewHistory,disableAdjust } = methods.actions;
  const [selectionModel, setSelectionModel] = methods.controlSelect;
  const { AdjustList } = methods.state;
  const columns = [
    {
      field: "DETAIL",
      headerName: "DETAIL",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => viewDetail(params.row)}>
            View
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "History",
      headerName: "History",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => viewHistory(params.row)}>
            History
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "ADJUST",
      headerName: "ADJUST",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton disabled={disableAdjust(params.row)} onClick={() => adjust(params.row)}>Adjust</PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={AdjustList||[]}
      rowHeight={40}
        customColumn={{ all: db_col, custom_col: columns, date: ["edw_data_dt"], date_time: ["adjust_datetime"] }}
      getRowId={(row) => row.template_name}
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "Adjust",
          disabled: isEmptyArray(selectionModel),
          buttonclick: adjustMultiple,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}

const db_col = [
  "edw_data_dt",
  "adjust_on_round",
  "template_name",
  "status_text",
  "adjust_datetime",
  "adjust_by",
  "adjust_result",
];

export default Index;
