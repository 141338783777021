import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import { CheckboxThree } from "stateless/hook-controls/checkbox";
//Api
import { common } from "utils";
import { API_URL } from "utils/api-utils";
//util
import { isEmptyStr } from "utils/common-function";
const LoadData = async (data) => {
  let url = `${API_URL.Inbound}/api/Master/GetMstGroupReportList`;
  let { result } = await common.to(axios.post(url, data));
  if (result && result.data) return result.data;
};
export function CheckboxInboundGroupReportList(props) {
  const { showTitle, filter, ...rest } = props;
  const [ApiData, setApiData] = useState([]);
  useEffect(() => {
    (async () => {
      let data = await LoadData();
      let dataKey = data.map((item) => item.group_report_name);
      setApiData(dataKey);
    })();
  }, []);
  function filtering() {
    let data = ApiData?.filter(
      (el) =>
        el?.toLowerCase().indexOf(filter?.toLowerCase()) !== -1 ||
        isEmptyStr(filter)
    );
    var result = data?.reduce(function (map, obj) {
      map[obj] = false;
      return map;
    }, {});
    return result;
  }
  return (
    <>
      <Grid container className="checkboxBox">
        {showTitle && (
          <Grid item xs={12} m={1}>
            <Typography variant="body1" style={{ color: "#594f74" }}>
              <b>GROUP REPORT</b>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            className="checkboxList"
            display="flex"
            m={1}
            justifyContent={"space-between"}
            flexWrap="wrap"
            flexGrow={1}
          >
            {<CheckboxThree options={filtering()} {...rest} />}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
