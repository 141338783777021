import { humanize } from "utils/common-function";
export const getExecutionPlanMaster = () => {
    const columns = [
        {
            dataField: "PLAN_ID",
            text: "Plan Id",
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "PLAN_NAME",
            text: "Plan Name",
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "TYPE_TEXT",
            text: "Plan Type",
            sort: true,
            // events: onClickCol,
            formatter: (cell, row) => {
                return <>{row.TYPE_TEXT}</>;
            },
        },
    ];
    return columns;
};
