export const IMMEDIATELY = 1;
export const ONETIME = 2;
export const DAILY = 3;
export const WEEKLY = 4;
export const MONTHLY = 5;
export const QUARTERLY = 6;
//export const HalfYear = 7;
export const YEARLY = 8;
export const HOURY_WEEKDAY = 9;
export const HOURY_MONTHDAY = 10;

export const PLANSTATUS ={
    Idle:1,
    Wait:2,
    Running:9,
    Success:10,
    Error:11,
    WaitTimeout:100,
    Canceled:101,
    Denied:102,
    Skipped:103,
    Hold:104,
    Invalid:105,
    Unknown:500,
} ;
