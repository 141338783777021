import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { DropzoneDialog } from "material-ui-dropzone";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";
//Component
import { Modal } from "stateless/modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Divider } from "@material-ui/core";
//Utils
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal.js";
import { common } from "../../../utils";
import { API_URL } from "../../../utils/api-utils";
import { MuiDateTimeToApi } from "utils/date";
import { titleCase, isEmptyArray, isEmptyStr } from "utils/common-function";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import { ToDateText, ToDateTimeText } from "utils/date";
import { TemplateID } from "../utils/constants";
import { CaptionFieldContext } from "../caption-field-context/caption-field-context";
const { log } = console;
//Const

const LoadDetail = async (data) => {
  let url = `${API_URL.Report}/api/Report/LoadDataPropertyList`;
  let { result } = await common.to(axios.post(url, data));
  if (result && result.data) return result.data;
};
const Row = (props) => {
  const { title, children, highlight } = props;
  return (
    <>
      <TableRow>
        <TableCell scope="row" className="tdHeaderRow">
          <Typography variant="body2" color="textSecondary" component="p">
            {title}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="textSecondary" component="p">
            <Highlighter
              searchWords={[highlight]}
              textToHighlight={children || ""}
            />
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
function PopupDetail(props) {
  const { data, highlight, ...rest } = props;
  let title = `Data Object : ${data.data_property?.pdm_column_name}`
  if(data?.data_object?.template_id === TemplateID.template2 ) title = `Data : ${data.data_property?.fm_fieldname_source}`
  return (
    <>
      <Modal
        maxWidth="sm"
        title={title}
        hiddenSave
        {...rest}
      >
        {/* <Button onClick={toggle}>Download</Button> */}
        <Box>
          <Box className="modalBoxTableDetail">
            {data?.data_object?.template_id === TemplateID.template1 && (
              <ContentTemplate1 data={data} highlight={highlight} />
            )}
            {data?.data_object?.template_id === TemplateID.template2 && (
              <ContentTemplate2 data={data} highlight={highlight} />
            )}
            <ContentUpdateInf
              data={data}
              highlight={highlight}
            ></ContentUpdateInf>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
function ContentTemplate1({ data, highlight }) {
  return (
    <>
      <Typography
        variant="body2"
        component="p"
        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
      >
        PDM Information( Semantic Layer)
      </Typography>
      <Table className="tableDetail">
        <colgroup>
          <col style={{ width: "190px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <Row title="Schema" highlight={highlight}>
            {data.data_property?.pdm_schema}
          </Row>
          <Row title="PDM Table Name/File Name" highlight={highlight}>
            {data.data_property?.pdm_table_name_file_name}
          </Row>
          <Row title="Column Name" highlight={highlight}>
            {data.data_property?.pdm_column_name}
          </Row>
          <Row title="Data Type" highlight={highlight}>
            {data.data_property?.pdm_datatype}
          </Row>
          <Row title="Key" highlight={highlight}>
            {data.data_property?.pdm_key}
          </Row>
          <Row title={titleCase("field_definition")} highlight={highlight}>
            {data.data_property?.pdm_field_definition}
          </Row>
          <Row title="Sample Value" highlight={highlight}>
            {data.data_property?.pdm_sample}
          </Row>
          <Row title={titleCase("remark")} highlight={highlight}>
            {data.data_property?.pdm_remark}
          </Row>
        </TableBody>
      </Table>
      <Divider width="100%" />
      <Typography
        variant="body2"
        component="p"
        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
      >
        {titleCase("Source Information")}
      </Typography>
      <Table className="tableDetail">
        <colgroup>
          <col style={{ width: "190px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <Row title={titleCase("Schema")} highlight={highlight}>
            {data.data_property?.src_schema}
          </Row>
          <Row
            title={titleCase("src_table_name_file_name")}
            highlight={highlight}
          >
            {data.data_property?.src_table_name_file_name}
          </Row>
          <Row title="Alias" highlight={highlight}>
            {data.data_property?.src_alias}
          </Row>
          <Row title="Column Name" highlight={highlight}>
            {data.data_property?.src_column}
          </Row>
          <Row title="Data Type" highlight={highlight}>
            {data.data_property?.src_datatype}
          </Row>
          <Row title={titleCase("remark")} highlight={highlight}>
            {data.data_property?.src_remark}
          </Row>
        </TableBody>
      </Table>
      <Divider width="100%" />
      <Typography
        variant="body2"
        component="p"
        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
      >
        {titleCase("Transform")}
      </Typography>
      <Table className="tableDetail">
        <colgroup>
          <col style={{ width: "190px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <Row title={titleCase("mapping_ref_name")} highlight={highlight}>
            {data.data_property?.trans_mapping_ref_name}
          </Row>
          <Row title="Business Rule Type" highlight={highlight}>
            {data.data_property?.trans_business_rule}
          </Row>
          <Row title="Business Rule/Join/Condition" highlight={highlight}>
            {data.data_property?.trans_join_condition}
          </Row>
          <Row title={titleCase("remark")} highlight={highlight}>
            {data.data_property?.trans_remark}
          </Row>
        </TableBody>
      </Table>
    </>
  );
}
function ContentTemplate2({ data, highlight }) {
  const { state } = useContext(CaptionFieldContext);
  const captionHeader = state?.FieldDictionary.filter(
    (el) => el.table_name === "header_template2"
  );
  const captionDetail = state?.FieldDictionary.filter(
    (el) => el.table_name === "detail_template2"
  );
  return (
    <>
      <Typography
        variant="body2"
        component="p"
        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
      >
        Summary
      </Typography>
      <Table className="tableDetail">
        <colgroup>
          <col style={{ width: "190px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <Row
            title={captionHeader.find(
              (el) => el.field === "data_object_table_name"
            )?.display}
            highlight={highlight}
          >
            {data.data_object?.data_object_table_name}
          </Row>
          <Row
            title={captionHeader.find(
              (el) => el.field === "interface_description"
            )?.display}
            highlight={highlight}
          >
            {data.data_object?.interface_description}
          </Row>
          <Row
            title={captionHeader.find(
              (el) => el.field === "filename_tablename"
            )?.display}
            highlight={highlight}
          >
            {data.data_object?.filename_tablename}
          </Row>
          <Row
            title={captionHeader.find((el) => el.field === "source_system")?.display}
            highlight={highlight}
          >
            {data.data_object?.source_system}
          </Row>
          <Row
            title={captionHeader.find((el) => el.field === "source_path")?.display}
            highlight={highlight}
          >
            {data.data_object?.source_path}
          </Row>
          <Row
            title={captionHeader.find((el) => el.field === "mode_of_transfer")?.display}
            highlight={highlight}
          >
            {data.data_object?.mode_of_transfer}
          </Row>
          <Row
            title={captionHeader.find((el) => el.field === "file_type")?.display}
            highlight={highlight}
          >
            {data.data_object?.file_type}
          </Row>
          <Row
            title={captionHeader.find((el) => el.field === "frequency")?.display}
            highlight={highlight}
          >
            {data.data_object?.frequency}
          </Row>
          <Row
            title={captionHeader.find((el) => el.field === "delivery_time")?.display}
            highlight={highlight}
          >
            {data.data_object?.delivery_time}
          </Row>
          <Row
            title={captionHeader.find(
              (el) => el.field === "availability_nature"
            )?.display}
            highlight={highlight}
          >
            {data.data_object?.availability_nature}
          </Row>
          <Row
            title={captionHeader.find(
              (el) => el.field === "extraction_criteria"
            )?.display}
            highlight={highlight}
          >
            {data.data_object?.extraction_criteria}
          </Row>
          <Row
            title={captionHeader.find((el) => el.field === "remarks")?.display}
            highlight={highlight}
          >
            {data.data_object?.remarks}
          </Row>
        </TableBody>
      </Table>
      <Divider width="100%" />
      <Typography
        variant="body2"
        component="p"
        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
      >
        {titleCase("Detail")}
      </Typography>
      <Table className="tableDetail">
        <colgroup>
          <col style={{ width: "190px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
        <Row
            title={"Format"}
            highlight={highlight}
          >
            {data.data_property?.format_type}
          </Row>
          <Row
            title={captionDetail.find((el) => el.field === "fm_sn")?.display}
            highlight={highlight}
          >
            {data.data_property?.fm_sn}
          </Row>
          <Row
            title={captionDetail.find(
              (el) => el.field === "fm_fieldname_source"
            )?.display}
            highlight={highlight}
          >
            {data.data_property?.fm_fieldname_source}
          </Row>
          <Row
            title={captionDetail.find((el) => el.field === "fm_data_type")?.display}
            highlight={highlight}
          >
            {data.data_property?.fm_data_type}
          </Row>
          <Row
            title={captionDetail.find((el) => el.field === "fm_pk")?.display}
            highlight={highlight}
          >
            {data.data_property?.fm_pk}
          </Row>
          <Row
            title={captionDetail.find((el) => el.field === "fm_nullable")?.display}
            highlight={highlight}
          >
            {data.data_property?.fm_nullable}
          </Row>
          <Row
            title={captionDetail.find((el) => el.field === "fm_description")?.display}
            highlight={highlight}
          >
            {data.data_property?.fm_description}
          </Row>
          <Row
            title={captionDetail.find((el) => el.field === "fm_format")?.display}
            highlight={highlight}
          >
            {data.data_property?.fm_format}
          </Row>
          <Row
            title={captionDetail.find((el) => el.field === "fm_remarks")?.display}
            highlight={highlight}
          >
            {data.data_property?.fm_remarks}
          </Row>
          <Row
            title={captionDetail.find((el) => el.field === "fm_sample")?.display}
            highlight={highlight}
          >
            {data.data_property?.fm_sample}
          </Row>
        </TableBody>
      </Table>
    </>
  );
}
function ContentUpdateInf({ data, highlight }) {
  return (
    <>
      <Divider width="100%" />
      <Typography
        variant="body2"
        component="p"
        style={{ fontSize: "1.1rem", fontWeight: "bold" }}
      >
        {titleCase("Updating Information")}
      </Typography>
      <Table className="tableDetail">
        <colgroup>
          <col style={{ width: "190px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <Row title={titleCase("update_date")} highlight={highlight}>
            {data.data_property?.update_inf_updated_date}
          </Row>
          <Row title={titleCase("update_by")} highlight={highlight}>
            {data.data_property?.update_inf_updated_by}
          </Row>
          <Row title="Review By" highlight={highlight}>
            {data.data_property?.update_inf_review}
          </Row>
          <Row title={titleCase("remark")} highlight={highlight}>
            {data.data_property?.update_inf_remark}
          </Row>
          <Row title={titleCase("sheet_name")} highlight={highlight}>
            {data.data_property?.sheet_name}
          </Row>
        </TableBody>
      </Table>
    </>
  );
}
export default PopupDetail;
