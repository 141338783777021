import { humanize } from "utils/common-function";
export const getJobsColumns = () => {
    const columns = [
        {
            dataField: "JOB_NAME",
            text: "Job Name",
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "ORIGIN",
            text: "Job Origin",
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "JOB_ID",
            text: "Job Id",
            sort: true,
            // events: onClickCol,
        },
        {
            dataField: "TYPE_TEXT",
            text: "Job Type",
            sort: true,
            // events: onClickCol,
        },
    ];
    return columns;
};
