import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
//Component
import { Modal } from "stateless/modal";
// Material
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//Utils
import { common } from "../../../utils";
import { API_URL } from "../../../utils/api-utils";
const { log } = console;

function PopupMerge(props) {
  const { data, open, onClose } = props;
  const {
    getlatest = () => {},
    canceldataset = () => {},
    ...otherprops
  } = props;
  const [Data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      if (!open) return;
      setData(data);
      setIsLoadData("");
    })();
  }, [data, open]);

  const [isLoadData, setIsLoadData] = useState("");
  const UpdateOrCancel = async () => {
    let param = {
      data_object_id: Data?.data_object_id,
      IS_LOAD_DATA: !!parseInt(isLoadData,10),
    };

    if (param.IS_LOAD_DATA) {
      getlatest();
      onClose();
    } else {
      canceldataset();
    }
  };

  const handleChange = (event) => {
    setIsLoadData(event.target.value);
  };

  return (
    <>
      <Modal
        maxWidth="xs"
        title="ข้อมูลที่ท่านกำลังแก้ไขไม่ Update"
        SaveText={"Ok"}
        hiddenSave={!isLoadData}
        onSave={UpdateOrCancel}
        {...otherprops}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={isLoadData}
              name="IS_LOAD_DATA"
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                onChange={handleChange}
                label="Update Data"
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                onChange={handleChange}
                label="Cancel Draft Data"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
export default PopupMerge;
