import axios from "axios";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
//Export Import Plan
export const GetListPlan = async (param) => {
  let url = `${API_URL.Job}/api/master/GetListPlan`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const ImportPlan = async (param) => {
  let url = `${API_URL.Job}/api/master/ImportPlan`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const ExportPlan = async (param) => {
  let url = `${API_URL.Job}/api/master/ExportPlan`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};


export const GetListMaster = async (param) => {
  let url = `${API_URL.Job}/api/master/GetListImportJobMaster`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const ImportStream = async (param) => {
  let url = `${API_URL.Job}/api/master/ImportStream`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const ExportStream = async (param) => {
  let url = `${API_URL.Job}/api/master/ExportStream`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};