import {
  createContext,
  useEffect,
  useState,
  useContext,
  useReducer,
} from "react";
import moment from "moment";
import { ApiDateTimeFormat } from "utils/date";
import { update_array } from "utils/common-function";
import { buildParameter } from "./parameter";
import * as api from "./api";
const getActions = (dispatch) => {
  return {
    SetCriteriaDailyPlan: (param) => {
      dispatch({ type: action_type.SetCriteriaDailyPlan, param });
    },
    SetCriteriaSelfServiceStatus: (param) => {
      dispatch({ type: action_type.SetCriteriaDailyPlan, param });
    },
  };
};
//State
const initial_state = {
  DailyPlanCriteria: {
    ScheduledateFrom: moment().format(ApiDateTimeFormat), //moment().subtract(1, "month").format(ApiDateTimeFormat),
    ScheduledateTo: moment().format(ApiDateTimeFormat),
    ResultdateFrom: moment().format(ApiDateTimeFormat), //moment().subtract(1, "month").format(ApiDateTimeFormat),
    ResultdateTo: moment().format(ApiDateTimeFormat),
    choice: "1",
    EXECUTION_TYPE_ID: [],
    PLAN_STATUS_ID: [],
  },
  SelfServiceStatusCriteria: {
    ScheduledateFrom: moment().format(ApiDateTimeFormat), //moment().subtract(1, "month").format(ApiDateTimeFormat),
    ScheduledateTo: moment().format(ApiDateTimeFormat),
    ResultdateFrom: moment().format(ApiDateTimeFormat), //moment().subtract(1, "month").format(ApiDateTimeFormat),
    ResultdateTo: moment().format(ApiDateTimeFormat),
    choice: "1",
    EXECUTION_TYPE_ID: [],
    PLAN_STATUS_ID: [],
  },
};
//1.Context
export const CriteriaContext = createContext({
  state: initial_state,
  actions: getActions(),
});
//2.Provider
export const CriteriaContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initial_state);
  const [actions] = useState(getActions(dispatch));
  //Method//
  return (
    <CriteriaContext.Provider value={{ state: state, actions: actions }}>
      {children}
    </CriteriaContext.Provider>
  );
};
export function withContext(Component) {
  return function (...props) {
    return (
      <CriteriaContextProvider>
        <Component {...props} />
      </CriteriaContextProvider>
    );
  };
}
//3.Reducer
export const action_type = {
  SetCriteriaDailyPlan: "Criteria_Execution_SetCriteriaDailyPlan",
  SetCriteriaSelfServiceStatus: "Criteria_Execution_SetCriteriaSelfServiceStatus",
};
const Reducer = (state = initial_state, action) => {
  switch (action.type) {
    case action_type.SetCriteriaDailyPlan:
      return {
        ...state,
        DailyPlanCriteria: action.param,
      };
      case action_type.SetCriteriaSelfServiceStatus:
        return {
          ...state,
          SelfServiceStatusCriteria: action.param,
        };
    default:
      break;
  }
  return state;
};
