import React, { useEffect } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
  Controller,
} from "react-hook-form";
//materail
import Box from "@material-ui/core/Box";
//stateless
import { Modal } from "stateless/modal";
import { SearchTextBoxFull } from "stateless/hook-controls/input";
import { ButtonSearchTextBox } from "stateless/button";
import DomainList from "stateless/selection-control/checkbox/domain_list";
function DomainName(props) {
  const { retriveData = () => {}, defaultSelected,name, ...rest } = props;
  const methods = useForm({ defaultValues: { [name]: [] } });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
  } = methods;
  useEffect(() => {
    setValue(name, defaultSelected);
  }, [defaultSelected,setValue,name]);

  function clean() {
    reset();
  }
  const footer = (
    <>
      <Box display="flex" flexgrow={1} justifyContent={"center"}>
        <ButtonSearchTextBox
          long
          onClick={() => {
            retriveData(getValues());
            reset();
          }}
        >
          Confirm
        </ButtonSearchTextBox>
      </Box>
    </>
  );
  const watch_data = useWatch({ control,name:"Fulltext" });
  if(!props.open) return null
  return (
    <>
      <Modal
        maxWidth="md"
        title={`Data Domain Name`}
        hiddenSave
        plain
        hideFooterDivder
        cleanUp={clean}
        ComponentFooter={footer}
        {...rest}
      >
        <FormProvider {...methods}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexgrow={1} justifyContent={"center"} mb={2}>
              <Box flexgrow={1} name="SearchTextBox">
                <SearchTextBoxFull
                  name="Fulltext"
                  onClear={() => setValue("Fulltext", "")}
                />
              </Box>
            </Box>
            <Box>
              <DomainList name={name} control={control} filter={watch_data} setValue={setValue} />
            </Box>
            
          </Box>
        </FormProvider>
      </Modal>
    </>
  );
}

export default DomainName;
