import {
  createContext,
  useEffect,
  useState,
  useContext,
  useReducer,
} from "react";
import { update_array } from "utils/common-function";
import { buildParameter } from "./parameter";
import * as api from "./api";
const getActions = (dispatch) => {
  return {
    LoadDetail: async (param) => {
      let data = await api.CreateReprocess(param);
      if (data) {
        dispatch({ type: action_type.SET_HEADER, data: data.Header });
        dispatch({ type: action_type.SET_DETAILS, data: data.Details });
        dispatch({ type: action_type.SET_SCHEDULE, data: data.Schedule });
      }
    },
    SaveHeader: async (param) => {
      await api.SaveReprocessHeader(param);
      dispatch({ type: action_type.SET_HEADER, data: param });
    },
    SaveDetail: async (param) => {
      let data = await api.SaveReprocessDetail(param);
      data && dispatch({ type: action_type.SET_DETAILS, data });
    },
    SaveSchdule: async (param) => {
      let data = await api.SaveScheduleReprocess(param);
      if (data) {
        dispatch({ type: action_type.SET_SCHEDULE, data });
      }
    },
    Submit: async (param) => {
      let error = await api.SubmitReprocess(param);
      return !error;
    },
    // SelfService Reprocess
    LoadSelfServiceDetail: async (param) => {
      let data = await api.CreateSelfServiceReprocess(param);
      if (data) {
        dispatch({ type: action_type.SET_HEADER, data: data.Header });
        dispatch({ type: action_type.SET_DETAILS, data: data.Details });
        dispatch({ type: action_type.SET_SCHEDULE, data: data.Schedule });
      }
    },
    SubmitSelfServiceReprocess: async (param) => {
      let error = await api.SubmitSelfServiceReprocess(param);
      return !error;
    },
  };
};
const initial_state = {
  HistoryData: {},
  Header: {},
  Schdule: {},
  Details: [],
  SelectedDetail: [],
  LOOP_DETECTED: false,
};
//1.Context
export const ReprocessContext = createContext({
  state: initial_state,
  actions: getActions(),
});
//2.Provider
export const ReprocessContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initial_state);
  const [actions] = useState(getActions(dispatch));
  //Method//
  return (
    <ReprocessContext.Provider value={{ state: state, actions: actions }}>
      {children}
    </ReprocessContext.Provider>
  );
};
export function withContext(Component) {
  return function (...props) {
    return (
      <ReprocessContextProvider>
        <Component {...props} />
      </ReprocessContextProvider>
    );
  };
}

export const action_type = {
  //PlanList
  SET_HEADER: "Reprocess_SET_HEADER",
  SET_DETAILS: "Reprocess_SET_DETAILS",
  SET_SCHEDULE: "Reprocess_SET_SCHEDULE",
  UPDATE_DEPENDENCY: "Reprocess_UPDATE_DEPENDENCY",
  UPDATE_SELECTED_DETAIL: "Reprocess_UPDATE_UPDATE_SELECTED_DETAIL",
  UPDATE_LOOPDETECTED: "Reprocess_UPDATE_LOOPDETECTED",
};
const Reducer = (state = initial_state, action) => {
  switch (action.type) {
    case action_type.SET_HEADER:
      return {
        ...state,
        //HistoryData: action.data?.History,
        Header: action.data,
      };
    case action_type.SET_DETAILS:
      return {
        ...state,
        //HistoryData: action.data?.History,
        Details: action.data,
      };
    case action_type.SET_SCHEDULE:
      return {
        ...state,
        Schdule: action.data,
      };
    case action_type.UPDATE_DEPENDENCY:
      //let newdata = update_array(state.Detail, [action.data], "PLAN_ID");
      return {
        ...state,
        Detail: action.data,
      };
    case action_type.UPDATE_SELECTED_DETAIL:
      return {
        ...state,
        SelectedDetail: action.data,
      };
    case action_type.UPDATE_LOOPDETECTED:
      //let newdata = update_array(state.Detail, [action.data], "PLAN_ID");
      return {
        ...state,
        LOOP_DETECTED: action.data,
      };
    default:
      break;
  }
  return state;
};
