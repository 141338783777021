import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useOpenDialog2 } from "apps/main/function-api/useOpenDialog";
import { UserApproverDialog } from "./dialog-user-approver";
//Material
import { Box } from "@mui/system";
import { GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
//Component
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
import { PurpleButton } from "stateless/button";
import { Modal } from "stateless/modal";
//utils
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { inform_success, inform_error, ConfirmCommon } from "utils/alert";
import {
  titleCase,
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyObj,
  isEmptyArray,
} from "utils/common-function";

export function TargetTableApproverDialog(props) {
  const {
    addApprover = () => {},
    deleteApprover = () => {},
    name,
    dataTable,
    onClose,
    clickOpen,
    toggle,
    ...rest
  } = props;

  const methods = useForm();
  const controlDialogUser = useOpenDialog2();

  if (!props.open) return null;

  return (
    <>
      <Modal
        maxWidth="md"
        onClose={onClose}
        title={titleCase(name)}
        hiddenSave
        plain
        hideFooterDivder
        {...rest}
      >
        <FormProvider {...methods}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Table
                  data={dataTable}
                  addUserApprover={controlDialogUser.clickOpen}
                  deleteApprover={deleteApprover}
                />
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
        <UserApproverDialog
          {...controlDialogUser}
          addApprover={addApprover}
          approverList={dataTable && dataTable.map((x) => x.username)}
          disableEnforceFocus
          name={"users_list"}
        />
      </Modal>
    </>
  );
}

function Table({ data, addUserApprover, deleteApprover }) {
  const columns = [
    {
      field: "delete",
      headerName: "DELETE",
      align: "center",
      minWidth: 150,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => deleteApprover(params.row)}>
            Delete
          </PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={data || []}
      rowHeight={40}
      customColumn={{
        all: db_col,
        custom_col: columns,
        date: [],
        date_time: [],
      }}
      getRowId={(row) => row.username}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "Add User",
          buttonclick: addUserApprover,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col = ["username"];
