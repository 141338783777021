import React, { useState, useEffect } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
//Material
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
//Component
import { BaseContainer } from "stateless/base-container";
import { BoxTemplate1 } from "stateless/Box";
import FormInput from "stateless/hook-controls/input";
import FormCheckBox from "stateless/hook-controls/checkbox";
import { Modal } from "stateless/modal";
import { ReactTable } from "stateless/react-table";
//Function
import { setDataToFormHookV2, isEmptyStr } from "utils/common-function";
import { getMasterColumns } from "./columns/role-maintenance";
import { buildParameter } from "./parameter";
import * as alertDialog from "utils/alert";
import * as api from "./api";

function RoleMaintenance() {
  //Context

  //State
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [DataList, setDataList] = useState([]);
  const [DataDetail, setDataDetail] = useState({});

  //Event
  const onOpenAddModal = () => {
    setOpenModal(true);
    setEditMode(false);
    setDataDetail(null);
  };
  const onOpenEditModal = () => {
    setOpenModal(true);
    setEditMode(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  //Function
  const Edit = (Detail) => {
    onOpenEditModal();
    setDataDetail(Detail);
  };

  const Delete = async (id, DisableDelete) => {
    if (DisableDelete) return;

    let res = await alertDialog.ConfirmDelete();
    if (!res) return;

    let param = {
      ROLE_ID: id,
    };

    let api_res = await api.DeleteRole(param);
    if (!api_res) return;
    fetch();
    alertDialog.del_success();
  };

  //--------
  const fetch = async () => {
    let res = await api.GetListRoleMaster();
    setDataList(res);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      <BaseContainer>
        <BoxTemplate1 title="Role Maintenance" add onClickAdd={onOpenAddModal}>
          <ReactTable
            keyField="ROLE_ID"
            data={DataList}
            columns={getMasterColumns(Edit, Delete)}
          />
        </BoxTemplate1>
        <DataModal
          editmode={editMode ? 1 : 0}
          open={openModal}
          onClose={onCloseModal}
          search={fetch}
          datadetail={DataDetail}
        />
      </BaseContainer>
    </div>
  );
}

function DataModal(props) {
  const { onClose } = props;
  const { open, editmode } = props;
  const { datadetail, search = () => {}, ...otherprops } = props;
  const method = useForm();
  const { setValue, getValues, setError, clearErrors, errors } = method;
  const [listModule, setListModule] = useState([]);

  //Effect
  useEffect(() => {
    (async () => {
      let data = await api.GetListModuleMaster();
      setListModule(data);
    })();
  }, [setListModule]);

  useEffect(() => {
    if (!open) return;
    if (editmode) {
      let data = setDataToFormHookV2(datadetail);
      setTimeout(() =>
        data?.forEach(({ name, value }) =>
          Array.isArray(value)
            ? value?.forEach((v) => setValue(v, true))
            : setValue(name, value)
        )
      );
    } else {
      setTimeout(() => setValue("ACTIVE", true));
    }
  }, [datadetail, setValue, open, editmode]);

  const SaveDetail = async () => {
    clearErrors("ROLE_ID");
    const ROLE_ID = getValues().ROLE_ID;

    if (editmode === 0 && isEmptyStr(ROLE_ID)) {
      setError("ROLE_ID", {
        type: "manual",
        message: "The Role ID field is required",
      });
      return;
    }

    const ID = editmode ? datadetail.ROLE_ID : getValues().ROLE_ID;
    let param = buildParameter.SaveDetail(ID, getValues(), listModule);

    let res = await api.SaveRole(param);
    if (res) {
      search();
      onClose();
      alertDialog.inform_success();
    }
  };

  //Style
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
  }));
  const classes = useStyles();

  // Props
  const inputProps = {
    readOnly: editmode ? true : false,
  };

  return (
    <div>
      <Modal
        {...otherprops}
        maxWidth="md"
        onSave={SaveDetail}
        title={editmode ? "Edit Role" : "Add Role"}
      >
        <FormProvider {...method}>
          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box m={1}>
                  <FormInput
                    name="ROLE_ID"
                    label="Role ID"
                    variant={editmode ? "filled" : ""}
                    required={editmode ? false : true}
                    InputProps={inputProps}
                    errorobj={errors}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={1} mb={5}>
                  <FormInput
                    name="ROLE_DESC"
                    label="Description"
                    multiline
                    minRows={3}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {Object.keys(listModule).map((Group) => (
                  <Box
                    m={1}
                    mb={2}
                    className="checkboxBox"
                    key={Group}
                    style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                  >
                    <Box
                      className="checkboxHeader"
                      display="flex"
                      flexWrap="nowrap"
                    >
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        color="textSecondary"
                      >
                        <b>Module</b> : {listModule[Group].Group}
                      </Typography>
                    </Box>
                    <Box display="flex" flexGrow="1" pl={3} pb={3}>
                      <Grid container spacing={1}>
                        {listModule[Group].Modules.map((el) => (
                          <Grid item xs={12} md={6} key={el.MODULE_NAME}>
                            <Box
                              display="flex"
                              m={1}
                              className="checkboxList"
                            >
                              <FormCheckBox
                                key={el.ID}
                                name={el.MODULE_NAME}
                                label={el.MODULE_NAME}
                              />
                            </Box>
                            <Box display="flex" pl={2} pr={2}>
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                {el.MODULE_DESC}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <FormCheckBox key="ACTIVE" name="ACTIVE" label="Active" />
                </Box>
              </Grid>
            </Grid>
          </div>
        </FormProvider>
      </Modal>
    </div>
  );
}
export default RoleMaintenance;
