import { Box } from "@material-ui/core";
import { GridFooterContainer, GridPagination } from "@mui/x-data-grid";
import { FooterButtonInbound } from "stateless/button";

export function CustomInboundFooterOneButtonComponent(props) {
    const { button_name, buttonclick, disabled } = props;
    return (
      <GridFooterContainer>
        <Box display={"flex"} flexDirection="column" width={"100%"}>
          <Box
            sx={{
              p: 1,
              display: "flex",
              backgroundColor: "#594F74",
              color: "#fff",
              borderEndStartRadius: 10,
              borderEndEndRadius: 10,
            }}
            justifyContent="flex-end"
            alignItems={"center"}
          >
            <FooterButtonInbound onClick={buttonclick} disabled={disabled}>
              {button_name}
            </FooterButtonInbound>
          </Box>
          <Box sx={{ p: 1, display: "flex" }} justifyContent="flex-end">
            <GridPagination></GridPagination>
          </Box>
        </Box>
      </GridFooterContainer>
    );
  }
  export function CustomInboundFooterTwoButtonComponent(props) {
    const { button_name1, buttonclick1, disabled1,button_name2, buttonclick2, disabled2  } = props;
    return (
      <GridFooterContainer>
        <Box display={"flex"} flexDirection="column" width={"100%"}>
          <Box
            sx={{
              p: 1,
              display: "flex",
              backgroundColor: "#594F74",
              color: "#fff",
              borderEndStartRadius: 10,
              borderEndEndRadius: 10,
            }}
            justifyContent="flex-end"
            alignItems={"center"}
          >
            <FooterButtonInbound onClick={buttonclick1} disabled={disabled1}>
              {button_name1}
            </FooterButtonInbound>
            <FooterButtonInbound onClick={buttonclick2} disabled={disabled2}>
              {button_name2}
            </FooterButtonInbound>
          </Box>
          <Box sx={{ p: 1, display: "flex" }} justifyContent="flex-end">
            <GridPagination></GridPagination>
          </Box>
        </Box>
      </GridFooterContainer>
    );
  }