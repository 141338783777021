import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { common } from "utils";
import { inform_error, inform_success } from "utils/alert";
import { API_URL } from "utils/api-utils";
import { isEmptyArray, isEmptyStr } from "utils/common-function";
import { MuiDateTimeToApi, dateformat, getToday } from "utils/date";
const { log } = console;
export const api_GetApproveList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetApproveList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetUploadDetailList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetUploadDetailList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetNextApproveRound = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetNextApproveRound`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_Approve = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/Approve`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_OpenRound = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/OpenRound`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_CloseRound = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/CloseRound`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_CheckCanCloseRound = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/CheckCanCloseRound`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_GetValidationColumnlList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetValidationColumnlList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function useApprove({ filter }) {
  const controlSelect = useState([]);
  const [selectionModel, setSelectionModel] = controlSelect;
  const [AdjustList, setAdjustList] = useState([]);
  const [Approval, setApproval] = useState();
  const [disabledCloseRound, setdisabledCloseRound] = useState(true);
  const [disabledOpenRound, setdisabledOpenRound] = useState(true);
  const formMethods = useForm({
    defaultValues: { datadate: getToday() },
  });
  const { getValues, control } = formMethods;
  const w_is_error = useWatch({ control, name: "is_error" });
  //state here
  const controlDialogDetail = useSelectRowOpenModal();
  const controlDialogHistory = useSelectRowOpenModal();
  const controlDialogAdjustHistory = useSelectRowOpenModal();
  const state = {
    AdjustList,
    controlDialogDetail,
    controlDialogHistory,
    controlDialogAdjustHistory,
    disabledCloseRound,
    disabledOpenRound,
    Approval,
  };
  const GetApproveList = useCallback(async () => {
    //call api
    let param = {
      data_dt: getValues().datadate,
      group_users: filter.GroupUser,
      is_error: w_is_error,
    };
    let [res, err] = await api_GetApproveList(param);
    if (err) return;
    let [res2, err2] = await api_GetNextApproveRound(param);
    let [res3, err3] = await api_CheckCanCloseRound(param);
    if (err) return;
    if (err2) return;
    if (err3) return;
    log(res2)
    setAdjustList(res);
    setApproval(res2);
    if(res2?.is_lock)
    setdisabledOpenRound(!res2?.is_lock)
    setdisabledCloseRound(res2?.is_lock)
  }, [getValues, filter, w_is_error]);

  useEffect(() => {
    GetApproveList();
  }, [GetApproveList]);

  //function here
  const actions = {
    viewDetail: async (row) => {
      controlDialogDetail.handleClickRow(row);
    },
    viewHistory: async (row) => {
      controlDialogHistory.handleClickRow(row);
    },
    viewAdjustHistory: async (row) => {
      controlDialogAdjustHistory.handleClickRow(row);
    },
    aprrove: async (row) => {
      let [res, err] = await api_Approve(row);
      if (err) return;
      if(!isEmptyStr(res)){
        inform_error(res,true)
      }
      inform_success("Approve complete.");
      GetApproveList();
    },
    closeRound: async (row) => {
      let [res, err] = await api_CloseRound({data_date : getValues().datadate});
      if (err) return;
      if (!isEmptyStr(res)) {
        inform_error(res,true)
      }
      inform_success("Close round complete.");
      GetApproveList();
    },
    openRound: async (row) => {
      let [res, err] = await api_OpenRound({data_date : getValues().datadate});
      if (err) return;
      if (!isEmptyStr(res)) {
        inform_error(res,true)
      }
      inform_success("Open round complete.");
      GetApproveList();
    },
    GetApproveList,
  };
  return {
    state,
    actions,
    formMethods,
    controlSelect,
  };
}

