import React, { useState, useEffect } from "react";
//Material
import Box from "@material-ui/core/Box";

//Component
import { ReactTable } from "../../../../stateless/react-table";
import { Modal } from "../../../../stateless/modal";
import ModalPlanDetail from "./modal-plan-detail";
import ModalPlanHistory from "./modal-plan-history";

//Function
import { getDependencyChainColumns } from "../columns/dependency-chain";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import { styleBookmarkRow } from "utils/style";

function ModalDependencies(props) {
    const { data } = props;

    const rowStyle = (row, rowIndex) => {
        if (row.POSITION === 0) return styleBookmarkRow;
    };
    // For Plan History
    const {
        isOpen: isOpenPlanHistory,
        close: closePlanHistory,
        Data: planHistory,
        handleClickRow: clickPlanHistory,
    } = useSelectRowOpenModal();

    const viewPlanHistory = async (row) => {
        clickPlanHistory(row);
    };

    return (
        <Modal {...props} title={`Dependencies`} hiddenSave maxWidth="xl" className="headerModalDependencyDetail">
            <Box display="block" bgcolor="background.paper">
                <ReactTable
                    rowStyle={rowStyle}
                    keyField="SEQUENCE"
                    data={data || []}
                    columns={getDependencyChainColumns(viewPlanHistory)}
                />
            </Box>
            <ModalPlanHistory
                open={isOpenPlanHistory}
                onClose={closePlanHistory}
                data={planHistory}
            />
        </Modal>
    );
}
export default ModalDependencies;
