import Box from "@material-ui/core/Box";
//Material
//Function
import { GetUser } from "utils/sessions";
import { isEmptyStr, titleCase } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import { styleTextLink } from "utils/style";
export const getColumn = (viewDetail = () => { }) => {
    const onClickCol = {
        onClick: async (e, column, columnIndex, row, rowIndex) => {
            viewDetail(row);
        },
    };
    let columns = [
        {
            dataField: "RESULT_DATE",
            text: titleCase("RESULT_DATE"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateText(row.RESULT_DATE)}</>;
            },
            style: styleTextLink,
            events: {
                onClick: async (e, column, columnIndex, row, rowIndex) => {
                    viewDetail(row);
                },
            },
        },
        {
            dataField: "STATUS_TEXT",
            text: titleCase("STATUS"),
            sort: true,
        },
        {
            dataField: "EXECUTION_TYPE",
            text: titleCase("EXECUTION_TYPE"),
            sort: true,
        },
        {
            dataField: "LAST_EXECUTED",
            text: titleCase("LAST_EXECUTED"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateTimeText(row.LAST_EXECUTED)}</>;
            },
        },
        {
            dataField: "LAST_FINISHED",
            text: titleCase("LAST_FINISHED"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateTimeText(row.LAST_FINISHED)}</>;
            },
        },
        {
            dataField: "REPROCESS_BY",
            text: titleCase("REPROCESS_BY"),
            sort: true,
        },
    ];
    return columns;
};
