import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { ReconcileReportDialog } from "../screen-admin-reconcile-report-maintenance/dialog-reconcile-report";
import { useFilterMaintenance } from "./function";
//Material
import { Box } from "@mui/system";
import { GridToolbar } from "@mui/x-data-grid";
//Component
import { BaseContainer } from "stateless/base-container";
import { PurpleButton } from "stateless/button";
import { CMDataGrid2 } from "stateless/data-grid/basic";
//utils
import { isEmptyArray, titleCase } from "utils/common-function";
import {
  CustomInboundFooterOneButtonComponent,
  CustomInboundFooterTwoButtonComponent,
} from "stateless/data-grid/footer/inbound-one-button-footer";
import { DialogUpdateDynamicFilter } from "./dialog-edit-filter";
import { DialogAddMultipleDynamicFilter } from "./dialog-add-multiple";

function Index() {
  const methods = useFilterMaintenance();
  const { formMethods, actions } = methods;
  return (
    <>
      <FormProvider {...formMethods}>
        <BaseContainer>
          <Box display={"flex"} justifyContent={"flex-end"} mb={1}>
            <PurpleButton onClick={actions.addmultiple}>
              Add multiple column
            </PurpleButton>
          </Box>
          <Table methods={methods} />
        </BaseContainer>
      </FormProvider>{" "}
      <DialogUpdateDynamicFilter
        controlDialog={methods.state.controlDialogDetail}
        refreshList={methods.actions.GetFilterTableList}
      />
      <DialogAddMultipleDynamicFilter
        controlDialog={methods.state.controlDialogAddMultiple}
        refreshList={methods.actions.GetFilterTableList}
      />
    </>
  );
}

function Table({ methods }) {
  const { addRow, editRow, deleteRow, deleteMultipleRow } = methods.actions;
  const [selectionModel, setSelectionModel] = useState([]);
  const { FilterList } = methods.state;
  const columns = [
    {
      field: "edit",
      headerName: "EDIT",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => editRow(params.row)}>Edit</PurpleButton>
        </Box>
      ),
    },
    {
      field: "delete",
      headerName: "DELETE",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => deleteRow(params.row)}>
            Delete
          </PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={FilterList}
      rowHeight={40}
      customColumn={{
        all: db_col,
        custom_col: columns,
        date: [],
        date_time: [],
      }}
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      getRowId={(row) => row.id}
      components={{
        Footer: CustomInboundFooterTwoButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name1: "Add Filter",
          buttonclick1: addRow,
          button_name2: "Delete Filter",
          disabled2: isEmptyArray(selectionModel),
          buttonclick2: () => {
            deleteMultipleRow(selectionModel);
          },
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col = ["table_name", "column_filter", "table_type", "schema_name"];
export default Index;
