import { GetUser } from "utils/sessions";

export const buildParameter = {
         SaveDetail: (id, formdata) => {
           let param = {
             TYPE_ID: id,
             TYPE_TEXT: formdata.TYPE_TEXT,
             RUN_REGULAR: formdata.RUN_REGULAR,
             RUN_REPROCESS: formdata.RUN_REPROCESS,
             SESSION_OWNER: GetUser(),
           };
           return param;
         },
       };
