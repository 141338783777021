export function uniqByKeepFirst(a, key) {
  let seen = new Set();
  return a.filter((item) => {
    let k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

export function uniqByKeepLast(a, key) {
  return [...new Map(a.map((x) => [key(x), x])).values()];
}
export function ArraySepecificProperties(data_arr, arr_properties) {
  return data_arr.map((el) =>
    arr_properties.reduce((a, p) => ({ ...a, [p]: el[p] }), 0)
  );
}
