import axios from "axios";
import moment from "moment";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { ToDateTextNoDash, getToday } from "utils/date";
import { DownloadFileExcel, DownloadFromBase64 } from "utils/file";
import { isEmptyArray, isEmptyStr } from "utils/common-function";

export const api_GetExportAdjustmentsList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetExportAdjustmentsList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetDataByTableNameList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetDataByTableName`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export function useExportAdjust({ filter }) {
  const controlSelect = useState([]);
  const [selectionModel] = controlSelect;
  const [ExportAdjustList, setExportAdjustList] = useState([]);
  const formMethods = useForm({ defaultValues: { datadate: getToday() } });
  const { getValues } = formMethods;
  //state here
  const controlDialogDetail = useSelectRowOpenModal();
  const state = { ExportAdjustList, controlDialogDetail };
  const getExportAdjustmentsList = useCallback(async () => {
    //call api
    let param = {
      DATADATE: getValues().datadate,
      group_reports: filter.GroupReport,
    };
    let [res, err] = await api_GetExportAdjustmentsList(param);
    if (err) return;
    setExportAdjustList(res);
  }, [getValues, filter]);
  useEffect(() => {
    getExportAdjustmentsList();
  }, [getExportAdjustmentsList]);
  //function here
  const download = async (row) => {
    
    let [res, err] = await api_GetDataByTableNameList(row);
    if (err) return;
    if (isEmptyArray(res)) return;
    let file_name = `${row.table_name}_${ToDateTextNoDash(row.data_date)}.xlsx`;
    DownloadFileExcel(res, file_name);
  };
  const downloadMultiple = async (row) => {
    let rows = ExportAdjustList.filter((el) =>
      selectionModel.includes(el.table_id)
    );
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      download(row);
    }
  };
  const actions = {
    viewDetail: async (row) => {
      controlDialogDetail.handleClickRow(row);
    },
    download,
    downloadMultiple,
    getExportAdjustmentsList,
  };
  return {
    state,
    actions,
    formMethods,
    controlSelect,
  };
}
