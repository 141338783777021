import React, { useState, useEffect } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
//Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//Component
import { ReactTable } from "../../../../stateless/react-table";
import { Modal } from "../../../../stateless/modal";
import FormInput from "../../../../stateless/hook-controls/input";
//Function
import * as api from "../api";
import { isEmptyStr } from "utils/common-function";
import { titleCase } from "utils/common-function";
import { ToDateTimeText } from "utils/date";
let { log: c } = console;
function ModalJobTalend(props) {
  const methods = useForm({defaultValues:{result:"Loading..."}});
  const {setValue} = methods
  const [resultData, setData] = useState([]);
  const [Log, setLog] = useState();
  const { open, data } = props;
  useEffect(() => {
    if (!open) return;
    
    (async () => {
      let param = {
        OriginName: data.ORIGIN,
        TaskGuid: data.JOB_GUID,
        TaskId: parseInt(data.JOB_ID,10),
        StartTime: data.STATUS_DATETIME,
        EndTime: data.STATUS_DATETIME,
      };
      let res = await api.GetLogTalend(param);
      if (!res) {
        setValue("result", "");
        return
      };
      setData(res);
    })();
  }, [open, data,setValue]);
  useEffect(() => {
    if (isEmptyStr(resultData?.LogResult)) return;
    //let data = JSON.parse(resultData?.LogResult);

    //methods.setValue("result", data?.result);
    setValue("result", resultData?.LogResult);
    setValue("OriginName", resultData?.OriginName);
  }, [resultData, setValue]);
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([methods.getValues().result], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "log.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
  }));
  const classes = useStyles();
  return (
    <>
      <FormProvider {...methods}>
        <Modal {...props} title={`Talend Job Log`} hiddenSave maxWidth="md">
          <Box
            p={1}
            className="boxTableDetail"
            display="flex"
            flexDirection="column"
          >
            <Table className="tableDetail">
              <colgroup>
                <col style={{ width: "150px" }} />
                <col style={{ width: "auto" }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell scope="row" className="tdHeaderRow">
                    <Typography className={classes.heading}>
                      {titleCase("Job Name")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.secondaryHeading}>
                      : {data?.JOB_ID} - {data?.JOB_NAME}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row" className="tdHeaderRow">
                    <Typography className={classes.heading}>
                      {titleCase("ORIGIN")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.secondaryHeading}>
                      : {data?.ORIGIN}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row" className="tdHeaderRow">
                    <Typography className={classes.heading}>
                      {titleCase("Logging Time")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.secondaryHeading}>
                      : {ToDateTimeText(resultData?.StartTime)} - {ToDateTimeText(resultData?.EndTime)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box height="430" pt={2} >
              <FormInput
                name="result"
                label="result"
                spellCheck ={false}
                multiline
                style={{ height: "300" }}
                rows={20}
              />
            </Box>
            <Box >
              <Button variant="contained" onClick={downloadTxtFile}>
                Download
              </Button>
            </Box>
          </Box>
        </Modal>
      </FormProvider>
    </>
  );
}
export default ModalJobTalend;
