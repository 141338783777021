import moment from "moment";
import {
  isEmptyArray,
  moveItem,
  update_array,
  remove_array,
  update_array2,
  concat_array_nodup,
} from "utils/common-function";
import { ApiDateTimeFormat } from "utils/date";
export const initial_state = {
  //Criteria
  //Plan
  CriteriaSearch: {
    dateTo: moment().format(ApiDateTimeFormat),
    DateFrom: moment().format(ApiDateTimeFormat), //moment().subtract(1, "month").format(ApiDateTimeFormat),
    EXECUTION_PLAN_TYPE_ID: [],
    PLAN_STATUS_ID: [],
  },
  //History
  CriteriaSearchHistory: {
    dateTo: moment().format(ApiDateTimeFormat),
    dateFrom: moment().format(ApiDateTimeFormat), //moment().subtract(1, "month").format(ApiDateTimeFormat),
    EXECUTION_TYPE_ID: [],
    PLAN_STATUS_ID: [],
  },
  //Exection plan list
  ExecutionPlanList: [],
  FilterExecutionPlanList: [],
  //Exection plan  history list
  ExecutionPlanHistoryList: [],
  FilterExecutionPlanHistoryList: [],
  //Detail
  PlanDetail: {},
  ScheduleSpecDetail: {},
  //Job in plan
  JobItemsList: [],
  JobItemsListTemp: [],
  //Denpendencies execution plan
  DependenciesList: [],
  ExecutionPlanMasterList: [],
  FilterExecutionPlanMasterList: [],
  DependenciesChainList: [],
  //Reporcess
  CriteriaSearchReprocess: {
    dateTo: moment().format(ApiDateTimeFormat),
    dateFrom: moment().format(ApiDateTimeFormat), //moment().subtract(1, "month").format(ApiDateTimeFormat),
  },
  CriteriaSearchGroupExecution: {
    dateTo: moment().format(ApiDateTimeFormat),
    dateFrom: moment().format(ApiDateTimeFormat), //moment().subtract(1, "month").format(ApiDateTimeFormat),
  },
  ReprocessList: [],
  FilterReprocessList: [],
  //Master Job
  JobList: [],
  FilterJoblist: [],
};

export const action_type = {
  //PlanList
  ASSIGN_ExecutionPlanList: "ExecutionPlan_GetExecutionPlanList",
  Update_ExecutionPlanList: "ExecutionPlan_Update_ExecutionPlanList",
  Remove_ExecutionPlanList: "ExecutionPlan_Remove_ExecutionPlanList",
  UpdateCriteria: "ExecutionPlan_UpdateCriteria",
  Filter_ExecutionPlanList: "ExecutionPlan_Filter_ExecutionPlanList",
  //PlanHistory
  AssignPlanHistoryList: "ExecutionPlan_AssignPlanHistoryList",
  UpdatePlanHistoryList: "ExecutionPlan_UpdatePlanHistoryList",
  AddPlanHistoryList: "ExecutionPlan_AddPlanHistoryList",
  UpdateCriteriaHistory: "ExecutionPlan_UpdateCriteriaHistory",
  UpdateFilterPlanHistoryList: "ExecutionPlan_UpdateFilterPlanHistoryList",
  //ReprocessList
  ASSIGN_ReprocessList: "ExecutionPlan_ASSIGN_ReprocessList",
  UpdateCriteriaReprocess: "ExecutionPlan_UpdateCriteriaReprocess",
  Filter_ReprocessList: "ExecutionPlan_Filter_ReprocessList",
  //Group Execution
  UpdateCriteriaGroupExecution: "ExecutionPlan_UpdateCriteriaGroupExecution",
  //Detail
  UPDATE_ExecutionPlanDetail: "ExecutionPlan_UpdateExecutionPlanDetail",
  UPDATE_JobList: "ExecutionPlan_UPDATE_JobList",
  UPDATE_FilterJobList: "ExecutionPlan_UPDATE_FilterJobList",
  UPDATE_JobItemList: "ExecutionPlan_UPDATE_JobItemList",
  MOVEUP_JobItemList: "ExecutionPlan_MOVEUP_JobItemList",
  MOVEDOWN_JobItemList: "ExecutionPlan_MOVEDOWN_JobItemList",
  REVERT_JobItemList: "ExecutionPlan_REVERT_JobItemList",
  UPDATE_DENPENDENCIES_LIST: "ExecutionPlan_UPDATE_DENPENDENCIES_LIST",
  UPDATE_ExecutionPlanMasterList:
    "ExecutionPlan_UPDATE_ExecutionPlanMasterList",
  UPDATE_FilterExecutionPlanMasterList:
    "ExecutionPlan_UPDATE_FilterExecutionPlanMasterList",
  UPDATE_DependenciesChainList: "ExecutionPlan_UPDATE_DependenciesChainList",
  CLEAR_SCREEN: "ExecutionPlan_CLEAR_DETAIL_STATE",
};
//3.Reducer
export const ExecutionPlanReducer = (state = initial_state, action) => {
  switch (action.type) {
    //Search
    case action_type.ASSIGN_ExecutionPlanList:
      return { ...state, ExecutionPlanList: action.data };
    case action_type.Update_ExecutionPlanList:
      let newdata = update_array2(state.ExecutionPlanList, action.data, [
        "PLAN_ID",
        "RESULT_DATE",
      ]);
      return { ...state, ExecutionPlanList: newdata };
    case action_type.Remove_ExecutionPlanList:
      let newdata_remove = remove_array(
        state.ExecutionPlanList,
        action.data,
        "PLAN_ID"
      );
      return { ...state, ExecutionPlanList: newdata_remove };
    case action_type.UpdateCriteria:
      return { ...state, CriteriaSearch: action.param };
    case action_type.Filter_ExecutionPlanList:
      let filteredPlan = filterPlan(state.ExecutionPlanList, action.param);
      return { ...state, FilterExecutionPlanList: filteredPlan };
    //Detail
    case action_type.UPDATE_ExecutionPlanDetail:
      let schedule = action.data?.PlanSchedule;
      // schedule = {
      //   ...schedule,
      //   TIME:
      //     schedule &&
      //     SetDateOrTimeForSchedule(schedule.SCHEDULE_TYPE_ID, schedule?.TIME),
      //   INTERVAL_ENDTIME:
      //     schedule &&
      //     SetDateOrTimeForSchedule(null, schedule?.INTERVAL_ENDTIME),
      // };
      return {
        ...state,
        PlanDetail: action.data,
        ScheduleSpecDetail: schedule,
      };
    //History
    case action_type.AssignPlanHistoryList:
      return {
        ...state,
        ExecutionPlanHistoryList: action.data,
        FilterExecutionPlanHistoryList: action.data,
      };
    case action_type.AddPlanHistoryList:
      let add_history = concat_array_nodup(
        state.ExecutionPlanHistoryList,
        action.data,
        "HISTORY_ID"
      );
      return {
        ...state,
        ExecutionPlanHistoryList: add_history,
        FilterExecutionPlanHistoryList: add_history,
      };
    case action_type.UpdatePlanHistoryList:
      let updated_data = update_array(
        state.ExecutionPlanHistoryList,
        action.data,
        "HISTORY_ID"
      );
      return {
        ...state,
        ExecutionPlanHistoryList: updated_data,
        FilterExecutionPlanHistoryList: updated_data,
      };
    case action_type.UpdateFilterPlanHistoryList:
      let filteredPlanHistory = filterPlan(
        state.ExecutionPlanHistoryList,
        action.param
      );
      return {
        ...state,
        FilterExecutionPlanHistoryList: filteredPlanHistory,
      };
    case action_type.UpdateCriteriaHistory:
      return { ...state, CriteriaSearchHistory: action.param };
    //JobItems
    case action_type.UPDATE_JobItemList:
      return {
        ...state,
        JobItemsList: action.data,
        JobItemsListTemp: action.data,
      };
    case action_type.MOVEUP_JobItemList:
      let datamoveup = moveJobItems(state.JobItemsListTemp, action.param, -1);
      return { ...state, JobItemsListTemp: datamoveup };
    case action_type.MOVEDOWN_JobItemList:
      let datamovedown = moveJobItems(state.JobItemsListTemp, action.param, 1);
      return { ...state, JobItemsListTemp: datamovedown };
    case action_type.REVERT_JobItemList:
      return { ...state, JobItemsListTemp: state.JobItemsList };
    //Denpendencies Execution plan
    case action_type.UPDATE_DENPENDENCIES_LIST:
      return { ...state, DependenciesList: action.data };
    case action_type.UPDATE_ExecutionPlanMasterList:
      return {
        ...state,
        ExecutionPlanMasterList: action.data,
        FilterExecutionPlanMasterList: action.data,
      };
    //Reprocess
    case action_type.ASSIGN_ReprocessList:
      return {
        ...state,
        ReprocessList: action.data,
        FilterReprocessList: action.data,
      };
    case action_type.UpdateCriteriaReprocess:
      return { ...state, CriteriaSearchReprocess: action.param };
    //Group
    case action_type.UpdateCriteriaGroupExecution:
      return { ...state, CriteriaSearchGroupExecution: action.param };
    //Master
    case action_type.UPDATE_DependenciesChainList:
      return { ...state, DependenciesChainList: action.data };
    case action_type.CLEAR_SCREEN:
      return { ...initial_state, CriteriaSearch: state.CriteriaSearch };
    case action_type.UPDATE_JobList:
      return { ...state, JobList: action.data, FilterJoblist: action.data };
    case action_type.UPDATE_FilterJobList:
      let p_JobType_lst = action.param.JOB_TYPE_ID;

      let filteredJobMaster = state.JobList?.filter(
        (el) =>
          p_JobType_lst?.includes(el.JOB_TYPE_ID + "") ||
          isEmptyArray(p_JobType_lst)
      );
      return { ...state, FilterJoblist: filteredJobMaster };
    case action_type.UPDATE_FilterExecutionPlanMasterList:
      let filteredPlanDepend = filterPlan(
        state.ExecutionPlanList,
        action.param
      );
      return { ...state, FilterExecutionPlanMasterList: filteredPlanDepend };
    default:
      break;
  }
  return state;
};
export const filterPlan = (list, criteria) => {
  let filtered = [...list];
  if (!isEmptyArray(criteria.EXECUTION_PLAN_TYPE_ID)) {
    filtered = filtered?.filter((el) =>
      criteria.EXECUTION_PLAN_TYPE_ID?.includes(el.EXECUTION_PLAN_TYPE_ID + "")
    );
  }
  if (!isEmptyArray(criteria.EXECUTION_TYPE_ID)) {
    filtered = filtered?.filter((el) =>
      criteria.EXECUTION_TYPE_ID?.includes(el.EXECUTION_TYPE_ID + "")
    );
  }
  if (!isEmptyArray(criteria.PLAN_STATUS_ID)) {
    filtered = filtered?.filter((el) =>
      criteria.PLAN_STATUS_ID?.includes(el.STATUS_ID + "")
    );
  }

  return filtered;
};
const moveJobItems = (jobitems = [], ITEM_ID, delta = 0) => {
  let idx = jobitems.findIndex((el) => el.ITEM_ID === ITEM_ID);
  if (idx === 0 && delta < 0) return jobitems;
  if (idx === jobitems.length - 1 && delta > 0) return jobitems;
  let data = moveItem(jobitems, idx, delta);
  return data;
};
