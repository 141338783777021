import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
//Material
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@material-ui/icons/Save";
//Component
import FormInput from "stateless/hook-controls/input";
import { KKPModal } from "stateless/modal/modal2";
//utils
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import {
  ConfirmCommon,
  Inform,
  inform_error,
  inform_success,
} from "utils/alert";
import {
  titleCase,
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyObj,
} from "utils/common-function";
import { SelectDynamicType } from "stateless/selection-control/combo/inbound/select-dynamic-type";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { PurpleButton } from "stateless/button";
import { GridToolbar } from "@mui/x-data-grid";
import { Chip, Typography } from "@mui/material";

export const api_GetFilterTable = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/GetFilterTable`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetFilterTableDistinctList = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/GetFilterTableDistinctList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_InsertMultipleDynamicFilter = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/InsertMultipleDynamicFilter`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

function useDynamicFilterDialog() {
  const formMethods = useForm({ defaultValues: {} });
  const { getValues, setError, clearErrors, setValue } = formMethods;
  const [ReportTableList, setReportTableList] = useState([]);
  const [DataDetail, setDataDetail] = useState([]);
  const state = { DataDetail, ReportTableList };

  const GetFilterTable = useCallback(async (params) => {
    let [res, err] = await api_GetFilterTable(params);
    if (err) return;
    setDataDetail(res);
  }, []);
  const GetFilterTableDistinctList = useCallback(async (params) => {
    let [res, err] = await api_GetFilterTableDistinctList();
    if (err) return;
    res = res.map((el, idx) => ({ ...el, id: idx }));
    setReportTableList(res);
  }, []);
  async function Save(fetchData, close, Data) {
    clearErrors("table_name");
    clearErrors("column_filter");
    clearErrors("table_type");
    clearErrors("schema_name");

    let param = { ...Data, ...getValues() };
    let isErr = false;
    if (isEmptyStr(param.table_name)) {
      setError("table_name", {
        type: "manual",
        message: "The Table name field is required",
      });
      isErr = true;
    }

    if (isEmptyStr(param.column_filter)) {
      setError("column_filter", {
        type: "manual",
        message: "The Column field is required",
      });
      isErr = true;
    }

    if (isEmptyStr(param.schema_name)) {
      setError("schema_name", {
        type: "manual",
        message: "The Schema name field is required",
      });
      isErr = true;
    }

    if (isEmptyStr(param.table_type)) {
      setError("table_type", {
        type: "manual",
        message: "The Type Name field is required",
      });
      isErr = true;
    }
    if (isErr) return;
    if (!(await ConfirmCommon("Confirm Save?"))) return;
    let [res, err] = await api_InsertMultipleDynamicFilter(param);
    if (err) return;
    if (!isEmptyStr(res)) {
      inform_error(res);
      return;
    }
    await inform_success("Save Complete.");
    fetchData();
    close();
  }
  const actions = {
    GetFilterTable,
    GetFilterTableDistinctList,
    Save,
    LoadData: (param) => {
      let hook = setDataToFormHookV2(param);
      hook?.forEach(({ name, value }) => setValue(name, value));
    },
  };
  return { state, actions, formMethods };
}
export function DialogAddMultipleDynamicFilter({ controlDialog, refreshList }) {
  const [mode, setMode] = useState();
  const { isOpen, Data, close } = controlDialog;
  const { state, actions, formMethods } = useDynamicFilterDialog();
  const { setValue, reset, errors, control } = formMethods;
  const { GetFilterTable, GetFilterTableDistinctList, Save, LoadData } =
    actions;
  const w_columns = useWatch({ control, name: "column_filter" });
  useEffect(() => {
    if (!isOpen) return;
    reset();
    GetFilterTableDistinctList();
  }, [isOpen, GetFilterTableDistinctList, reset]);

  useEffect(() => {
    if (isEmptyObj(state.DataDetail)) return;
    let hook = setDataToFormHookV2(state.DataDetail);
    hook?.forEach(({ name, value }) => setValue(name, value));
  }, [setValue, state.DataDetail]);

  return (
    <>
      <FormProvider {...formMethods}>
        <KKPModal
          control={controlDialog}
          title={`Add multiple Dynamic filter`}
          maxWidth="md"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <SelectDynamicType
                  name="table_type"
                  label={titleCase("table_type")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormInput
                  name="schema_name"
                  label={titleCase("schema_name")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormInput
                  name="table_name"
                  label={titleCase("table_name")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography sx={{ color: "#bdbdbd" }}>
                  Multiple value by comma-separated example : column1,column2
                </Typography>
                <FormInput
                  name="column_filter"
                  label={titleCase("column_filter")}
                  required={true}
                  errorobj={errors}
                />
                <Box my={1}>
                  <TagColumnList value={w_columns} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box display={"flex"}>
            <Table data={state.ReportTableList} onClickRow={LoadData} />
          </Box>
          <Box
            mt={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => Save(refreshList, close, Data)}
              >
                Save
              </Button>
            </Box>
            <Box m={1}>
              <Button variant="outlined" onClick={close}>
                Close
              </Button>
            </Box>
          </Box>
        </KKPModal>
      </FormProvider>
    </>
  );
}
function TagColumnList({ value }) {
  let arr_column = value?.split(",");
  return (
    <>
      {arr_column?.map((el,idx) => (
        <Chip label={el} key={idx}></Chip>
      ))}
    </>
  );
}
function Table({ data, onClickRow }) {
  const columns = [
    {
      field: "Load",
      headerName: "Action",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => onClickRow(params.row)}>
            Load
          </PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={data || []}
      rowHeight={40}
      customColumn={{
        all: db_col,
        custom_col: columns,
        // date: ["edw_data_dt"],
        // date_time: ["adjust_datetime"],
      }}
      getRowId={(row) => row.id}
      components={{
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col = ["table_type", "schema_name", "table_name"];
