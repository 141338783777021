import { Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import InboundCriteriaBox from "stateless/Inbound/Inbound-criteria-box";
import DataDateBox from "stateless/Inbound/datadate-box";
import { BaseContainer } from "stateless/base-container";
import { CMDataGrid } from "stateless/data-grid";
import { MaterialUIDatePickers } from "stateless/hook-controls/datepicker/datepicker";
import { useValidation, useValidationDetail } from "./function";
import { Button, FooterButtonInbound, PurpleButton } from "stateless/button";
import { FilterManual, FilterShow } from "stateless/filterbar";
import useFilterbar from "stateless/filterbar/useFilterbar";
import { ToDateRangeText, ToDateText } from "utils/date";
import { KKPModal } from "stateless/modal/modal2";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import {
  GridFooterContainer,
  GridPagination,
  GridToolbar,
} from "@mui/x-data-grid";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { isEmptyArray } from "utils/common-function";
import FormCheckBox from "stateless/hook-controls/checkbox";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
const { log } = console;
const filter_field_list = ["GroupUser", "Module", "GroupReport"];
const filter_field_silgle_list = ["DataDate"];
const formatter = [
  {
    field: "DataDate",
    handler: (val) => {
      return ToDateRangeText(ToDateText(val.from), ToDateText(val.to));
    },
  },
];
const default_filter = {
  GroupUser: [],
  Module: [],
  GroupReport: [],
};
function Index() {
  const controlFilter = useFilterbar({
    fieldList: filter_field_list,
    defaultValues: default_filter,
    fieldSingle: filter_field_silgle_list,
    Formatter: formatter,
  });
  const methods = useValidation({ filter: controlFilter.data });
  const { actions, state, formMethods } = methods;
  return (
    <>
      <FormProvider {...formMethods}>
        <InboundCriteriaBox>
          <DataDateBox onChange={actions.getValidationList} dateHighlight={state.ValidationDateList}/>
        </InboundCriteriaBox>
        <BaseContainer>
          <Box display={"flex"}>
            <FilterManual
              {...controlFilter}
              groupUser
              module
              //Search={() => GetUploadManualList()}
            />
            <Box display={"flex"} pr={3}></Box>
            <Box>
              <FormCheckBox
                label={
                  <Box mr={3} display={"flex"} alignItems="center">
                    <Typography>View ACTUAL_RESULT ERROR</Typography>
                  </Box>
                }
                name="is_error"
              ></FormCheckBox>
            </Box>
            {/* <Box>
              <PurpleButton>DOWNLOAD VALIDATION LIST</PurpleButton>
            </Box> */}
          </Box>
          <Box my={1}>
            <FilterShow {...controlFilter} />
          </Box>
          <Table methods={methods} />
        </BaseContainer>
      </FormProvider>
      <ValidationErroDialog
        controlDialogError={methods.state.controlDialogError}
        methods={methods}
      />
    </>
  );
}

function Table({ methods }) {
  const { viewError, download, downloadMultiple } = methods.actions;
  const [selectionModel, setSelectionModel] = methods.controlSelect;
  const { ValidationList } = methods.state;
  const columns = [
    { field: "validation_desciption", headerName: "VALIDATION_DESCIPTION" },
    {
      field: "action",
      headerName: "Detail",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <Button onClick={() => viewError(params.row)}>View Error</Button>
        </Box>
      ),
    },
    {
      field: "download",
      headerName: "DOWNLOAD",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <Button onClick={() => download(params.row)}>Download</Button>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={ValidationList}
      rowHeight={40}
      customColumn={{ all: db_col, custom_col: columns, date : ['data_dt'] }}
      getRowId={(row) => row.id}
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "DOWNLOAD(.XLSX)",
          disabled: isEmptyArray(selectionModel),
          buttonclick: downloadMultiple,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          //csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
function ValidationErroDialog({ controlDialogError, methods }) {
  const { isOpen, Data } = controlDialogError;
  const { state, actions } = useValidationDetail({ Data });
  const { GetValidationDetailList, download } = actions;
  useEffect(() => {
    if (!isOpen) return;
    GetValidationDetailList(Data);
  }, [isOpen, GetValidationDetailList, Data]);

  return (
    <>
      <KKPModal control={controlDialogError} title="Validation Error List">
        <Typography>
          VALIDATION_DESCIPTION : {Data?.validation_desciption}
        </Typography>
        <Box mt={2}></Box>
        <TableErrorDetail
          data={state.ValidationDetailList}
          col={state.ValidationDetailColumn}
          download={download}
        />
      </KKPModal>
    </>
  );
}
function TableErrorDetail({ data, col, download }) {
  return (
    <CMDataGrid2
      rows={data || []}
      rowHeight={50}
      customColumn={{ all: col, date : ['DATA_DT']}}
      getRowId={(row) => row.DATA_KEY}
      columnVisibilityModel={{
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
      }}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
      }}
      componentsProps={{
        footer: {
          button_name: "DOWNLOAD(.XLSX)",
          disabled: false,
          buttonclick: download,
        },
      }}
    />
  );
}

const db_col = [
  "REPORT_NM",
  "VALIDATION_RULE",
  "DATA_KEY",
  "VALUE",
  "VALIDATION_DATE_TIME",
  "DATA_VERSION",
  "DATA_DT",
  "PRCS_NM",
  "LD_ID",
  "UPDT_PRCS_NM",
  "UPDT_LD_ID",
];
export default Index;
