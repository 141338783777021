import React, { useState, useEffect } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
//Material
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
//Component
import { BaseContainer } from "stateless/base-container";
import { BoxTemplate1 } from "stateless/Box";
import FormInput from "stateless/hook-controls/input";
import FormCheckBox from "stateless/hook-controls/checkbox";
import CheckboxRole from "stateless/selection-control/checkbox/role";
import { Modal } from "stateless/modal";
import { ReactTable } from "stateless/react-table";
//Function
import { setDataToFormHookV2, isEmptyStr } from "utils/common-function";
import { getMaintenanceColumns, getUserColumns,getBuUserColumns } from "./columns/bu-maintenance";
import * as alertDialog from "utils/alert";
import { buildParameter } from "./parameter";
import { checkDuplicateBu, GetBuGroupList, GetUserList } from "./api";
import { DeleteBu } from "./api";
import { SaveBu } from "./api";

function BuMaintenance() {
  //Context

  //State
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [DataList, setDataList] = useState([]);
  const [DataDetail, setDataDetail] = useState({});

  //Event
  const onOpenAddModal = () => {
    setOpenModal(true);
    setEditMode(false);
    setDataDetail(null);
  };
  const onOpenEditModal = () => {
    setOpenModal(true);
    setEditMode(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  //Function
  const Edit = (Detail) => {
    onOpenEditModal();
    setDataDetail(Detail);
  };

  const Delete = async (id) => {
    let res = await alertDialog.ConfirmDelete();
    if (!res) return;

    let param = {
      ID: id,
    };
    let api_res = await DeleteBu(param);
    if (!api_res) return;
    fetch();
    alertDialog.del_success();
  };

  //--------
  const fetch = async () => {
    let res = await GetBuGroupList();
    setDataList(res);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      <BaseContainer>
        <BoxTemplate1 title="Bu Maintenance" add onClickAdd={onOpenAddModal}>
          <ReactTable
            keyField="GROUP_BU"
            data={DataList}
            columns={getMaintenanceColumns(Edit, Delete)}
          />
        </BoxTemplate1>
        <DataModal
          editmode={editMode ? 1 : 0}
          open={openModal}
          onClose={onCloseModal}
          search={fetch}
          datadetail={DataDetail}
        />
      </BaseContainer>
    </div>
  );
}

function DataModal(props) {
  const { onClose } = props;
  const { open, editmode } = props;
  const { datadetail, search = () => { }, ...otherprops } = props;
  const method = useForm();
  const { setValue, getValues, setError, clearErrors, errors, control } =
    method;
  const [listUser, setListUser] = useState([]);
  const [listAddUserToBu, setListAddUserToBu] = useState([]);
  //Effect
  useEffect(() => {
    if (!open) return;
    (async () => {
      let param = {
        GROUP_BU: datadetail?.GROUP_BU,
      };
      let data = await GetUserList(param);
      setListUser(data);
    })();
  }, [setListUser, datadetail, open]);

  //Effect
  useEffect(() => {
    if (!open) return;
    setListAddUserToBu([]);
    if (editmode) {
      let data = setDataToFormHookV2(datadetail);
      setTimeout(() =>
        data?.forEach(({ name, value }) => setValue(name, value))
      );
    } else {
      setTimeout(() => setValue("ACTIVE", true));
    }
  }, [datadetail, setValue, open, editmode]);

  //Function
  const AddUserToBU = (userID) => {
    //console.log(userID);
    console.log(listUser);
    const newListUser = listUser.filter((x) => x.USER_ID !== userID);
    let newUser = {
      USER_ID: userID,
    };
    setTimeout(() => setListUser(newListUser));
    setTimeout(() => setListAddUserToBu(listAddUserToBu => [...listAddUserToBu, newUser] ));
    console.log(newListUser);
    console.log(listAddUserToBu);
  };

   //Function
   const DelUserToBU = (userID) => {
    console.log(userID);
    console.log(listAddUserToBu);
    // const newListUser = listAddUserToBu.filter((x) => x.USER_ID !== userID);
    // let newUser = {
    //   USER_ID: userID,
    // };
    // //console.log(newListUser);
    // setListUser(listUser => [...listUser, newUser]);
    // setListAddUserToBu(newListUser);
    //console.log(newListUser);
    //console.log(listAddUserToBu);
  };

  const SaveDetail = async () => {
    clearErrors("GROUP_BU");

    const GROUP_BU = getValues().GROUP_BU;

    if (isEmptyStr(GROUP_BU)) {
      setError("GROUP_BU", {
        type: "manual",
        message: "The GROUP_BU field is required",
      });
      return;
    }

    const ID = editmode ? datadetail.ID : null;
    let param = buildParameter.SaveDetail(ID, getValues());

    let checkdup = editmode ? 0 : await checkDuplicateBu(param);
    if (checkdup > 0) {
      setError("GROUP_BU", {
        type: "manual",
        message: "The BU is Duplicate.",
      });
      return;
    }

    let res = await SaveBu(param);
    if (res) {
      search();
      onClose();
      alertDialog.inform_success();
    }
  };

  return (
    <div>
      <Modal
        {...otherprops}
        onSave={SaveDetail}
        title={editmode ? "Edit Bu" : "Add Bu"}
        //maxWidth="lg"
      >
        <FormProvider {...method}>
          <Box m={3}>
            <FormInput
              variant={editmode ? "filled" : ""}
              InputProps={editmode ? { readOnly: true } : null}
              required
              name="GROUP_BU"
              label="BU NAME"
              errorobj={errors}
            />
          </Box>
          {/* <Box m={3}>
            <ReactTable
              keyField="USER_ID"
              data={listUser}
              columns={getUserColumns(AddUserToBU)}
            />
          </Box>
          <Box m={3}>
            <ReactTable
              keyField="USER_ID"
              data={listAddUserToBu}
              columns={getBuUserColumns(DelUserToBU)}
            />
          </Box> */}
          <Box mt={3} ml={4.5} flexGrow={1} style={{ marginTop: "0" }}>
            <FormCheckBox
              key="ACTIVE"
              name="ACTIVE"
              label="Active"
              errorobj={errors}
            />
          </Box>
        </FormProvider>
      </Modal>
    </div>
  );
}

export default BuMaintenance;
