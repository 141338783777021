import React, { useEffect, useState } from "react";
import FormSelect from "../../hook-controls/select";
function SelectDataListStatus(props) {
  const [Data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    let data = [
      { id: " ", label: "All" },
      { id: "Draft", label: "Draft" },
      { id: "Published", label: "Published" },
      { id: "Edited", label: "Edited" },
      { id: "Unlisted", label: "Unlisted" },
      { id: "Outdate", label: "Outdate" },
    ];
    //const cbo= data&&data.map(({ TYPE_DESC: label, TYPE_ID: id }) =>({ label, id }));
    setData(data);
  };
  return (
    <>
      <FormSelect {...props} options={Data} />
    </>
  );
}

export default SelectDataListStatus;
