import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { humanize } from "utils/common-function";
export const getExecutionJobsColumns = (
    editjob = () => { },
    deletejob = () => { },
    up = () => { },
    down = () => { },
    isRerun = false
) => {
    const columns = [
        {
            dataField: "OP2",
            text: "",
            //sort: true,

            // events: onClickCol,
            formatter: (cell, row) => {
                return (
                    <Box component="div" display="flex" justifyContent="center">
                        <EditIcon
                            color="action"
                            //className={classes.icon}
                            onClick={() => {
                                editjob(row);
                            }}
                        />
                        {isRerun ? null : (
                            <DeleteIcon
                                color="action"
                                //className={classes.icon}
                                disabled={isRerun}
                                onClick={() => {
                                    deletejob(row.ITEM_ID);
                                }}
                            />
                        )}
                    </Box>
                );
            },
        },
        {
            dataField: "JOB_NAME",
            text: "Job Name",

            // events: onClickCol,
        },
        {
            dataField: "ORIGIN",
            text: "Job Origin",

            // events: onClickCol,
        },
        {
            dataField: "TYPE_TEXT",
            text: "Job Type",
            // events: onClickCol,
        },
        {
            dataField: "JOB_ID",
            text: "Job Id",

            // events: onClickCol,
        },
        {
            dataField: "OP1",
            text: "",
            //sort: true,

            // events: onClickCol,
            formatter: (cell, row) => {
                return (
                    <Box component="div" display="flex" justifyContent="center">
                        <ArrowDropUpIcon
                            color="action"
                            //className={classes.icon}
                            onClick={() => {
                                up(row.ITEM_ID);
                            }}
                        />

                        <ArrowDropDownIcon
                            color="action"
                            //className={classes.icon}

                            onClick={() => {
                                down(row.ITEM_ID);
                            }}
                        />
                    </Box>
                );
            },
        },
    ];
    return columns;
};
