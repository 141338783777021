import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import FormSelect from "stateless/hook-controls/select";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
export const api_GetData = async (param) => {
  let url = `${API_URL.Inbound}/api/Master/GetMstGroupReportList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function SelectReportGroupMaster({ ...props }) {
  const [Data, setData] = useState([]);
  let name = props.name;
  const { setValue } = useFormContext();
  useEffect(() => {
    (async () => {
      let [res, err] = await api_GetData();
      if (err) return;
      const cbo =
        res &&
        res.map(({ group_report_name: label, group_report_name: id }) => ({
          label,
          id,
        }));
      setData(cbo);
    })();
  }, [name, setValue]);
  return (
    <>
      <FormSelect {...props} options={Data} />
    </>
  );
}
