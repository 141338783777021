import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormCheckbox from "../../hook-controls/checkbox";
import { CheckboxThree } from "stateless/hook-controls/checkbox";
//Api
import { common } from "utils";
import { API_URL } from "utils/api-utils";
//util
import { isEmptyStr } from "utils/common-function";
const LoadSubDomain = async (data) => {
  let url = `${API_URL.Report}/api/Master/GetSubDomainList`;
  let { result } = await common.to(axios.post(url, data));
  if (result && result.data) return result.data;
};
function CheckboxSubDomain(props) {
  const {filter,...rest} = props
  const [ApiData, setApiData] = useState([]);
  useEffect(() => {
    (async () => {
      let data = await LoadSubDomain();
      setApiData(data);
    })();
  }, []);
  function filtering() {
    let data = ApiData?.filter(el=>el?.toLowerCase().indexOf(filter?.toLowerCase()) !== -1 || isEmptyStr(filter))
    var result = data?.reduce(function (map, obj) {
      map[obj] = false;
      return map;
    }, {});
    return result
  }
  return (
    <>
      <Box display="flex" className="checkboxBox">
        {" "}
        <Box
          className="checkboxList"
          display="flex"
          pl={3}
          justifyContent={'space-between'}
          flexWrap="wrap"
          flexGrow={1}
        >
          {<CheckboxThree options={filtering()} {...rest} />}
        </Box>
      </Box>
    </>
  );
}

export default CheckboxSubDomain;
