import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useOpenDialog } from "apps/main/function-api/useOpenDialog";
import React, { useEffect, useState } from "react";
import { Button } from "stateless/button";
import { DropzoneDialog } from "material-ui-dropzone";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
import axios from "axios";
export const api_GetListSystemConfigByGroup = async (param) => {
  let url = `${API_URL.Job}/api/Master/GetListSystemConfigByGroup`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
function UploadBox({ onUploadFile }) {
  const [uploadIsOpen, uploadOnClose, uploadOnOpen] = useOpenDialog();

  const [file, setFile] = useState([]);
  const [maximunSize, setmaximunSize] = useState(40000000);
  async function upload(files = []) {
    try {
      if (files.length > 0) {
        setFile(files[0]);
      }
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {
    (async () => {
      let [res, err] = await api_GetListSystemConfigByGroup({
        CONFIG_GROUP: "INBOUND",
      });
      if (err) return;
      let size = res.find(
        (el) => el.CONFIG_NAME === "MAX_SIZE_ADJUST_FILE"
      )?.INT_VALUE;
      if(size)
        setmaximunSize(size);
    })();
  }, []);
  return (
    <>
      <Box display={"flex"}>
        <Box width={100} mt={1} ml={1} mr={1}>
          <Typography>Upload Files :</Typography>
        </Box>
        <Box>
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
            style={{ height: "39px", marginTop: "4px" }}
          >
            <InputBase
              readOnly
              sx={{ ml: 1, flex: 1 }}
              placeholder="Choose File"
              inputProps={{
                "aria-label": "choose file",
                style: { cursor: "pointer" },
              }}
              onClick={uploadOnOpen}
              value={file?.name || ""}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={uploadOnOpen}
            >
              <DriveFolderUploadIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <Button
              onClick={() => {
                onUploadFile(file);
                setFile(null);
              }}
            >
              Upload
            </Button>
          </Paper>
        </Box>
      </Box>
      <DropzoneDialog
        key={"uploadfile"}
        acceptedFiles={[".csv,.xls,.xlsx"]}
        cancelButtonText={"cancel"}
        filesLimit={1}
        submitButtonText={"submit"}
        maxFileSize={maximunSize}
        open={uploadIsOpen}
        onClose={uploadOnClose}
        onSave={(files) => {
          upload(files);
          uploadOnClose();
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </>
  );
}

export default UploadBox;
