import React, { useState, useEffect } from "react";
//Material
import Box from "@material-ui/core/Box";
//Component
import { ReactTable } from "../../../../../stateless/react-table";
import { Modal } from "../../../../../stateless/modal";
import { getColumn } from "../../columns/popup-daily-reprocess-detail";
import ModalPlanDetailFocus from "../../modal/modal-plan-detail-end";
//Function
import * as api from "../../api";
import { Typography } from "@material-ui/core";
import { useSelectRowOpenModal } from "../../../function-api/useSelectRowOpenModal.js";

let { log: c } = console;
function ReprocessDetail(props) {
  const [DataList, setDataList] = useState([]);
  const { open, data } = props;
  useEffect(() => {
    if (!open) return;
    (async () => {
      let res = await api.GetPlanReprocessHistory({
        PLAN_ID: data?.PLAN_ID,
        RESULT_DATE: data?.RESULT_DATE,
      });
      setDataList(res);
    })();
  }, [open, data]);

  // For Plan Detail
  const {
    isOpen: isOpenPlanDetail,
    close: closePlanDetail,
    Data: planDetail,
    handleClickRow: clickPlanDetail,
  } = useSelectRowOpenModal();

  const viewPlanDetail = async (row) => {
    clickPlanDetail(row);
  };

  return (
    <>
      <Modal
        {...props}
        title={`Plan Reprocess History`}
        hiddenSave
        maxWidth="lg"
        className="headerModalReprocessHistoryDetail"
      >
        <Typography>
          Plan: {data?.PLAN_ID} - {data?.PLAN_NAME}
        </Typography>
        <Box display="block" bgcolor="background.paper">
          <ReactTable
            keyField="JOB_GUID"
            data={DataList}
            columns={getColumn(viewPlanDetail)}
          />
        </Box>
      </Modal>
      <ModalPlanDetailFocus
        open={isOpenPlanDetail}
        onClose={closePlanDetail}
        data={planDetail}
      />
    </>
  );
}

export default ReprocessDetail;
