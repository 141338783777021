import { useCallback, useState } from "react";
import { isEmptyObj } from "utils/common-function";

export function useDynamicFilter() {
  const [filterData, setfilterData] = useState({});
  function getFilterValue(field, selected_data) {
    setfilterData((prev) => ({ ...prev, [field]: selected_data }));
  }
  const RefreshCurrentFilter = useCallback((arr_field_name = []) => {
    setfilterData((prev) => Remove(arr_field_name, prev));
  }, []);
  function Remove(arr_field_name = [], currentFilter) {
    if (isEmptyObj(currentFilter)) return currentFilter;
    let new_filter = {};
    Object.keys(currentFilter).forEach((property) => {
      if (arr_field_name.includes(property)) {
        new_filter[property] = currentFilter[property] || [];
      }
      //return new_filter;
    });
    return new_filter
  }
  return {
    filterData,
    getFilterValue,
    RefreshCurrentFilter,
  };
}
