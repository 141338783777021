import React, { useState } from "react";
import { common } from "utils";
import { isEmptyObj } from "utils/common-function";

function useFilterbar(props) {
  const {
    fieldList,
    fieldSingle,
    defaultValues,
    Formatter,
  } = props;
  let auto_defaultValues = isEmptyObj(defaultValues)
    ? fieldList?.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue]: [],
        }),
        {}
      )
    : defaultValues;
  const [filter, setFilter] = useState(auto_defaultValues);
  const [filter_single, setfilter_single] = useState({});
  function getFilterValue(name, value) {
    setFilter((prev) => ({ ...prev, [name]: value }));
  }
  function ClearFilterValue() {
    //Clear all
    setFilter(auto_defaultValues);
    setfilter_single({});
  }
  function RemoveTag(name, value) {
    setFilter((prev) => ({
      ...prev,
      [name]: prev[name].filter((el) => el !== value),
    }));
  }
  function expand_arr() {
    return common.expand_array(filter);
  }
  function getsingleFilterValue(name, value) {
    setfilter_single((prev) => ({ ...prev, [name]: value }));
  }
  function RemoveTagSingle(name) {
    setfilter_single(({ [name]: d, ...rest_prev }) => ({
      ...rest_prev,
    }));
  }
  function expand_singleValue() {
    let res = Object.getOwnPropertyNames(filter_single)?.map((name) => ({
      name: name,
      value:
        Formatter?.find((el) => el.field === name)?.handler(
          filter_single[name]
        ) || filter_single[name].toString(),
    }));
    return res;
  }
  return {
    data: filter,
    getFilterValue,
    clear: ClearFilterValue,
    field: fieldList,
    data_expand: expand_arr(),
    onRemove: RemoveTag,
    //single
    fieldsingle: fieldSingle,
    dataSingle: filter_single,
    getsingleFilterValue,
    RemoveTagSingle,
    data_expand_single: expand_singleValue(),
  };
}

export default useFilterbar;
