import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
//Material
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
//Component
import { BoxTemplate1 } from "stateless/Box";
import FormInput from "stateless/hook-controls/input";
import { BaseContainer } from "stateless/base-container";
import ModalOriginConfig from "./modal-origin-config";

//Function
import { GetUser } from "utils/sessions";
import { isEmptyStr } from "utils/common-function";
import { buildParameter } from "./parameter";
import { inform_success } from "utils/alert";
import { titleCase } from "utils/common-function";
import * as api from "./api";

function SystemConfig() {
  const methods = useForm();
  const { getValues, setValue, setError, clearErrors, errors } = methods;

  const [listConfig, setListConfig] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      let res = await api.GetListSystemConfigByGroup({
        CONFIG_GROUP: "BMWPORTAL.",
      });
      setListConfig(res);
    };
    fetch();
  }, []);

  useEffect(() => {
    listConfig?.forEach((el) => {
      if (el?.DATA_TYPE.toUpperCase() === "INT") {
        // Int Value
        setValue(el.CONFIG_NAME, el?.INT_VALUE ?? "");
      } else if (el?.DATA_TYPE.toUpperCase() === "DOUBLE") {
        // Double Value
        setValue(el.CONFIG_NAME, el?.DOUBLE_VALUE ?? "");
      } else if (el?.DATA_TYPE.toUpperCase() === "STRING") {
        // String Value
        setValue(el.CONFIG_NAME, el?.STRING_VALUE ?? "");
      }
    });
  }, [listConfig, setValue]);

  async function onClearCache() {
    let err = await api.ClearCache();
    if (!err) {
      inform_success("Clear cache success.");
    }
  }

  const SaveConfig = async (event) => {
    let CONFIG_NAME = event.target.name;
    clearErrors(CONFIG_NAME);

    // if (!getValues()[CONFIG_NAME]) {
    //   setError(CONFIG_NAME, {
    //     type: "manual",
    //     message: `Please input ` + titleCase(CONFIG_NAME),
    //   });
    //   return;
    // }

    let param = buildParameter.SaveConfig(getValues(), CONFIG_NAME, listConfig);
    if (param) {
      let result = await api.SaveSystemConfig(param);
      return result;
    }
  };

  return (
    <div>
      <BaseContainer>
        <FormProvider {...methods}>
          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <BoxTemplate1 height={"100%"} title="System Configuration">
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                  >
                    {listConfig?.map((el) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          key={el?.CONFIG_NAME}
                          style={{ width: "100%" }}
                        >
                          <Tooltip
                            title={el.DESCRIPTION ? el.DESCRIPTION : ""}
                            arrow
                          >
                            <Box m={1} mb={1.5}>
                              <FormInput
                                type={
                                  el?.DATA_TYPE.toUpperCase() === "STRING"
                                    ? "text"
                                    : "number"
                                }
                                name={el?.CONFIG_NAME}
                                label={el?.LABEL}
                                onBlur={SaveConfig}
                                errorobj={errors}
                              />
                            </Box>
                          </Tooltip>
                        </Grid>
                      );
                    })}
                  </Grid>
                </BoxTemplate1>
              </Grid>
              <Grid item xs={12} md={6}>
                <ModalOriginConfig height={"100%"} />
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
        <Box mt={3}>
          <Button variant="contained" onClick={onClearCache}>
            Clear Cache
          </Button>
        </Box>
      </BaseContainer>
    </div>
  );
}
export default SystemConfig;
