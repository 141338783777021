import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFormContext, Controller } from "react-hook-form";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Box from "@material-ui/core/Box";
// import Divider from '@material-ui/core/Divider';
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import { borderRadius } from "@mui/system";
// import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width:500,
    height: "50px",
    borderRadius: "50px",
    zIndex: 1,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: "50px",
    margin: 4,
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();

  const { control, errors } = useFormContext();
  const { onClear=()=>{},placeholder,...rest } = props;
  const { name } = rest;
  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }
  return (
    <Paper  className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="Search">
        <SearchIcon />
      </IconButton>
      <Controller
        control={control}
        defaultValue=""
        {...rest}
        render={({ field }) => {
          return (
            <InputBase
            
              {...field}
              {...rest}
              className={classes.input}
              placeholder={placeholder||"Find exactly what you need"}
              style={{borderRadius: "50px"}}
            />
          );
        }}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
        onClick={onClear}
      >
        <CancelIcon style={{ color: "#594f74" }} />
      </IconButton>
    </Paper>
  );
}
