import React, { useState, useEffect, useCallback } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
//Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DescriptionIcon from "@material-ui/icons/Description";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
//Component
import { ReactTable } from "../../../../stateless/react-table";
import FormInput from "../../../../stateless/hook-controls/input";
import { Modal } from "../../../../stateless/modal";
//Function
import * as api from "../api";
import { buildParameter } from "../parameter";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import { titleCase } from "utils/common-function";
import { getColumPlanHistory } from "../columns/popup-plan-history";
import ModalPlanDetailFocus from "./modal-plan-detail-end";
import { styleInvalidRow } from "utils/style";
import { isEmptyStr } from "utils/common-function";
import { inform_success } from "utils/alert";
import { GetUser } from "utils/sessions";
import { useSignalR } from "../../function-api/useSignalR";
import { url, methodLogDeployPlan } from "../signalr";
import { da } from "date-fns/locale";
let { log: c } = console;
function ModalDeploy(props) {
  const [deployLog, setdeployLog] = useState([]);
  const [uploadResult, setUploadResult] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { open } = props;
  const {
    isOpen: isOpenDrop,
    close: closeDrop,
    Data: planDetail,
    handleClickRow: UploadFile,
    toggle,
  } = useSelectRowOpenModal();
  async function getLogDeploy() {
    let res = await api.GetLogDeployPlan();
    if (!res) return;
    setdeployLog(res);
  }
  useEffect(() => {
    setdeployLog();
    setUploadResult();
    if (!open) return;
    getLogDeploy();
  }, [open]);
  const DeployLogCallback = useCallback(
    (data) => {
      setdeployLog((prev) => ({
        ...prev,
        //Message: data.FullLog ?? prev?.Message,
        Message: prev?.Message + "\n" + (isEmptyStr(data.Log) ? "" : data.Log),
        CanUpload: data.CanUpload,
      }));
      if (data.Success && open) {
        inform_success("Deploy success.");
      }
    },
    [open]
  );
  useSignalR(url, methodLogDeployPlan, DeployLogCallback, !open);
  function Upload(files) {
    let file = files[0];
    getBase64(file);
  }
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      let base = reader.result;
      if (isEmptyStr(base)) return;
      let res = await api.DeployPlanPreview({
        base64: base,
      });
      setUploadResult(res);
      getLogDeploy();
      setConfirmOpen(true);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
  }));

  return (
    <>
      <Modal
        {...props}
        title={`Deploy Plan`}
        onSave={getLogDeploy}
        SaveText={"Refresh"}
        spellCheck={false}
        maxWidth="md"
        className="headerModalImportDetail"
      >
        <Box p={1} className="boxTableDetail">
          <Box flexGrow={1}>
            <Button
              variant="contained"
              onClick={toggle}
              disabled={!deployLog?.CanUpload}
            >
              Upload
            </Button>
          </Box>
        </Box>
        <Box display="block" bgcolor="background.paper">
          <br />
          Deploy Log
          <TextField
            variant="outlined"
            fullWidth
            multiline
            value={deployLog?.Message}
          />
        </Box>
      </Modal>
      <DropzoneDialog
        acceptedFiles={[".json"]}
        cancelButtonText={"cancel"}
        filesLimit={1}
        submitButtonText={"submit"}
        maxFileSize={30000000}
        open={isOpenDrop}
        onClose={closeDrop}
        onSave={(file) => {
          Upload(file);
          toggle();
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
      <ModalConfirmDeploy
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
          getLogDeploy();
        }}
        data={uploadResult}
      />
    </>
  );
}

function ModalConfirmDeploy(props) {
  const { data, onClose } = props;
  const {
    isOpen: isOpenDetail,
    close: closeDetail,
    Data: deployPlanDetail,
    handleClickRow: viewDeployDetail,
  } = useSelectRowOpenModal();
  const {
    isOpen: isOpenDependencyDetail,
    close: closeDependencyDetail,
    Data: deployPlanDependencyDetail,
    handleClickRow: viewDeployDependencyDetail,
  } = useSelectRowOpenModal();
  async function cancel() {
    onClose();
    if (isEmptyStr(data?.FileName)) return;
    api.CancelDeployPlan({ FileName: data?.FileName });
  }
  function confirm() {
    api.DeployPlan({ FileName: data?.FileName });
    inform_success("");
    onClose();
  }
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([data?.BackupJson], { type: "application/json" });
    element.href = URL.createObjectURL(file);
    element.download = "backup_deploy.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const rowStyle = (row, rowIndex) => {
    if (!isEmptyStr(row.ValidateMessage)) return styleInvalidRow;
  };
  return (
    <>
      <Modal
        {...props}
        title={`Deploy Plan Result`}
        SaveText="Confirm"
        CloseText="Cancel"
        onClose={cancel}
        onSave={confirm}
        maxWidth="md"
        className="headerModalImportDetail"
        hiddenSave={
          !isEmptyStr(data?.ErrorMessage) || isEmptyStr(data?.FileName)
        }
      >
        <Box p={1} className="boxTableDetail">
          {isEmptyStr(data?.ErrorMessage) ? null : (
            <Alert severity="error">{data?.ErrorMessage}</Alert>
          )}
        </Box>
        <Box m={1}>
          <Typography variant="h6">PlanDeploy</Typography>
        </Box>

        <ReactTable
          searchable={false}
          rowStyle={rowStyle}
          keyField="Number"
          data={data?.data?.PlanDeploy || []}
          columns={[
            {
              dataField: "a",
              text: titleCase(""),
              formatter: (cell, row, rowIndex) => {
                return (
                  <DescriptionIcon
                    onClick={() => {
                      viewDeployDetail(row);
                    }}
                  />
                );
              },
            },
            {
              dataField: "Number",
              text: titleCase("Number"),
            },
            {
              dataField: "Server",
              text: titleCase("Server"),
            },
            {
              dataField: "PlanName",
              text: titleCase("PlanName"),
            },
            {
              dataField: "ProcessName",
              text: titleCase("ProcessName"),
            },
            {
              dataField: "PlanType",
              text: titleCase("PlanType"),
            },
            {
              dataField: "Status",
              text: titleCase("Status"),
            },
            {
              dataField: "Frequency",
              text: titleCase("Frequency"),
            },
            {
              dataField: "SchedulingTime",
              text: titleCase("SchedulingTime"),
            },
            {
              dataField: "DataDateType",
              text: titleCase("DataDateType"),
            },
            {
              dataField: "SLA",
              text: titleCase("SLA"),
            },
            {
              dataField: "ValidateMessage",
              text: titleCase("ValidateMessage"),
            },
          ]}
        />

        <Box m={1}>
          <Typography variant="h6">PlanDependency</Typography>
        </Box>
        <ReactTable
          searchable={false}
          rowStyle={rowStyle}
          keyField="PlanName"
          data={data?.data?.PlanDependency || []}
          columns={[
            {
              dataField: "a",
              text: titleCase(""),
              formatter: (cell, row, rowIndex) => {
                return (
                  <DescriptionIcon
                    onClick={() => {
                      viewDeployDependencyDetail(row);
                    }}
                  />
                );
              },
            },
            {
              dataField: "PlanName",
              text: titleCase("PlanName"),
            },
            {
              dataField: "Status",
              text: titleCase("Status"),
            },
          ]}
        />
        <Box m={1}>
          <Typography variant="h6">PlanDelete</Typography>
        </Box>
        <ReactTable
          searchable={false}
          rowStyle={rowStyle}
          keyField="PlanName"
          data={data?.data?.PlanDelete || []}
          columns={[
            
            {
              dataField: "PlanName",
              text: titleCase("PlanName"),
            },
            // {
            //   dataField: "Status",
            //   text: titleCase("Status"),
            // },
          ]}
        />
        <Button
          variant="contained"
          onClick={downloadTxtFile}
          disabled={isEmptyStr(data?.BackupJson)}
        >
          Download backup file
        </Button>
      </Modal>

      <DeployPlanDetailsModal
        open={isOpenDetail}
        onClose={closeDetail}
        plan={deployPlanDetail}
      />
      <DeployPlanDependencyModal
        open={isOpenDependencyDetail}
        onClose={closeDependencyDetail}
        plan={deployPlanDependencyDetail}
      />
    </>
  );
}
function DeployPlanDetailsModal(props) {
  const { open, plan, onClose } = props;
  const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Modal
        {...props}
        title={plan?.PlanName || "Plan detail"}
        maxWidth="md"
        className="headerModalImportDetail"
        hiddenSave={true}
      >
        {isEmptyStr(plan?.ValidateMessage) ? null : (
          <Box>
            <Alert severity="error">
              <List dense>
                {" "}
                {plan?.ValidateMessage?.split(";").map((el) => (
                  <ListItem key={el}>
                    <ListItemText primary={el} key={el}></ListItemText>
                  </ListItem>
                ))}
              </List>
            </Alert>
          </Box>
        )}
        <Box display="block" bgcolor="background.paper">
          <ReactTable
            searchable={false}
            keyField="DependencyPlanName"
            data={plan?.Dependency || []}
            columns={[
              {
                dataField: "DependencyPlanName",
                text: titleCase("DependencyPlanName"),
              },
              {
                dataField: "DataDateType",
                text: titleCase("DataDateType"),
              },
              {
                dataField: "SLA",
                text: titleCase("SLA"),
              },
              {
                dataField: "Remark",
                text: titleCase("Remark"),
              },
            ]}
          />
          <ReactTable
            searchable={false}
            keyField="id"
            data={
              plan?.SourceTable?.map((el, idx) => ({ ...el, id: idx })) || []
            }
            columns={[
              {
                dataField: "SourceSchemaName",
                text: titleCase("SourceSchemaName"),
              },
              {
                dataField: "SourceTableName",
                text: titleCase("SourceTableName"),
              },
              {
                dataField: "SourceDbType",
                text: titleCase("SourceDbType"),
              },
              {
                dataField: "Remark",
                text: titleCase("Remark"),
              },
            ]}
          />
          <ReactTable
            searchable={false}
            keyField="Sequence"
            data={plan?.Jobs?.map((el, idx) => ({ ...el, id: idx })) || []}
            columns={[
              {
                dataField: "Sequence",
                text: titleCase("Sequence"),
              },
              {
                dataField: "taskName",
                text: titleCase("taskName"),
              },
              {
                dataField: "JobType",
                text: titleCase("JobType"),
              },
              {
                dataField: "JobParameters",
                text: titleCase("JobParameters"),
              },
              {
                dataField: "Server",
                text: titleCase("Server"),
              },
              {
                dataField: "active",
                text: titleCase("active"),
              },

              {
                dataField: "authPass",
                text: titleCase("authPass"),
              },
              {
                dataField: "authUser",
                text: titleCase("authUser"),
              },
              {
                dataField: "contextName",
                text: titleCase("contextName"),
              },
              {
                dataField: "description",
                text: titleCase("description"),
              },
              {
                dataField: "taskType",
                text: titleCase("taskType"),
              },
              {
                dataField: "importType",
                text: titleCase("importType"),
              },
              {
                dataField: "Project",
                text: titleCase("Project"),
              },
              {
                dataField: "nexusArtifactId",
                text: titleCase("nexusArtifactId"),
              },
              {
                dataField: "nexusGroupId",
                text: titleCase("nexusGroupId"),
              },
              {
                dataField: "nexusJobVersionSuffix",
                text: titleCase("nexusJobVersionSuffix"),
              },
              {
                dataField: "nexusRepository",
                text: titleCase("nexusRepository"),
              },
              {
                dataField: "nexusVersion",
                text: titleCase("nexusVersion"),
              },
              {
                dataField: "onUnknownStateJob",
                text: titleCase("onUnknownStateJob"),
              },
              {
                dataField: "pauseOnError",
                text: titleCase("pauseOnError"),
              },
              {
                dataField: "runAsUser",
                text: titleCase("runAsUser"),
              },
              {
                dataField: "executionServerName",
                text: titleCase("executionServerName"),
              },
              {
                dataField: "logLevel",
                text: titleCase("logLevel"),
              },
              {
                dataField: "timeout",
                text: titleCase("timeout"),
              },
              {
                dataField: "Status",
                text: titleCase("Status"),
              },
              {
                dataField: "DeployStatus",
                text: titleCase("DeployStatus"),
              },
              {
                dataField: "Remark",
                text: titleCase("Remark"),
              },
            ]}
          />
        </Box>
      </Modal>
    </>
  );
}
function DeployPlanDependencyModal(props) {
  const { open, plan, onClose } = props;
  const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
  }));

  return (
    <>
      <Modal
        {...props}
        title={plan?.PlanName || "Plan Dependency"}
        maxWidth="md"
        className="headerModalImportDetail"
        hiddenSave={true}
      >
        
        <Box display="block" bgcolor="background.paper">
          <ReactTable
            searchable={false}
            keyField="DependencyPlanName"
            data={plan?.Dependency || []}
            columns={[
              {
                dataField: "DependencyPlanName",
                text: titleCase("DependencyPlanName"),
              },
              {
                dataField: "DataDateType",
                text: titleCase("DataDateType"),
              },
              {
                dataField: "SLA",
                text: titleCase("SLA"),
              },
              {
                dataField: "Remark",
                text: titleCase("Remark"),
              },
            ]}
          />
          
       
        </Box>
      </Modal>
    </>
  );
}
export default ModalDeploy;
