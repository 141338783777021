import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { DropzoneDialog } from "material-ui-dropzone";
//Component
import { Modal } from "stateless/modal";
import { Button } from "stateless/button";

//import {} from 'assets/Metadata-Data_Domain_Template.xlsx'
//Context
import { CaptionFieldContext } from "apps/data-catalog/caption-field-context/caption-field-context";

//Utils
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal.js";
import { common } from "../../../utils";
import { API_URL } from "../../../utils/api-utils";
import { titleCase, isEmptyArray, isEmptyStr } from "utils/common-function";
import { inform_success } from "utils/alert";
import moment from "moment";
import { Backdrop, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { uniqByKeepLast } from "utils/array-function";
import {
  DialogImportResultDetail,
  DownloadImportInformation,
  ListFile,
  ListFileImportResult,
} from "../stateless-datacatalog/import-file-detail";
const { log } = console;
var path = require("path");
//Const

const ImportMultiple = async (param, setloading) => {
  let url = `${API_URL.Report}/api/Report/ImportMultipleObject`;
  if (typeof setloading === "function") {
    setloading(true);
  }
  let { result, error } = await common.to(axios.post(url, param));
  if (typeof setloading === "function") {
    setloading(false);
  }
  return [result?.data, error];
};
function PopupImport(props) {
  const { success, ...rest } = props;
  const { state } = useContext(CaptionFieldContext); //field
  const {
    isOpen: isOpenDrop,
    close: closeDrop,
    Data: planDetail,
    handleClickRow: UploadFile,
    toggle,
  } = useSelectRowOpenModal();
  const [FileList, setFileList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [ImportResult, setImportResult] = useState([]);
  const {
    isOpen: isOpenViewResult,
    close: closeViewResult,
    Data: resultDetail,
    handleClickRow: clickViewResult,
  } = useSelectRowOpenModal();
  function Upload(files) {
    let files_unique = uniqByKeepLast(files, (it) => it.name);
    //setWb((prev) => ({ ...prev, name: files[0].name }));
    setFileList(files_unique);
    setImportResult([]);
  }
  useEffect(() => {
    if (!props.open) return;
    setImportResult([]);
    setFileList([]);
  }, [props.open]);
  async function ClickImport() {
    const formData = new FormData();
    for (let index = 0; index < FileList.length; index++) {
      const file = FileList[index];
      formData.append("Files", file);
    }
    let [res, err] = await ImportMultiple(formData, setLoading);
    if (err) return;
    setImportResult(res);
    inform_success("Import Success");
    success();
    setFileList([]);
    //onClose();
  }
  function RemoveFile(name) {
    setFileList((prev) => prev.filter((el) => el.name !== name));
  }
  function onClose() {
    props.onClose();
  }
  return (
    <>
      <Modal
        SaveText="Import"
        title="Import Object"
        hiddenSave={isEmptyArray(FileList)}
        onSave={ClickImport}
        maxWidth="sm"
        {...rest}
        onClose={onClose}
      >
        <Button onClick={toggle}>Select File</Button>
        <Box display={"flex"} width="100%">
          <ListFile FileList={FileList} RemoveFile={RemoveFile} />
        </Box>
        <Box display={"flex"} width="100%">
          <ListFileImportResult
            ImportResult={ImportResult}
            view={clickViewResult}
          />
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={Loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Modal>
      <DialogImportResultDetail
        onClose={closeViewResult}
        open={isOpenViewResult}
        Data={resultDetail}
      />
      <DropzoneDialog
        acceptedFiles={[".xlsx"]}
        cancelButtonText={"cancel"}
        filesLimit={100}
        submitButtonText={"submit"}
        maxFileSize={30000000}
        open={isOpenDrop}
        onClose={closeDrop}
        onSave={(file) => {
          Upload(file);
          toggle();
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </>
  );
}

export default PopupImport;
