import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { common } from "utils";
import { ConfirmCommon, inform_success, Inform } from "utils/alert";
import { API_URL } from "utils/api-utils";
import { isEmptyArray } from "utils/common-function";
import { dateformat, getToday } from "utils/date";
const { log } = console;
export const api_GetAdjustmentList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetAdjustmentList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetAdjustDetailList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetAdjustDetailList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_AdjustFile = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/AdjustFile`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetNextApproveRound = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetNextApproveRound`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_GetValidationColumnlList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetValidationColumnlList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function useAdjust({ filter }) {
  const controlSelect = useState([]);
  const [selectionModel, setSelectionModel] = controlSelect;
  const [AdjustList, setAdjustList] = useState([]);
  const [ApprovalRound, setApprovalRound] = useState({});
  const formMethods = useForm({
    defaultValues: { datadate: getToday() },
  });
  const { getValues, control } = formMethods;
  const w_is_error = useWatch({ control, name: "is_error" });
  //state here
  const controlDialogDetail = useSelectRowOpenModal();
  const controlDialogHistory = useSelectRowOpenModal();
  const state = { AdjustList, controlDialogDetail, controlDialogHistory };
  const GetAdjustmentList = useCallback(async () => {
    //call api
    let param = {
      data_dt: getValues().datadate,
      group_users: filter.GroupUser,
      is_error: w_is_error,
    };
    let [res, err] = await api_GetAdjustmentList(param);
    let [res2, err2] = await api_GetNextApproveRound(param);
    if (err) return;
    if (err2) return;

    setAdjustList(res);
    setApprovalRound(res2);
  }, [getValues, filter, w_is_error]);

  useEffect(() => {
    GetAdjustmentList();
  }, [GetAdjustmentList]);
  //function here
  const actions = {
    viewDetail: async (row) => {
      controlDialogDetail.handleClickRow(row);
    },
    viewHistory: async (row) => {
      controlDialogHistory.handleClickRow(row);
    },
    adjust: async (row) => {
      if (!(await ConfirmCommon("Are you sure you want to Adjust the file ?")))
        return;
      let [res, err] = await api_AdjustFile([row]);
      if (err) return;
      await inform_success("Adjust success.");
      GetAdjustmentList();
    },
    adjustMultiple: async () => {
      let rows = AdjustList.filter((el) =>
        selectionModel.includes(el.template_name)
      );

      // Validate
      const filteredData = rows.filter(
        (row) => !(row.status_id === 11 && !ApprovalRound.is_lock)
      );
      const templateNames = filteredData.map((item) => item.template_name);
      if (templateNames.length > 0) {
        const text = "</ul><br><p>Cannot be adjusted.</p>";
        const liElements = templateNames.map((value) => `<li>${value}</li>`);
        const htmlContent = `<ul>${liElements.join("")}${text}`;
        await Inform("", htmlContent);
        return;
      }

      if (!(await ConfirmCommon("Are you sure you want to Adjust the file ?")))
        return;
      let [res, err] = await api_AdjustFile(rows);
      if (err) return;
      await inform_success("Adjust success.");
      GetAdjustmentList();
    },
    disableAdjust: (row) => {
      return !(row.status_id === 11 && !ApprovalRound.is_lock);
    },
    GetAdjustmentList,
  };
  return {
    state,
    actions,
    formMethods,
    controlSelect,
  };
}
export function useAdjustDetail() {
  const [AdjustDetailList, setAdjustDetailList] = useState([]);
  const [AdjustDetailColumn, setAdjustDetailColumn] = useState([]);
  const formMethods = useForm();
  const { control } = formMethods;
  const w_is_History = useWatch({ control, name: "is_history" });
  const GetAdjustDetailList = useCallback(async (params) => {
    let [rescol, errcol] = await api_GetValidationColumnlList({
      table_name: params.validation_desciption,
    });
    if (errcol) return;
    if (isEmptyArray(rescol)) return;
    setAdjustDetailColumn(rescol?.map((el) => el.column_name));
    let [res, err] = await api_GetAdjustDetailList({
      table_name: params.validation_desciption,
    });
    if (err) return;
    log(res);
    setAdjustDetailList(res);
  }, []);
  const state = { AdjustDetailList, AdjustDetailColumn, w_is_History };
  const actions = {
    GetAdjustDetailList,
  };
  return { state, actions, formMethods };
}
