import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
  Controller,
} from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
//Material
import Box from "@mui/material/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import EditIcon from "@material-ui/icons/Edit";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
//component
import { BaseContainer } from "stateless/base-container";
import FormInput from "stateless/hook-controls/input";
import { Button } from "stateless/button";
import { ReactTable } from "stateless/react-table";
import SelectStatus from "stateless/selection-control/combo/datalist-status";
import { BoxTemplate1 } from "stateless/Box";
import { PopupHistory } from "../screen-data-object/popup-history";
//context
import { DatasetContext, withContext } from "../screen-data-object/dataobject-context";
//utils
import {
  titleCase,
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyObj,
} from "utils/common-function";
import { ConfirmCommon, Inform, inform_success } from "utils/alert";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import axios from "axios";
import { API_URL } from "../../../utils/api-utils";
import { common } from "../../../utils";
import { MuiDateTimeToApi } from "../../../utils/date";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal.js";
import PopupMerge from "./popup-merge";
//const

//api

export const CancelDataObject = async (param) => {
  let url = `${API_URL.Report}/api/Report/CancelDataObject`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const PublishDataObject = async (
  param = { Dataset_Ids: [], Status: "" }
) => {
  let url = `${API_URL.Report}/api/Report/PublishDataObject`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const CheckLastestPublish = async (param) => {
  let url = `${API_URL.Report}/api/Report/CheckLastestPublish`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const SaveSbxDataObject = async (param) => {
  let url = `${API_URL.Report}/api/Report/SaveSbxDataObject`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const CheckIsExistsDataObject = async (param) => {
  let url = `${API_URL.Report}/api/Report/CheckIsExistsDataObject`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const LoadDataObjectSbx = async (param) => {
  let url = `${API_URL.Report}/api/Report/LoadDataObjectSbx`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const GetLatestIndex = async (param) => {
  let url = `${API_URL.Report}/api/Report/GetLatestIndex`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
const { log } = console;

//
//
//SCREEN
//
//
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function DatasetEdit() {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const methods = useForm({ defaultValues: { data_privacy_exist: false } });
  const [SbxData, setSbxData] = useState({});
  const { getValues, setValue, control } = methods;
  const {
    isOpen: isOpenPopupMerge,
    close: closePopupMerge,
    toggle: openPopupMerge,
  } = useSelectRowOpenModal();
  useEffect(() => {
    if (location.state === undefined) {
      history.goBack();
      return;
    }
    (async () => {
      let data = {};
      if (isEmptyStr(location.state.sbx_id)) {
        history.goBack();
        return;
      }
      let [res, err] = await LoadDataObjectSbx({
        sbx_id: location.state.sbx_id,
      });
      if (err) history.goBack();
      if (isEmptyObj(res)) {
        await Inform("Data not exists");
        return;
      }
      data = res;

      let hook = setDataToFormHookV2(
        data,
        [
          "sbx_updated_timestamp",
          "system_created_timestamp",
          "imported_timestamp",
          "sbx_created_timestamp",
        ],
        ["registration_date", "last_update_date", "status_update_date"]
      );
      setSbxData(data);
      hook?.forEach(({ name, value }) => setValue(name, value));
      //window.history.replaceState({}, document.title);
    })();
  }, [history, location.state, setValue]);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  async function Save() {
    let param = {
      ...SbxData,
      ...getValues(),
      registration_date: MuiDateTimeToApi(getValues().registration_date),
      last_update_date: MuiDateTimeToApi(getValues().last_update_date),
      status_update_date: MuiDateTimeToApi(getValues().status_update_date),
      system_latest_updated_timestamp: MuiDateTimeToApi(
        SbxData.system_latest_update_timestamp
      ),
      sbx_updated_timestamp: MuiDateTimeToApi(SbxData.sbx_updated_timestamp),
      system_created_timestamp: MuiDateTimeToApi(
        SbxData.system_created_timestamp
      ),

      //CREATED_DATE: SbxData.CREATED_DATE ? null : state.CREATED_DATE,
    };
    log(param)
    if (isEmptyStr(SbxData.data_object_id)) {
      let [res, err] = await CheckIsExistsDataObject(param);
      if (err) return;
      if (res) {
        Inform("Data object isa already exists in system.");
        return;
      }
    }

    await SaveSbxDataObject(param);
    await inform_success("Save Complete.")
    handleClick();
    history.goBack();
  }
  async function Cancel() {
    if (!(await ConfirmCommon("Confirm Cancel Draft" ))) return;
    let [res, err] = await CancelDataObject([{ sbx_id: SbxData.sbx_id }]);
    if (err) return;
    history.goBack();
  }
  async function GetLatest() {
    //dump tnx latest to sbx
    let data = {};
    data = await GetLatestIndex({
      data_object_id: SbxData.data_object_id,
      SESSION_ID: SbxData.SESSION_ID,
    });
    let hook = setDataToFormHookV2(data);
    hook?.forEach(({ name, value }) => setValue(name, value));
    setSbxData(data);
  }
  return (
    <div>
      <BaseContainer>
        <Box p={1}>
          <FormProvider {...methods}>


            <Grid container spacing={2} sx={{ p: 3 }}>
              <Grid item  xs={12} >
                <FormInput
                  name="session_owner"
                  label={"session_owner"}
                  disabled
                />
              </Grid>
              <Grid item  xs={12}>
                <FormInput
                  name="sheet_name"
                  label={"Sheet Name"}
                  disabled={!isEmptyStr(SbxData.data_object_id)}
                />
              </Grid>

              <Grid item  xs={12}>
                <FormInput
                  name="schema_name"
                  label={"Schema Name"}
                  disabled={!isEmptyStr(SbxData.data_object_id)}
                />
              </Grid>
              <Grid item  xs={12}>
                <FormInput
                  name="data_object_table_name"
                  label={"Data Object Table Name"}
                  disabled={!isEmptyStr(SbxData.data_object_id)}
                />
              </Grid>
              <Grid item  xs={12}>
                <FormInput name="domain" label={"Domain Name"} />
              </Grid>
              <Grid item  xs={12}>
                <FormInput name="sub_domain" label={titleCase("sub_domain")} />
              </Grid>
              <Grid item  xs={12}>
                <FormInput name="description" label={"Description"} />
              </Grid>
              <Grid item  xs={12}>
                <FormInput
                  name="business_glossary"
                  label={"Business Glossary"}
                />
              </Grid>
              <Grid item  xs={12}>
                <FormInput
                  name="data_specialist_name"
                  label={titleCase("data_specialist_name")}
                />
              </Grid>
              <Grid item  xs={12}>
                <FormInput name="data_object_type" label={"Data Object Type"} />
              </Grid>
              <Grid item  xs={12}>
                <FormInput name="data_source" label={"Data Source"} />
              </Grid>
              <Grid item  xs={12}>
                <FormInput name="data_storage" label={"Data Storage"} />
              </Grid>
              <Grid item  xs={12}>
                <FormInput
                  name="data_update_frequency"
                  label={titleCase("data_update_frequency")}
                />
              </Grid>

              <Grid item  xs={12}>
                <FormInput
                  name="keyword_for_search"
                  label={titleCase("keyword_for_search")}
                />
              </Grid>
              <Grid item  xs={12}>
                <FormInput
                  name="registration_date"
                  type="date"
                  label={titleCase("registration_date")}
                />
              </Grid>
              <Grid item  xs={12}>
                <FormInput
                  name="last_update_date"
                  type="date"
                  label={titleCase("last_update_date")}
                />
              </Grid>
              <Grid item  xs={12}>
                <FormInput
                  name="author_name"
                  label={titleCase("author_name")}
                />
              </Grid>
              <Grid item  xs={12}>
                <FormInput name="status" label={titleCase("status")} />
              </Grid>
              <Grid item  xs={12}>
                <FormInput
                  name="status_update_date"
                  type="date"
                  label={titleCase("status_update_date")}
                />
              </Grid>
              <Grid item  xs={12}>
              <FormInput
                  name="data_privacy_exist"
                  label={titleCase("data_privacy_exist")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  name="imported_by"
                  label={titleCase("imported_by")}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  name="imported_timestamp"
                  label={titleCase("imported_date")}
                  type="datetime-local"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  name="sbx_created_timestamp"
                  label={titleCase("Created Draft Data")}
                  disabled
                  type="datetime-local"
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  name="sbx_updated_timestamp"
                  label={titleCase("Updated Draft Data")}
                  disabled
                  type="datetime-local"
                />
              </Grid>
              
            </Grid>
          </FormProvider>
          <Box
            sx={{
              display: "flex",
              bgcolor: "background.paper",
              justifyContent: "flex-end",
            }}
          >
            <Box m={1}>
              <Button onClick={() => history.goBack()}>Close</Button>
            </Box>

            <Box m={1}>
              <Button onClick={Save}>Save Draft</Button>
            </Box>

            <Box m={1}>
              <Button onClick={Cancel}>Cancel Draft</Button>
            </Box>
          </Box>
        </Box>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Save complete
          </Alert>
        </Snackbar>
      </BaseContainer>
      <PopupMerge
        open={isOpenPopupMerge}
        onClose={closePopupMerge}
        data={SbxData}
        getlatest={GetLatest}
        canceldataset={Cancel}
      ></PopupMerge>
    </div>
  );
}

export default withContext(DatasetEdit);
