import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
export const getMasterColumns = (editData, deleteData) => {
  const columns = [
    {
      dataField: "OP2",
      text: "",
      formatter: (cell, row) => {
        return (
          <Box component="div" display="flex" justifyContent="center">
            <EditIcon
              color="action"
              onClick={() => {
                editData(row);
              }}
            />
            <DeleteIcon
              color={row.DisableDelete  ? "disabled" : "action"}
              onClick={() => {
                deleteData(row.ROLE_ID, row.DisableDelete);
              }}
            />
          </Box>
        );
      },
    },
    {
      dataField: "ROLE_ID",
      text: "Role ID",
      sort: true,
    },
    {
      dataField: "ROLE_DESC",
      text: "Role Description",
      sort: true,
    },
    {
      dataField: "ModuleText",
      text: "Modules",
      sort: true,
    },
    {
      dataField: "ACTIVE",
      text: "Active",
      sort: true,
    },
    {
      dataField: "REFERENCED_USER",
      text: "Referenced Users",
      sort: true,
    },
  ];
  return columns;
};
