import {
    createContext,
    useEffect,
    useState,
    useContext,
    useReducer,
  } from "react";
  import moment from "moment";

  const getActions = (dispatch) => {
    return {
      SetExampleData: (param) => {
          dispatch({ type: action_type.SetExampleData, param });
      },
    }
  };
  //State
  const initial_state = {
    SampleProp:{}
  };
  //1.Context
  export const SampleContext = createContext({
    state: initial_state,
    actions: getActions(),
  });
  //2.Provider
  export const SampleContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initial_state);
    const [actions] = useState(getActions(dispatch));
    //Method//
    return (
      <SampleContext.Provider value={{ state: state, actions: actions }}>
        {children}
      </SampleContext.Provider>
    );
  };
  export function withContext(Component) {
    return function (...props) {
      return (
        <SampleContextProvider>
          <Component {...props} />
        </SampleContextProvider>
      );
    };
  }
  //3.Reducer
  export const action_type = {
      SetExampleData: "SetExampleData",
  };
  const Reducer = (state = initial_state, action) => {
    switch (action.type) {
      case action_type.SetExampleData:
        return {
          ...state,
          SampleProp: action.param,
        };
        
      default:
        break;
    }
    return state;
  };
  