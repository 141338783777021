export const getMasterColumns = () => {
  const columns = [
    {
      dataField: "PLAN_ID",
      text: "Plan ID",
      sort: true,
    },
    {
      dataField: "PLAN_NAME",
      text: "Plan Name",
      sort: true,
    },
    {
      dataField: "TYPE_TEXT",
      text: "Plan Type",
      sort: true,
    },
    {
      dataField: "SCHEDULE_TEXT",
      text: "Schedule",
      sort: true,
    }
  ];
  return columns;
};
