import axios from "axios";
import { API_URL } from "./../../utils/api-utils";
import  {common}  from "./../../utils";
//Job 
export const GetJobTypeList =async (param)=>{
    let url = `${API_URL.Job}/api/master/GetJobTypeList`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
   
}
export const GetJobStatusList =async (param)=>{
    let url = `${API_URL.Job}/api/master/GetJobStatusList`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
   
}
//Plan
export const GetScheduleList = async (param) => {
    let url = `${API_URL.Job}/api/master/GetScheduleList`;
    let { result } = await common.to(axios.post(url));
    if (result && result.data) return result.data;
  };
  export const GetJobMasterList = async () => {
    let url = `${API_URL.Job}/api/master/GetJobMasterList`;
    let { result } = await common.to(axios.post(url));
    if (result && result.data) return result.data;
  };
  export const GetDataDateTypeList = async () => {
    let url = `${API_URL.Job}/api/master/GetDataDateTypeList`;
    let { result } = await common.to(axios.post(url));
    if (result && result.data) return result.data;
  };
  export const GetRunModeList = async () => {
    let url = `${API_URL.Job}/api/master/GetRunModeList`;
    let { result } = await common.to(axios.post(url));
    if (result && result.data) return result.data;
  };
  export const GetDependencyDataDateTypeList = async () => {
    let url = `${API_URL.Job}/api/master/GetDependencyDataDateTypeList`;
    let { result } = await common.to(axios.post(url));
    if (result && result.data) return result.data;
  };
  export const GetPlanTypeList =async (param)=>{
    let url = `${API_URL.Job}/api/master/GetPlanTypeList`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
   
}
export const GetExecutionTypeList =async (param)=>{
  let url = `${API_URL.Job}/api/master/GetExecutionTypeList`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
 
}
export const GetPlanStatusList =async (param)=>{
    let url = `${API_URL.Job}/api/master/GetPlanStatusList`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
   
}

//Role
export const GetRolesList = async (param) => {
  let url = `${API_URL.Lobby}/api/Portal/GetRolesList`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

//bu

export const GetBuList =async (param)=>{
  let url = `${API_URL.Job}/api/master/GetBuList`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
 
}