import React, { useMemo, useContext } from "react";
//Material
import Box from "@material-ui/core/Box";
import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { generate_mui_col } from "utils/mui-table";
import PropTypes from "prop-types";
//Context
import { CaptionFieldContext } from "apps/data-catalog/caption-field-context/caption-field-context";

export const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  autoHeight: true,
  rowHeight: 30,
  headerHeight: 40,
  pageSize: 50,
  rowsPerPageOptions: [5, 10, 50, 100],
}));
export const CMDataGrid = (props) => {
  const { state } = useContext(CaptionFieldContext);
  const { customColumn, columns, ...rest } = props;
  const CustomColumn = useMemo(
    () => generate_mui_col(customColumn, state.FieldDictionary),
    [customColumn, state.FieldDictionary]
  );
  return (
    <Box style={{ width: "100%" }}>
      <DataGrid
        autoHeight
        rowHeight={30}
        headerHeight={40}
        {...rest}
        columns={CustomColumn || columns}
        disableSelectionOnClick
        pageSize={50}
        rowsPerPageOptions={[5, 10, 50, 100]}
      />
    </Box>
  );
};
export const CMDataGrid2 = (props) => {
  //{ all = [], date = [], date_time = [], custom_col = [] }
  const [pageSize, setPageSize] = React.useState(50);
  const { state } = useContext(CaptionFieldContext);
  const {
    autoHeight,
    maxHeight = "610px",
    minHeight = "270px",
    customColumn,
    columns,
    ...rest
  } = props;
  const CustomColumn = useMemo(
    () => generate_mui_col(customColumn, state.FieldDictionary),
    [customColumn, state.FieldDictionary]
  );
  const boxMaxHeight = autoHeight ? "auto" : maxHeight;
  const boxMinHeight = autoHeight ? "auto" : minHeight;
  return (
    <Box style={{ height: boxMaxHeight, width: "100%" }}>
      <DataGrid
        style={{ maxHeight: boxMaxHeight, minHeight: boxMinHeight }}
        headerHeight={40}
        autoHeight={autoHeight || false}
        {...rest}
        columns={CustomColumn || columns}
        disableSelectionOnClick
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 50, 100]}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#594F74",
            color: "#fff",
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root":
            {
              color: "#fff",
            },
        }}
      />
    </Box>
  );
};
CMDataGrid.propTypes = {
  customColumn: PropTypes.shape({
    all: PropTypes.array,
    date: PropTypes.array,
    date_time: PropTypes.array,
    custom_col: PropTypes.array,
  }),
};
export default CMDataGrid;
