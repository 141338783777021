import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import { FormProvider } from "react-hook-form";
import InboundCriteriaBox from "stateless/Inbound/Inbound-criteria-box";
import DataDateBox from "stateless/Inbound/datadate-box";
import { BaseContainer } from "stateless/base-container";
import { PurpleButton } from "stateless/button";
import { FilterManual, FilterShow } from "stateless/filterbar";
import useFilterbar from "stateless/filterbar/useFilterbar";
import { ToDateRangeText, ToDateTimeText, ToDateText } from "utils/date";
import {
  GridFooterContainer,
  GridPagination,
  GridToolbar,
} from "@mui/x-data-grid";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { KKPModal } from "stateless/modal/modal2";
import { isEmptyArray } from "utils/common-function";
import { ViewTableDynamicDialog } from "../screen-admin-target-table-maintenance/dialog-table-view";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
import { useExportAdjust } from "./function";

const filter_field_list = ["GroupUser", "Module", "GroupReport"];
const filter_field_silgle_list = ["DataDate"];
const formatter = [
  {
    field: "DataDate",
    handler: (val) => {
      return ToDateRangeText(ToDateText(val.from), ToDateText(val.to));
    },
  },
];
const default_filter = {
  GroupUser: [],
  Module: [],
  GroupReport: [],
};
function Index() {
  const controlFilter = useFilterbar({
    fieldList: filter_field_list,
    defaultValues: default_filter,
    fieldSingle: filter_field_silgle_list,
    Formatter: formatter,
  });
  const methods = useExportAdjust({ filter: controlFilter.data });
  const { actions, state, formMethods } = methods;
  return (
    <>
      <FormProvider {...formMethods}>
        <InboundCriteriaBox>
          <DataDateBox onChange={actions.getExportAdjustmentsList} />
        </InboundCriteriaBox>
        <BaseContainer>
          <Box display={"flex"}>
            <FilterManual
              {...controlFilter}
              //Search={() => GetUploadManualList()}
              groupReport
            />
          </Box>
          <Box my={1}>
            <FilterShow {...controlFilter} />
          </Box>
          <Table methods={methods} />
        </BaseContainer>
      </FormProvider>{" "}
      <ViewTableDynamicDialog
        controlDialog={methods.state.controlDialogDetail}
        methods={methods}
      />
    </>
  );
}

function Table({ methods }) {
  const { viewDetail, download, downloadMultiple } = methods.actions;
  const [selectionModel, setSelectionModel] = methods.controlSelect;
  const { ExportAdjustList } = methods.state;
  const columns = [
    {
      field: "action",
      headerName: "DETAIL",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => viewDetail(params.row)}>
            View
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "download",
      headerName: "DOWNLOAD",
      align: "center",
      minWidth: 150,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => download(params.row)}>
            Download (.XLSX)
          </PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={ExportAdjustList}
      rowHeight={40}
      customColumn={{
        all: db_col,
        custom_col: columns,
        date: ["edw_data_dt", "data_date"],
        date_time: ["last_modified_date"],
      }}
      getRowId={(row) => row.table_id}
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "DOWNLOAD (.XLSX)",
          disabled: isEmptyArray(selectionModel),
          buttonclick: downloadMultiple,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col = [
  "table_id",
  "report_group",
  "table_name",
  "table_type",
  "data_date",
  "adjust_completed",
  "last_approve_round",
];
export default Index;
