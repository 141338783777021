import React, { useState, useEffect } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
//Material
import Box from "@material-ui/core/Box";
//Component
import { Modal } from "../../../../../stateless/modal";
import MuiRadio from "../../../../../stateless/hook-controls/radio";
//Function
import * as api from "../../api";
import { buildParameter } from "../../parameter";
import * as alertDialog from "utils/alert";
let { log: c } = console;
const holdOptions = [
  { label: "Hold", value: "1" },
  { label: "Unhold", value: "2" },
];

function HoldModal(props) {
  const methods = useForm();
  const { data, onClose } = props;
  async function Hold() {
    if(methods.getValues().hold === undefined){
      await alertDialog.inform_error("Please select Hold or Unhold.");
      return;
    }
    let param = buildParameter.HoldPlans(methods.getValues(), data);
    let res = await api.HoldPlans(param);
    if (!res) return;
    let arr_plan = res?.map((el) => el.PLAN_ID);
    let distinct_plan_id = [...new Set(arr_plan)];
    let old_sts = param.Hold ? "IDLE" : "HOLD";
    let new_sts = param.Hold ? "HOLD" : "IDLE";
    let txt =
      distinct_plan_id.length > 0
        ? `${distinct_plan_id.length} ${old_sts} plans successfully set to ${new_sts}`
        : `No ${old_sts} plans selected.`;
    await alertDialog.Inform(txt);
    methods.setValue("hold",undefined);
    onClose();
  }
  return (
    <>
      <FormProvider {...methods}>
        <Modal {...props} title={`Hold`} onSave={Hold}>
          {/* <Typography>{JSON.stringify(data)}</Typography> */}
          {/* console.log(props) */}
          <Box display="block" bgcolor="background.paper">
            <MuiRadio
              name="hold"
              options={holdOptions}
            ></MuiRadio>
          </Box>
        </Modal>
      </FormProvider>
    </>
  );
}

export default HoldModal;
