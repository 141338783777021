import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
// Util
import { common } from "utils";
//Material
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
//Component
import { Modal } from "../../../../stateless/modal";
import FormInput from "../../../../stateless/hook-controls/input";
//Function
import { useSelectItem } from "../../../../stateless/react-table/useSelectItem";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import {
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyArray,
  titleCase,
} from "utils/common-function";
import * as alertDialog from "utils/alert";
//
function ModalOrigin(props) {
  const { open, onClose } = props;
  const method = useForm();
  const { getValues, setError, clearErrors } = method;
  const { JsonFile, selectedItems } = props;
  useEffect(() => {
    if (!open) return null;
  }, [open]);

  const SaveOrigin = async () => {
    clearErrors("ORIGIN");
    const ORIGIN = getValues().ORIGIN;

    if (isEmptyStr(ORIGIN)) {
      setError("ORIGIN", {
        type: "manual",
        message: "The Origin field is required",
      });
      return;
    }

    await selectedItems?.map(async (plan_name) => {
      const result = JsonFile.filter(
        (data) => data.Plan.tnx_execution_plan.PLAN_NAME === plan_name
      );
      if (result) {
        await result[0]?.ListJobMaster.map(async (detail) => {
          detail.ORIGIN = ORIGIN;
        });
        await result[0]?.Plan.list_tnx_execution_plan_job_detail.map(
          async (detail) => {
            detail.ORIGIN = ORIGIN;
          }
        );
      }
    });

    onClose();
    alertDialog.inform_success();
  };
  return (
    <>
      <Modal
        {...props}
        title={`Set Origin`}
        onSave={SaveOrigin}
        maxWidth="sm"
      >
        <FormProvider {...method}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box m={1}>
                <FormInput name="ORIGIN" label="Origin" />
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </Modal>
    </>
  );
}
export default ModalOrigin;
