import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@material-ui/core/Box";
import { CheckboxThree } from "stateless/hook-controls/checkbox";
//Api
import { common } from "utils";
import { API_URL } from "utils/api-utils";
//util
import { isEmptyStr } from "utils/common-function";
const LoadData = async (data) => {
  let url = `${API_URL.Service}/api/UploadMaster/GetTemplateList`;
  let { result } = await common.to(axios.post(url, data));
  if (result && result.data) return result.data;
};
export function CheckboxInboundModuleList(props) {
  const { filter, ...rest } = props;
  const [ApiData, setApiData] = useState([]);
  useEffect(() => {
    (async () => {
      //let data = await LoadData();
      setApiData(["HP", "LN_GENL"]);
    })();
  }, []);
  function filtering() {
    let data = ApiData?.filter(
      (el) =>
        el?.toLowerCase().indexOf(filter?.toLowerCase()) !== -1 ||
        isEmptyStr(filter)
    );
    var result = data?.reduce(function (map, obj) {
      map[obj] = false;
      return map;
    }, {});
    return result;
  }
  return (
    <>
      <Box display="flex" className="checkboxBox">
        {" "}
        <Box
          className="checkboxList"
          display="flex"
          pl={3}
          justifyContent={"space-between"}
          flexWrap="wrap"
          flexGrow={1}
        >
          {<CheckboxThree options={filtering()} {...rest} />}
        </Box>
      </Box>
    </>
  );
}

