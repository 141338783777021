import { RestartAlt } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import FormSelect from "stateless/hook-controls/select";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
export const api_GetData = async (param) => {
  let url = `${API_URL.Job}/api/SelfService/GetSelfservicePlanOwnerList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function SelectOwnerList(props) {
  const { hasall, ...rest } = props;
  const [Data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      let [res, err] = await api_GetData({ HasAll: hasall });
      if (err) return;
      const cbo = res && res.map((el) => ({ label: el, id: el }));
      setData(cbo);
    })();
  }, [hasall]);
  return (
    <>
      <FormSelect {...rest} options={Data} />
    </>
  );
}
