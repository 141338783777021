import React, { useEffect, useState, useContext } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Paper } from "@material-ui/core";
//Component
import { Modal } from "../../../../stateless/modal";
import { BaseContainer } from "../../../../stateless/base-container";
import FormInput from "../../../../stateless/hook-controls/input";
import PlanJobItems from "./plan-job-items";
import PlanDependencies, { DependenciesChain } from "./plan-denpendencies";
import { ScheduleType } from "../../../../stateless/selection-control/combo/schedule-type";
import ComboDataDateType from "../../../../stateless/selection-control/combo/date-type";
import ComboExecutionPlanType from "../../../../stateless/selection-control/combo/execution-plan-type";
//Context
import { ExecutionPlanContext } from "../job-execution-plan-context";
import {
  LoadingContext,
  withLoadingContext,
} from "../../../../context/loading-context";
//Functions
import * as alertDialog from "utils/alert";
import * as CONS from "../constants";
import { HookisvalidDate } from "utils/date";
import { GetUser } from "utils/sessions";
import { hours, Weekly, days, Monthly2, months, SchduleSpec } from "../spec";
import {
  setDataToFormHookV2,
  isEmptyStr,
  titleCase,
  isEmptyArray,
  ArrayTextReduce,
} from "utils/common-function";
import { apiError, ConfirmSave, Inform, ConfirmCommon } from "utils/alert";
import { buildParameter } from "../parameter";
import * as api from "../api";
import moment from "moment";
let { log: c } = console;
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  loader: {
    position: "fixed",
    top: 56,
    [theme.breakpoints.up("sm")]: {
      top: 64,
    },
    left: 0,
    width: "100%",
  },
}));
const validationSchema = Yup.object().shape({
  PLAN_NAME: Yup.string().required("Plan Name is required"),
  //SCHEDULE_TEXT: Yup.string().required("Schdule is required"),
  VALIDATION_KEY: Yup.string().required("Process Name is required"),
  EXECUTION_PLAN_TYPE_ID: Yup.string().required(
    "Execution Plan Type is required"
  ),
  DATADATE_TYPE_ID: Yup.string().required("Datadate Type is required"),
});
const AddExecutionPlan = (props) => {
  //Context
  const { state } = useContext(ExecutionPlanContext);
  const { actions: load_actions, state: load_state } =
    useContext(LoadingContext);
  const CriteriaMethods = useForm({ resolver: yupResolver(validationSchema) });
  const history = useHistory();
  //State
  const classes = useStyles();
  //Effect
  useEffect(() => {
    load_actions.setisLoading(false);
  }, [load_actions]);
  useEffect(() => {
    if (!state?.PlanDetail?.SESSION_ID) history.goBack();
  }, [state?.PlanDetail, history]);
  //Function
  const isRerun = () => {
    return state.PlanDetail.EXECUTION_PLAN_TYPE_ID === 2;
  };
  //Event

  if (!state?.PlanDetail?.SESSION_ID) return null;
  return (
    <div style={getstyle(isRerun())}>
      <BaseContainer>
        {load_state?.isLoading ? (
          <Box className={classes.loader}>
            <LinearProgress className={classes.loader} />
          </Box>
        ) : null}
        <FormProvider {...CriteriaMethods}>
          <Box mb={1}>
            <Detail />
          </Box>
        </FormProvider>
        <Box mb={1}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <PlanJobItems />
            </Grid>
            <Grid item xs={12} md={6}>
              <PlanDependencies />
            </Grid>
            <Grid item xs={12} md={12} m={3}>
              <DependenciesChain />
            </Grid>
          </Grid>
        </Box>
      </BaseContainer>
    </div>
  );
};
const getstyle = (isRerun) => {
  if (isRerun)
    return {
      backgroundColor: `rgba(${255}, ${140}, ${20},${0.5})`,
    };
  else
    return {
      backgroundColor: `rgba(${135}, ${206}, ${235},${0.5})`,
    };
};

const Detail = () => {
  //Context
  const history = useHistory();
  const context = useContext(ExecutionPlanContext);
  const Loadingcontext = useContext(LoadingContext);
  const { state, actions } = context;
  const methods = useFormContext();
  const { getValues, setValue, register, handleSubmit, control } = methods;
  //state

  const [OpenSchduleModal, setOpenSchduleModal] = useState(false);
  const classes = useStyles();
  const CurrentDataDateType = useWatch({
    control,
    name: "DATADATE_TYPE_ID", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: null, // default value before the render
  });
  //Effect
  useEffect(() => {
    let data2 = {
      ...state.ScheduleSpecDetail,
      ...state.PlanDetail,
    };
    data2 = { ...data2, DATADATE_VALUE: data2.DATADATE_VALUE };
    let data = setDataToFormHookV2(data2);
    data?.forEach(({ name, value }) => setValue(name, value));
  }, [state.PlanDetail, state.ScheduleSpecDetail, setValue]);
  // useEffect(() => {
  //   register(
  //     { name: "PLAN_NAME" },
  //     {
  //       required: true,
  //       validate: (value) => {
  //         return !!value.trim();
  //       },
  //     }
  //   );
  //   register(
  //     { name: "VALIDATION_KEY" },
  //     {
  //       required: true,
  //       validate: (value) => {
  //         return !!value.trim();
  //       },
  //     }
  //   );
  //   register({ name: "DATADATE_TYPE_ID" }, { required: true });
  //   register(
  //     { name: "SCHEDULE_TEXT" },
  //     {
  //       required: true,
  //       validate: (value) => {
  //         return !!value.trim();
  //       },
  //     }
  //   );
  //   register({
  //     name: "DATADATE_VALUE",
  //     setValueAs: (value) => parseInt(value, 10),
  //   });
  // }, [register]);
  // useEffect(() => {
  //   register(
  //     { name: "DATADATE_VALUE" },
  //     {
  //       required: CurrentDataDateType === 1,
  //       max: {
  //         value: 0,
  //         message: `value must between 0 and -31`, // JS only: <p>error message</p> TS only support string
  //       },
  //       min: {
  //         value: -31,
  //         message: `value must between 0 and -31`, // JS only: <p>error message</p> TS only support string
  //       },
  //     }
  //   );
  // }, [register, CurrentDataDateType]);
  //Function+Event
  async function validate() {
    let msg = [];
    let res = true;
    if(getValues().PlanSchedule.SCHEDULE_TEXT === null){
      msg.push("Please select Schedule");
      alertDialog.inform_error("Please select Schedule.", true);
      return false;
    }
    let { result, error } = await api.ValidateSubmitExecutionPlan({
      ...state.PlanDetail,
      PLAN_NAME: getValues().PLAN_NAME,
    });
    if (error) return false;
    let msg_server = result.data;
    if (!isEmptyArray(msg_server)) msg = msg.concat(msg_server);
    if (isEmptyArray(state.JobItemsList)) {
      msg.push("No job selected");
    }
    let looprisk = state.DependenciesChainList.some((el) => el.LOOP_DETECTED);
    if (looprisk) msg.push("Circular dependency detected, please check.");
    //Conclusion
    if (!isEmptyArray(msg)) {
      await Inform("", ArrayTextReduce(msg));
      res = false;
    }
    return res;
  }
  const onSubmit = async () => {
    let resplanname = await SavePlanName();
    if (!resplanname) return;
    if (!(await validate())) return;
    let EXECUTE_IMMEDIATELY = false;
    if (
      state.ScheduleSpecDetail.SCHEDULE_TYPE_ID === 1 &&
      getValues().ENABLED
    ) {
      let res = await ConfirmSave();

      if (res.isConfirmed) EXECUTE_IMMEDIATELY = true;
      if (res.isDismissed) return;
    }

    // Call Api

    let res = await Loadingcontext.actions.withLoading(() =>
      actions.SubmitExecutionPlan({ ...state.PlanDetail, EXECUTE_IMMEDIATELY })
    );
  };
  const onCancel = async () => {
    let param = {
      ...state.PlanDetail,
      SESSION_OWNER: GetUser(),
    };
    // Call Api
    let res = await Loadingcontext.actions.withLoading(() =>
      actions.CancelEditExecutionPlan(param)
    );
  };
  const SavePlanName = async (e) => {
    let param = {
      ...getValues(),
      SESSION_ID: state.PlanDetail.SESSION_ID,
      SESSION_OWNER: GetUser(),
      MAX_CONCURRENT: 1,
      DATADATE_TYPE_ID: parseInt(getValues().DATADATE_TYPE_ID, 10),
      DATADATE_VALUE: parseInt(getValues().DATADATE_VALUE, 10),
    };
    if (param.DATADATE_VALUE > 0) {
      alertDialog.inform_error("Data date value must be less than 0.", true);
      return;
    }
    let result = await Loadingcontext.actions.withLoading(() =>
      actions.SavePlanName(param)
    );
    return result;
  };
  //Component

  return (
    <>
      <Paper p={2}>
        <Box display="flex" flexDirection="column" p={1}>
          <Box display="flex" minWidth="100px" justifyContent="flex-end">
            <Box>Plan Id : {context.state.PlanDetail.PLAN_ID || "New"}</Box>
          </Box>
          <Box display="flex" flexWrap="wrap" pr={2}>
            <Box display="flex" flexWrap="wrap" flexGrow={1} width="100%">
              <Box m={1} mb={2} flexBasis={200} flexGrow={1}>
                <FormInput
                  required
                  name="PLAN_NAME"
                  label="Plan Name"
                  onBlur={SavePlanName}
                />
              </Box>
              <Box m={1} mb={2} display="flex" flexGrow={1} alignItems="center">
                <Box flexGrow={1}>
                  <FormInput
                    required
                    name="SCHEDULE_TEXT"
                    label="Schdule Text"
                    className="groupBoxInput"
                    disabled
                    onBlur={SavePlanName}
                  />
                </Box>
                <Box width={100}>
                  <Button
                    className="groupBoxButton"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setOpenSchduleModal(true);
                    }}
                  >
                    Edit Schdule
                  </Button>
                </Box>
              </Box>
              <Box m={1} flexBasis={100} flexGrow={1} display="flex">
                <Box pl={1} style={{ paddingLeft: "10px", marginTop: "5px" }}>
                  {/* <FormCheckbox name="ENABLED" label="Enable"></FormCheckbox> */}
                  <input
                    type="checkbox"
                    //ref={register}
                    {...register("ENABLED")}
                    name="ENABLED"
                    label="Enable"
                    onBlur={SavePlanName}
                  />{" "}
                  Enable
                </Box>{" "}
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" flexGrow={1} width="100%">
              <Box m={1} flexBasis={200} flexGrow={1}>
                <FormInput
                  required
                  name="VALIDATION_KEY"
                  label={titleCase("process name")}
                  onBlur={SavePlanName}
                />
              </Box>
              <Box m={1} flexBasis={150}>
                {" "}
                <FormInput
                  name="OWNER"
                  label={titleCase("OWNER")}
                  onBlur={SavePlanName}
                />
              </Box>
              <Box m={1} flexBasis={200}>
                {" "}
                <ComboExecutionPlanType
                  required
                  name="EXECUTION_PLAN_TYPE_ID"
                  label={titleCase("EXECUTION_PLAN_TYPE")}
                  onBlur={SavePlanName}
                />
              </Box>
              <Box m={1} flexBasis={200}>
                {" "}
                <ComboDataDateType
                  required
                  name="DATADATE_TYPE_ID"
                  label={titleCase("DATADATE_TYPE")}
                  onBlur={SavePlanName}
                />
              </Box>
              <Box m={1} display={"flex"} flexBasis={200}>
                {" "}
                <FormInput
                  type="number"
                  required
                  name="DATADATE_VALUE"
                  label={titleCase("DATADATE_VALUE")}
                  onBlur={SavePlanName}
                  InputProps={{ inputProps: { max: 0 } }}
                />
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" flexGrow={1} width="100%">
              <Box m={1} flexBasis={350}>
                {" "}
                <FormInput
                  name="TAGS"
                  label={titleCase("TAGS")}
                  onBlur={SavePlanName}
                />
              </Box>
              <Box m={1} flexBasis={200} flexGrow={1}>
                <FormInput
                  name="PLAN_DESC"
                  multiline
                  label="Description"
                  onBlur={SavePlanName}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <div className={classes.grow} />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          style={{ paddingBottom: "10px" }}
        >
          <Box m={2} className="btnstyle">
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          </Box>
          <Box m={2} className="btnstyle">
            <Button variant="contained" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>
      <ScheduleModal
        open={OpenSchduleModal}
        onClose={() => {
          setOpenSchduleModal(false);
        }}
      />
    </>
  );
};
const ScheduleModal = (props) => {
  //Context
  const Loadingcontext = useContext(LoadingContext);
  const context = useContext(ExecutionPlanContext);
  const { state, actions } = context;
  const methods = useForm({ mode: "onChange" });
  const { getValues, setValue, setError, unregister, control } = methods;
  //State
  const CurrentType = useWatch({
    control,
    name: "SCHEDULE_TYPE_ID", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    // defaultValue: null, // default value before the render
  });
  //Effect
  useEffect(() => {
    if (!props.open) return;
    methods.clearErrors();
    unregister(
      days
        .map((el) => el.name)
        .concat(Weekly.map((el) => el.name))
        .concat(months.map((el) => el.name))
        .concat(hours.map((el) => el.name))
    );
    const fetch = async () => {
      let data2 = {
        ...state.ScheduleSpecDetail,
      };
      // c(data2)
      let data = setDataToFormHookV2(data2, []);
      setTimeout(() =>
        data?.forEach(({ name, value }) => setValue(name, value))
      );
    };
    fetch();
  }, [props.open, setValue, state.ScheduleSpecDetail, unregister, methods]);
  const isRerun = () => {
    return state.PlanDetail.EXECUTION_PLAN_TYPE_ID === 2;
  };

  const getScheduleFieldObj = () => {
    //เอาค่าใหม่ทับค่าเก่า
    let form = getValues();
    let schdule = {};
    let cur_col = [];
    let uncheckall = false;
    switch (CurrentType) {
      case CONS.DAILY:
        if (!form.IS_Specific_Day) uncheckall = true;
        cur_col = days;
        break;
      case CONS.WEEKLY:
        cur_col = Weekly;
        break;
      case CONS.MONTHLY:
        cur_col = days.concat(Monthly2);
        break;
      case CONS.YEARLY:
        cur_col = days.concat(months);
        break;
      case CONS.QUARTERLY:
        cur_col = days.concat(months);
        break;
      case CONS.HOURY_WEEKDAY:
        cur_col = hours.concat(Weekly);
        break;
      case CONS.HOURY_MONTHDAY:
        cur_col = hours.concat(days);
        break;
      default:
        break;
    }

    for (const [key, value] of Object.entries(form)) {
      if (cur_col.find((el) => el.name === key)) {
        schdule = { ...schdule, [key]: uncheckall ? false : value };
      }
    }
    return schdule;
  };
  function HasSomeCheckDate() {
    let field = days.concat(Monthly2);
    let result = false;
    for (const [key, value] of Object.entries(state.ScheduleSpecDetail)) {
      if (field.find((el) => el.name === key) && value) {
        result = true;
      }
    }
    return result;
  }
  function validate(data) {
    methods.clearErrors();
    let result = true;
    if (data.isInterval && data.SCHEDULE_TYPE_ID !== CONS.IMMEDIATELY) {
      if (!(data.INTERVAL_MINUTES > 0)) {
        setError("INTERVAL_MINUTES", {
          type: "manual",
          message: `Invalid value`,
        });
        result = false;
      }

      if (isEmptyStr(data.INTERVAL_ENDTIME)) {
        setError("INTERVAL_ENDTIME", {
          type: "manual",
          message: `Invalid value`,
        });
        result = false;
      }
    }
    if (data.HOUR_MINUTE < 0 || data.HOUR_MINUTE > 59) {
      setError("HOUR_MINUTE", {
        type: "manual",
        message: `Invalid value`,
      });
      result = false;
    }
    if (data.SCHEDULE_TYPE_ID === CONS.ONETIME) {
      if (!data.ONETIME_DATETIME) {
        setError("ONETIME_DATETIME", {
          type: "manual",
          message: `Invalid empty value`,
        });
        result = false;
      }
    }
    if (!HookisvalidDate(data, ["ONETIME_DATETIME"])) {
      setError("ONETIME_DATETIME", {
        type: "manual",
        message: `Invalid value`,
      });
      result = false;
    }
    if (
      data.SCHEDULE_TYPE_ID !== CONS.IMMEDIATELY &&
      data.SCHEDULE_TYPE_ID !== CONS.ONETIME
    ) {
      if (!isEmptyStr(data.TIME_DATA)) {
        let times = data.TIME_DATA?.split(",");
        let valid = true;
        for (let index = 0; index < times.length; index++) {
          let element = times[index].replace(":", "");
          let len = element.length;
          if (len < 3 || len > 4 || isNaN(element)) {
            valid = false;
            break;
          }
          //    c(element, moment(element, "HH:mm", true));
          //    if (!moment(element, "HH:mm", true).isValid()) {
          //        valid = false;
          //        break;
          //    }
        }
        if (!valid) {
          setError("TIME_DATA", {
            type: "manual",
            message: `Invalid value`,
          });
          result = false;
        }
      } else {
        setError("TIME_DATA", {
          type: "manual",
          message: `Invalid empty value`,
        });
        result = false;
      }
      if (!result) return false;
    }

    if (
      data.SCHEDULE_TYPE_ID === CONS.WEEKLY ||
      data.SCHEDULE_TYPE_ID === CONS.HOURY_WEEKDAY
    ) {
      var lstweek = Weekly.map((el) => {
        return data[el.name];
      });
      result = lstweek.includes(true);
      if (!result) {
        alertDialog.Inform("Please select Weekly.");
      }
    }

    if (
      data.SCHEDULE_TYPE_ID === CONS.MONTHLY ||
      data.SCHEDULE_TYPE_ID === CONS.QUARTERLY ||
      data.SCHEDULE_TYPE_ID === CONS.YEARLY
    ) {
      var lstDays = days.map((el) => {
        return data[el.name];
      });
      var lstMonthly2 = Monthly2.map((el) => {
        return data[el.name];
      });

      result = lstDays.includes(true) || lstMonthly2.includes(true);
      if (!result) {
        alertDialog.Inform("Please select Days.");
        return false;
      }

      if (
        data.SCHEDULE_TYPE_ID === CONS.QUARTERLY ||
        data.SCHEDULE_TYPE_ID === CONS.YEARLY
      ) {
        var lstMonths = months.map((el) => {
          return data[el.name];
        });
        result = lstMonths.includes(true);
        if (!result) {
          alertDialog.Inform("Please select Month.");
          return false;
        }
      }
    }

    return result;
  }
  const SavePlanScheduleSbx = async (e) => {
    let boolScheduleDetail = getScheduleFieldObj(); //Update only type selected || other save old values from db || (boolean value)
    let param = buildParameter.SavePlanSchedule(
      state.PlanDetail,
      state.ScheduleSpecDetail,
      boolScheduleDetail,
      getValues()
    );
    console.log(param);
    if (!validate({ ...getValues(), ...param.Schedule })) {
      return;
    }
    await Loadingcontext.actions.withLoading(async () =>
      actions.SavePlanScheduleSbx(param)
    );
    props.onClose();
  };
  return (
    <>
      <Modal {...props} onSave={SavePlanScheduleSbx} title="Schedule">
        <FormProvider {...methods}>
          <Box display="block" bgcolor="background.paper">
            <ScheduleType
              name="SCHEDULE_TYPE_ID"
              label="Schdule"
              isRerun={isRerun()}
              onChange={(e) => {
                setValue("SCHEDULE_TYPE_ID", e.target.value, {
                  shouldDirty: true,
                });
              }}
            />
            <SchduleSpec
              CurrentType={CurrentType}
              interval={state.ScheduleSpecDetail.INTERVAL_MINUTES}
              isSpecificDay={HasSomeCheckDate()}
            ></SchduleSpec>
          </Box>
        </FormProvider>
      </Modal>
    </>
  );
};
export default withLoadingContext(AddExecutionPlan);
