import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import { FormProvider } from "react-hook-form";
import InboundCriteriaBox from "stateless/Inbound/Inbound-criteria-box";
import DataDateBox from "stateless/Inbound/datadate-box";
import UploadBox from "stateless/Inbound/upload-box";
import { BaseContainer } from "stateless/base-container";
import { useUploadAdjust, useUploadError, useUploadDetail } from "./function";
import { PurpleButton } from "stateless/button";
import { FilterManual, FilterShow } from "stateless/filterbar";
import useFilterbar from "stateless/filterbar/useFilterbar";
import { ToDateRangeText, ToDateTimeText, ToDateText } from "utils/date";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { KKPModal } from "stateless/modal/modal2";
import { isEmptyArray } from "utils/common-function";
import {
  GridFooterContainer,
  GridPagination,
  GridToolbar,
} from "@mui/x-data-grid";
import { ViewAdjustHistoryDialog } from "../stateless/dialog/dialog-adjust-history";
import { ViewAdjustFileDialog } from "../stateless/dialog/dialog-adjust-file-view";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
import { Backdrop, CircularProgress } from "@mui/material";
const { log } = console;
const filter_field_list = ["GroupUser", "Module", "GroupReport"];
const filter_field_silgle_list = ["DataDate"];
const formatter = [
  {
    field: "DataDate",
    handler: (val) => {
      return ToDateRangeText(ToDateText(val.from), ToDateText(val.to));
    },
  },
];
const default_filter = {
  GroupUser: [],
  Module: [],
  GroupReport: [],
};

function Index() {
  const controlFilter = useFilterbar({
    fieldList: filter_field_list,
    defaultValues: default_filter,
    fieldSingle: filter_field_silgle_list,
    Formatter: formatter,
  });
  const methods = useUploadAdjust({ filter: controlFilter.data });
  const { actions, state, formMethods } = methods;
  return (
    <>
      <FormProvider {...formMethods}>
        <InboundCriteriaBox>
          <DataDateBox onChange={actions.getUploadAdjustmentsList} />
          <UploadBox onUploadFile={actions.uploadAdjustment} />
        </InboundCriteriaBox>
        <BaseContainer>
          <Box display={"flex"}>
            <FilterManual
              {...controlFilter}
              groupUser
              //Search={() => GetUploadManualList()}
            />
          </Box>
          <Box my={1}>
            <FilterShow {...controlFilter} />
          </Box>
          <Table methods={methods} />
        </BaseContainer>
      </FormProvider>{" "}
      <UploadErrorDialog
        controlDialogError={methods.state.controlDialogError}
        methods={methods}
      />
      <ViewAdjustFileDialog
        controlDialog={methods.state.controlDialogDetail}
        methods={methods}
      />
      <ViewAdjustHistoryDialog
        controlDialog={methods.state.controlDialogHistory}
        methods={methods}
      />
    </>
  );
}

function UploadErrorDialog({ controlDialogError, methods }) {
  const { state, actions } = useUploadError();
  const { isOpen, Data } = controlDialogError;
  const { GetUploadErrorList } = actions;
  useEffect(() => {
    if (!isOpen) return;
    GetUploadErrorList(Data);
  }, [isOpen, GetUploadErrorList, Data]);
  return (
    <>
      <KKPModal
        control={controlDialogError}
        title="Upload CSV Template Error Detail"
      >
        <TableErrorDetail data={state.UploadErrorList} />
      </KKPModal>
    </>
  );
}
function Table({ methods }) {
  const {
    checkUpload,
    viewError,
    viewDetail,
    download,
    viewHistory,
    downloadMultiple,
  } = methods.actions;
  const [selectionModel, setSelectionModel] = methods.controlSelect;
  const { UploadAdjustList } = methods.state;
  const columns = [
    {
      field: "adjust_on_round",
      headerName: "Adjust On Round",
      minWidth: 150,
    },
    {
      field: "template_name",
      headerName: "File",
      minWidth: 250,
    },
    {
      field: "edw_data_dt",
      headerName: "Data Date",
      minWidth: 100,
    },
    {
      field: "check_upload",
      headerName: "CHECK",
      align: "center",
      minWidth: 100,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => checkUpload(params.row)}>
            Check
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "action_err",
      headerName: "CHK VIEW",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => viewError(params.row)}>
            View Error
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "DETAIL",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => viewDetail(params.row)}>
            View
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "action2",
      headerName: "History",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => viewHistory(params.row)}>
            History
          </PurpleButton>
        </Box>
      ),
    },
    {
      field: "download",
      headerName: "DOWNLOAD",
      align: "center",
      minWidth: 150,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => download(params.row)}>
            Download (.CSV)
          </PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={UploadAdjustList || []}
      rowHeight={40}
      customColumn={{
        all: db_col,
        custom_col: columns,
        date: ["edw_data_dt"],
        date_time: ["upload_datetime", "check_datetime"],
      }}
      getRowId={(row) => row.template_name}
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "DOWNLOAD (.CSV)",
          disabled: isEmptyArray(selectionModel),
          buttonclick: downloadMultiple,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}

function TableErrorDetail({ data, col }) {
  const columns = [
    {
      field: "id",
      headerName: "NO",
      minWidth: 150,
    },
    {
      field: "EDW_DATA_DT",
      headerName: "DATA_DATE",
      valueGetter: ({ value }) => value && `${ToDateText(value)}`,
      minWidth: 150,
    },
    {
      field: "DATE_CHK",
      headerName: "DATE_CHK",
      valueGetter: ({ value }) => value && `${ToDateTimeText(value)}`,
      minWidth: 150,
    },
    {
      field: "TABLE_NAME",
      headerName: "TABLE_NAME",
      minWidth: 250,
    },
    {
      field: "FIELD_NAME",
      headerName: "FIELD_NAME",
      minWidth: 150,
    },
    {
      field: "ROW_ALL",
      headerName: "ROW_ALL",
      minWidth: 150,
      type: "number",
    },
    {
      field: "ERR_01",
      headerName: "ERR_01",
      minWidth: 150,
      type: "number",
    },
    {
      field: "CONDITION",
      headerName: "CONDITION",
      minWidth: 150,
    },
    {
      field: "ERR_LISTS",
      headerName: "ERR_LISTS",
      minWidth: 250,
    },
  ];
  return (
    <CMDataGrid2
      rows={data || []}
      rowHeight={50}
      customColumn={{ custom_col: columns }}
      getRowId={(row) => row.id}
      
    />
  );
}

const db_col = [
  "adjust_on_round",
  "template_name",
  "edw_data_dt",
  "upload_datetime",
  "upload_by",
  "check_result",
  "check_datetime",
];
export default Index;
