import axios from "axios";
import React, { useEffect, useState } from "react";
import FormSelect from "stateless/hook-controls/select";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
const MockData = [
  { label: "-Template1-", id: "1" },
  { label: "-Template2-", id: "2" },
];
const Load = async (param) => {
  let url = `${API_URL.Report}/api/Master/GetTemplateList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export function SelectTemplate(props) {
  const [ApiData, setApiData] = useState([]);
  useEffect(() => {
    (async () => {
      let [res, err] = await Load();
      if (err) return;
      setApiData(
        res?.map(({ template_id: id, template_name: label }) => ({ id, label }))
      );
    })();
  }, []);
  return (
    <div>
      <FormSelect {...props} options={ApiData || MockData} />
    </div>
  );
}
