import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import FormSelect from "stateless/hook-controls/select";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
export const api_GetData = async (param) => {
  let url = `${API_URL.Inbound}/api/Master/GetFilterTypeList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function SelectDynamicType(props) {
  const [Data, setData] = useState([]);
  let name = props.name;
  const { control, setValue } = useFormContext();
  const w_value = useWatch({ control, name: name });
  useEffect(() => {
    (async () => {
      let [res, err] = await api_GetData();
      if (err) return;
      const cbo = res && res.map((el) => ({ label: el, id: el }));

      setData(cbo);
    })();
  }, [setValue]);
  useEffect(() => {
    // console.log(Data,w_value);
    // let found = Data.find((el) => el.value === w_value);
    // if (!found) setValue(name, undefined);
  }, [Data, w_value, name, setValue]);

  return (
    <>
      <FormSelect {...props} options={Data} />
    </>
  );
}
