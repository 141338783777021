//Library
import queryString from 'query-string';
import { SetTokens } from "./../../utils/sessions";
import * as api from "./api";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const HandlePaperTicket = () => {
    const values = queryString.parse(window.location.search);
    const history = useHistory();
    useEffect(() => {
        ProcessToken();
    });
    const ProcessToken = async () => {
        let jwt = await api.GetTokenByTicket({ PaperTicket: values.id });
        if (jwt) {
            SetTokens(jwt.UserId, jwt.AccessToken, jwt.RefreshToken);
            history.push(values.target);
        } else {
            history.push("/Login");
        }
    };
    return (<div></div>);
}
export default HandlePaperTicket
