import { titleCase } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import DescriptionIcon from "@material-ui/icons/Description";
export const getColumJobHistory = (getLog) => {
  const columns = [
    {
      dataField: "a",
      text: titleCase(""),
      formatter: (cell, row, rowIndex) => {
        return (
          <DescriptionIcon
            onClick={() => {
              getLog(row);
            }}
          />
        );
      },
    },
    {
      dataField: "STATUS_DATETIME",
      text: titleCase("STATUS_DATETIME"),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return <>{ToDateTimeText(row.STATUS_DATETIME)}</>;
      },
    },
    {
      dataField: "STATUS_TEXT",
      text: titleCase("STATUS"),
      sort: true,
    },
    {
      dataField: "EXECUTION_TYPE",
      text: titleCase("EXECUTION_TYPE"),
      sort: true,
    },
    {
      dataField: "PLAN_NAME",
      text: titleCase("PLAN_NAME"),
      sort: true,
    },
    {
      dataField: "PARAMETERS",
      text: titleCase("ACTUAL_PARAMETERS"),
      sort: true,
    },
    {
      dataField: "MESSAGE",
      text: titleCase("MESSAGE"),
      sort: true,
    },
  ];
  return columns;
};
