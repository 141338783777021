import React, { useState, useEffect } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
//Material
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
//Component
import { BaseContainer } from "stateless/base-container";
import { BoxTemplate1 } from "stateless/Box";
import FormInput from "stateless/hook-controls/input";
import FormCheckBox from "stateless/hook-controls/checkbox";
import { Modal } from "stateless/modal";
import { ReactTable } from "stateless/react-table";
//Function
import { setDataToFormHookV2,isEmptyStr } from "utils/common-function";
import { getMasterColumns } from "./columns/job-type-master";
import * as alertDialog from "utils/alert";
import { buildParameter } from "./parameter";
import { GetListJobTypeMaster } from "./api";
import { DeleteJobType } from "./api";
import { SaveJobType } from "./api";


function JobTypeMaster() {
  //Context

  //State
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [DataList, setDataList] = useState([]);
  const [DataDetail, setDataDetail] = useState({});

  //Event
  const onOpenAddModal = () => {
    setOpenModal(true);
    setEditMode(false);
    setDataDetail(null);
  };
  const onOpenEditModal = () => {
    setOpenModal(true);
    setEditMode(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  //Function
  const Edit = (Detail) => {
    onOpenEditModal();
    setDataDetail(Detail);
  };

  const Delete = async (id, REFERENCED_JOB) => {

    if (REFERENCED_JOB > 0) return;

    let res = await alertDialog.ConfirmDelete();
    if (!res) return;

    let param = {
      TYPE_ID: id,
    };

    let api_res = await DeleteJobType(param);
    if (!api_res) return;
    fetch();
    alertDialog.del_success();
  };

  //--------
  const fetch = async () => {
    let res = await GetListJobTypeMaster();
    setDataList(res);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      <BaseContainer>
        <BoxTemplate1 title="Job Type Master" add onClickAdd={onOpenAddModal}>
          <ReactTable
            keyField="TYPE_ID"
            data={DataList}
            columns={getMasterColumns(Edit, Delete)}
          />
        </BoxTemplate1>
        <DataModal
          editmode={editMode ? 1 : 0}
          open={openModal}
          onClose={onCloseModal}
          search={fetch}
          datadetail={DataDetail}
        />
      </BaseContainer>
    </div>
  );
}

function DataModal(props) {

  const { onClose } = props;
  const { open, editmode } = props;
  const { datadetail, search = () => {}, ...otherprops } = props;
  const method = useForm();
  const { setValue, getValues, setError, clearErrors, errors } = method;

  //Effect
  useEffect(() => {
    if (!open) return;
    let data = setDataToFormHookV2(datadetail);
    setTimeout(() => data?.forEach(({ name, value }) => setValue(name, value)));
  }, [datadetail, setValue, open]);

  const SaveDetail = async () => {

    clearErrors("TYPE_TEXT");
    const TYPE_TEXT = getValues().TYPE_TEXT;

    if (isEmptyStr(TYPE_TEXT)) {
      setError("TYPE_TEXT", {
        type: "manual",
        message: "The Type Text field is required",
      });
      return;
    }

    const ID = editmode ? datadetail.TYPE_ID : null;
    let param = buildParameter.SaveDetail(ID, getValues());
    let res = await SaveJobType(param);
    if (res) {
      search();
      onClose();
      alertDialog.inform_success();
    }
  }

  return (
    <div>
      <Modal {...otherprops} onSave={SaveDetail} title={editmode ? "Edit Job Type" : "Add Job Type"}>
        <FormProvider {...method}>
          <Box m={3} maxWidth="500px" flexGrow={1} className="box-input">
            {editmode === 1 && (
              <Typography variant="subtitle2" display="block" gutterBottom>
              Job Type ID : {datadetail.TYPE_ID}
              </Typography>
            )}
          </Box>
         <Box m={3} maxWidth="500px" flexGrow={1} className="box-input">
            <FormInput
              required
              name="TYPE_TEXT"
              label="Type Text"
              errorobj={errors}
            />
          </Box>
          <Box mt={3} ml={4.5} maxWidth="500px" flexGrow={1} style={{marginTop:"0"}} >
            <FormCheckBox
              key="RUN_REGULAR"
              name="RUN_REGULAR"
              label="Run in regular process "
              errorobj={errors}
            />
          </Box>
          <Box ml={4.5} maxWidth="500px" flexGrow={1} style={{marginTop:"0"}}>
            <FormCheckBox
              key="RUN_REPROCESS"
              name="RUN_REPROCESS"
              label="Run in reprocess"
              errorobj={errors}
            />
          </Box>
        </FormProvider>
      </Modal>
    </div>
  );
}
export default JobTypeMaster;
