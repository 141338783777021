import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
export const getMasterColumns = (editJobType, deleteJobType) => {
  const columns = [
    {
      dataField: "OP2",
      text: "",
      //sort: true,

      // events: onClickCol,
      formatter: (cell, row) => {
        return (
          <Box component="div" display="flex" justifyContent="center">
            <EditIcon
              color="action"
              onClick={() => {
                editJobType(row);
              }}
            />
            <DeleteIcon
              color={row.REFERENCED_JOB > 0  ? "disabled" : "action"}
              onClick={() => {
                deleteJobType(row.TYPE_ID, row.REFERENCED_JOB);
              }}
            />
          </Box>
        );
      },
    },
    {
      dataField: "TYPE_ID",
      text: "Type ID",
      sort: true,
    },
    {
      dataField: "TYPE_TEXT",
      text: "Type Text",
      sort: true,
    },
    {
      dataField: "RUN_REGULAR",
      text: "Run in regular process",
      sort: true,
    },
    {
      dataField: "RUN_REPROCESS",
      text: "Run in reprocess plan",
      sort: true,
    },
    {
      dataField: "REFERENCED_JOB",
      text: "Referenced jobs",
      sort: true,
    },
  ];
  return columns;
};
