import React, { useEffect, useContext, useCallback, useState } from "react";
import { ReactTable } from "../../../../stateless/react-table";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//Material
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
//Component
import { BaseContainer } from "../../../../stateless/base-container";
import { MaterialUIDatePickers } from "../../../../stateless/hook-controls/datepicker/datepicker";
import TextBox from "../../../../stateless/hook-controls/input";
import { FormDatePicker } from "../../../../stateless/hook-controls/datepicker";
import ComboBackToDateRange from "../../../../stateless/selection-control/combo/backto-date-range";
import FormSelect from "stateless/hook-controls/select";
//Context
import { ExecutionPlanContext } from "../job-execution-plan-context";
import {
  LoadingContext,
  withLoadingContext,
} from "../../../../context/loading-context";
//Function
import { getColumn as getColumnExecutionGroup } from "../columns/ExecutionGroup";
import { buildParameter } from "../parameter";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import * as api from "../api";
import * as alertDialog from "utils/alert";
import { checkDateRange, MuiDateTimeToApi } from "utils/date";
import { ToDateRangeText, ToDateTimeText, ToDateText } from "utils/date";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSignalR } from "module/function-api/useSignalR";
import {
  methodEventKkpGroupStatusChanged,
  methodExecuteGroupResulChanged,
  url,
} from "./signalr";
import moment from "moment";
import { CMDataGrid } from "stateless/data-grid";
import ComboRunMode from "stateless/selection-control/combo/run-mode";
const validationSchema = Yup.object().shape({
  GROUP_NM: Yup.string().required("Group name is required"),
});
function GroupExecution() {
  const { actions: load_actions, state: load_state } =
    useContext(LoadingContext);
  const context = useContext(ExecutionPlanContext);
  const [DataRow, setDataRow] = useState([]);
  const [openPopUpRun, setOpenPopUpRun] = useState(false);
  let popupplan_control = useSelectRowOpenModal();
  const history = useHistory();
  const { state, actions } = context;
  const methods = useForm({
    defaultValues: context.state.CriteriaSearchGroupExecution,
  });
  const { getValues } = methods;
  const Search = useCallback(() => {
    load_actions.withLoading(async () => {
      let res = await api.GetExecutionGroupHistory(getValues());
      setDataRow(res);
    });
  }, [load_actions, getValues]);
  const signalRResultHandler = useCallback((data) => {
    setDataRow((prev) =>
      prev.map((p) =>
        p.history_id === data.ExecuteHistoryId
          ? { ...p, message: data.Message }
          : p
      )
    );
  }, []);
  const signalRStatusHandler = useCallback((data) => {
    setDataRow((prev) =>
      prev.map((p) =>
        p.history_id === data.history_id
          ? {
              ...p,
              status_id: data.status_id,
              message: data.message,
              status_color: data.status_color,
              status_text: data.status_text,
            }
          : p
      )
    );
  }, []);
  useSignalR(url, methodExecuteGroupResulChanged, signalRResultHandler);
  useSignalR(url, methodEventKkpGroupStatusChanged, signalRStatusHandler);
  useEffect(() => {
    Search();
  }, [history, Search]);
  const rowStyle = (row, rowIndex) => {
    return { backgroundColor: row.status_color };
  };
  const columns = getColumnExecutionGroup(popupplan_control.handleClickRow);
  async function Run(params) {
    params = { ...params, DATA_DT: MuiDateTimeToApi(params.DATA_DT) };
    let [res, err] = await api.RunExecutionGroup(params);
    if (err) return;
    alertDialog.inform_success("Send execution command complete.");
    setOpenPopUpRun(false);
    Search();
  }
  const tablecomponent = () => {
    return (
      <>
        <Box m={2} display="flex" className="btnstyle">
          <Button
            variant="contained"
            onClick={() =>
              history.push("/JobMonitoring/GroupExecutionManagement")
            }
            style={{ width: 150 }}
          >
            Group management
          </Button>
          <Box mr={1}></Box>
          <Button variant="contained" onClick={Search}>
            Refresh
          </Button>
          <Box mr={1}></Box>
          <Button variant="contained" onClick={() => setOpenPopUpRun(true)}>
            Run
          </Button>
        </Box>
      </>
    );
  };
  return (
    <>
      {load_state?.isLoading ? <LinearProgress /> : null}
      <BaseContainer>
        <FormProvider {...methods}>
          <Criteria Search={Search} />
        </FormProvider>
        <ReactTable
          data={DataRow || []}
          rowStyle={rowStyle}
          columns={columns}
          keyField="history_id"
          headcomponent={tablecomponent}
          exportCsv
        />
        <PopUpRun
          open={openPopUpRun}
          handleClose={() => setOpenPopUpRun(false)}
          Run={Run}
        />
        <PopUpPlan
          open={popupplan_control.isOpen}
          handleClose={() => popupplan_control.close()}
          Data={popupplan_control.Data}
        />
      </BaseContainer>
    </>
  );
}
const Criteria = ({ Search }) => {
  const context = useContext(ExecutionPlanContext);
  const methods = useFormContext();
  const { getValues, setError, clearErrors } = methods;

  const dateChange = (e) => {
    if (!validate()) return;
    context.actions.SetCriteriaSearchGroupExecution(getValues());
    Search();
  };
  function validate() {
    let dateform_name, dateTo_name;

    dateform_name = "dateFrom";
    dateTo_name = "dateTo";

    clearErrors();
    let valid = checkDateRange(
      getValues()[dateform_name],
      getValues()[dateTo_name]
    );
    console.log(valid);
    if (!valid) {
      setError(dateform_name, {
        type: "manual",
        //message: `Invalid date range`,
      });
      setError(dateTo_name, {
        type: "manual",
        message: `Invalid date range`,
      });
      return false;
    }
    return true;
  }
  return (
    <>
      <Box display="flex" flexWrap="wrap" className="criteriaStyle">
        <Box p={1} minWidth="175px" style={{ paddingLeft: "0" }}>
          <MaterialUIDatePickers
            name="dateFrom"
            label="From"
            onChange={dateChange}
          />
        </Box>

        <Box
          p={1}
          minWidth="175px"
          className="dropDownBox"
          style={{ paddingLeft: "0" }}
        >
          <MaterialUIDatePickers
            name="dateTo"
            label="To"
            minDate={context.state.CriteriaSearchGroupExecution.dateFrom}
            onChange={dateChange}
          />
        </Box>
        {/* <Box p={1} flexGrow={1}>
          <CheckboxPlanType name="PLAN_TYPE_ID" onChange={filterdata} />
        </Box>
        <Box p={1} flexGrow={1} maxWidth={400}>
          <CheckboxPlanStatus name="PLAN_STATUS_ID" onChange={filterdata} />
        </Box> */}
      </Box>
    </>
  );
};
function PopUpRun({ open, handleClose, Run }) {
  const methods = useForm({
    defaultValues: { DATA_DT: moment(),RUN_MODE:"N" },
    resolver: yupResolver(validationSchema),
  });
  const { getValues, reset, handleSubmit } = methods;
  useEffect(() => {
    if (!open) reset();
  }, [open, reset]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Run Execution Group</DialogTitle>
          <DialogContent>
            <DialogContentText>Input Parameter.</DialogContentText>
            <Box display={"flex"} flexDirection="column">
              <TextBox name="GROUP_NM" label={"Group Name"} />
              <Box mb={1}></Box>
              <MaterialUIDatePickers name="DATA_DT" label={"Data Date"} />
              <Box mb={1}></Box>
              <ComboRunMode name="RUN_MODE" label={"Run Mode"} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={(e) => {
                handleSubmit(() => Run(getValues()))(e);
              }}
            >
              Run
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
}
function PopUpPlan({ open, handleClose, Data }) {
  const methods = useForm({ defaultValues: { DATA_DT: moment() } });
  const { getValues, reset } = methods;
  const [DataRow, setDataRow] = useState([]);
  const LoadData = useCallback(async () => {
    let [res, err] = await api.GetListPlanInGroup(Data || {});
    if (err) return;
    setDataRow(res);
  }, [Data]);
  useEffect(() => {
    if (!open) {
      reset();
      return;
    }
    LoadData();
  }, [LoadData, open, reset]);

  const columns = [
    { field: "plan_id", headerName: "Plan ID", editable: false },
    {
      field: "plan_name",
      headerName: "Plan Name",
      minWidth: 400,
      editable: false,
    },
    { field: "priority", headerName: "Priority", editable: false },
    
    {
        field: "status_datetime",
        headerName: "Status DateTime",
        valueGetter: ({ value }) => value && `${ToDateTimeText(value)}`,
        minWidth: 150,
        editable: false,
    },
    {
      field: "status_text",
      headerName: "Status",
      editable: false,
    },
    {
      field: "message",
      headerName: "Error Message",
      minWidth: 500,
      editable: false,
    },
  ];
  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
          <DialogTitle>Plans in group {Data?.group_name_display}.</DialogTitle>
          <DialogContent>
            <Box display="flex">
              <Box flexGrow={1} width={"80%"}>
                <DialogContentText></DialogContentText>

                <CMDataGrid
                  rows={DataRow}
                  customColumn={{ custom_col: columns }}
                  getRowId={(row) => row.group_name + row.plan_id}
                  //experimentalFeatures={{ newEditingApi: true }}
                 // columnVisibilityModel={{ plan_id: false }}
                  components={{
                    Footer: CustomFooterStatusComponent,
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
}
function CustomFooterStatusComponent(props) {
  return <Box sx={{ p: 1, display: "flex" }}></Box>;
}
export default withLoadingContext(GroupExecution);
