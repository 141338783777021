import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
export const getOriginColumns = (editOrigin, deleteOrigin) => {
  const columns = [
    {
      dataField: "OP2",
      text: "",
      //sort: true,

      // events: onClickCol,
      formatter: (cell, row) => {
        return (
          <Box component="div" display="flex" justifyContent="center">
            <EditIcon
              color="action"
              onClick={() => {
                editOrigin(row);
              }}
            />
            <DeleteIcon
              color="action"
              onClick={() => {
                deleteOrigin(row.ORIGIN_ID);
              }}
            />
          </Box>
        );
      },
    },
    {
      dataField: "ORIGIN_NAME",
      text: "Origin Name",

      // events: onClickCol,
    },
    {
      dataField: "EDGE_URL",
      text: "Edge URL",

      // events: onClickCol,
    },
  ];
  return columns;
};
