import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridFooterContainer, GridPagination } from "@mui/x-data-grid";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { FooterButtonInbound } from "stateless/button";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
import { KKPModal } from "stateless/modal/modal2";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { isEmptyArray } from "utils/common-function";
import { ToDateTextNoDash, getToday } from "utils/date";
import { DownloadFileExcel } from "utils/file";
import { DynamicFilterList } from "../stateless/dynamic-filter/filter_list";
import { useDynamicFilter } from "../stateless/dynamic-filter/useDynamicFilter";

export const api_GetDataByTableNameList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetDataByTableName`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
function useViewTableDynamic({Data}) {
  const [DataRow, setDataRow] = useState([]);
  const [DataDataColumn, setDataColumn] = useState([]);
  const state = { DataRow, DataDataColumn };
  const GetTableDataList = useCallback(async (params) => {
    let [res, err] = await api_GetDataByTableNameList(params);
    if (err) return;
    setDataRow(res);
    if (isEmptyArray(res)) return;
    let col = Object.getOwnPropertyNames(res[0]);
    setDataColumn(col);
  }, []);
  function download(params) {
    let file_name = `${Data.table_name}_${ToDateTextNoDash(Data.data_date)}.xlsx`;
    DownloadFileExcel(DataRow, file_name);
  }
  const actions = {
    GetTableDataList,
    download,
  };

  return { state, actions };
}
export function ViewTableDynamicDialog({ controlDialog }) {
  const { isOpen, Data } = controlDialog;
  const controlDynamicFilter = useDynamicFilter();
  const { state, actions, formMethods } = useViewTableDynamic({Data});
  const { GetTableDataList,download } = actions;
  
  useEffect(() => {
    if (!isOpen) return;
    GetTableDataList({...Data,dynamicFilter:controlDynamicFilter.filterData});
  }, [isOpen, GetTableDataList, Data,controlDynamicFilter.filterData]);

  return (
    <>
      <FormProvider {...formMethods}>
        <KKPModal control={controlDialog} title="View Target Table">
          <Typography>Table Name : {Data?.table_name}</Typography>
          <Box my={1}>
            <DynamicFilterList
              {...controlDynamicFilter}
              table_info={useMemo(
                () => ({ table_type: "target_table", table_name: Data?.table_name }),
                [Data]
              )}
            />
          </Box>
          <Box mt={2}></Box>
          <Table
            data={state.DataRow}
            col={state.DataDataColumn}
            download={download}
          />
        </KKPModal>
      </FormProvider>
    </>
  );
}
function Table({ data, col, download }) {
  const columns = [];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={data || []}
      rowHeight={40}
      customColumn={{
        all: col,
        custom_col: columns,
        date: ["data_date"],
        date_time: ["upload_datetime", "adjust_datetime"],
      }}
      getRowId={(row) => row.id}
      components={
        {
          Footer: CustomInboundFooterOneButtonComponent,
          //Toolbar: GridToolbar,
        }
      }
      componentsProps={{
        footer: {
          button_name: "DOWNLOAD (.XLSX)",
          disabled: false,
          buttonclick: download,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}