import { GetUser } from "utils/sessions";

export const buildParameter = {
         SaveDetail: (id, formdata) => {
           let param = {
             ID: id,
             USER_ID: formdata.USER_ID,
             ROLES: formdata.ListRole,
             ACTIVE: formdata.ACTIVE,
             GROUP_BU: formdata.GROUP_BU,
             LOCAL_USER: formdata.LOCAL_USER,
             PASSWORD: formdata.LOCAL_USER ? formdata.PASSWORD : null,
             SESSION_OWNER: GetUser(),
           };
           return param;
         },
       };
