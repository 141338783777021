import moment from "moment";
import { isEmptyStr } from "./common-function";
export const dateMuiformat = "yyyy-MM-dd";
export const dateTimeMuiformat = "yyyy-MM-dd HH:mm";
export const MonthMuiformat = "MMMM-yyyy";
//Text
export const dateformat = "yyyy-MM-DD";
export const dateTimeformat = "yyyy-MM-DD HH:mm";
export const dateformat2 = "yyyyMMDD";
//For send api
export const ApiDateTimeFormat = "yyyy-MM-DDTHH:mm:ss";

//integer param
export function ExcelDateIntToApi(params) {
  if (typeof params !== "number") return null;
  return MuiDateTimeToApi(new Date(Math.floor(params - 25569) * 86400 * 1000));
}
export function MuiDateTimeToApi(params) {
  let date = moment(params).isValid()
    ? moment(params).format(ApiDateTimeFormat)
    : params;
  return date || null;
}

export function HookisvalidDate(data = [], fieldname = []) {
  let result = true;
  for (let idx = 0; idx < fieldname.length; idx++) {
    const el = fieldname[idx];
    if (data[el] && !moment(data[el]).isValid()) return false;
  }
  return result;
}
export function ToDateText(params) {
  let date =
    moment(params).isValid() && params !== undefined
      ? moment(params).format(dateformat)
      : "";
  return date;
}
export function ToMonthText(params) {
  let date =
    moment(params).isValid() && params !== undefined
      ? moment(params).format(MonthMuiformat)
      : "";
  return date;
}
export function ToDateTimeText(params) {
  let date =
    moment(params).isValid() && params !== undefined
      ? moment(params).format(dateTimeformat)
      : "";
  return date;
}

export function checkDateRange(start, end) {
  // Parse the entries
  var startDate = moment(start);
  var endDate = moment(end);
  //console.log(startDate,endDate)
  // Make sure they are valid
   if (isNaN(startDate)) {
     //alert("The start date provided is not valid, please enter a valid date.");
     return false;
  }
  if (isNaN(endDate)) {
      //alert("The end date provided is not valid, please enter a valid date.");
      return false;
  }
  // Check the date range, 86400000 is the number of milliseconds in one day
  var difference = (endDate - startDate) // (86400000 * 7);
  //console.log(difference)
  if (difference < 0) {
      //alert("The start date must come before the end date.");
      return false;
  }
  // if (difference <= 1) {
  //     //alert("The range must be at least seven days apart.");
  //     return false;
  //  }
  return true;
}
export function ToDateRangeText(from, to) {
  if (!isEmptyStr(from) && !isEmptyStr(to)) return `${from} to ${to}`;
  return `${from} ${to}`;
}
export function getToday() {
  return moment().startOf("day").format(dateformat)
  
}
export function ToDateTextNoDash(params) {
  let date =
    moment(params).isValid() && params !== undefined
      ? moment(params).format(dateformat2)
      : "";
  return date;
}
export function DateGetDay(params) {
  return moment(params).date();
}