import { useMemo, useState } from "react";
import { isEmptyArray, isEmptyStr, titleCase } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import { mapping_caption } from "utils/mapping_caption";

const { log } = console;

export function generate_mui_col(Column,FieldDictionary) {
  let { all = [], date = [], date_time = [], custom_col = [] } = Column;
  let col = all.map((el) => {
    let extra = custom_col.find((c) => c.field === el);
    custom_col = removeItemOnce(custom_col, extra);
    return {
      ...{
        field: el,
        headerName: extra?.headerName || mapping_caption(el,FieldDictionary),
        minWidth: 200,
        flex: 1,
      },
      ...formatdate(el),
      ...formatdatetime(el),
      ...{ ...extra },
    };
  });
  function formatdate(el) {
    return date.includes(el)
      ? {
          valueGetter: (params) => `${ToDateText(params.row[el])}`,
        }
      : {};
  }
  function formatdatetime(el) {
    return date_time.includes(el)
      ? {
          valueGetter: (params) => `${ToDateTimeText(params.row[el])}`,
        }
      : {};
  }
  return [...col, ...custom_col];
}
export function CreateMuiColumnObj(p1, p2, p3, p4) {
  return { all: p1, date: p2, date_time: p3, custom_col: p4 };
}
function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
