import { isEmptyArray, concat_array_nodup } from "utils/common-function";
import moment from "moment";
import { ApiDateTimeFormat } from "utils/date";
export const initial_state = {
  //Criteria
  CriteriaSearch: {
    dateTo: moment().format(ApiDateTimeFormat),
    dateFrom: moment().format(ApiDateTimeFormat), //moment().subtract(1, "month").format(ApiDateTimeFormat),
    JOB_STATUS_ID: [],
    JOB_TYPE_ID: [],
  },
  ExecutedJobList: [],
  FilterExecutedJobList: [],
};
export const action_type = {
  UpdateCriteria: "Jobmonitoring_UpdateCriteria",
  Assign_JobMonitoringList: "Jobmonitoring_Assign_JobMonitoringList",
  Update_JobMonitoringList: "Jobmonitoring_Update_JobMonitoringList",
  UPDATE_FilterJobMonitoringList:
    "Jobmonitoring_UPDATE_FilterJobMonitoringList",
};
//3.Reducer
export const JobMonitorReducer = (state = initial_state, action) => {
  switch (action.type) {
    case action_type.UpdateCriteria:
      return { ...state, CriteriaSearch: action.param };
    case action_type.Assign_JobMonitoringList:
      return { ...state, ExecutedJobList: action.data };
    case action_type.Update_JobMonitoringList:
      let new_data = concat_array_nodup(
        state.ExecutedJobList,
        action.data,
        "HISTORY_ID"
      );
      return { ...state, ExecutedJobList: new_data };
    case action_type.UPDATE_FilterJobMonitoringList:
      let p_JobType = action.param.JOB_TYPE_ID;
      let p_JobStatus = action.param.JOB_STATUS_ID;
      let filtered = state.ExecutedJobList?.filter(
        (el) =>
          p_JobType?.includes(el.JOB_TYPE_ID + "") || isEmptyArray(p_JobType)
      );
      filtered = filtered?.filter(
        (el) =>
          p_JobStatus?.includes(el.JOB_STATUS_ID + "") ||
          isEmptyArray(p_JobStatus)
      );
      return { ...state, FilterExecutedJobList: filtered };

    default:
      break;
  }
  return state;
};
