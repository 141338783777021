import React, { useState, useEffect } from "react";
import axios from "axios";
import { DropzoneDialog } from "material-ui-dropzone";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
//Materials
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
//Component
import { Modal } from "stateless/modal";
import { Button } from "stateless/button";
import { ReactTable } from "stateless/react-table";
//Utils
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal.js";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { MuiDateTimeToApi } from "utils/date";
import { titleCase, isEmptyArray, isEmptyStr } from "utils/common-function";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import { ToDateText, ToDateTimeText } from "utils/date";
const { log } = console;
//Const.
export const api_LoadDatasetIndexHistoryList = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/LoadDatasetIndexHistoryList`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
const historycolumns = [
  {
    dataField: "HISTORY_ID",
    text: titleCase("HISTORY_ID"),
    sort: true,
    hidden: true,
    // events: onClickCol,
  },
  {
    dataField: "data_object_id",
    text: titleCase("data_object_id"),
    sort: true,
    hidden: true,
    // events: onClickCol,
  },
  {
    dataField: "sheet_name",
    text: titleCase("sheet_name"),
    sort: true,
  },
  {
    dataField: "DATA_OBJECT_TABLE_NAME",
    text: titleCase("DATA_OBJECT_TABLE_NAME"),
    sort: true,
  },
  {
    dataField: "DESCRIPTION",
    text: titleCase("DESCRIPTION"),
    sort: true,
    // events: onClickCol,
  },
  {
    dataField: "BUSINESS_GLOSSARY",
    text: titleCase("BUSINESS_GLOSSARY"),
    sort: true,
  },
  {
    dataField: "DATA_SPECIALIST_NAME",
    text: titleCase("DATA_SPECIALIST_NAME"),
    sort: true,
  },
  {
    dataField: "DATA_OBJECT_TYPE",
    text: titleCase("DATA_OBJECT_TYPE"),
    sort: true,
  },
  {
    dataField: "DATA_SOURCE",
    text: titleCase("DATA_SOURCE"),
    sort: true,
  },
  {
    dataField: "DATA_STORAGE",
    text: titleCase("DATA_STORAGE"),
    sort: true,
  },
  {
    dataField: "DATA_UPDATE_FREQUENCY",
    text: titleCase("DATA_UPDATE_FREQUENCY"),
    sort: true,
  },
  {
    dataField: "DATA_PRIVACY_EXIST",
    text: titleCase("DATA_PRIVACY_EXIST"),
    sort: true,
  },
  {
    dataField: "KEYWORD_FOR_SEARCH",
    text: titleCase("KEYWORD_FOR_SEARCH"),
    sort: true,
  },
  {
    dataField: "REGISTRATION_DATE",
    text: titleCase("REGISTRATION_DATE"),
    sort: true,
    formatter: (cell, row, rowIndex) => {
      return <>{ToDateText(row.REGISTRATION_DATE)}</>;
    },
  },
  {
    dataField: "LAST_UPDATE_DATE",
    text: titleCase("LAST_UPDATE_DATE"),
    sort: true,
    formatter: (cell, row, rowIndex) => {
      return <>{ToDateText(row.LAST_UPDATE_DATE)}</>;
    },
  },
  {
    dataField: "AUTHOR_NAME",
    text: titleCase("AUTHOR_NAME"),
    sort: true,
  },
  {
    dataField: "IMPORTED_ID",
    text: titleCase("IMPORTED_ID"),
    sort: true,
  },
  {
    dataField: "PUBLISHED_DATETIME",
    text: titleCase("PUBLISHED_DATETIME"),
    sort: true,
    formatter: (cell, row, rowIndex) => {
      return <>{ToDateTimeText(row.PUBLISHED_DATETIME)}</>;
    },
  },
];
export function PopupHistory(props) {
  const { datasetid, open } = props;
  const [Data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      if (!open) return;
      let data = await api_LoadDatasetIndexHistoryList({
        DatasetId: datasetid,
      });
      setData(data);
    })();
    return () => {
      setData();
    };
  }, [datasetid, open]);

  return (
    <>
      <Modal
        SaveText="Import"
        hiddenSave
        maxWidth="md"
        title="History"
        {...props}
      >
        <ReactTable
          data={Data || []}
          columns={historycolumns}
          keyField={"HISTORY_ID"}
        />
      </Modal>
    </>
  );
}
export default PopupHistory