import axios from "axios";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
//Master
export const GetListJobTypeMaster = async (param) => {
  let url = `${API_URL.Job}/api/master/GetListJobTypeMaster`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const DeleteJobType = async (param) => {
  let url = `${API_URL.Job}/api/master/DeleteJobType`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const SaveJobType = async (param) => {
    let url = `${API_URL.Job}/api/master/SaveJobType`;
    let { result } = await common.to(axios.post(url, param));
    if (result && result.data) return result.data;
  };

  

  