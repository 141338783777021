import { inform_success, ConfirmCommon, Inform } from "utils/alert";
export function SubmitInformation(selected_row){
    var set_count = selected_row.reduce((status_count, current_row) => {
        
        if (current_row === null) current_row = "No Status";
        if (typeof status_count[current_row] !== "undefined") {
          status_count[current_row]++;
          return status_count;
        } else {
          status_count[current_row] = 1;
          return status_count;
        }
      }, {});
    let status_arr = [];
    for (var x in set_count) {
        if (set_count.hasOwnProperty(x)) {
            status_arr.push(x + ": " + set_count[x] + " rows");
        }
    }
    Inform("Submit Success.",<div>{status_arr.map((el,idx)=><div key={idx}>{el}</div>)}</div>);

}
export function SortDetailTemplate2(data) {
  const order =['Header Format','Detail Format','Trailer Format']
  var arr = data.sort(function(a,b) {
    return order.indexOf( a.key ) - order.indexOf( b.key );
    //for the sake of recent versions of Google Chrome use:
    //return a.key.charCodeAt(0) > b.key.charCodeAt(0); or return a.key.charCodeAt(0) - b.key.charCodeAt(0);
});
return arr
}