import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@material-ui/core/Box";
import FormCheckbox from "stateless/hook-controls/checkbox";
import { CheckboxThree } from "stateless/hook-controls/checkbox";
//Api
import { common } from "utils";
import { API_URL } from "utils/api-utils";
//util
import { isEmptyStr } from "utils/common-function";
const Load = async (data) => {
  let url = `${API_URL.Inbound}/api/Master/GetDynamicFilterDataList`;
  let { result } = await common.to(axios.post(url, data));
  if (result && result.data) return result.data;
};
export function CheckboxDynamicList(props) {
  const { filter, param, ...rest } = props;
  const [ApiData, setApiData] = useState([]);
  useEffect(() => {
    (async () => {
      let data = await Load(param);
      setApiData(data);
    })();
  }, [param]);
  function filtering() {
    let data = ApiData?.filter(
      (el) =>
        el?.toLowerCase().indexOf(filter?.toLowerCase()) !== -1 ||
        isEmptyStr(filter)
    );
    var result = data?.reduce(function (map, obj) {
      map[obj] = false;
      return map;
    }, {});
    return result;
  }
  return (
    <>
      <Box display="flex" className="checkboxBox">
        {" "}
        <Box
          className="checkboxList"
          display="flex"
          justifyContent={"space-between"}
          pl={3}
          flexWrap="wrap"
          flexGrow={1}
        >
          {<CheckboxThree options={filtering()} {...rest} />}
        </Box>
      </Box>
    </>
  );
}
