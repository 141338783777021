import React from "react";
import { AppbarLogo } from "../stateless/appbar";
import { GetUser } from "./../utils/sessions";
export default function Layout({ children }) {
  return (
    <>
      <AppbarLogo user={GetUser()} >
        <>
          <section name="body" className="body">{children}</section>
          <section name="footer"></section>
        </>
      </AppbarLogo>
    </>
  );
}
