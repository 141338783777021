import React from "react";
import "./SearchResultCard.css";
import Highlighter from "react-highlight-words";
//material
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "assets/IconList.png";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//component
import { ToDateText, ToDateTimeText } from "utils/date";

import { ButtonView } from "stateless/button";
//utils
import {
  isEmptyObj,
  isEmptyStr,
  titleCase,
  addHighlightHtml,
} from "utils/common-function";
import { TemplateID } from "apps/data-catalog/utils/constants";

const useStyles = makeStyles({
  root: {
    height: "230px",
    marginBottom: "20px",
    borderRadius: "10px",
    paddingBottom: "10px",
  },
  images: {
    width: "140px",
  },
});

const CustomRow = ({
  title,
  children = <></>,
  islink = false,
  highlight,
  onClick = () => {},
}) => (
  <TableRow>
    <TableCell scope="row" className="tdHeaderRow">
      <Typography gutterBottom component="span" style={{ color: "#3D3C3E" }}>
        {title}
      </Typography>
    </TableCell>
    <TableCell className="resultsText">
      {/* <Link href="#" onClick={() => clickURL(data.data_object?.sheet_name)} > */}
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        title={children}
      >
        {islink ? (
          <Link href="#" onClick={() => onClick(children)}>
            <Typography
              color="textSecondary"
              component="span"
              style={{ color: "#4E24BD" }}
            >
              <Highlighter
                searchWords={[highlight]}
                textToHighlight={children || ""}
              />
            </Typography>
          </Link>
        ) : (
          <Highlighter
            searchWords={[highlight]}
            textToHighlight={children || ""}
          />
        )}
      </Typography>
      {/* </Link> */}
    </TableCell>
  </TableRow>
);

export default function ImgMediaCard(props) {
  const classes = useStyles();
  const { data, clickView, clickURL, highlight } = props;
  return (
    <Card className={classes.root}>
      <Box display="flex">
        <CardMedia
          className={classes.images}
          component="img"
          alt="Contemplative Reptile"
          height="230"
          image={Icon}
          title="Contemplative Reptile"
        />
        {/* <Box display="flex">
        </Box> */}

        <CardContent style={{ flexGrow: "1", padding: "0 16px" }}>
          <Box display="flex" alignItems="flex-start">
            {data?.data_object?.template_id === TemplateID.template1 && (
              <Template1Content
                data={data}
                highlight={highlight}
                clickURL={clickURL}
              />
            )}
            {data?.data_object?.template_id === TemplateID.template2 && (
              <Template2Content
                data={data}
                highlight={highlight}
                clickURL={clickURL}
              />
            )}
          </Box>
        </CardContent>

        {/* <CardContent style={{ flexGrow: "4" }}>
          
        </CardContent> */}
        <CardActions
          style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
        >
          <ButtonView onClick={() => clickView(data)} />
        </CardActions>
      </Box>
    </Card>
  );
}
function Template1Content({ data, highlight, clickURL }) {
  return (
    <>
      <Table className="tableDetail">
        <colgroup>
          <col style={{ width: "180px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <CustomRowHead
            highlight={highlight}
            title={`Data Object ${
              !isEmptyStr(data.data_object["data_object_type"])
                ? `(${data.data_object["data_object_type"]})`
                : ""
            } :`}
            islink
            onClick={clickURL}
          >
            {data.data_object["data_object_table_name"]}
          </CustomRowHead>
          <CustomRow
            highlight={highlight}
            title={"Data Domain"}
            islink
            onClick={clickURL}
          >
            {data.data_object?.domain}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Data Sub Domain Name"}>
            {data.data_object?.sub_domain}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Object Description "}>
            {data.data_object["description"]}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Business Glossary"}>
            {data.data_object["business_glossary"]}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Publish Date"}>
            {ToDateText(data.data_version["published_datetime"])}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Version"}>
            {data.data_version["version"]}
          </CustomRow>
        </TableBody>
      </Table>
      <Divider
        style={{
          height: "auto",
          margin: "15px 10px",
          borderLeft: "1px solid #000",
          alignSelf: "stretch",
        }}
        orientation="vertical"
      />

      <Table className="tableDetail" display="none">
        <colgroup>
          <col style={{ width: "180px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <CustomRowHead
            title={"Column Detail"}
            islink
            highlight={highlight}
            onClick={clickURL}
          >
            {/* {data.data_property?.pdm_column_name} */}
          </CustomRowHead>
          <CustomRow highlight={highlight} title={"Column Name"}>
            {data.data_property?.pdm_column_name}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Data Type"}>
            {data.data_property?.pdm_datatype}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Schema"}>
            {data.data_property?.pdm_schema}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Field Definition"}>
            {data.data_property?.pdm_field_definition}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Sample Value"}>
            {data.data_property?.pdm_sample}
          </CustomRow>
        </TableBody>
      </Table>
      <Divider
        style={{
          height: "auto",
          margin: "15px 10px",
          borderLeft: "1px solid #000",
          alignSelf: "stretch",
        }}
        orientation="vertical"
      />

      <Table className="tableDetail" display="none">
        <colgroup>
          <col style={{ width: "180px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <CustomRowHead
            title={"Source Data Object :"}
            islink
            highlight={highlight}
            onClick={clickURL}
          >
            {data.data_property["src_table_name_file_name"]}
          </CustomRowHead>
          <CustomRow highlight={highlight} title={"Source Column Name"}>
            {data.data_property?.src_column}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Source Data Type"}>
            {data.data_property?.src_datatype}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Source Schema"}>
            {data.data_property?.src_schema}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Source Remark"}>
            {data.data_property?.src_remark}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Ailas"}>
            {data.data_property?.src_alias}
          </CustomRow>
        </TableBody>
      </Table>
    </>
  );
}
function Template2Content({ data, highlight, clickURL }) {
  return (
    <>
      <Table className="tableDetail">
        <colgroup>
          <col style={{ width: "180px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <CustomRowHead
            highlight={highlight}
            title={`Data Object ${
              !isEmptyStr(data.data_object["data_object_type"])
                ? `(${data.data_object["data_object_type"]})`
                : ""
            } :`}
            islink
            onClick={clickURL}
          >
            {data.data_object["data_object_table_name"]}
          </CustomRowHead>
          <CustomRow
            highlight={highlight}
            title={"Interface Description"}
            islink
            onClick={clickURL}
          >
            {data.data_object?.interface_description}
          </CustomRow>
          <CustomRow highlight={highlight} title={"File Name/Table Name"}>
            {data.data_object["filename_tablename"]}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Source System"}>
            {data.data_object["source_system"]}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Source Path"}>
            {data.data_object["source_path"]}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Publish Date"}>
            {ToDateText(data.data_version["published_datetime"])}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Version"}>
            {data.data_version["version"]}
          </CustomRow>
        </TableBody>
      </Table>
      <Divider
        style={{
          height: "auto",
          margin: "15px 10px",
          borderLeft: "1px solid #000",
          alignSelf: "stretch",
        }}
        orientation="vertical"
      />

      <Table className="tableDetail" display="none">
        <colgroup>
          <col style={{ width: "180px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <CustomRowHead
            title={data.data_property?.format_type}
            islink
            highlight={highlight}
            onClick={clickURL}
          >
            {/* {data.data_property?.pdm_column_name} */}
          </CustomRowHead>
          <CustomRow highlight={highlight} title={"Field Name (Source)"}>
            {data.data_property?.fm_fieldname_source}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Data Type"}>
            {data.data_property?.fm_data_type}
          </CustomRow>
          <CustomRow highlight={highlight} title={"PK"}>
            {data.data_property?.fm_pk}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Nullable"}>
            {data.data_property?.fm_nullable}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Description"}>
            {data.data_property?.fm_description}
          </CustomRow>
        </TableBody>
      </Table>
      <Divider
        style={{
          height: "auto",
          margin: "15px 10px",
          borderLeft: "1px solid #000",
          alignSelf: "stretch",
        }}
        orientation="vertical"
      />
      <Table className="tableDetail" display="none">
        <colgroup>
          <col style={{ width: "180px" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <TableBody>
          <CustomRowHead
            title={"Description"}
            islink
            highlight={highlight}
            onClick={clickURL}
          >
            {data.data_property["src_table_name_file_name"]}
          </CustomRowHead>
          <CustomRow highlight={highlight} title={"Format"}>
            {data.data_property?.fm_format}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Remarks"}>
            {data.data_property?.fm_remarks}
          </CustomRow>
          <CustomRow highlight={highlight} title={"Sample"}>
            {data.data_property?.fm_sample}
          </CustomRow>
        </TableBody>
      </Table>
    </>
  );
}
const CustomRowHead = ({
  title,
  children,
  islink = false,
  highlight,
  onClick = () => {},
}) => (
  <TableRow>
    <TableCell scope="row" className="tdHeaderRow" colSpan={3}>
      <Box mb={2}>
        <Typography
          gutterBottom
          component="span"
          style={{ fontSize: "1.3rem", color: "#3D3C3E" }}
        >
          {title}{" "}
          {islink ? (
            <Link href="#" onClick={() => onClick(children)}>
              <Typography
                color="textSecondary"
                component="span"
                style={{ color: "#4E24BD" }}
              >
                <Highlighter
                  searchWords={[highlight]}
                  textToHighlight={children || ""}
                />
              </Typography>
            </Link>
          ) : (
            <Highlighter
              searchWords={[highlight]}
              textToHighlight={children || ""}
            />
          )}
        </Typography>
      </Box>
    </TableCell>
    <TableCell className="resultsText">
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{ fontSize: "1.4rem" }}
      ></Typography>
    </TableCell>
  </TableRow>
);
