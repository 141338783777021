import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
//Material
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@material-ui/icons/Save";
//Component
import FormInput from "stateless/hook-controls/input";
import { KKPModal } from "stateless/modal/modal2";
//utils
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { ConfirmCommon, Inform, inform_success } from "utils/alert";
import {
  titleCase,
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyObj,
} from "utils/common-function";
import { CheckboxInboundGroupUserList } from "stateless/selection-control/checkbox/inbound/group_user";

export const api_GetReconcileReportData = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/GetReconcileReportData`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_SaveReconcileReport = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/SaveReconcileReport`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

function useReconcileReportData() {
  const formMethods = useForm({ defaultValues: {} });
  const { getValues, setError, clearErrors } = formMethods;

  const [DataRow, setDataRow] = useState([]);
  const state = { DataRow };

  const GetReconcileReportData = useCallback(async (params) => {
    let [res, err] = await api_GetReconcileReportData(params);
    if (err) return;
    setDataRow(res);
  }, []);

  async function SaveReconcileReport(fetchData, close) {
    clearErrors("area_name");
    clearErrors("report_group");
    clearErrors("schema_name");
    clearErrors("report_name");
    clearErrors("display_name");

    let param = getValues();

    if (isEmptyStr(param.area_name)) {
      setError("area_name", {
        type: "manual",
        message: "The Area field is required",
      });
      return;
    }

    if (isEmptyStr(param.report_group)) {
      setError("report_group", {
        type: "manual",
        message: "The Report Group field is required",
      });
      return;
    }

    if (isEmptyStr(param.schema_name)) {
      setError("schema_name", {
        type: "manual",
        message: "The Schema name field is required",
      });
      return;
    }

    if (isEmptyStr(param.report_name)) {
      setError("report_name", {
        type: "manual",
        message: "The Report Name field is required",
      });
      return;
    }

    if (isEmptyStr(param.display_name)) {
      setError("display_name", {
        type: "display_name",
        message: "The Display Name field is required",
      });
      return;
    }

    if (!(await ConfirmCommon("Confirm Save?"))) return;
    let [res, err] = await api_SaveReconcileReport(param);
    if (err) return;
    await inform_success("Save Complete.");
    fetchData();
    close();
  }

  const actions = {
    GetReconcileReportData,
    SaveReconcileReport,
  };
  return { state, actions, formMethods };
}
export function ReconcileReportDialog({ controlDialog, methods }) {
  const [mode, setMode] = useState();
  const { isOpen, Data, close } = controlDialog;
  const { state, actions, formMethods } = useReconcileReportData();
  const { setValue, reset, errors, control } = formMethods;
  const { GetReconcileReportData, SaveReconcileReport } = actions;
  const { GetReconcileReportList } = methods.actions;

  useEffect(() => {
    if (!isOpen) return;
    reset();
    if (!isEmptyObj(Data)) {
      GetReconcileReportData(Data);
      setMode("Edit");
    } else {
      setMode("Add");
    }
  }, [isOpen, GetReconcileReportData, Data, setValue, reset]);

  useEffect(() => {
    if (isEmptyObj(state.DataRow)) return;
    let hook = setDataToFormHookV2(state.DataRow);
    hook?.forEach(({ name, value }) => setValue(name, value));
  }, [setValue, state.DataRow]);

  return (
    <>
      <FormProvider {...formMethods}>
        <KKPModal
          control={controlDialog}
          title={`${mode} Reconcile Report`}
          maxWidth="md"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <FormInput
                  name="area_name"
                  label={titleCase("area")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormInput
                  name="report_group"
                  label={titleCase("report_group")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormInput
                  name="schema_name"
                  label={titleCase("schema_name")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormInput
                  name="report_name"
                  label={titleCase("report_name")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormInput
                  name="display_name"
                  label={titleCase("display_name")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12} >
              <CheckboxInboundGroupUserList
                showTitle
                name={"group_user"}
                control={control}
                setValue={setValue}
              />
            </Grid>
          </Grid>
          <Box
            mt={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() =>
                  SaveReconcileReport(GetReconcileReportList, close)
                }
              >
                Save
              </Button>
            </Box>
            <Box m={1}>
              <Button variant="outlined" onClick={close}>
                Close
              </Button>
            </Box>
          </Box>
        </KKPModal>
      </FormProvider>
    </>
  );
}
