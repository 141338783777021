import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
export const getMaintenanceColumns = (editUser, deleteUser) => {
  const columns = [
    {
      dataField: "OP2",
      text: "",
      //sort: true,

      // events: onClickCol,
      formatter: (cell, row) => {
        return (
          <Box component="div" display="flex" justifyContent="center">
            <EditIcon
              color="action"
              onClick={() => {
                editUser(row);
              }}
            />
            <DeleteIcon
              color="action"
              onClick={() => {
                deleteUser(row.ID);
              }}
            />
          </Box>
        );
      },
    },
    {
      dataField: "USER_ID",
      text: "User ID",
      sort: true,
    },
    {
      dataField: "ROLE_TEXT",
      text: "Roles",
      sort: true,
    },
    {
      dataField: "GROUP_BU",
      text: "GROUP BU",
      sort: true,
    },
    {
      dataField: "ACTIVE",
      text: "Active",
      sort: true,
    },
    {
      dataField: "LOCAL_USER",
      text: "Local User",
      sort: true,
    },
  ];
  return columns;
};
