import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { DropzoneDialog } from "material-ui-dropzone";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
//Component
import { Modal } from "stateless/modal";
import { CMDataGrid } from "stateless/data-grid";

//Utils
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal.js";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { MuiDateTimeToApi } from "utils/date";
import { titleCase, isEmptyArray, isEmptyStr } from "utils/common-function";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import { ToDateText, ToDateTimeText } from "utils/date";
const { log } = console;
//Const
const db_col = [
  "fm_sn",
  "format_type",
  "fm_format",
  "fm_fieldname_source",
  "fm_nullable",
  "fm_remarks",
  "fm_description",
  "fm_data_type",
  "fm_sample",
  "fm_pk",
];
const LoadSbxDataPropertyList = async (param) => {
  let url = `${API_URL.Report}/api/Report/LoadSbxDataPropertyByObjList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
function Component(props) {
  const { data, open } = props;
  const [Data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      if (!open) return;
      let [res, err] = await LoadSbxDataPropertyList(data);
      if (err) return;
      setData(res);
    })();
  }, [data, open]);
  return (
    <>
      <Modal
        maxWidth="lg"
        title={`Data Property of ${data?.data_object_table_name}`}
        hiddenSave
        {...props}
      >
        {/* <Button onClick={toggle}>Download</Button> */}
        <CMDataGrid
          rows={Data || []}
          getRowId={(row) => row.sbx_id}
          customColumn={{
            all: db_col,
            date: ["update_inf_updated_date",'reserve_date'],
            custom_col: ["imported_timestamp"],
          }}
        />
      </Modal>
    </>
  );
}

export default Component;
