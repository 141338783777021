import { titleCase } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import { styleTextLink } from "utils/style";

export const getColumPlanHistory = (viewDetail = () => { }) => {
    const onClickCol = {
        onClick: async (e, column, columnIndex, row, rowIndex) => {
            viewDetail(row);
        },
    };
    const columns = [
        {
            dataField: "STATUS_DATETIME",
            text: titleCase("STATUS_DATETIME"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateTimeText(row.STATUS_DATETIME)}</>;
            },
        },
        {
            dataField: "STATUS_TEXT",
            text: titleCase("STATUS"),
            sort: true,
        },
        {
            dataField: "RESULT_DATE",
            text: titleCase("RESULT_DATE"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateText(row.RESULT_DATE)}</>;
            },
            style: styleTextLink,
        },
        {
            dataField: "EXECUTION_TYPE",
            text: titleCase("EXECUTION_TYPE"),
            sort: true,
        },
        {
            dataField: "LAST_EXECUTED",
            text: titleCase("LAST_EXECUTED"),
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>{ToDateTimeText(row.LAST_EXECUTED)}</>;
            },
        },
        {
            dataField: "MESSAGE",
            text: titleCase("MESSAGE"),
            sort: true,
        },
    ];
    var foundIndex = columns.findIndex((x) => x.dataField === "RESULT_DATE");
    columns[foundIndex] = { ...columns[foundIndex], events: onClickCol };
    return columns;
};
