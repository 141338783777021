import { titleCase } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import { styleTextLink } from "utils/style";
export const getColumnDependecyIgnore = (viewDetail = () => {}) => {
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      viewDetail(row);
    },
  };
  const columns = [
    {
      dataField: "PLAN_ID",
      text: titleCase("PLAN_ID"),
      sort: true,
    },
    {
      dataField: "PLAN_NAME",
      text: titleCase("PLAN_NAME"),
      sort: true,
      style: styleTextLink,
    },
    {
      dataField: "VALIDATE_AS_DATE_TEXT",
      text: titleCase("VALIDATE_AS_DATES"),
      sort: true,
    },
    {
      dataField: "STATUS_TEXT",
      text: titleCase("STATUS"),
      sort: true,
    },
    {
      dataField: "LAST_EXECUTED",
      text: titleCase("LAST_EXECUTED"),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return <>{ToDateTimeText(row.LAST_EXECUTED)}</>;
      },
    },
    {
      dataField: "DATADATE_TYPE_TEXT",
      text: titleCase("DATADATE_TYPE"),
      sort: true,
    },
    {
      dataField: "DEPENDS_ON_TEXT",
      text: titleCase("DEPENDS ON"),
      sort: true,
    },
  ];
  var foundIndex = columns.findIndex((x) => x.dataField === "PLAN_NAME");
  columns[foundIndex] = { ...columns[foundIndex], events: onClickCol };
  return columns;
};
