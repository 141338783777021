import React, { useState, useEffect, useCallback } from "react";
//Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//Component
import { ReactTable } from "../../../../stateless/react-table";
import { Modal } from "../../../../stateless/modal";
import { MenuPopupState } from "../../../../stateless/popup-button";
import ModalHold from "../daily-plan-status/modal-hold";
import ModalSetInvalid from "../daily-plan-status/modal-setinvalid";
//Function
import * as api from "../api";
import { buildParameter } from "../parameter";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import { titleCase } from "utils/common-function";
import { getColumPlanHistory } from "../columns/popup-plan-history";
import ModalPlanDetailFocus from "./modal-plan-detail-end";
import { styleBookmarkRow } from "utils/style";
import * as alertDialog from "utils/alert";
import { useSelectItem } from "../../../../stateless/react-table/useSelectItem";
import { isEmptyArray } from "utils/common-function";
let { log: c } = console;
function ModalPlanHistory(props) {
  const [resultData, setData] = useState([]);
  const { open, data } = props;
  const {
    isOpen: isOpenHold,
    close: closeHold,
    Data: dataHold,
    handleClickRow: clickHold,
  } = useSelectRowOpenModal();
  const {
    isOpen: isOpenSetInvalid,
    close: closeSetInvalid,
    Data: dataInvalidate,
    handleClickRow: clickInvalidate,
  } = useSelectRowOpenModal();
  const LoadData = useCallback(async () => {
    let res = await api.GetPlanHistory({
      PLAN_ID: data?.PLAN_ID,
      ResultDates: data?.ResultDates,
    });
    if (!res) return;
    setData(res);
  }, [data]);
  useEffect(() => {
    if (!open) return;
    LoadData();
  }, [open, LoadData]);
  useEffect(() => {
    if (!isOpenSetInvalid||!isOpenHold) return;
    LoadData();
  }, [isOpenSetInvalid, isOpenHold,LoadData]);
  // For Plan Detail
  const {
    isOpen: isOpenPlanDetail,
    close: closePlanDetail,
    Data: planDetail,
    handleClickRow: clickPlanDetail,
  } = useSelectRowOpenModal();
  const { handleOnSelect, handleOnSelectAll, SelectedItems } = useSelectItem(
    "INSTANCE_ID"
  );
  const viewPlanDetail = async (row) => {
    clickPlanDetail(row);
  };
  const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
  }));
  const classes = useStyles();
  const rowStyle = (row, rowIndex) => {
    if (row.INSTANCE_ID === data.INSTANCE_ID) return styleBookmarkRow;
  };
  const captionText = () => {
    return (
      `Plan Execution History` +
      (data?.VALIDATE_AS_DATE_TEXT === undefined
        ? " (All Result Dates)"
        : " of Result Dates: " + data?.VALIDATE_AS_DATE_TEXT)
    );
  };
  
  function onHold() {
    if (isEmptyArray(SelectedItems)) return;
    let all = resultData?.History?.filter((el) => SelectedItems.includes(el.INSTANCE_ID));
    clickHold(all);
  }
  function onInvalidate() {
    if (isEmptyArray(SelectedItems)) return;
    clickInvalidate(SelectedItems);
  }
  async function onForceSuccess() {
    if (isEmptyArray(SelectedItems)) return;
    let confirm = await alertDialog.ConfirmCommon(
      "Confirm force Success?",
      "yes",
      "no"
    );
    if (!confirm) return;
    let param = buildParameter.SuccessPlanParam(SelectedItems);
    let res = await api.SuccessPlans(param);
    let txt = `Set status success ${res.length || 0} plans`;
    await alertDialog.Inform(txt);
    LoadData()
  }
  async function onCancelRunning() {
    if (isEmptyArray(SelectedItems)) return;
    let confirm = await alertDialog.ConfirmCommon(
      "Confirm force cancel?",
      "yes",
      "no"
    );
    if (!confirm) return;
    let param = buildParameter.CancelRunningPlanParam(SelectedItems);
    let res = await api.CancelRunningPlans(param);
    let txt = `Set status cancel ${res.length || 0} plans`;
    await alertDialog.Inform(txt);
    LoadData()
  }
  const lst_btn = [
    { name: "Hold", method: onHold },
    { name: "Invalidate", method: onInvalidate },
    { name: "Force Success", method: onForceSuccess },
    { name: "Cancel Running", method: onCancelRunning },
  ];
  const tablecomponent = () => {
    return (
      <>
        <Box m={2} className="btnstyle">
          <MenuPopupState items={lst_btn}>Set Status</MenuPopupState>
        </Box>
      </>
    );
  };
  return (
    <>
      <Modal
        {...props}
        title={captionText()}
        hiddenSave
        maxWidth="md"
        className="headerModalPlanHistoryDetail"
      >
        <Box p={1} className="boxTableDetail">
          <Table className="tableDetail">
            <colgroup>
              <col style={{ width: "150px" }} />
              <col style={{ width: "auto" }} />
            </colgroup>
            <TableBody>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("Plan")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {data?.PLAN_TYPE_TEXT} - {data?.PLAN_ID} -{" "}
                    {data?.PLAN_NAME}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box display="block" bgcolor="background.paper">
          <ReactTable
            rowStyle={rowStyle}
            keyField="INSTANCE_ID"
            data={resultData.History || []}
            headcomponent={tablecomponent}
            columns={getColumPlanHistory(viewPlanDetail)}
            clickToSelect
            selectOptions={{ selected: SelectedItems }}
            handleOnSelect={handleOnSelect}
            handleOnSelectAll={handleOnSelectAll}
          />
        </Box>
      </Modal>
      <ModalPlanDetailFocus
        open={isOpenPlanDetail}
        onClose={closePlanDetail}
        data={planDetail}
      />
      <ModalSetInvalid
        open={isOpenSetInvalid}
        onClose={closeSetInvalid}
        data={dataInvalidate}
      />
      <ModalHold open={isOpenHold} onClose={closeHold} data={dataHold} />
    </>
  );
}
export default ModalPlanHistory;
