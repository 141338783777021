import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
//Material
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@material-ui/icons/Save";
//Component
import FormInput from "stateless/hook-controls/input";
import { KKPModal } from "stateless/modal/modal2";
//utils
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import {
  ConfirmCommon,
  Inform,
  inform_error,
  inform_success,
} from "utils/alert";
import {
  titleCase,
  setDataToFormHookV2,
  isEmptyStr,
  isEmptyObj,
} from "utils/common-function";
import { SelectDynamicType } from "stateless/selection-control/combo/inbound/select-dynamic-type";
import ReportFilterAutoComplete from "../stateless/Autocomplete/report_filter";

export const api_GetFilterTable = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/GetFilterTable`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_SaveDynamicFilter = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/SaveDynamicFilter`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

function useDynamicFilterDialog() {
  const formMethods = useForm({ defaultValues: {} });
  const { getValues, setError, clearErrors, control } = formMethods;

  const [DataDetail, setDataDetail] = useState([]);
  const w_table_name = useWatch({ control, name: "table_name" });
  const w_schema_name = useWatch({ control, name: "schema_name" });
  const state = { DataDetail, w_table_name, w_schema_name };

  const GetFilterTable = useCallback(async (params) => {
    let [res, err] = await api_GetFilterTable(params);
    if (err) return;
    setDataDetail(res);
  }, []);

  async function Save(fetchData, close, Data) {
    clearErrors("table_name");
    clearErrors("column_filter");
    clearErrors("table_type");
    clearErrors("schema_name");

    let param = { ...Data, ...getValues() };
    
    let isErr = false;
    if (isEmptyStr(param.table_name)) {
      setError("table_name", {
        type: "manual",
        message: "The Table name field is required",
      });
      isErr = true;
    }

    if (isEmptyStr(param.column_filter)) {
      setError("column_filter", {
        type: "manual",
        message: "The Column field is required",
      });
      isErr = true;
    }

    if (isEmptyStr(param.schema_name)) {
      setError("schema_name", {
        type: "manual",
        message: "The Schema name field is required",
      });
      isErr = true;
    }

    if (isEmptyStr(param.table_type)) {
      setError("table_type", {
        type: "manual",
        message: "The Type Name field is required",
      });
      isErr = true;
    }
    if (isErr) return;
    if (!(await ConfirmCommon("Confirm Save?"))) return;
    let [res, err] = await api_SaveDynamicFilter(param);
    if (err) return;
    if (!isEmptyStr(res)) {
      inform_error(res);
      return;
    }
    await inform_success("Save Complete.");
    fetchData();
    close();
  }
  const actions = {
    GetFilterTable,
    Save,
  };
  return { state, actions, formMethods };
}
export function DialogUpdateDynamicFilter({ controlDialog, refreshList }) {
  const [mode, setMode] = useState();
  const { isOpen, Data, close } = controlDialog;
  const { state, actions, formMethods } = useDynamicFilterDialog();
  const { setValue, reset, errors } = formMethods;
  const { GetFilterTable, Save } = actions;
  const { w_table_name, w_schema_name } = state;
  useEffect(() => {
    if (!isOpen) return;
    reset();
    if (!isEmptyObj(Data)) {
      GetFilterTable(Data);
      setMode("Edit");
    } else {
      setMode("Add");
    }
  }, [isOpen, GetFilterTable, Data, setValue, reset]);

  useEffect(() => {
    if (isEmptyObj(state.DataDetail)) return;
    let hook = setDataToFormHookV2(state.DataDetail);
    hook?.forEach(({ name, value }) => setValue(name, value));
  }, [setValue, state.DataDetail]);

  return (
    <>
      <FormProvider {...formMethods}>
        <KKPModal
          control={controlDialog}
          title={`${mode} Dynamic filter`}
          maxWidth="md"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <SelectDynamicType
                  name="table_type"
                  label={titleCase("table_type")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormInput
                  name="schema_name"
                  label={titleCase("schema_name")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormInput
                  name="table_name"
                  label={titleCase("table_name")}
                  required={true}
                  errorobj={errors}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <ReportFilterAutoComplete
                  name="column_filter"
                  
                  label={titleCase("column_filter")}
                  required={true}
                  errorobj={errors}
                  table_name={w_table_name}
                  schema_name={w_schema_name}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            mt={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => Save(refreshList, close, Data)}
              >
                Save
              </Button>
            </Box>
            <Box m={1}>
              <Button variant="outlined" onClick={close}>
                Close
              </Button>
            </Box>
          </Box>
        </KKPModal>
      </FormProvider>
    </>
  );
}
