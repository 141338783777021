import axios from "axios";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { getToday } from "utils/date";
import { inform_success, inform_error, ConfirmCommon } from "utils/alert";
import { isEmptyStr } from "utils/common-function";

export const api_GetReconcileReportList = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/GetReconcileReportList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_DeleteReconcileReport = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/DeleteReconcileReport`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export function useReconcileReport({ filter }) {
  const [ReconcileReportList, setReconcileReport] = useState([]);
  const formMethods = useForm({ defaultValues: { datadate: getToday() } });

  //state here
  const controlDialogDetail = useSelectRowOpenModal();
  const state = { ReconcileReportList, controlDialogDetail };
  const GetReconcileReportList = useCallback(async () => {
    //call api
    let param = {
      group_reports: filter.GroupReport,
    };
    let [res, err] = await api_GetReconcileReportList(param);
    if (err) return;
    setReconcileReport(res);
  }, [filter]);
  useEffect(() => {
    GetReconcileReportList();
  }, [GetReconcileReportList]);
  //function here

  const actions = {
    addRow: async () => {
      controlDialogDetail.handleClickRow();
    },
    editRow: async (row) => {
      controlDialogDetail.handleClickRow(row);
    },
    deleteRow: async (row) => {
      if (!(await ConfirmCommon("Confirm Delete"))) return;
      let [res, err] = await api_DeleteReconcileReport({
        id: row.id,
      });
      if (err) return;
      if (res > 0) {
        inform_success("Deleted.");
        GetReconcileReportList();
      } else {
        inform_error("Failed to delete");
      }
    },
    GetReconcileReportList,
  };
  return {
    state,
    actions,
    formMethods,
  };
}
