import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@material-ui/core/Box";
import { Grid } from "@mui/material";
// const MuiCheckbox = (props) => {
//   const { label, name } = props;
//   return (
//     <FormControlLabel
//       control={<Checkbox name={name} {...props} />}
//       label={label}
//     />
//   );
// };

function FormCheckBox(props) {
  const { register } = useFormContext();
  const { label, name } = props;
  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <input
            type="checkbox"
            {...register(name)}
            color="primary"
            {...props}
            style={{ marginRight: "5px" }}
          />
        }
        label={label}
      />
    </React.Fragment>
  );
}

export const CheckboxThree = ({
  control,
  error,
  helperText,
  label,
  name,
  options = [],
}) => {
  return (
    <FormControl
      required
      error={error}
      component="fieldset"
      variant="standard"
      fullWidth
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref, ...field } }) => (
          <FormGroup>
            <Grid container spacing={5}>
              {Object.keys(options).map((key) => {
                return (
                  <Grid item xs={12} md={4} key={key}>
                    <FormControlLabel
                      label={key}
                      control={
                        <Checkbox
                          {...field}
                          name={key}
                          checked={
                            value && value.some((option) => option === key)
                          }
                          onChange={(event, checked) => {
                            if (checked) {
                              onChange([...value, event.target.name]);
                            } else {
                              onChange(
                                value.filter(
                                  (value) => value !== event.target.name
                                )
                              );
                            }
                          }}
                          inputRef={ref}
                        />
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </FormGroup>
        )}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default FormCheckBox;
