import { createContext, useState, useReducer } from "react";
import * as api from "./api";
import { JobMonitorReducer,action_type,initial_state } from "./reducer";

const getActions =(dispatch)=>{
  const actions = {
    //JobList
    getJobList:async(param)=>{
      let data = await api.getJobMoinitoringList(param);
      dispatch({type:action_type.Assign_JobMonitoringList,data})

    },
    updateJobList:async(newlist)=>{
      dispatch({type:action_type.Update_JobMonitoringList,data:newlist})

    },
    filtering_joblist:(param)=>{
      dispatch({type:action_type.UPDATE_FilterJobMonitoringList,param}) 
    },
    SetCriteriaSearch :(param)=>{
      dispatch({ type: action_type.UpdateCriteria, param });
    },
  };
  return actions
}
export const JobMonitoringContext = createContext({
  state: initial_state,
  actions: getActions(),
});



export const JobMonitoringContextProvider = ({ children }) => {
  const [JobMonitorState, dispatch] = useReducer(
    JobMonitorReducer,
    initial_state
  );
  const [actions] = useState(getActions(dispatch))
  return (
    <JobMonitoringContext.Provider value={{
      state: JobMonitorState,
      actions:actions
      //actions: { ...initial_method, ...actions },
    }}>
      {children}
    </JobMonitoringContext.Provider>
  );
};
