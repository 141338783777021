import { string } from "yup";
import { GetUser } from "utils/sessions";
export const buildParameter = {
  SaveConfig: (formdata, configName, listConfig) => {
    let config = listConfig.find((x) => x.CONFIG_NAME === configName);
    if (config?.DATA_TYPE.toUpperCase() === "INT") {
      // Int Value
      if (config?.INT_VALUE === parseInt(formdata[configName],10)) return null;
    } else if (config?.DATA_TYPE.toUpperCase() === "DOUBLE") {
      // Double Value
      if (config?.DOUBLE_VALUE === parseFloat(formdata[configName]))
        return null;
    } else if (config?.DATA_TYPE.toUpperCase() === "STRING") {
      // String Value
      if (config?.STRING_VALUE === formdata[configName]) return null;
    }
    let param = {
      CONFIG_GROUP: config?.CONFIG_GROUP,
      CONFIG_NAME: config?.CONFIG_NAME,
      INT_VALUE: config?.DATA_TYPE.toUpperCase() === "INT" ? parseInt(formdata[configName],10) : null,
      DOUBLE_VALUE: config?.DATA_TYPE.toUpperCase() === "DOUBLE"
        ? parseFloat(formdata[configName])
        : null,
      STRING_VALUE: config?.DATA_TYPE.toUpperCase() === "STRING" ? (formdata[configName] === "" ? null : formdata[configName]) : null,
      SESSION_OWNER: GetUser(),
    };
    console.log(param)
    return param;
  },
  SaveOriginConfig: (formdata) => {
    let param = {
      ORIGIN_NAME: formdata.ORIGIN_NAME,
      EDGE_URL: formdata.EDGE_URL,
      SESSION_OWNER: GetUser(),
    };
    return param;
  },
};
