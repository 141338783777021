import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Paper, Typography } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
//Component
import { Modal } from "../../../../stateless/modal";
import { BaseContainer } from "../../../../stateless/base-container";
import FormInput from "../../../../stateless/hook-controls/input";
import { ControlFormRadio } from "../../../../stateless/hook-controls/radio";
import { BoxTemplate1 } from "../../../../stateless/Box/box";
import { ReactTable } from "../../../../stateless/react-table";
import { ScheduleType } from "../../../../stateless/selection-control/combo/schedule-type";
import { MaterialUIDatePickers } from "../../../../stateless/hook-controls/datepicker/datepicker";
import ModalPlanDetail from "../modal/modal-plan-detail";
import ImpactModal from "./popup-impact";
import ModalIgnore from "./popup-dependecy-ignore";
//Context
import { ReprocessContext, withContext } from "../reprocess-context";
import {
  LoadingContext,
  withLoadingContext,
} from "../../../../context/loading-context";
//Functions
import { getColumn } from "../columns/reprocess-plan";
import * as CONS from "../constants";
import { hours, Weekly, days, months, SchduleSpec } from "../spec";
import { apiError, Inform, inform_success } from "utils/alert";
import { HookisvalidDate } from "utils/date";
import { ArrayTextReduce, isEmptyArray } from "utils/common-function";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import {
  setDataToFormHookV2,
  isEmptyStr,
  titleCase,
} from "utils/common-function";
import { MuiDateTimeToApi } from "utils/date";
import { GetUser } from "utils/sessions";
import { GetReprocessImpactedPlans } from "../api";
import * as api from "../api";
import { buildParameter } from "../parameter";
import * as alertDialog from "utils/alert";
import { styleBookmarkRow, style_next_executed_fail_Row } from "utils/style";
import moment from "moment";
import { el } from "date-fns/locale";
let { log: c } = console;
const validationSchema = Yup.object().shape({
  REPROCESS_NOTE: Yup.string().required("Reprocess note is required"),
});
export function EditReprocessPlan() {
  const methods = useForm({
    defaultValue: { SCHEDULE_TEXT: "A" },
    resolver: yupResolver(validationSchema),
  });
  const { register } = methods;
  const location = useLocation();
  const history = useHistory();
  const Loadingcontext = useContext(LoadingContext);
  const { state, actions } = useContext(ReprocessContext);
  const LoadDetail = useCallback(
    (param) => {
      Loadingcontext.actions.withLoading(() => actions.LoadDetail(param));
    },
    [actions, Loadingcontext.actions]
  );
  useEffect(() => {
    Loadingcontext.actions.setisLoading(false);
  }, [Loadingcontext.actions]);
  register("EXECUTION_TYPE_ID");
  useEffect(() => {
    if (location.state === undefined) {
      history.goBack();
      return;
    } //Goback
    else if (location.state !== undefined) {
      let param = {
        PlanList: location.state.List,
        User: GetUser(),
      };
      LoadDetail(param);
    }
  }, [history, location.pathname, location.state, LoadDetail, register]);

  return (
    <div
      style={{
        backgroundColor: `rgba(${255}, ${140}, ${20},${0.5})`,
      }}
    >
      {Loadingcontext.state?.isLoading ? (
        <div className="lineLoading">
          <LinearProgress />
        </div>
      ) : null}
      <BaseContainer>
        <FormProvider {...methods}>
          <Box mb={1}>
            <Header />
          </Box>
          <Box mb={1}>
            <Detail />
          </Box>
        </FormProvider>
      </BaseContainer>
    </div>
  );
}
const options = [
  { value: "2", label: "Reprocess (Rerun)" },
  { value: "3", label: "Reprocess (Force)" },
];
const Header = () => {
  const { register, getValues, setValue, handleSubmit, control } =
    useFormContext();
  const { state, actions } = useContext(ReprocessContext);
  const history = useHistory();
  const Loadingcontext = useContext(LoadingContext);
  const { HistoryData } = state;
  const [open, setopen] = useState(false);
  // useEffect(() => {
  //   register(
  //     "REPROCESS_NOTE" ,
  //     {
  //       required: true,
  //       validate: (value) => {
  //         return !!value.trim();
  //       },
  //     }
  //   );
  // });
  const watch_EXCUTION_TYPE_ID = useWatch({
    control,
    name: "EXECUTION_TYPE_ID", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "2", // default value before the render
  });
  useEffect(() => {
    let data = setDataToFormHookV2(state.Header);
    data?.forEach(({ name, value }) => setValue(name, value));
  }, [state.Header, setValue, state.Details]);
  useEffect(() => {
    setValue(
      "EXECUTION_TYPE_ID",
      state.Details[0]?.EXECUTION_TYPE_ID.toString()
    );
  }, [setValue, state.Details]);
  async function validate(data_reprocess) {
    let msg = [];
    let res = true;
    let msg_server = await api.ValidateSubmitReprocess(state.Header);
    if (!isEmptyArray(msg_server)) msg = msg.concat(msg_server);

    if (isEmptyArray(data_reprocess))
      msg.push("There is no plan allowed for this reprocess plan.");
    //Conclusion
    if (!isEmptyArray(msg)) {
      await Inform("", ArrayTextReduce(msg));
      res = false;
    } else if (data_reprocess.length !== state.Details?.length) {
      res = await alertDialog.ConfirmCommon(
        "There are some plans are not allowed for this reprocess plan.\n\nSubmit anyway?"
      );
    }
    return res;
  }
  const Submit = async () => {
    let data_reprocess = state.Details.filter(
      (el) => !checkNextExecution(el.NEXT_EXECUTED, state.Schdule)
    );
    if (isEmptyStr(watch_EXCUTION_TYPE_ID.toString())) {
      Inform("Please select run mode");
      return;
    }
    if (!(await validate(data_reprocess))) return;
    await SaveHeader();
    let param = buildParameter.SubmitReprocessParam(
      state.Header,
      data_reprocess
    );

    let pass = await Loadingcontext.actions.withLoading(() =>
      actions.Submit(param)
    );

    if (pass) {
      await inform_success();
      history.goBack();
    }
  };

  async function Cancel() {
    await Loadingcontext.actions.withLoading(() =>
      api.CancelReprocess(buildParameter.GetSbxReprocessParam(state.Header))
    );
    history.goBack();
  }
  async function SaveHeader() {
    Loadingcontext.actions.withLoading(async () => {
      await actions.SaveHeader({
        ...state.Header,
        REPROCESS_NOTE: getValues().REPROCESS_NOTE,
      });
    });
  }
  function onChangeRadio(e) {
    setValue("EXECUTION_TYPE_ID", e.target.value);
    //actions.SaveDetail(state.Details);
  }
  async function SaveExecutionType(e) {
    let dtl = state.Details.map((el) => ({
      ...el,
      EXECUTION_TYPE_ID: watch_EXCUTION_TYPE_ID,
    }));
    dtl?.forEach(async (element) => {
      await actions.SaveDetail(element);
    });
  }
  return (
    <>
      <Paper p={2}>
        <Box display="flex" flexDirection="column" p={1}>
          <Box display="flex" minWidth="100px" pb={2} justifyContent="flex-end">
            <Box></Box>
          </Box>
          <Box display="flex" flexWrap="wrap" pr={2} alignItems="center">
            <Box m={1} flexGrow={1}>
              <FormInput
                required
                name="REPROCESS_NOTE"
                label={titleCase("REPROCESS_NOTE")}
                onBlur={SaveHeader}
              />
            </Box>
            <Box display="flex" m={1} flexGrow={1} alignItems="center">
              <Box flexGrow={1}>
                <FormInput
                  className="groupBoxInput"
                  name="SCHEDULE_TEXT"
                  label={titleCase("SCHEDULE_TEXT")}
                  disabled
                  value={state.Schdule?.SCHEDULE_TEXT || ""}
                />
              </Box>

              <Box width={100}>
                <Button
                  className="groupBoxButton"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setopen(true);
                  }}
                >
                  Edit Schdule
                </Button>
              </Box>
            </Box>
            <Box display="flex" m={1} flexGrow={1} alignItems="center">
              <Box flexGrow={1}>
                <ControlFormRadio
                  required
                  value={watch_EXCUTION_TYPE_ID?.toString() || "2"}
                  name="EXECUTION_TYPE_ID"
                  onChange={onChangeRadio}
                  onBlur={SaveExecutionType}
                  options={options}
                  row
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          style={{ paddingBottom: "10px" }}
        >
          <Box m={2} className="btnstyle">
            <Button
              variant="contained"
              onClick={handleSubmit(Submit)}
              disabled={Loadingcontext.state?.isLoading}
            >
              Submit
            </Button>
          </Box>
          <Box m={2} className="btnstyle">
            <Button variant="contained" onClick={Cancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>
      <SecheduleModal
        open={open}
        onClose={() => {
          setopen(false);
        }}
      />
    </>
  );
};

function checkNextExecution(dt, schedule) {
  let now = moment();
  if (schedule.TIME) now = moment(schedule.TIME);
  return moment(dt) >= now.add(-1, "minutes"); // if true not sent to submit & text opacity 0.5
}
const Detail = () => {
  const { state, actions } = useContext(ReprocessContext);
  const Loadingcontext = useContext(LoadingContext);
  const rowStyle = useCallback(
    (row, rowIndex) => {
      let style = {};
      if (row.POSITION === 0) style = { ...styleBookmarkRow };
      if (checkNextExecution(row.NEXT_EXECUTED, state.Schdule))
        style = { ...style, ...style_next_executed_fail_Row };
      return style;
    },
    [state.Schdule]
  );
  const {
    Data: DataDetail,
    isOpen: openDetail,
    handleClickRow: clickViewDetail,
    close: closeDetail,
  } = useSelectRowOpenModal();
  const {
    Data: rowdata,
    isOpen: openEdit,
    handleClickRow: clickEdit,
    close: closeEdit,
  } = useSelectRowOpenModal();
  const {
    Data: impactData,
    isOpen: openImpact,
    handleClickRow: showImapct,
    close: closeImpact,
  } = useSelectRowOpenModal();
  const {
    Data: depData,
    isOpen: openDep,
    handleClickRow: showDep,
    close: closeDep,
  } = useSelectRowOpenModal();
  async function getImpact() {
    let include0 = false;
    let param = buildParameter.GetReprocessImpactedPlansParam(
      state.Header,
      include0
    );
    let res = await await Loadingcontext.actions.withLoading(() =>
      GetReprocessImpactedPlans(param)
    );
    showImapct(res);
  }
  async function getDependencies() {
    let include0 = false;
    let param = buildParameter.GetReprocessDependencyPlansParam(
      state.Header,
      include0
    );
    let res = await await Loadingcontext.actions.withLoading(() =>
      api.GetReprocessDependencyPlans(param)
    );
    showDep(res);
  }
  return (
    <>
      <BoxTemplate1 title="Reprocess Chain" height={600}>
        <Box display="flex" justifyContent="flex-end">
          <Box
            mr={1}
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            style={{ paddingBottom: "10px" }}
          >
            <Box m={2} className="btnstyle">
              <Button
                variant="contained"
                onClick={getDependencies}
                className="btn-large"
                disabled={Loadingcontext.state?.isLoading}
              >
                View Dependencies
              </Button>
            </Box>
            <Box m={2} className="btnstyle">
              <Button
                variant="contained"
                onClick={getImpact}
                className="btn-large"
                disabled={Loadingcontext.state?.isLoading}
              >
                View Impacted Plans
              </Button>
            </Box>
          </Box>
        </Box>
        <ReactTable
          keyField="INSTANCE_ID"
          rowStyle={rowStyle}
          data={state.Details || []}
          columns={getColumn(
            clickViewDetail,
            clickEdit,
            state.Header.SCHEDULE_ID
          )}
        />
      </BoxTemplate1>

      <ModalPlanDetail
        open={openDetail}
        onClose={closeDetail}
        data={DataDetail}
      />
      <DependecyEditModal data={rowdata} open={openEdit} onClose={closeEdit} />
      <ImpactModal data={impactData} open={openImpact} onClose={closeImpact} />
      <ModalIgnore
        data={depData}
        open={openDep}
        onClose={closeDep}
        reprocessid={state.Header.REPROCESS_PLAN_ID}
      />
    </>
  );
};

const DependecyEditModal = (props) => {
  const { data } = props;
  const { state, actions } = useContext(ReprocessContext);
  const methods = useForm({ mode: "onChange" });
  const { getValues, setValue, setError, handleSubmit, register } = methods;
  async function Save() {
    let param = {
      ...data,
      FORCE_RESULT_DATE: MuiDateTimeToApi(getValues().FORCE_RESULT_DATE),
    };
    await actions.SaveDetail(param);
    props.onClose();
  }
  useEffect(() => {
    register("FORCE_RESULT_DATE", { required: true });
  });
  useEffect(() => {
    if (!props.open) return;
    let data2 = setDataToFormHookV2(data, []);
    setTimeout(() =>
      data2?.forEach(({ name, value }) => setValue(name, value))
    );
  }, [props.open, data, setValue]);
  return (
    <>
      <Modal
        {...props}
        onSave={handleSubmit(Save)}
        title={data?.PLAN_NAME || "Edit"}
      >
        <FormProvider {...methods}>
          <Box display="block" bgcolor="background.paper">
            <MaterialUIDatePickers
              name="FORCE_RESULT_DATE"
              label={titleCase("FORCE_RESULT_DATE")}
            />
          </Box>
        </FormProvider>
      </Modal>
    </>
  );
};
const SecheduleModal = (props) => {
  const { state, actions } = useContext(ReprocessContext);
  const methods = useForm({ mode: "onChange", defaultValues: state.Schdule });
  const { getValues, setValue, setError, unregister, control } = methods;
  const CurrentType = useWatch({
    control,
    name: "SCHEDULE_TYPE_ID", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: null, // default value before the render
  });
  function validate(data) {
    methods.clearErrors();
    let result = true;
    if (data.isInterval && data.SCHEDULE_TYPE_ID !== CONS.IMMEDIATELY) {
      if (!(data.INTERVAL_MINUTES > 0)) {
        setError("INTERVAL_MINUTES", {
          type: "manual",
          message: `Invalid value`,
        });
        result = false;
      }

      if (isEmptyStr(data.INTERVAL_ENDTIME)) {
        setError("INTERVAL_ENDTIME", {
          type: "manual",
          message: `Invalid value`,
        });
        result = false;
      }
    }
    if (data.HOUR_MINUTE < 0 || data.HOUR_MINUTE > 59) {
      setError("HOUR_MINUTE", {
        type: "manual",
        message: `Invalid value`,
      });
      result = false;
    }
    if (!HookisvalidDate(data, ["ONETIME_DATETIME"])) {
      setError("ONETIME_DATETIME", {
        type: "manual",
        message: `Invalid value`,
      });
      result = false;
    }

    return result;
  }
  async function SaveScheduleSbx() {
    let cur = getValues();
    let schedule = {
      ...state.Schdule,
      ...cur,
      INTERVAL_MINUTES:
        cur.isInterval && cur.SCHEDULE_TYPE_ID !== 1
          ? parseInt(cur.INTERVAL_MINUTES, 10)
          : null,
      INTERVAL_ENDTIME:
        cur.isInterval && cur.SCHEDULE_TYPE_ID !== 1
          ? cur.INTERVAL_ENDTIME
          : null,
      ONETIME_DATETIME: isEmptyStr(cur.ONETIME_DATETIME)
        ? null
        : cur.ONETIME_DATETIME,
      HOUR_MINUTE: parseInt(cur.HOUR_MINUTE, 10),
    };
    schedule.TIME = schedule.TIME ? schedule.TIME : null;
    if (!validate(schedule)) {
      return;
    }
    await actions.SaveSchdule(schedule);
    props.onClose();
  }
  useEffect(() => {
    if (!props.open) return;
    let data = setDataToFormHookV2(state.Schdule);
    setTimeout(() => data?.forEach(({ name, value }) => setValue(name, value)));
  }, [props.open, state.Schdule, setValue]);
  return (
    <>
      <Modal {...props} onSave={SaveScheduleSbx} title="Schedule">
        <FormProvider {...methods}>
          <Box display="block" bgcolor="background.paper">
            <ScheduleType
              name="SCHEDULE_TYPE_ID"
              label="Schdule"
              isRerun
              onChange={(e) => {
                setValue("SCHEDULE_TYPE_ID", e.target.value, {
                  shouldDirty: true,
                });
              }}
            />
            <SchduleSpec
              CurrentType={CurrentType}
              isRerun
              interval={state.Schdule?.INTERVAL_MINUTES}
            ></SchduleSpec>
          </Box>
        </FormProvider>
      </Modal>
    </>
  );
};
export default withLoadingContext(withContext(EditReprocessPlan));
