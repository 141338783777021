import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { isEmptyArray } from "utils/common-function";
import { ToDateText, ToDateTextNoDash, dateformat, getToday } from "utils/date";
import { DownloadFileCsv } from "utils/file";

export const api_GetReconcileResultList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetReconcileResultList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetReconcileDateList = async (param) => {
  
  let url = `${API_URL.Inbound}/api/Reconcile/GetReconcileDateList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_GetReconcileColumnList = async (param) => {
  let url = `${API_URL.Inbound}/api/Reconcile/GetReconcileColumnList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function useReconcile({ filter, dynamicFilter }) {
  const [ReconcileResultList, setReconcileResultList] = useState([]);
  const [ReconcileDateList, setReconcileDateList] = useState([]);
  const [ReconcileColumnList, setReconcileColumnList] = useState([]);
  const formMethods = useForm({
    defaultValues: { datadate: getToday() },
  });
  const { getValues, control } = formMethods;
  const w_reconcile_area = useWatch({ control, name: "area_name" });
  const w_reconcile_report_group = useWatch({ control, name: "report_group" });
  const w_reconcile_type = useWatch({ control, name: "reconcile_type" });
  const w_reconcile = useWatch({ control, name: "reconcile" });
  //state here
  const state = {
    ReconcileResultList,
    ReconcileDateList,
    w_reconcile_area,
    w_reconcile_report_group,
    w_reconcile_type,
    w_reconcile,
    ReconcileColumnList,
  };
  const getReconcileResultList = useCallback(async () => {
    
    //call api
    let param = {
      name: w_reconcile,
      DATADATE: getValues().datadate,
      group_users: filter.GroupUser,
      dynamicFilter: dynamicFilter,
    };
    let [res_col, err_col] = await api_GetReconcileColumnList(param);
    if (err_col) return;
    if (isEmptyArray(res_col)) {
      
      setReconcileColumnList([]);
      return;
    }
    let cols = res_col?.map((el) => el.COLUMN_NAME);
    setReconcileColumnList(cols);
    let [res_date, err_date] = await api_GetReconcileDateList(param);
    setReconcileDateList(res_date.map((el)=>ToDateText(el.DATA_DT)))
    let [res, err] = await api_GetReconcileResultList(param);
    if (err) return;
    res = res?.map((el, idx) => ({ id: idx, ...el }));
    setReconcileResultList(res || []);
    
    
  }, [getValues, w_reconcile, filter, dynamicFilter]);
  useEffect(() => {
    getReconcileResultList();
  }, [getReconcileResultList]);
  //function here

  const actions = {
    getReconcileResultList,
    download: () => {
      let file_name = `${getValues().reconcile}_${ToDateTextNoDash(
        getValues().datadate
      )}.csv`;
      DownloadFileCsv(ReconcileResultList, file_name);
    },
  };
  return {
    state,
    actions,
    formMethods,
  };
}
