import Box from "@material-ui/core/Box";
//Material
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
//Function
import { GetUser } from "utils/sessions";
import { isEmptyStr, titleCase } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import { styleTextLink } from "utils/style";
export const getExecutionPlanHistoryColumns = (
  clickView=()=>{},
) => {
  let columns = [
    {
      dataField: "STATUS_DATETIME",
      text: titleCase("STATUS_DATETIME"),
      sort: true,
      // events: onClickCol,
      formatter: (cell, row) => {
        return <>{ToDateTimeText(row.STATUS_DATETIME)}</>;
      },
    },
    {
      dataField: "STATUS_TEXT",
      text: "Plan Status",
      sort: true,
      // events: onClickCol,
    },
      {
          dataField: "RESULT_DATE",
          text: titleCase("RESULT_DATE"),
          sort: true,
          // events: onClickCol,
          formatter: (cell, row) => {
              return <>{ToDateText(row.RESULT_DATE)}</>;
          },
      },
    {
      dataField: "PLAN_ID",
      text: titleCase("PLAN_ID"),
      sort: true,
        events: {
            onClick: async (e, column, columnIndex, row, rowIndex) => {
                clickView(row);
            },
        },
        style: styleTextLink,
      // events: onClickCol,
    },
    //{
    //  dataField: "PLAN_NAME",
    //  text: titleCase("PLAN_NAME"),
    //  sort: true,
    //  events: {
    //    onClick: async (e, column, columnIndex, row, rowIndex) => {
    //      clickView(row);
    //    },
    //  },
    //  style: styleTextLink,
    //  // events: onClickCol,
    //},
    //{
    //  dataField: "TYPE_TEXT",
    //  text: titleCase("Plan Type"),
    //  sort: true,
    //  // events: onClickCol,
    //},
    {
      dataField: "EXECUTION_TYPE_TEXT",
      text: titleCase("Execution Type"),
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "EXECUTION_P_ID",
      text: "Plan Execution Id",
      sort: true,
      //
    },
    {
      dataField: "MESSAGE",
      text: titleCase("MESSAGE"),
      sort: true,
      // events: onClickCol,
    },
    //{
    //  dataField: "DATADATE_TYPE_TEXT",
    //  text: titleCase("Datadate Type"),
    //  sort: true,
    //  // events: onClickCol,
    //},
  ];
  return columns;
};
