import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { ToDateText, ToDateTimeText } from "utils/date";
export const getMasterColumns = (editData, deleteData) => {
  const columns = [
    {
      dataField: "OP2",
      text: "",
      formatter: (cell, row) => {
        return (
          <Box component="div" display="flex" justifyContent="center">
            <EditIcon
              color="action"
              onClick={() => {
                editData(row);
              }}
            />
            <DeleteIcon
              color={row.REFERENCE  ? "disabled" : "action"}
              onClick={() => {
                deleteData(row.JOB_GUID);
              }}
            />
          </Box>
        );
      },
    },
    {
      dataField: "ORIGIN",
      text: "Origin",
      sort: true,
    },
    {
      dataField: "JOB_ID",
      text: "Job ID",
      sort: true,
    },
    {
      dataField: "JOB_NAME",
      text: "Job Name",
      sort: true,
    },
    {
      dataField: "TYPE_TEXT",
      text: "Job Type",
      sort: true,
    },
    {
      dataField: "PARAMETERS",
      text: "Parameters",
      sort: true,
    },
    {
      dataField: "ACTIVE",
      text: "Active",
      sort: true,
    },
    // {
    //   dataField: "REFERENCE",
    //   text: "Reference Plan",
    //   sort: true,
    // },
    // {
    //   dataField: "CREATED_DATE",
    //   text: "Created Date",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <>{ToDateTimeText(row.CREATED_DATE)}</>;
    //   },
    // },
    // {
    //   dataField: "CREATED_BY",
    //   text: "Created By",
    //   sort: true,
    // },
    // {
    //   dataField: "UPDATED_DATE",
    //   text: "Updated Date",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <>{ToDateTimeText(row.UPDATED_DATE)}</>;
    //   },
    // },
    // {
    //   dataField: "UPDATED_BY",
    //   text: "Updated By",
    //   sort: true,
      
    // },
  ];
  return columns;
};
