import React, { useEffect } from "react";
import { AppbarWithMenu } from "stateless/appbar";
import {Banner} from "stateless/banner";
import { GetUser } from "./../utils/sessions";
import { MenuContext } from "../apps/main/portal/menu-context";
export default function LayoutMenu({ children, ScreenName, helper }) {
  const { state, actions } = React.useContext(MenuContext);
  const { MenuList } = state;
  const { GetMenuList } = actions;
  useEffect(() => {
    GetMenuList();
  }, [GetMenuList]);
  return (
    <>
      <AppbarWithMenu
        user={GetUser()}
        ScreenList={MenuList}
        ScreenName={ScreenName}
        helper={helper}
      >
        <>
        <Banner ScreenName={ScreenName}/>
          <section name="body" className="body">
            {children}
          </section>
          <section name="footer"></section>
        </>
      </AppbarWithMenu>
    </>
  );
}
