import axios from "axios";
import React, { useEffect, useState } from "react";
import FormSelect from "stateless/hook-controls/select";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
export const api_GetData = async (param) => {
  let url = `${API_URL.Inbound}/api/Master/GetReconcileAreaList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export function SelectAreaReconcile(props) {
  const [Data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      let [res, err] = await api_GetData();
      if (err) return;
      const cbo =
        res &&
        res.map(({ area_name: label, area_name: id }) => ({ label, id }));
      setData(cbo);
    })();
  }, []);
  return (
    <>
      <FormSelect {...props} options={Data} />
    </>
  );
}
