import axios from "axios";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
import { getToday } from "utils/date";
import { inform_success, inform_error, ConfirmCommon } from "utils/alert";
import { isEmptyStr } from "utils/common-function";

export const api_GetFilterTableList = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/GetFilterTableList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
export const api_DeleteDynamicFilter = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/DeleteDynamicFilter`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};


export function useFilterMaintenance() {
  const [FilterList, setFilterList] = useState([]);
  const formMethods = useForm({ defaultValues: { datadate: getToday() } });
  //state here
  const controlDialogDetail = useSelectRowOpenModal();
  const controlDialogAddMultiple = useSelectRowOpenModal();
  const state = { FilterList, controlDialogDetail,controlDialogAddMultiple };
  //Callback
  const GetFilterTableList = useCallback(async () => {
    //call api
    let [res, err] = await api_GetFilterTableList();
    if (err) return;
    setFilterList(res);
  }, []);
  useEffect(() => {
    GetFilterTableList();
  }, [GetFilterTableList]);
  //function here
  const actions = {
    addRow: async () => {
      controlDialogDetail.handleClickRow();
    },
    editRow: async (row) => {
      controlDialogDetail.handleClickRow(row);
    },
    deleteRow: async (row) => {
      if (!(await ConfirmCommon("Confirm Delete"))) return;
      let [res, err] = await api_DeleteDynamicFilter([row]);
      if (err) return;
      GetFilterTableList()
      inform_success("Deleted.");
    },
    deleteMultipleRow: async (selected) => {
      if (!(await ConfirmCommon("Confirm Delete"))) return;
      let param = FilterList.filter(el=>selected.includes(el.id))
      let [res, err] = await api_DeleteDynamicFilter(param);
      if (err) return;
      GetFilterTableList()
      inform_success("Deleted.");
    },
    addmultiple:()=>{
      controlDialogAddMultiple.handleClickRow()
    },
    GetFilterTableList,
  };
  return {
    state,
    actions,
    formMethods,
  };
}
