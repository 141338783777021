import React, { useState, useEffect } from "react";
//Material
import Box from "@material-ui/core/Box";
//Component
import { ReactTable } from "stateless/react-table";
import { Modal } from "stateless/modal";
import { getColumnImpact } from "../../../columns/popup-impact";
import ModalPlanDetail from "../../../modal/modal-plan-detail";
//Function
import { useSelectRowOpenModal } from "../../../../function-api/useSelectRowOpenModal.js";

const rowStyle = (row, rowIndex) => {
    return { backgroundColor: row.STATUS_COLOR };
};
function ImapactNodal(props) {
    const { data } = props;
    const {
        isOpen: isOpenDetail,
        close: closeDetail,
        Data: dataDetail,
        handleClickRow: clickDetail,
    } = useSelectRowOpenModal();

    const viewPlan = async (row) => {
        clickDetail(row);
    };

    return (
        <>
            <Modal {...props} title={`Impacted Plans`} hiddenSave maxWidth="xl" className="headerModalDependencyDetail">
                <Box display="block" bgcolor="background.paper">
                    <ReactTable
                        exportCsv
                        rowStyle={rowStyle}
                        keyField="SEQUENCE"
                        data={data || []}
                        columns={getColumnImpact(viewPlan)}
                    />
                </Box>
            </Modal>
            <ModalPlanDetail
                open={isOpenDetail}
                onClose={closeDetail}
                data={dataDetail}
            />
        </>
    );
}

export default ImapactNodal;
