import React, { useEffect, useContext, useCallback, useState } from "react";
import { ReactTable } from "../../../../stateless/react-table";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//Material
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  DataGrid,
  GridCellEditStopReasons,
  GridCellModes,
  useGridApiRef,
} from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleIcon from "@mui/icons-material/AddCircle";
//Component
import { BaseContainer } from "../../../../stateless/base-container";
import { MaterialUIDatePickers } from "../../../../stateless/hook-controls/datepicker/datepicker";
import TextBox from "../../../../stateless/hook-controls/input";
import { FormDatePicker } from "../../../../stateless/hook-controls/datepicker";
import ComboBackToDateRange from "../../../../stateless/selection-control/combo/backto-date-range";
import FormInput from "../../../../stateless/hook-controls/input";
import ComboDataDateType from "../../../../stateless/selection-control/combo/date-type";
//Context
import { ExecutionPlanContext } from "../job-execution-plan-context";
import { CMDataGrid } from "stateless/data-grid";
import {
  LoadingContext,
  withLoadingContext,
} from "../../../../context/loading-context";
//Function
import { getColumn as getColumnExecutionGroup } from "../columns/ExecutionGroup";
import { buildParameter } from "../parameter";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import * as api from "../api";
import * as alertDialog from "utils/alert";
import { checkDateRange, MuiDateTimeToApi } from "utils/date";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSignalR } from "module/function-api/useSignalR";
import {
  methodEventKkpGroupStatusChanged,
  methodExecuteGroupResulChanged,
  url,
} from "./signalr";
import moment from "moment";
import {
  isEmptyStr,
  setDataToFormHookV2,
  titleCase,
} from "utils/common-function";
const { log } = console;
const validationSchema = Yup.object().shape({
  group_name: Yup.string().required("Group name is required"),
});
const getColumn = (clickEdit, clickDelete, clickManage) => {
  return [
    {
      dataField: "group_name",
      text: titleCase("group_name"),
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "group_name_display",
      text: titleCase("group_name_display"),
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "description",
      text: titleCase("description"),
      sort: true,
      // events: onClickCol,
    },
    {
        dataField: "datadate_type_name",
        text: titleCase("group_datadate_type"),
        sort: true,
        // events: onClickCol,
    }, {
        dataField: "group_datadate_value",
        text: titleCase("group_datadate_value"),
        sort: true,
        // events: onClickCol,
    },
    {
      dataField: "OP1",
      text: "",
      sort: false,
      csvExport: false,
      // events: onClickCol,
      formatter: (cell, row) => {
        return (
          <Box component="div" display="flex" justifyContent="center">
            <>
              <EditIcon
                color="action"
                //className={classes.icon}
                onClick={() => {
                  clickEdit(row);
                }}
              />
              <DeleteIcon
                color="action"
                //className={classes.icon}
                onClick={() => {
                  clickDelete(row);
                }}
              />
              <SettingsIcon
                color="action"
                onClick={() => {
                  clickManage(row);
                }}
              />
            </>
          </Box>
        );
      },
    },
  ];
};
function GroupExecutionManagement() {
  const { actions: load_actions, state: load_state } =
    useContext(LoadingContext);
  const context = useContext(ExecutionPlanContext);
  const [DataRow, setDataRow] = useState([]);
  const [EditMode, setEditMode] = useState(false);
  const [openPopUpRun, setOpenPopUpRun] = useState(false);
  const history = useHistory();
  const { state, actions } = context;
  const methods = useForm({
    defaultValues: context.state.CriteriaSearchReprocess,
  });
  let popupedit_control = useSelectRowOpenModal();
  let popupmapping_control = useSelectRowOpenModal();
  const { getValues } = methods;
  const Search = useCallback(() => {
    load_actions.withLoading(async () => {
      let [res] = await api.GetExecutionGroupMasterList({});
      setDataRow(res);
    });
  }, [load_actions]);
  async function Save(params, isEdit) {
    if (isEdit) {
      let [res, err] = await api.EditExecutionGroupMaster(params);
      if (err) return;
      await alertDialog.inform_success("Save complete.");
      popupedit_control.close();
    } else {
      let [res, err] = await api.AddExecutionGroupMaster(params);
      if (err) return;
      if (!isEmptyStr(res)) {
        await alertDialog.inform_error(res);
        return;
      }
      await alertDialog.inform_success("Save complete.");
      popupedit_control.close();
    }
    Search();
  }
  useEffect(() => {
    Search();
  }, [history, Search]);
  async function LoadForEdit(row) {
    let [res, err] = await api.GetExecutionGroupMasterList(row);
    if (err) return;
    setEditMode(true);

    popupedit_control.handleClickRow(res[0]);
  }
  async function Delete(row) {
    if (!(await alertDialog.ConfirmDelete())) return;
    let [res, err] = await api.DeleteExecutionGroupMaster(row);
    if (err) return;
    if (!isEmptyStr(res)) {
      alertDialog.inform_error(res);
      return;
    }
    alertDialog.inform_success("Deleted.");
    Search();
  }
  const tablecomponent = () => {
    return (
      <>
        <Box m={2} display="flex" className="btnstyle">
          <Button
            variant="contained"
            onClick={() => {
              popupedit_control.toggle();
              setEditMode(false);
            }}
            style={{ width: 150 }}
          >
            New group
          </Button>
          <Box mr={1}></Box>
          <Button variant="contained" onClick={Search}>
            Refresh
          </Button>
          <Box mr={1}></Box>
        </Box>
      </>
    );
  };
  let columns = getColumn(
    LoadForEdit,
    Delete,
    popupmapping_control.handleClickRow
  );
  return (
    <>
      {load_state?.isLoading ? <LinearProgress /> : null}
      <BaseContainer>
        {/* <FormProvider {...methods}>
          <Criteria Search={Search} />
        </FormProvider> */}
        <ReactTable
          data={DataRow || []}
          columns={columns}
          keyField="group_name"
          headcomponent={tablecomponent}
        />
        <PopNewEditGroup
          open={popupedit_control.isOpen}
          handleClose={() => popupedit_control.close()}
          isEdit={EditMode}
          data={EditMode ? popupedit_control.Data : {}}
          Save={Save}
        />
        <PopUpMapping
          open={popupmapping_control.isOpen}
          handleClose={() => popupmapping_control.close()}
          Data={popupmapping_control.Data}
        />
      </BaseContainer>
    </>
  );
}
const Criteria = ({ Search }) => {
  const context = useContext(ExecutionPlanContext);
  const methods = useFormContext();
  const { getValues, setValue, setError, clearErrors } = methods;

  const dateChange = (e) => {
    if (!validate()) return;
    context.actions.SetCriteriaSearchReprocess(getValues());
    Search();
  };
  const handleOnChange = (e) => {
    setValue(e.target.name, e.target.value);
    context.actions.SetCriteriaSearchReprocess(getValues());
    Search();
  };
  function validate() {
    let dateform_name, dateTo_name;

    dateform_name = "dateFrom";
    dateTo_name = "dateTo";

    clearErrors();
    let valid = checkDateRange(
      getValues()[dateform_name],
      getValues()[dateTo_name]
    );
    if (!valid) {
      setError(dateform_name, {
        type: "manual",
        //message: `Invalid date range`,
      });
      setError(dateTo_name, {
        type: "manual",
        message: `Invalid date range`,
      });
      return false;
    }
    return true;
  }
  return (
    <>
      <Box display="flex" flexWrap="wrap" className="criteriaStyle">
        <Box p={1} minWidth="175px" style={{ paddingLeft: "0" }}>
          <MaterialUIDatePickers
            name="dateFrom"
            label="From"
            onChange={dateChange}
          />
        </Box>

        <Box
          p={1}
          minWidth="175px"
          className="dropDownBox"
          style={{ paddingLeft: "0" }}
        >
          <MaterialUIDatePickers
            name="dateTo"
            label="To"
            minDate={context.state.CriteriaSearchReprocess.dateFrom}
            onChange={dateChange}
          />
        </Box>
        {/* <Box p={1} flexGrow={1}>
          <CheckboxPlanType name="PLAN_TYPE_ID" onChange={filterdata} />
        </Box>
        <Box p={1} flexGrow={1} maxWidth={400}>
          <CheckboxPlanStatus name="PLAN_STATUS_ID" onChange={filterdata} />
        </Box> */}
      </Box>
    </>
  );
};
function PopNewEditGroup({ open, handleClose, Save, isEdit, data }) {
    const validationSchema = Yup.object().shape({
        group_datadate_type_id: Yup.string().required("Group Datadate Type is required"),
        group_datadate_value: Yup.string().required("Group Datadate Value is required"),
    });

    const methods = useForm({ resolver: yupResolver(validationSchema) });
    const { getValues, reset, setValue, handleSubmit } = methods;
    const CriteriaMethods = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    reset();
    let hook = setDataToFormHookV2(data);
    hook?.forEach(({ name, value }) => setValue(name, value));
  }, [open, reset, data, setValue]);
  //
  return (
    <>
          <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            {!isEdit ? "Add new Execution Group" : "Edit Execution Group"}
          </DialogTitle>
          <DialogContent>
            <Box display={"flex"} flexDirection="column">
              <TextBox
                required
                name="group_name"
                label={"Group Name"}
                disabled={isEdit}
              />
              <Box mb={1}></Box>
              <TextBox name="group_name_display" label={"Group Name Display"} />
              <Box mb={1}></Box>
              <TextBox name="description" label={"Description"} />
              <Box mb={1}></Box>
              <ComboDataDateType name="group_datadate_type_id" label={"Group Datadate Type"} />
              <Box mb={1}></Box>
                <FormInput required
                    type="number"
                    defaultValues={-1}
                    name="group_datadate_value"
                    label={"Group Datadate Value"}
                    InputProps={{ inputProps: { min: -31, max: 0 } }}
                />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={(e) => {
                handleSubmit(() => Save(getValues(), isEdit))(e)
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
}
function PopUpMapping({ open, handleClose, Data }) {
  const methods = useForm({ defaultValues: { DATA_DT: moment() } });
  const { getValues, reset, control } = methods;
  const [DataRow, setDataRow] = useState([]);
  const [filterDataRow, setFilterDataRow] = useState([]);
  const [DataRowPlan, setDataRowPlan] = useState([]);
  const [filteredDataRowPlan, setFilteredDataRowPlan] = useState([]);
  const [cellModesModel, setCellModesModel] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const LoadData = useCallback(async () => {
    let [res, err] = await api.GetMappingExecutionGroupList(Data || {});
    if (err) return;
    setDataRow(res);
  }, [Data]);
  useEffect(() => {
    if (!open) {
      reset();
      setDataRow([]);
      setFilterDataRow([]);
    }
    LoadData();
  }, [LoadData, open, reset]);
  let plan_filter = useWatch({ control, name: "plan_name_master" });
  let plan_mapping_filter = useWatch({ control, name: "plan_name_tnx" });
  useEffect(() => {
    (async () => {
      let [res, err] = await api.GetExecutionPlanForGroup();
      if (err) return;
      setDataRowPlan(res);
    })();
  }, []);
  useEffect(() => {
    let res = DataRowPlan.filter(
      (el) =>
        !DataRow.map((dr) => dr.plan_id).includes(el.PLAN_ID) &&
        (isEmptyStr(plan_filter) ||
          el.PLAN_NAME.toLowerCase().includes(plan_filter.toLowerCase()))
    );
    setFilteredDataRowPlan(res);
  }, [plan_filter, DataRowPlan, DataRow]);
  useEffect(() => {
    let res = DataRow.filter(
      (el) =>
        isEmptyStr(plan_mapping_filter) ||
        el.plan_name.toLowerCase().includes(plan_mapping_filter.toLowerCase())
    );
    setFilterDataRow(res);
  }, [plan_mapping_filter, DataRow]);

  const columns = [
    {
      field: "plan_name",
      headerName: "Plan Name",
      minWidth: 350,
      editable: false,
    },
    { field: "plan_id", headerName: "plan_id", editable: false },
    {
      field: "priority",
      headerName: "Priority",
      type: "number",
      editable: true,
    },
    { field: "active", headerName: "Active", type: "boolean", editable: true },
    {
      field: "action",
      headerName: "",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <DeleteIcon
            color="action"
            //className={classes.icon}
            onClick={() => {
              RemovePlan(params.row);
            }}
          />
        </Box>
      ),
    },
  ];
  const columnsPlans = [
    {
      field: "PLAN_NAME",
      headerName: "Plan Name",
      width: 350,
      editable: false,
    },
    { field: "PLAN_ID", headerName: "PLAN_ID", editable: false },
    {
      field: "action",
      headerName: "",
      align: "center",
      width: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <AddCircleIcon
            color="action"
            //className={classes.icon}
            onClick={() => {
              AddPlan(params.row);
            }}
          />
        </Box>
      ),
    },
  ];
  function UpdateDataRow(params) {
    setDataRow((prev) =>
      prev.map((el) => {
        if (el.group_name + el.plan_id === params.id)
          return { ...el, [params.field]: params.value };
        return el;
      })
    );
  }
  async function Save(params) {
    let [res, err] = await api.UpdateMappingExecutionGroupList({
      Group: Data,
      Plans: DataRow,
    });
    if (err) return;
    alertDialog.inform_success("Save complete.");
  }
  function RemovePlan(params) {
    setDataRow((prev) => prev.filter((el) => el.plan_id !== params.plan_id));
  }
  function AddPlan(params) {
    let found = DataRow.some((el) => el.plan_id === params.PLAN_ID);
    if (found) return;
    setDataRow((prev) => [
      ...prev,
      {
        ...Data,
        plan_id: params.PLAN_ID,
        active: true,
        priority: 1,
        plan_name: params.PLAN_NAME,
      },
    ]);
  }
  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle>Execution Group Setting</DialogTitle>
          <DialogContent sx={{ minHeight: "100%" }}>
            <Box display="flex">
              <Box flexGrow={1} width={"60%"}>
                <DialogContentText>
                  Plans in {Data?.group_name_display}.
                </DialogContentText>
                <TextBox
                  name="plan_name_tnx"
                  label="Plan name"
                  fullWidth={false}
                />
                <Box mb={1}></Box>
                <CMDataGrid
                  rows={filterDataRow}
                  onCellEditCommit={(p) => UpdateDataRow(p)}
                  cellModesModel={cellModesModel}
                  onCellModesModelChange={(model) => setCellModesModel(model)}
                  customColumn={{ custom_col: columns }}
                  getRowId={(row) => row.group_name + row.plan_id}
                  //experimentalFeatures={{ newEditingApi: true }}
                  columnVisibilityModel={{ plan_id: false }}
                  components={{
                    Footer: CustomFooterStatusComponent,
                  }}
                />
              </Box>
              <Box ml={1} flexGrow={1} width={"40%"}>
                <DialogContentText>List of all plans</DialogContentText>
                <TextBox
                  name="plan_name_master"
                  label="Plan name"
                  fullWidth={false}
                />
                <Box mb={1}></Box>
                <CMDataGrid
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  rows={filteredDataRowPlan}
                  customColumn={{ custom_col: columnsPlans }}
                  getRowId={(row) => row.PLAN_ID}
                  //experimentalFeatures={{ newEditingApi: true }}
                  columnVisibilityModel={{ PLAN_ID: false }}
                  //checkboxSelection
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={LoadData}>Reload</Button>
            <Button
              onClick={() => {
                Save();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
}
function CustomFooterStatusComponent(props) {
  return <Box sx={{ p: 1, display: "flex" }}></Box>;
}
export default withLoadingContext(GroupExecutionManagement);
