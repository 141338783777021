import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
export function DownloadFromBase64(pure_base64,file_name) {
    var a = document.createElement("a"); //Create <a>
    a.href = "data:application/octet-stream;base64," + pure_base64; //Image Base64 Goes here
    a.download = file_name; //File name Here
    a.click();
} 
export function GetDataFrom64(pure_base64) {
    let wb = XLSX.read(pure_base64, {
        type: "base64",
        cellDates: true,
        raw: true,
        defval: "",
        // cellNF: false,
        //cellText: true,
      });
  
      let ws = wb.Sheets[wb.SheetNames[0]];
      let data = XLSX.utils.sheet_to_json(ws, { blankrows: true, defval: "" });
      return data
} 
export function DownloadFileCsv(arr_obj,filename) {
    const ws = XLSX.utils.json_to_sheet(arr_obj);
    const csv = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob(["\uFEFF" + csv], {
      type: "text/csv; charset=utf-8",
    });
    saveAs(blob, filename);
}
export function DownloadFileExcel(arr_obj,filename) {
    const ws = XLSX.utils.json_to_sheet(arr_obj);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
    XLSX.writeFile(workbook, filename);
}