import { GetUser } from "utils/sessions";

export const buildParameter = {
         SaveDetail: (id, formdata) => {
           let param = {
             GROUP_BU: formdata.GROUP_BU,
             ACTIVE: formdata.ACTIVE,
             SESSION_OWNER: GetUser(),
           };
           return param;
         },
       };
