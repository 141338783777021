import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { common } from "utils";
import { API_URL } from "utils/api-utils";
export const api_GetFilterTableFieldList = async (param) => {
  let url = `${API_URL.Inbound}/api/AdminMaintenance/GetFilterTableFieldList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};
function ReportFilterAutoComplete(allprops) {
  const { schema_name, table_name, ...props } = allprops;
  const [Options, setOptions] = useState([]);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(props.name)) {
    isError = true;
    errorMessage = errors[props.name].message;
  }
  const w_name = useWatch({ control, name: props.name });
  useEffect(() => {
    (async () => {
      let [res, err] = await api_GetFilterTableFieldList({
        schema_name,
        table_name,
      });
      if (err) return;
      setOptions(res);
    })();
  }, [schema_name, table_name]);
  //console.log(w_name);
  return (
    <Autocomplete
    size="small"
      value={w_name || ""}
      onChange={(even, newValue) => {
        console.log(newValue);
        setValue(props.name, newValue);
      }}
      options={Options.map((option) => option)}
      renderInput={(params) => (
        <TextField
          
          helperText={errorMessage}
          error={isError}
          {...params}
          {...props}
        />
      )}
    />
  );
}

export default ReportFilterAutoComplete;
