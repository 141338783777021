import React, { useState, useEffect, useContext, useCallback } from "react";
import { common } from "utils";
//Material
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
//Component
import Button from "@material-ui/core/Button";
import { ReactTable } from "stateless/react-table";
import ModalImport from "./modal/modal-import-plan";
//Function
import {
    setDataToFormHookV2,
    isEmptyStr,
    isEmptyArray,
} from "utils/common-function";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import { getMasterColumns } from "./columns/plan-master";
import { GetUser } from "utils/sessions";
import * as alertDialog from "utils/alert";
import * as api from "./api";
// Context

function PlanMaster() {
    //Context

    //State
    const [openImportModal, setOpenImportModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [DataList, setDataList] = useState([]);
    const { handleOnSelect, handleOnSelectAll, SelectedItems } = useSelectItem(
        "JOB_GUID"
    );
    //Event
    const onOpenImportModal = () => {
        setOpenImportModal(true);
    };
    const onCloseImportModal = () => {
        setOpenImportModal(false);
        fetch();
    };
    //---------------------------
    const fetch = async () => {
        let res = await api.GetListMaster();
        setDataList(res);
    };
    useEffect(() => {
        fetch();
    }, []);

    //Function
    const withLoading = async (promise_function) => {
        setIsLoading(true);
        let result = await common.to_raw(promise_function());
        setIsLoading(false);
        return result;
    };

    const Export = async () => {
        if (isEmptyArray(SelectedItems)) return;

        let param = {
            SelectedItems: SelectedItems,
            SESSION_OWNER: GetUser(),
        };

        let res = await withLoading(async () => api.ExportStream(param));
        if (res) {
            const today = new Date();
            const date =
                today.getFullYear() +
                String(today.getMonth() + 1).padStart(2, "0") +
                String(today.getDate()).padStart(2, "0") +
                "_" +
                String(today.getHours()).padStart(2, "0") +
                String(today.getMinutes()).padStart(2, "0") +
                String(today.getSeconds()).padStart(2, "0");
            const fileName = "JOBS_" + date;
            const json = JSON.stringify(res, undefined, 4);
            const blob = new Blob([json], { type: "application/json" });
            const href = await URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    //---------------------------
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));
    const classes = useStyles();

    const tablecomponent = () => {
        return (
            <>
                <Box m={2} className="btnstyle">
                    <Button
                        variant="contained"
                        onClick={onOpenImportModal}
                        disabled={isLoading}
                    >
                        Import
          </Button>
                </Box>
                <Box m={2} className="btnstyle">
                    <Button variant="contained" onClick={Export} disabled={isLoading}>
                        Export
          </Button>
                </Box>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    };

    return (
        <>
            {isLoading ? <LinearProgress /> : null}
            <Typography style={{ marginLeft: "5px" }}>Stream Master</Typography>
            <ReactTable
                keyField="JOB_GUID"
                data={DataList}
                columns={getMasterColumns()}
                headcomponent={tablecomponent}
                clickToSelect
                handleOnSelect={handleOnSelect}
                handleOnSelectAll={handleOnSelectAll}
            />
            <ModalImport open={openImportModal} onClose={onCloseImportModal} />
        </>
    );
}

export default PlanMaster;
