import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
// Util
import { common } from "utils";
//Material
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//Component
import { ReactTable } from "stateless/react-table";
import { BaseContainer } from "stateless/base-container";
import CheckboxExecutionType from "stateless/selection-control/checkbox/execution-type";
import CheckboxPlanStatus from "stateless/selection-control/checkbox/planstatus";
import { MaterialUIDatePickers } from "stateless/hook-controls/datepicker/datepicker";
import ModalPlanDetail from "../../modal/modal-plan-detail";
import ModalReprocessDetail from "./modal-reprocess-detaill";
import ModalHold from "./modal-hold";
import ModalSetInvalid from "./modal-setinvalid";
import ModalImpacts from "../../Reprocess/popup-impact";
//Context
import {
  withContext,
  SelfServiceStatusContext,
} from "./self-service-status-context";
import { CriteriaContext } from "../../criteria-context";
import { LoadingContext, withLoadingContext } from "context/loading-context";
//Function
import { useSelectItem } from "stateless/react-table/useSelectItem";
import * as api from "../../api";
import { getColumn } from "../../columns/daily-plan";
import * as alertDialog from "utils/alert";
import { buildParameter } from "../../parameter";
import { useSelectRowOpenModal } from "../../../function-api/useSelectRowOpenModal.js";
import { isEmptyArray } from "utils/common-function";
import { url, methodOnPlanStatusChanged } from "../../signalr";
import { checkDateRange } from "utils/date";
import { useSignalR } from "../../../function-api/useSignalR";
import { SelectOwnerList } from "stateless/selection-control/combo/owner-list";
let { log: c } = console;
const SelfServiceStatus = (props) => {
  //State
  const context = useContext(SelfServiceStatusContext);
  const { state: criteria_state } = useContext(CriteriaContext);
  const methods = useForm({
    defaultValues: criteria_state.DailyPlanCriteria,
  });
  const { actions } = context;
  const Loading_context = useContext(LoadingContext);
  const { actions: load_actions } = Loading_context;
  const { getValues } = methods;
  const history = useHistory();
  const {
    isOpen: isOpenPlandetail,
    close: closePlandetail,
    Data: datarowPlandetail,
    handleClickRow: clickViewPlandetail,
  } = useSelectRowOpenModal();
  const {
    isOpen: isOpenReprocessdetail,
    close: closeReprocessdetail,
    Data: datarowReprocessdetail,
    handleClickRow: clickViewReprocessdetail,
  } = useSelectRowOpenModal();
  const { handleOnSelect, handleOnSelectAll, SelectedItems } =
    useSelectItem("INSTANCE_ID");
  const {
    isOpen: isOpenHold,
    close: closeHold,
    Data: dataHold,
    handleClickRow: clickHold,
  } = useSelectRowOpenModal();
  const {
    isOpen: isOpenSetInvalid,
    close: closeSetInvalid,
    Data: dataInvalidate,
    handleClickRow: clickInvalidate,
  } = useSelectRowOpenModal();
  const {
    isOpen: isOpenImpacts,
    close: closeImpacts,
    Data: dataImpacts,
    handleClickRow: clickImpacts,
  } = useSelectRowOpenModal();
  //CallBack
  const Search = useCallback(async () => {
    let paramsearch = buildParameter.GetSelfServiceStatusList(getValues());
    load_actions.withLoading(() =>
      actions.GetSelfServiceStatusList(paramsearch)
    );
  }, [getValues, actions, load_actions]);
  const filterdata = useCallback(() => {
    let param = getValues();
    actions.FilteringSelfServiceStatus(param);
  }, [actions, getValues]);
  const signalRHandler = useCallback(
    (data) => {
      actions.UpdateSelfServiceStatusList(data);
    },
    [actions]
  );
  //Effect
  useEffect(() => {
    (async () => {
      let param = buildParameter.GetSbxReprocessParam();
      if (await api.CheckCurrentEditReprocess(param)) {
        await alertDialog.Informcurrentedit();
        history.push({
          pathname: "/JobMonitoring/Self-Service-Reprocess",
          state: { List: [] },
        });
      }
      Search();
    })();
  }, [history, Search]);
  useEffect(() => {
    filterdata();
  }, [context.state.SelfServiceStatusList, filterdata]);
  useSignalR(url, methodOnPlanStatusChanged, signalRHandler);

  //Function
  function Reprocess() {
    if (isEmptyArray(SelectedItems)) return;
    let all = context.state.SelfServiceStatusFilterList.filter((el) =>
      SelectedItems.includes(el.INSTANCE_ID)
    );
    history.push({
      pathname: "/JobMonitoring/Self-Service-Reprocess",
      state: { List: all },
    });
  }
  function onHold() {
    if (isEmptyArray(SelectedItems)) return;
    let all = context.state.SelfServiceStatusFilterList.filter((el) =>
      SelectedItems.includes(el.INSTANCE_ID)
    );
    clickHold(all);
  }
  function onInvalidate() {
    if (isEmptyArray(SelectedItems)) return;
    clickInvalidate(SelectedItems);
  }
  async function onForceSuccess() {
    if (isEmptyArray(SelectedItems)) return;
    let confirm = await alertDialog.ConfirmCommon(
      "Confirm force Success?",
      "yes",
      "no"
    );
    if (!confirm) return;
    let param = buildParameter.SuccessPlanParam(SelectedItems);
    let res = await api.SuccessPlans(param);
    let txt = `Set status success ${res.length || 0} plans`;
    await alertDialog.Inform(txt);
  }
  async function onCancelRunning() {
    if (isEmptyArray(SelectedItems)) return;
    let confirm = await alertDialog.ConfirmCommon(
      "Confirm force cancel?",
      "yes",
      "no"
    );
    if (!confirm) return;
    let param = buildParameter.CancelRunningPlanParam(SelectedItems);
    let res = await api.CancelRunningPlans(param);
    let txt = `Set status cancel ${res.length || 0} plans`;
    await alertDialog.Inform(txt);
  }
  async function ViewImpacts() {
    if (isEmptyArray(SelectedItems)) return;
    let include0 = false;
    let param = buildParameter.GetImpactedPlansParam(SelectedItems, include0);

    let res = await api.GetSelfServiceImpactPlan(param);
    clickImpacts(res);
  }
  //component
  const columns = getColumn(clickViewPlandetail, clickViewReprocessdetail);
  const rowStyle = (row, rowIndex) => {
    return { backgroundColor: row.STATUS_COLOR };
  };
  const lst_btn = [
    { name: "Hold", method: onHold },
    { name: "Invalidate", method: onInvalidate },
    { name: "Force Success", method: onForceSuccess },
    { name: "Cancel Running", method: onCancelRunning },
  ];
  const tablecomponent = () => {
    return (
      <>
        <Box m={2} className="btnstyle">
          <Button variant="contained" onClick={Search}>
            Refresh
          </Button>
        </Box>
        <Box m={2} className="btnstyle">
          <Button variant="contained" onClick={ViewImpacts}>
            Impacted Plans
          </Button>
        </Box>

        <Box m={2} className="btnstyle">
          <Button variant="contained" onClick={Reprocess}>
            Reprocess
          </Button>
        </Box>
      </>
    );
  };
  return (
    <>
      {Loading_context.state?.isLoading ? (
        <div className="lineLoading">
          <LinearProgress />
        </div>
      ) : null}
      <BaseContainer>
        <FormProvider {...methods}>
          <div>
            <Criteria />
          </div>
        </FormProvider>
        <ReactTable
          data={context.state.SelfServiceStatusFilterList}
          columns={columns}
          keyField="INSTANCE_ID"
          rowStyle={rowStyle}
          headcomponent={tablecomponent}
          exportCsv
          clickToSelect
          handleOnSelect={handleOnSelect}
          handleOnSelectAll={handleOnSelectAll}
        />
      </BaseContainer>
      <ModalPlanDetail
        open={isOpenPlandetail}
        onClose={closePlandetail}
        data={datarowPlandetail}
      />
      <ModalReprocessDetail
        open={isOpenReprocessdetail}
        onClose={closeReprocessdetail}
        data={datarowReprocessdetail}
      />
      <ModalHold open={isOpenHold} onClose={closeHold} data={dataHold} />
      <ModalSetInvalid
        open={isOpenSetInvalid}
        onClose={closeSetInvalid}
        data={dataInvalidate}
      />
      <ModalImpacts
        open={isOpenImpacts}
        onClose={closeImpacts}
        data={dataImpacts}
      />
    </>
  );
};

const Criteria = () => {
  const context = useContext(SelfServiceStatusContext);
  const { actions: criteria_actions, state: criteria_state } =
    useContext(CriteriaContext);
  const Loading_context = useContext(LoadingContext);
  const { actions: load_actions } = Loading_context;
  const methods = useFormContext();
  const { getValues, setValue, control, register, clearErrors, setError } =
    methods;
  const CurrentChoice = useWatch({
    control,
    name: "choice", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    //defaultValue: "1", // default value before the render
  });
  useEffect(() => {
    register("choice");
  });
  const Search = () => {
    let param = buildParameter.GetSelfServiceStatusList(getValues());
    c(getValues());
    // Call Api
    load_actions.withLoading(() =>
      context.actions.GetSelfServiceStatusList(param)
    );
  };
  const filterdata = (e) => {
    let param = getValues();
    common.reCheckbox(getValues(), e);
    load_actions.withLoading(() =>
      context.actions.FilteringSelfServiceStatus(param)
    );
    criteria_actions.SetCriteriaDailyPlan(param);
  };
  const hadleOnChange = (e) => {
    setValue(e.target.name, e.target.value);
    if (!validate(e.target.value)) return;
    criteria_actions.SetCriteriaDailyPlan(getValues());
    Search();
  };
  const dateChange = (e) => {
    if (!validate()) return;

    criteria_actions.SetCriteriaDailyPlan(getValues());
    Search();
  };
  const ownerChange = (e) => {
    setValue(e.target.name, e.target.value);
    criteria_actions.SetCriteriaSelfServiceStatus({
      ...getValues(),
      Owner: e.target.value,
    });
    Search();
  };
  function validate(choice = CurrentChoice) {
    let dateform_name, dateTo_name;
    if (choice === "1") {
      dateform_name = "ScheduledateFrom";
      dateTo_name = "ScheduledateTo";
    } else {
      dateform_name = "ResultdateFrom";
      dateTo_name = "ResultdateTo";
    }
    clearErrors();
    let valid = checkDateRange(
      getValues()[dateform_name],
      getValues()[dateTo_name]
    );
    if (!valid) {
      setError(dateform_name, {
        type: "manual",
        //message: `Invalid date range`,
      });
      setError(dateTo_name, {
        type: "manual",
        message: `Invalid date range`,
      });
      return false;
    }
    return true;
  }
  function disablechoice(param) {
    return !(CurrentChoice === param);
  }
  return (
    <>
      <Box pl={3} flexGrow={1}></Box>
      <Box display="flex" flexWrap="wrap" className="criteriaStyle">
        <RadioGroup
          row
          name="choice"
          value={CurrentChoice}
          onChange={hadleOnChange}
          style={{ paddingTop: "8px" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            ml={2}
            mr={2}
            className="criteriaRadioStyle"
          >
            <Box className="boxDate">
              <FormControlLabel
                key={1}
                value="1"
                control={<Radio />}
                label="Schedule"
              />
              <Box p={1} flexGrow={1}>
                <MaterialUIDatePickers
                  name="ScheduledateFrom"
                  label="Schedule Date From"
                  onChange={dateChange}
                  disabled={disablechoice("1")}
                />
              </Box>
              <Box p={1} flexGrow={1} className="dropDownStyle">
                <MaterialUIDatePickers
                  name="ScheduledateTo"
                  label="Schedule Date To"
                  onChange={dateChange}
                  disabled={disablechoice("1")}
                  minDate={criteria_state.DailyPlanCriteria.ScheduledateFrom}
                />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            ml={2}
            mr={2}
            className="criteriaRadioStyle"
          >
            <Box className="boxDate">
              <FormControlLabel
                key={2}
                value="2"
                control={<Radio />}
                label="Result"
              />
              <Box p={1} flexGrow={1}>
                <MaterialUIDatePickers
                  name="ResultdateFrom"
                  label="Result Date From"
                  onChange={dateChange}
                  disabled={disablechoice("2")}
                />
              </Box>

              <Box p={1} flexGrow={1} className="dropDownStyle">
                <MaterialUIDatePickers
                  name="ResultdateTo"
                  label="Result Date To"
                  onChange={dateChange}
                  disabled={disablechoice("2")}
                  minDate={criteria_state.DailyPlanCriteria.ResultdateFrom}
                />
              </Box>
            </Box>
          </Box>
        </RadioGroup>
        <Box
          flexBasis={"250px"}
          flexGrow={1}
          p={1}
          flexDirection="column"
          justifyContent="space-around"
          style={{ paddingBottom: "5px" }}
          className="boxCriteria"
        >
          <Box pb={1} style={{ paddingBottom: "8px" }}>
            <CheckboxExecutionType
              name="EXECUTION_TYPE_ID"
              onChange={filterdata}
            />
          </Box>

          <Box>
            <CheckboxPlanStatus name="PLAN_STATUS_ID" onChange={filterdata} />
          </Box>
        </Box>
      </Box>
      {/* <Box width={"200"}>
        Owner :{" "}
        <SelectOwnerList name="Owner" hasall={true} onChange={ownerChange} />
      </Box> */}
    </>
  );
};
export default withContext(withLoadingContext(SelfServiceStatus));
