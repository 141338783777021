import React from "react";
import { FormProvider } from "react-hook-form";
import { TargetTableDialog } from "./dialog-target-table";
import { TemplateMasterDialog } from "./dialog-template-master";
import { useTargetTable } from "./function";
//Material
import { Box } from "@mui/system";
import { GridToolbar } from "@mui/x-data-grid";
import { List, ListItem } from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
//Component
import useFilterbar from "stateless/filterbar/useFilterbar";
import { FilterManual, FilterShow } from "stateless/filterbar";
import { BaseContainer } from "stateless/base-container";
import { PurpleButton } from "stateless/button";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
//utils
import { titleCase } from "utils/common-function";
import { getFormattedItemList } from "utils/componentUtils";

const filter_field_list = ["GroupUser", "Module", "GroupReport"];
const filter_field_silgle_list = [];
const formatter = [];
const default_filter = {
  GroupUser: [],
  Module: [],
  GroupReport: [],
};
function Index() {
  const controlFilter = useFilterbar({
    fieldList: filter_field_list,
    defaultValues: default_filter,
    fieldSingle: filter_field_silgle_list,
    Formatter: formatter,
  });
  const methods = useTargetTable({ filter: controlFilter.data });
  const { formMethods } = methods;
  return (
    <>
      <FormProvider {...formMethods}>
        <BaseContainer>
          <Box display={"flex"} justifyContent="space-between">
            <FilterManual {...controlFilter} groupReport groupUser />{" "}
            <Box component="div" display="flex">
              <PurpleButton
                onClick={methods.state.controlDialogTemplate.clickOpen}
                startIcon={<SettingsIcon />}
              >
                TEMPLATE
              </PurpleButton>
            </Box>
          </Box>
          <Box my={1}>
            <FilterShow {...controlFilter} />
          </Box>
          <Table methods={methods} />
        </BaseContainer>
      </FormProvider>{" "}
      <TargetTableDialog
        controlDialog={methods.state.controlDialogDetail}
        methods={methods}
      />
      <TemplateMasterDialog
        {...methods.state.controlDialogTemplate}
        disableEnforceFocus
      />
    </>
  );
}

function Table({ methods }) {
  const { addRow, viewRow, deleteRow } = methods.actions;
  const { TargetTableList } = methods.state;

  
  const columns = [
    {
      field: "area_name",
      headerName: "Area",
      minWidth: 120,
      flex: 0,
    },
    {
      field: "table_name",
      headerName: titleCase("table_name"),
      minWidth: 300,
      flex: 0,
    },
    {
      field: "template_file_name",
      headerName: "Template File Name",
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          {getFormattedItemList(params)}
        </Box>
      ),
    },
    {
      field: "group_user",
      headerName: titleCase("group_user"),
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          {getFormattedItemList(params)}
        </Box>
      ),
    },
    {
      field: "report_group",
      headerName: "Group Report",
    },
    {
      field: "edit",
      headerName: "EDIT",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => viewRow(params.row)}>EDIT</PurpleButton>
        </Box>
      ),
    },
    {
      field: "delete",
      headerName: "DELETE",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => deleteRow(params.row)}>
            Delete
          </PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={TargetTableList}
      customColumn={{
        all: db_col,
        custom_col: columns,
        date: [],
        date_time: [],
      }}
      getRowHeight={() => "auto"}
      getRowId={(row) => row.table_id}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "Add Target Table",
          buttonclick: addRow,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col = [
  "area_name",
  "table_name",
  "template_file_name",
  "group_user",
  "report_group",
];
export default Index;
