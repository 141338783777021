import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
  Controller,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
//Material
import Box from "@material-ui/core/Box";
import Link from "@mui/material/Link";
//component
import { BaseContainer } from "stateless/base-container";
import EditIcon from "@material-ui/icons/Edit";
import { Modal } from "stateless/modal";
import PopupDetail from "./popup-detail";
import PopupEdit from "./popup-edit-list";
import { MenuPopupState } from "stateless/popup-button";
import { CMDataGrid } from "stateless/data-grid";
//context
import { DatasetContext, withContext } from "./dataobject-context";
//utils
import { isEmptyArray, isEmptyStr, titleCase } from "utils/common-function";
import { ConfirmCommon, inform_success } from "utils/alert";
import { useSelectItem } from "stateless/react-table/useSelectItem";
import axios from "axios";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal.js";
import { styleTextLink } from "utils/style";
import { CreateMuiColumnObj } from "utils/mui-table";
import { SelectTemplate } from "../stateless-datacatalog/select";
const { log } = console;
//const
const db_col = [
  "status_text",
  "is_edit",
  "action",
  "property_count",
  "sheet_name",
  "schema_name",
  "object_name",
  "description",
  "version",
  "published_by",
  "published_datetime",
  "created_by",
  "created_date",
  "updated_date",
  "updated_by",
];
const date_col = [];
const datetime_col = ["published_datetime", "created_date", "updated_date"];

//api
export const LoadVersionList = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/LoadVersionList`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

export const api_LoadDatasetSbx = async (param = {}) => {
  let url = `${API_URL.Report}/api/Report/LoadDatasetSbx`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const UpdateStatus = async (param = { Dataset_Ids: [], Status: "" }) => {
  let url = `${API_URL.Report}/api/Report/UpdateStatus`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
export const UpdateStatusVersion = async (param) => {
  let url = `${API_URL.Report}/api/Report/UpdateStatusVersion`;
  let { result, error } = await common.to(axios.post(url, param));
  return [result?.data, error];
};

//
//
//SCREEN
//
//
function DataVersionList() {
  const [selectionModel, setSelectionModel] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const methods = useForm({ defaultValues: { Template: "1" } });

  const [ObjList, setObjList] = useState([]);
  const { getValues, setValue, control } = methods;
  const {
    isOpen: isOpenDetail,
    close: closeCetail,
    Data: datarowDetail,
    handleClickRow,
  } = useSelectRowOpenModal();
  const {
    isOpen: isOpenEdit,
    close: closeEdit,
    Data: datarowEdit,
    handleClickRow: handleClickEdit,
  } = useSelectRowOpenModal();
  const template_id = useWatch({ control, name: "Template" });
  const GetDataList = useCallback(async () => {
    let param = { template_id: template_id?.toString() };
    let [data] = await LoadVersionList(param);
    setObjList(data);
  }, [template_id]);
  useEffect(() => {
    GetDataList();
  }, [GetDataList]);

  const col = useMemo(() => {
    let custom_col = [
      {
        field: "object_name",
        headerName: "Object Name",
        renderCell: (params) => (
          <Link onClick={() => handleClickRow(params["row"])} href="#">
            {params.value}
          </Link>
        ),
      },
      {
        field: "status_text",
        headerName: "Status",
        minWidth: 100,
      },
      {
        field: "action",
        headerName: "Edit",
        align: "center",
        minWidth: 50,
        flex: 0,
        sortable: false,
        renderCell: (params) => (
          <Box component="div" display="flex" justifyContent="center">
            <EditIcon
              color="action"
              //className={classes.icon}
              onClick={() => {
                handleClickEdit(params["row"]);
              }}
            />
          </Box>
        ),
      },
      {
        field: "is_edit",
        headerName: "Editing",
        minWidth: 100,
        valueGetter: (params) => (params.row.is_edit ? "yes" : "No"),
      },
      {
        field: "published_datetime",
        headerName: "Published Date",
      },
      {
        field: "property_count",
        type: "number",
        valueGetter: (params) => params.row.property_count || 0,
        minWidth: 120,
      },
    ];
    return CreateMuiColumnObj(db_col, date_col, datetime_col, custom_col);
  }, [handleClickRow, handleClickEdit]);
  async function UpdateStatus(status) {
    if (!(await ConfirmCommon("Confirm Change to " + status))) return;
    let selected = selectionModel.map((el) => ({ data_object_id: el }));
    let param = { Status: status, versions: selected };
    let [res, err] = await UpdateStatusVersion(param);
    if (err) return;
    await inform_success("Status changed.");
    await GetDataList();
    setSelectionModel([]);
  }
  const lst_btn = [
    {
      name: "Publish",
      method: () => {
        UpdateStatus("Published");
      },
    },
    {
      name: "Unlisted",
      method: () => {
        UpdateStatus("Unlisted");
      },
    },
    // {
    //   name: "Outdated",
    //   method: () => {
    //     UpdateStatus("Outdated");
    //   },
    // },
  ];
  return (
    <div>
      <BaseContainer>
        <FormProvider {...methods}>
          <Box my={1} display="flex" justifyContent={"flex-end"}>
            <Box style={{ width: "150" }} mr={1}>
              <SelectTemplate name="Template" />
            </Box>

            <MenuPopupState
              items={lst_btn}
              disabled={isEmptyArray(selectionModel)}
            >
              Change Status
            </MenuPopupState>
          </Box>
          <CMDataGrid
            rows={ObjList || []}
            getRowId={(row) => row.data_object_id}
            customColumn={col}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
          />
          <Box style={{ width: "100%" }}></Box>
        </FormProvider>
      </BaseContainer>
      <PopupDetail
        open={isOpenDetail}
        onClose={closeCetail}
        data={datarowDetail}
      />
      <PopupEdit open={isOpenEdit} onClose={closeEdit} data={datarowEdit} />
    </div>
  );
}

export default withContext(DataVersionList);
