import { Box } from "@mui/system";
import React from "react";

function InboundCriteriaBox({ children }) {
  return (
    <Box
      sx={{ backgroundColor: "#594F74", color: "#fff" }}
      p={2}
      display="flex"
    >
      <Box mr={3} />
      {children}
    </Box>
  );
}

export default InboundCriteriaBox;
