import React from "react";
import { FormProvider } from "react-hook-form";
import { ReconcileReportDialog } from "./dialog-reconcile-report";
import { useReconcileReport } from "./function";
//Material
import { Box } from "@mui/system";
import { GridToolbar } from "@mui/x-data-grid";
//Component
import useFilterbar from "stateless/filterbar/useFilterbar";
import { FilterManual, FilterShow } from "stateless/filterbar";
import { BaseContainer } from "stateless/base-container";
import { PurpleButton } from "stateless/button";
import { CMDataGrid2 } from "stateless/data-grid/basic";
import { CustomInboundFooterOneButtonComponent } from "stateless/data-grid/footer/inbound-one-button-footer";
//utils
import { titleCase } from "utils/common-function";
import { getFormattedItemList } from "utils/componentUtils";

const filter_field_list = ["GroupUser", "Module", "GroupReport"];
const filter_field_silgle_list = [];
const formatter = [];
const default_filter = {
  GroupUser: [],
  Module: [],
  GroupReport: [],
};
function Index() {
  const controlFilter = useFilterbar({
    fieldList: filter_field_list,
    defaultValues: default_filter,
    fieldSingle: filter_field_silgle_list,
    Formatter: formatter,
  });
  const methods = useReconcileReport({ filter: controlFilter.data });
  const { formMethods } = methods;
  return (
    <>
      <FormProvider {...formMethods}>
        <BaseContainer>
          <Box display={"flex"}>
            <FilterManual {...controlFilter} groupReport />
          </Box>
          <Box my={1}>
            <FilterShow {...controlFilter} />
          </Box>
          <Table methods={methods} />
        </BaseContainer>
      </FormProvider>{" "}
      <ReconcileReportDialog
        controlDialog={methods.state.controlDialogDetail}
        methods={methods}
      />
    </>
  );
}

function Table({ methods }) {
  const { addRow, editRow, deleteRow } = methods.actions;
  const { ReconcileReportList } = methods.state;
  const columns = [
    {
      field: "area_name",
      headerName: "Area",
    },
    {
      field: "report_group",
      headerName: "Group Report",
    },
    {
      field: "report_name",
      headerName: titleCase("report_name"),
      minWidth: 350,
      flex: 0,
    },
    {
      field: "group_user",
      headerName: titleCase("group_user"),
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          {getFormattedItemList(params)}
        </Box>
      ),
    },
    {
      field: "edit",
      headerName: "EDIT",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => editRow(params.row)}>Edit</PurpleButton>
        </Box>
      ),
    },
    {
      field: "delete",
      headerName: "DELETE",
      align: "center",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <Box component="div" display="flex" justifyContent="center">
          <PurpleButton onClick={() => deleteRow(params.row)}>
            Delete
          </PurpleButton>
        </Box>
      ),
    },
  ];
  return (
    <CMDataGrid2
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      rows={ReconcileReportList}
      getRowHeight={() => "auto"}
      customColumn={{
        all: db_col,
        custom_col: columns,
        date: [],
        date_time: [],
      }}
      getRowId={(row) => row.id}
      components={{
        Footer: CustomInboundFooterOneButtonComponent,
        Toolbar: GridToolbar,
      }}
      componentsProps={{
        footer: {
          button_name: "Add Reconcile Report",
          buttonclick: addRow,
        },
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
    />
  );
}
const db_col = [
  "area_name",
  "report_group",
  "schema_name",
  "report_name",
  "display_name",
];
export default Index;
