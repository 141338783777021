import React, { useState, useEffect } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
//Material
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
//Component
import { ReactTable } from "../../../../stateless/react-table";
import { Modal } from "../../../../stateless/modal";
import { getColumPlanDetail } from "../columns/popup-plan-detail";
import { getColumn } from "../columns/popup-daily-reprocess-detail";
import MuiRadio from "../../../../stateless/hook-controls/radio";
import FormCheckBox from "../../../../stateless/hook-controls/checkbox";
//Function
import * as api from "../api";
import { Typography } from "@material-ui/core";
import { buildParameter } from "../parameter";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import * as alertDialog from "utils/alert";
let { log: c } = console;
const validOptions = [
  { label: "Valid", value: "0" },
  { label: "Invalid", value: "1" },
];

function SetInvalidModal(props) {
  const methods = useForm({ defaultValues: { IncludeImpacted: true } });
  const { data, onClose } = props;
  async function Save() {
    if(methods.getValues().Invalid === undefined){
      await alertDialog.inform_error("Please select Valid or Invalid.");
      return;
    }
    let param = buildParameter.InvalidatePlanParam(methods.getValues(), data);
    let res = await api.InvalidatePlans(param);
    if (!res) return;
    let old_sts = param.Invalid ? "SUCCESS" : "INVALID";
    let new_sts = param.Invalid ? "INVALID" : "SUCCESS";
    let txt =
      res.length > 0
        ? `${res.length} ${old_sts} plans successfully set to ${new_sts}`
        : `No ${old_sts} plans selected.`;
    await alertDialog.Inform(txt);
    methods.setValue("Invalid",undefined);
    onClose();
  }
  return (
    <>
      <FormProvider {...methods}>
        <Modal {...props} title={`InvalidatePlans`} onSave={Save}>
          {/* <Typography>{JSON.stringify(data)}</Typography> */}
          <Box display={'flex'} alignItems="center">
            <Box display="block" bgcolor="background.paper">
              <MuiRadio
                name="Invalid"
                options={validOptions}
              ></MuiRadio>
            </Box>
            <Box ml={3} m={1} p={2}>
              <FormCheckBox
                key="IncludeImpacted"
                name="IncludeImpacted"
                label="IncludeImpacted"
              />
            </Box>
          </Box>
        </Modal>
      </FormProvider>
    </>
  );
}

export default SetInvalidModal;
