import * as XLSX from "xlsx";
export function readFile(file, callback) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = callback;
  //XLSX.read
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}
export function GetTemplate(callback, path) {
  var url = window.location.origin + path;
  var oReq = new XMLHttpRequest();
  oReq.open("GET", url, true);
  oReq.responseType = "arraybuffer";
  oReq.onload = function (e) {
    var arraybuffer = oReq.response;
    /* convert data to binary string */
    var data = new Uint8Array(arraybuffer);
    var arr = [];
    for (var i = 0; i !== data.length; ++i)
      arr[i] = String.fromCharCode(data[i]);
    var bstr = arr.join("");
    /* Call XLSX */
    var workbook = XLSX.read(bstr, {
      type: "binary",
    });

    /* DO SOMETHING WITH workbook HERE */
    var first_sheet_name = workbook.SheetNames[0];
    /* Get worksheet */
    var worksheet = workbook.Sheets[first_sheet_name];
    // console.log(XLSX.utils.sheet_to_json(worksheet, {
    //     raw: true
    // }));
    if (callback) callback(worksheet);
  };

  oReq.send();
}
// Given Column to Number
export function colToNumber(str) {
  var num = 0;
  var i = 0;
  while (i < str.length) {
    num = str[i].charCodeAt() - 64 + num * 26;
    i++;
  }
  return num;
}
//Given Number to Column name
export function numberToCol(num) {
  var str = "",
    q,
    r;
  while (num > 0) {
    q = (num - 1) / 26;
    r = (num - 1) % 26;
    num = Math.floor(q);
    str = String.fromCharCode(65 + r) + str;
  }
  return str;
}
