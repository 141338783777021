import axios from "axios";
import { API_URL } from "utils/api-utils";
import { common } from "utils";
//Master
export const GetListUserMaintenance = async (param) => {
  let url = `${API_URL.Lobby}/api/Portal/GetListUserMaintenance`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const DeleteUser = async (param) => {
  let url = `${API_URL.Lobby}/api/Portal/DeleteUser`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const SaveUser = async (param) => {
  let url = `${API_URL.Lobby}/api/Portal/SaveUser`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};

export const checkDuplicateUser = async (param) => {
  let url = `${API_URL.Lobby}/api/Portal/checkDuplicateUser`;
  let { result } = await common.to(axios.post(url, param));
  if (result && result.data) return result.data;
};
