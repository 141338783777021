import {
    createContext,
    useEffect,
    useState,
    useContext,
    useReducer,
  } from "react";
  import moment from "moment";

  const getActions = (dispatch) => {
    return {
      SetDatasetList: (param) => {
          dispatch({ type: action_type.SetDatasetList, param });
      },
      SetDatasetDetail: (param) => {
        dispatch({ type: action_type.SetDatasetDetail, param });
    },
    }
  };
  //State
  const initial_state = {
    DatasetList:[],
    DatasetDetail:{}
  };
  //1.Context
  export const DatasetContext = createContext({
    state: initial_state,
    actions: getActions(),
  });
  //2.Provider
  export const DatasetContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initial_state);
    const [actions] = useState(getActions(dispatch));
    //Method//
    return (
      <DatasetContext.Provider value={{ state: state, actions: actions }}>
        {children}
      </DatasetContext.Provider>
    );
  };
  export function withContext(Component) {
    return function (...props) {
      return (
        <DatasetContextProvider>
          <Component {...props} />
        </DatasetContextProvider>
      );
    };
  }
  //3.Reducer
  export const action_type = {
      SetDatasetList: "SetDatasetList",
      SetDatasetDetail:"SetDatasetDetail",
  };
  const Reducer = (state = initial_state, action) => {
    switch (action.type) {
      case action_type.SetDatasetList:
        return {
          ...state,
          DatasetList: action.param,
        };
        case action_type.SetDatasetDetail:
        return {
          ...state,
          DatasetDetail: action.param,
        };
      default:
        break;
    }
    return state;
  };
  