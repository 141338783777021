import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
export const getMaintenanceColumns = (editData, deleteData) => {
  const columns = [
    {
      dataField: "OP2",
      text: "",
      //sort: true,

      // events: onClickCol,
      formatter: (cell, row) => {
        return (
          <Box component="div" display="flex" justifyContent="center">
            <EditIcon
              color="action"
              onClick={() => {
                editData(row);
              }}
            />
            <DeleteIcon
              color="action"
              onClick={() => {
                deleteData(row.GROUP_BU);
              }}
            />
          </Box>
        );
      },
    },
    {
      dataField: "GROUP_BU",
      text: "BU ",
      sort: true,
    },
    {
      dataField: "ACTIVE",
      text: "Active",
      sort: true,
    }
  ];
  return columns;
};
export const getUserColumns = (AddUser) => {
  const columns = [
    {
      dataField: "OP2",
      text: "",
      //sort: true,

      // events: onClickCol,
      formatter: (cell, row) => {
        return (
          <Box component="div" display="flex" justifyContent="center">
            <AddIcon
              color="action"
              onClick={() => {
                AddUser(row.USER_ID);
              }}
            />
          
          </Box>
        );
      },
    },
    {
      dataField: "USER_ID",
      text: "USER ID",
      sort: true,
    },
    {
      dataField: "GROUP_BU",
      text: "BU",
      sort: true,
    },

  ];
  return columns;
};

export const getBuUserColumns = (DelUser) => {
  const columns = [
    {
      dataField: "OP2",
      text: "",
      //sort: true,

      // events: onClickCol,
      formatter: (cell, row) => {
        return (
          <Box component="div" display="flex" justifyContent="center">
            <DeleteIcon
              color="action"
              onClick={() => {
                DelUser(row.USER_ID);
              }}
            />
          
          </Box>
        );
      },
    },
    {
      dataField: "USER_ID",
      text: "USER ID",
      sort: true,
    }

  ];
  return columns;
};