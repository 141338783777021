import React, { useEffect, useState, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
//Material
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
//Component
import { BoxTemplate1 } from "stateless/Box";
import { Modal } from "stateless/modal";
import { ReactTable } from "stateless/react-table";
import CheckboxJobtype from "stateless/selection-control/checkbox/jobtype";
import FormInput from "stateless/hook-controls/input";
import SelfServiceJobMaster from "apps/job-monitoring/job-execution-plan/SelfService/dialog-job-master/self-service-job-master";
//Context
import { ExecutionPlanContext } from "../../../job-execution-plan-context";
import { LoadingContext } from "context/loading-context";
//Functions
import { useSelectRowOpenModal } from "apps/main/function-api/useSelectRowOpenModal";
import { getExecutionJobsColumns } from "../../../columns/execution-jobs";
import { getJobsColumns } from "../../../columns/master-jobs";
import { setDataToFormHookV2, objectsAreSame } from "utils/common-function";
import { GetUser } from "utils/sessions";
import { buildParameter } from "../../../parameter";
import * as api from "../../../api";
import * as alertDialog from "utils/alert";
import { common } from "utils";

function PlanSelfServiceJobItems() {
  //Context
  const { state, actions } = useContext(ExecutionPlanContext);
  const Loadingcontext = useContext(LoadingContext);
  //State
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [JobDetail, setJobDetail] = useState({});
  //Function
  const EditJob = (Jobdetail) => {
    setOpenEditModal(true);
    setJobDetail(Jobdetail);
  };
  const DeleteJob = async (id) => {
    let res = await alertDialog.ConfirmDelete();
    if (!res) return;

    let param = {
      SESSION_ID: state.PlanDetail.SESSION_ID,
      SESSION_OWNER: GetUser(),
      ITEM_ID: id,
    };
    let api_res = await Loadingcontext.actions.withLoading(() =>
      actions.DeleteJob(param)
    );
    if (!api_res) return;
    alertDialog.del_success();
    Loadingcontext.actions.withLoading(() =>
      actions.GetSBXPlanJobDetailList({
        SESSION_ID: state.PlanDetail.SESSION_ID,
      })
    );
  };
  //Event
  const onOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const onCloseAddModal = () => {
    setOpenAddModal(false);
  };
  const onCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const MoveUp = (id) => {
    actions.MoveUp(id);
  };
  const MoveDown = (id) => {
    actions.MoveDown(id);
  };
  const SaveJobSequence = async () => {
    let param = buildParameter.UpdateJobSequenceSbxParam(
      state.PlanDetail,
      state.JobItemsListTemp
    );
    Loadingcontext.actions.withLoading(() =>
      actions.UpdateJobItemsSequence(param)
    );
    let paramJoblist = buildParameter.GetSBXPlanJobDetailList(state.PlanDetail);
    actions.GetSBXPlanJobDetailList(paramJoblist);
  };
  const RevertSequence = () => {
    actions.RevertSequence();
  };
  const SequenceHasChanged = () => {
    return objectsAreSame(state?.JobItemsListTemp, state?.JobItemsList);
  };
  const isRerun = () => {
    return state.PlanDetail.EXECUTION_PLAN_TYPE_ID === 2;
  };
  return (
    <div>
      <BoxTemplate1
        title="Jobs in This Plan"
        add={!isRerun()}
        onClickAdd={onOpenAddModal}
        height={600}
      >
        <Box display="flex" flexDirection="row" style={{ marginBottom: "5px" }}>
          <Box m={2} className="btnstyle">
            <Button
              variant="contained"
              disabled={SequenceHasChanged()}
              onClick={SaveJobSequence}
            >
              Save Sequence
            </Button>
          </Box>
          <Box m={2} className="btnstyle">
            <Button
              variant="contained"
              disabled={SequenceHasChanged()}
              onClick={RevertSequence}
            >
              Revert
            </Button>
          </Box>
        </Box>

        <ReactTable
          style={{ margin: "0 5px" }}
          keyField="ITEM_ID"
          data={state?.JobItemsListTemp}
          columns={getExecutionJobsColumns(
            EditJob,
            DeleteJob,
            MoveUp,
            MoveDown,
            isRerun()
          )}
        />
      </BoxTemplate1>
      <AddJobModal open={openAddModal} onClose={onCloseAddModal} />
      <EditJobModal
        open={openEditModal}
        onClose={onCloseEditModal}
        jobdetail={JobDetail}
      />
    </div>
  );
}

function AddJobModal(props) {
  const { state, actions } = useContext(ExecutionPlanContext);
  const controlDialogSelfServiceJobMaster = useSelectRowOpenModal();
  const Loadingcontext = useContext(LoadingContext);
  const method = useForm();
  const [SelectedJob, setSelectedJob] = useState([]);
  const [waiting, setWaiting] = useState(true);
  useEffect(() => {
    const load = async () => {
      if (!props.open) return;
      setSelectedJob([]);
      setWaiting(true);
      await actions.GetSelfServiceJobMasterList();
      setWaiting(false);
    };
    load();
  }, [props.open, actions]);
  const AddJob = async () => {
    let param = buildParameter.AddJobItems(state.PlanDetail, SelectedJob);
    await Loadingcontext.actions.withLoading(() =>
      actions.AddJobToPlanSbx(param)
    );
    let paramJoblist = buildParameter.GetSBXPlanJobDetailList(state.PlanDetail);
    await actions.GetSBXPlanJobDetailList(paramJoblist);
    props.onClose();
  };
  const onFilterJobList = (e) => {
    let param = method.getValues();
    if (param?.JOB_TYPE_ID === false) {
      param.JOB_TYPE_ID = [];
    }
    common.reCheckbox(param, e);
    actions.FilteringJobList(param);
  };
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedJob((prev) => [...prev, row]);
    } else {
      setSelectedJob((prev) => prev.filter((x) => x !== row));
    }
  };
  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelectedJob(rows);
    } else {
      setSelectedJob([]);
    }
  };
  const tablecomponentAddJobMaster = () => {
    return (
      <>
        <Box m={3} className="btnstyle">
          <Button
            variant="contained"
            onClick={() => controlDialogSelfServiceJobMaster.handleClickRow()}
          >
            Add Job Master
          </Button>
        </Box>
      </>
    );
  };
  return (
    <div>
      <Modal {...props} onSave={AddJob} title="Add Jobs " maxWidth="xl">
        <FormProvider {...method}>
          <Box
            display="block"
            bgcolor="background.paper"
            style={{ margin: "0 5px" }}
          >
            <CheckboxJobtype name="JOB_TYPE_ID" onChange={onFilterJobList} />
          </Box>
          {waiting ? "Loading.." : null}
          <ReactTable
            headcomponent={tablecomponentAddJobMaster}
            clickToSelect
            keyField="JOB_ID"
            columns={getJobsColumns()}
            data={state.FilterJoblist}
            handleOnSelect={handleOnSelect}
            handleOnSelectAll={handleOnSelectAll}
          />
          <br></br>
        </FormProvider>
        <SelfServiceJobMaster
          controlDialog={controlDialogSelfServiceJobMaster}
          refreshList={actions.GetSelfServiceJobMasterList}
        />
      </Modal>
    </div>
  );
}
function EditJobModal(props) {
  const method = useForm();
  const { setValue } = method;
  const Loadingcontext = useContext(LoadingContext);
  const { jobdetail, ...otherprops } = props;
  const { open } = otherprops;
  const { actions, state } = React.useContext(ExecutionPlanContext);
  React.useEffect(() => {
    let data = setDataToFormHookV2(jobdetail);
    setTimeout(() => data?.forEach(({ name, value }) => setValue(name, value)));
  }, [jobdetail, setValue, open]);
  const Save = async (e) => {
    let jobdtl = { ...jobdetail, ...method.getValues() };
    let param = buildParameter.SavePlanJobDetailSbx(state.PlanDetail, jobdtl);
    await Loadingcontext.actions.withLoading(() => api.EditJobDetail(param));
    let paramJoblist = buildParameter.GetSBXPlanJobDetailList(state.PlanDetail);
    await actions.GetSBXPlanJobDetailList(paramJoblist);
    props.onClose();
  };

  return (
    <>
      <Modal {...otherprops} onSave={Save} title="Edit job detail">
        <FormProvider {...method}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={2}>
              <Typography>Job Id</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>{jobdetail.JOB_ID}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Job Name</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>{jobdetail.JOB_NAME}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Job Type</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>{jobdetail?.TYPE_TEXT}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Parameters</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormInput name="PARAMETERS" label="Parameters" />
            </Grid>
          </Grid>
        </FormProvider>
      </Modal>
    </>
  );
}
export default PlanSelfServiceJobItems;
