import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import "./dashboard.css";
// Util
import { common } from "utils";
//Material
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from "@mui/icons-material/Warning";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { SearchOutlined } from '@material-ui/icons';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DataGrid } from '@mui/x-data-grid';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SchemaIcon from '@mui/icons-material/Schema';
//Component
import { ReactTable } from "../../../stateless/react-table";
import { BaseContainer } from "../../../stateless/base-container";
import { MaterialUIDatePickers } from "../../../stateless/hook-controls/datepicker/datepicker";
import CheckboxPlanStatus from "../../../stateless/selection-control/checkbox/planstatus";
import CheckboxPlanType from "../../../stateless/selection-control/checkbox/plantype";

function Dashboard() {
  const methods = useForm();
  return (
    <BaseContainer>
      <Grid container className="gridCriteria">
        <Grid item xs={4}>
          <Grid container spacing={2} className="cardTile">
            <Grid item xs={12} paddingBottom={"10px"}>
              <Card className="card1">
                <CardContent>
                  <Box>
                    <Typography sx={{ fontSize: 20 }} gutterBottom>
                      All 71,245 Plan
                    </Typography>
                    <Typography variant="body2">Running 15%</Typography>
                  </Box>
                  <IconButton>
                    <InsertChartIcon fontSize="20px" />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className="card2">
                <CardContent>
                  <Box>
                    <Typography>Stream Name</Typography>
                    <Typography sx={{ fontSize: 20 }}>Success 40%</Typography>
                  </Box>
                  <IconButton>
                    <WarningIcon fontSize="20px" />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className="card3">
                <CardContent>
                  <Box>
                    <Typography>Stream Name</Typography>
                    <Typography sx={{ fontSize: 20 }}>Error 15%</Typography>
                  </Box>
                  <IconButton>
                    <CheckCircleIcon fontSize="20px" />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <TextField 
                  fullWidth
                  id="standard-bare"
                  variant="outlined"
                  defaultValue="Search"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
              />
            </Grid>
          </Grid>
        </Grid>
        <FormProvider {...methods}>
          <Grid item xs={3}>
            <Card className="cardDateTime">
              <CardContent>
                <Typography
                  display={"flex"}
                  sx={{ fontSize: 18 }}
                  color="#3361FF"
                  gutterBottom
                >
                  <CalendarMonthIcon /> Date Time
                </Typography>
                  <CriteriaDate/>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={5}>
            <Card className="cardCategory">
              <CardContent>
                <Typography
                  display={"flex"}
                  sx={{ fontSize: 18 }}
                  color="#3361FF"
                  gutterBottom
                >
                  <FilterAltIcon /> Category
                </Typography>
                <Criteria />
              </CardContent>
            </Card>
          </Grid>
        </FormProvider>
      </Grid>
      <Grid container className="gridTable">
        <Grid item xs={12} pb={"20px"}>
          <TableData/>
        </Grid>
        <Grid item xs={6} pr={"10px"}>
          <TableDependencies/>
        </Grid>
        <Grid item xs={6} pl={"10px"}>
          <TableProcess/>
        </Grid>
      </Grid>
      
    </BaseContainer>
  );
}
function Criteria(params) {
  const methods = useFormContext();
  const { getValues, setValue, control } = methods;
  const filterdata = (e) => {
    let param = getValues();
    //common.reCheckbox(getValues(), e);
    // load_actions.withLoading(() =>
    //   context.actions.FilteringExecutionPlan(param)
    // );
    // context.actions.SetCriteriaSearch(param);
  };
  return (
    <>
      <Box mb={"20px"} mt={"20px"}>
        <CheckboxPlanType name="EXECUTION_PLAN_TYPE_ID" onChange={filterdata} />
      </Box>
      <Box mb={"20px"}>
        <CheckboxPlanStatus
            name="EXECUTION_PLAN_STATUS_ID"
            onChange={filterdata}
          />
      </Box>
      <Box textAlign={"right"}>
        <Button variant="contained">
            Confirm
        </Button>
      </Box>
    </>
  );
}

function CriteriaDate(params) {
  const methods = useFormContext();
  const { getValues, setValue, control } = methods;
  return (
    <>
      <Box mb={"20px"}>
        <Typography>Data Date From</Typography>
        <MaterialUIDatePickers
          name="DateFrom"
          //onChange={dateChange}
        />
        <Typography textAlign={"center"}>-</Typography>
        <Typography>Data Date To</Typography>
        <MaterialUIDatePickers
          name="DateTo"
          //onChange={dateChange}
        />
      </Box>
      <Box textAlign={"right"}>
        <Button variant="contained">
            Confirm
        </Button>
      </Box>
    </>
  );
}
function TableData(){
  const rows = [
    { id: 1, col1: 'EDP_ADAMS', col2: '260', col3: 'EDP_ADAMS', col4: '260', col5: 'EDP_ADAMS', col6: '260', col7: '260', col8: '260' },
    { id: 1, col1: 'EDP_ADAMS', col2: '260', col3: 'EDP_ADAMS', col4: '260', col5: 'EDP_ADAMS', col6: '260', col7: '260', col8: '260' },
  ];
  
  const columns = [
    { field: 'col1', headerName: 'Stream Name',flex: 1, minWidth: 50, },
    { field: 'col2', headerName: 'ID',width: 200,},
    { field: 'col3', headerName: 'Data Date',width: 200, },
    { field: 'col4', headerName: 'Start',width: 200, },
    { field: 'col5', headerName: 'End',width: 200,},
    { field: 'col6', headerName: 'Est. Finish',width: 200, },
    { field: 'col7', headerName: 'Status',width: 200,},
    { field: 'col8', headerName: 'Ramark',flex: 1,minWidth: 50, },
  ];
  return (
    <Card>
      <CardContent>
        <Typography
          display={"flex"}
          sx={{ fontSize: 18 }}
          color="#3361FF"
          gutterBottom
        >
          <LeaderboardIcon /> Data
        </Typography>
        <div style={{ width: '100%' }}>
          <div style={{ height: 350, width: '100%' }}>
            <DataGrid rows={rows} columns={columns}  getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd' } />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
function TableDependencies(){
  const rows = [
    { id: 1, col1: 'EDP_ADAMS', col2: '260', col3: 'EDP_ADAMS', col4: '260', col5: 'EDP_ADAMS', col6: '260', col7: '260' },
    { id: 1, col1: 'EDP_ADAMS', col2: '260', col3: 'EDP_ADAMS', col4: '260', col5: 'EDP_ADAMS', col6: '260', col7: '260' },
  ];
  
  const columns = [
    { field: 'col1', headerName: 'Dependencies Name',flex: 1, minWidth: 200, },
    { field: 'col2', headerName: 'ID',width: 100,},
    { field: 'col3', headerName: 'Data Date',width: 200, },
    { field: 'col4', headerName: 'Start',width: 100, },
    { field: 'col5', headerName: 'End',width: 100,},
    { field: 'col6', headerName: 'Status',width: 200,},
    { field: 'col7', headerName: 'Ramark',flex: 1,minWidth: 200, },
  ];
  return (
    <Card>
      <CardContent>
        <Box className="headerTable">
          <Typography
            display={"flex"}
            sx={{ fontSize: 18 }}
            color="#3361FF"
            gutterBottom
          >
            <SchemaIcon /> Dependencies
          </Typography>
          <Typography color="#6B7A99" >Stream Name : - </Typography>
        </Box>
        <div style={{ width: '100%' }}>
          <div style={{ height: 350, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd' } />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
function TableProcess(){
  const rows = [
    { id: 1, col1: 'EDP_ADAMS', col2: '260', col3: 'EDP_ADAMS', col4: '260', col5: 'EDP_ADAMS', col6: '260', col7: '260' },
    { id: 1, col1: 'EDP_ADAMS', col2: '260', col3: 'EDP_ADAMS', col4: '260', col5: 'EDP_ADAMS', col6: '260', col7: '260' },
  ];
  
  const columns = [
    { field: 'col1', headerName: 'Process Name',flex: 1, minWidth: 200, },
    { field: 'col2', headerName: 'ID',width: 100,},
    { field: 'col3', headerName: 'Data Date',width: 200, },
    { field: 'col4', headerName: 'Start',width: 100, },
    { field: 'col5', headerName: 'End',width: 100,},
    { field: 'col6', headerName: 'Status',width: 200,},
    { field: 'col7', headerName: 'Ramark',flex: 1,minWidth: 200, },
  ];
  return (
    <Card>
      <CardContent>
      <Box className="headerTable">
          <Typography
            display={"flex"}
            sx={{ fontSize: 18 }}
            color="#3361FF"
            gutterBottom
          >
            <SchemaIcon /> Process
          </Typography>
          <Typography color="#6B7A99" >Stream Name : - </Typography>
        </Box>
        <div style={{ width: '100%' }}>
          <div style={{ height: 350, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd' } />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
export default Dashboard;
