export const getMasterColumns = () => {
  const columns = [
    {
      dataField: "JOB_ID",
      text: "Stream ID",
      sort: true,
    },
    {
      dataField: "JOB_NAME",
      text: "Stream Name",
      sort: true,
    },
    {
      dataField: "TYPE_TEXT",
      text: "Stream Type",
      sort: true,
    },
    {
      dataField: "ORIGIN",
      text: "ORIGIN",
      sort: true,
    },
  ];
  return columns;
};
