import React, { useState, useEffect, useContext } from "react";
//Material
import Box from "@material-ui/core/Box";
//Component
import { ReactTable } from "stateless/react-table";
import { Modal } from "stateless/modal";
import { getColumnDependecyIgnore } from "../../../columns/popup-dependecy-ignore";
//Context
import { ReprocessContext } from "../../../reprocess-context";
import ModalPlanDetail from "../../../modal/modal-plan-detail";
//Function
import { useSelectItem } from "stateless/react-table/useSelectItem";
import * as api from "../../../api";
import { buildParameter } from "../../../parameter";
import { useSelectRowOpenModal } from "../../../../function-api/useSelectRowOpenModal.js";

const rowStyle = (row, rowIndex) => {
    return { backgroundColor: row.STATUS_COLOR };
};
function ImapactModal(props) {
    const { data, reprocessid, onClose } = props;
    const [checked_plan, setchecked_plan] = useState([]);
    const { actions, state } = useContext(ReprocessContext)
    useEffect(() => {
        setchecked_plan(data?.filter((el) => el.CHECKED)?.map((el) => el.PLAN_ID));
    }, [data, setchecked_plan]);

    const { handleOnSelect, handleOnSelectAll, SelectedItems } = useSelectItem(
        "PLAN_ID",
        checked_plan
    );
    const selectRow = {
        selected: SelectedItems || [], // should be a row keys array
    };
    async function Save() {
        let param = buildParameter.SaveReprocessDependencyPlansParam(
            reprocessid,
            data,
            SelectedItems
        );
        if (await api.SaveReprocessDependencyIgnore(param)) return;
        onClose();
    }
    const viewPlan = async (row) => {
        clickDetail(row);
    };

    const {
        isOpen: isOpenDetail,
        close: closeDetail,
        Data: dataDetail,
        handleClickRow: clickDetail,
    } = useSelectRowOpenModal();

    return (
        <>
            <Modal {...props} title={`Dependencies`} maxWidth="xl" onSave={Save} className="headerModalDependencyDetail">
                <Box display="block" bgcolor="background.paper">
                    <ReactTable
                        exportCsv
                        rowStyle={rowStyle}
                        keyField="PLAN_ID"
                        data={data || []}
                        columns={getColumnDependecyIgnore(viewPlan)}
                        selectOptions={selectRow}
                        clickToSelect
                        handleOnSelect={handleOnSelect}
                        handleOnSelectAll={handleOnSelectAll}
                    />
                </Box>
            </Modal>
            <ModalPlanDetail
                open={isOpenDetail}
                onClose={closeDetail}
                data={dataDetail}
            />
        </>
    );
}

export default ImapactModal;
