import Box from "@material-ui/core/Box";
//Material
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
//Function
import { GetUser } from "utils/sessions";
import { isEmptyStr, titleCase } from "utils/common-function";
import { formatDate, formatDateTime } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
import { styleTextLink } from "utils/style";
export const getColumn = (
  clickViewPlan = () => {},
  clickViewReprocess = () => {}
) => {
  let columns = [
    {
      dataField: "NEXT_EXECUTED",
      text: titleCase("Scheduled Datetime"),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            {ToDateTimeText(row.NEXT_EXECUTED) +
              (row.EXECUTION_TYPE_ID === 2 || row.EXECUTION_TYPE_ID === 3
                ? " **"
                : "")}
          </>
        );
      },
    },
    {
      dataField: "PLAN_ID",
      text: titleCase("PLAN_ID"),
      sort: true,
    },
    {
      dataField: "PLAN_NAME",
      text: titleCase("PLAN_NAME"),
      sort: true,
      style: styleTextLink,
      events: {
        onClick: async (e, column, columnIndex, row, rowIndex) => {
          clickViewPlan(row);
        },
      },
    },
    {
      dataField: "OWNER",
      text: titleCase("OWNER"),
      sort: true,
      // events: onClickCol,
    },
    {
      dataField: "RESULT_DATE",
      text: titleCase("RESULT_DATE"),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return <>{ToDateText(row.RESULT_DATE)}</>;
      },
    },
    {
      dataField: "STATUS_TEXT",
      text: titleCase("STATUS"),
      sort: true,
    },
    {
      dataField: "EXECUTION_TYPE",
      text: titleCase("EXECUTION_TYPE"),
      sort: true,
      style: function callback(cell, row, rowIndex, colIndex) {
        if (row.EXECUTION_TYPE_ID === 2 || row.EXECUTION_TYPE_ID === 3)
          return styleTextLink;
      },
      events: {
        onClick: async (e, column, columnIndex, row, rowIndex) => {
          if (row.EXECUTION_TYPE_ID === 2 || row.EXECUTION_TYPE_ID === 3)
            clickViewReprocess(row);
        },
      },
    },
    {
      dataField: "PLAN_TYPE_TEXT",
      text: titleCase("Plan Type"),
      sort: true,
    },
    {
      dataField: "LAST_FINISHED",
      text: titleCase("LAST_FINISHED"),
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return <>{ToDateTimeText(row.LAST_FINISHED)}</>;
      },
    },
    {
      dataField: "MESSAGE",
      text: titleCase("MESSAGE"),
      sort: true,
    },
    {
      dataField: "PLAN_GROUP",
      text: titleCase("GROUP"),
      sort: true,
    },
    {
      dataField: "TAGS",
      text: "TAGS",
      hidden: true,
      // events: onClickCol,
    },
  ];
  return columns;
};
