import React, { useEffect, useContext, useCallback } from "react";
import { ReactTable } from "../../../../stateless/react-table";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
//Material
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
//Component
import { BaseContainer } from "../../../../stateless/base-container";
import { MaterialUIDatePickers } from "../../../../stateless/hook-controls/datepicker/datepicker";
import ComboBackToDateRange from "../../../../stateless/selection-control/combo/backto-date-range";
import ReprocessDetails from "./reprocess-details.jsx";
//Context
import { ExecutionPlanContext } from "../job-execution-plan-context";
import {
  LoadingContext,
  withLoadingContext,
} from "../../../../context/loading-context";
//Function
import { getColumn as getColumnReprocess } from "../columns/reprocess";
import { buildParameter } from "../parameter";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import * as api from "../api";
import * as alertDialog from "utils/alert";
import { checkDateRange } from "utils/date";
function ReprocessPlan() {
  const {
    isOpen,
    close,
    Data,
    handleClickRow: clickView,
  } = useSelectRowOpenModal();
  const { actions: load_actions, state: load_state } = useContext(
    LoadingContext
  );
  const context = useContext(ExecutionPlanContext);
  const history = useHistory();
  const { state, actions } = context;
  const methods = useForm({
    defaultValues: context.state.CriteriaSearchReprocess,
  });
  const { getValues } = methods;
  const Search = useCallback(() => {
    let paramsearch = buildParameter.GetReprocessList(getValues());
    load_actions.withLoading(() => actions.GetReprocessPlanList(paramsearch));
  }, [actions, getValues, load_actions]);

  useEffect(() => {
    (async () => {
      let param = buildParameter.GetSbxReprocessParam();
      if (await api.CheckCurrentEditReprocess(param)) {
        await alertDialog.Informcurrentedit();
        history.push({
          pathname: "/JobMonitoring/Reprocess",
          state: { Instance_Ids: [] },
        });
      }
      Search();
    })();
  }, [history,Search]);
  async function edit(data) {
    let param = buildParameter.EditReprocess(data);
    await api.EditReprocess(param);
    history.push({
      pathname: "/JobMonitoring/Reprocess",
      state: { Instance_Ids: [] },
    });
  }
  const deleteReprocess = async (data) => {
    let res = await alertDialog.ConfirmDelete();
    if (!res) return;

    let err = await load_actions.withLoading(() => api.DeleteReprocess(data));
    if (err) return;
    alertDialog.del_success();
    Search();
  };
  const columns = getColumnReprocess(clickView, edit, deleteReprocess);
  const tablecomponent = () => {
    return (
      <>
        <Box m={2} display="flex" className="btnstyle">
          <Button variant="contained" onClick={Search}>
            Refresh
          </Button>
        </Box>
      </>
    );
  };
  return (
    <>
      {load_state?.isLoading ? <LinearProgress /> : null}
      <BaseContainer>
        <FormProvider {...methods}>
          <Criteria />
        </FormProvider>
        <ReactTable
          data={state.FilterReprocessList}
          columns={columns}
          keyField="REPROCESS_PLAN_ID"
          headcomponent={tablecomponent}
          exportCsv
        />
      </BaseContainer>
      <ReprocessDetails open={isOpen} id={Data} onClose={close} />
    </>
  );
}
const Criteria = () => {
  const context = useContext(ExecutionPlanContext);
  const Loadingcontext = useContext(LoadingContext);
  const methods = useFormContext();
  const { getValues, setValue,setError,clearErrors } = methods;
  const Search = () => {
    let param = buildParameter.GetReprocessList(getValues());
    // Call Api
    Loadingcontext.actions.withLoading(() =>
      context.actions.GetReprocessPlanList(param)
    );
  };
  // const filterdata = () => {
  //   let param = getValues();
  //   Loadingcontext.actions.withLoading(() =>
  //     context.actions.FilteringExecutionPlanHistory(param)
  //   );

  //   context.actions.SetCriteriaSearchHistory(getValues());
  // };
  const dateChange = (e) => {
    if (!validate()) return;
    context.actions.SetCriteriaSearchReprocess(getValues());
    Search();
  };
  const handleOnChange = (e) => {
    setValue(e.target.name, e.target.value);
    context.actions.SetCriteriaSearchReprocess(getValues());
    Search();
  };
  function validate() {
    let dateform_name, dateTo_name;

      dateform_name = "dateFrom";
      dateTo_name = "dateTo";
    
    clearErrors();
    let valid = checkDateRange(
      getValues()[dateform_name],
      getValues()[dateTo_name]
    );
    if (!valid) {
      setError(dateform_name, {
        type: "manual",
        //message: `Invalid date range`,
      });
      setError(dateTo_name, {
        type: "manual",
        message: `Invalid date range`,
      });
      return false;
    }
    return true;
  }
  return (
    <>
      <Box display="flex" flexWrap="wrap" className="criteriaStyle">
        <Box p={1} minWidth="175px" style={{paddingLeft:"0"}}>
          <MaterialUIDatePickers
            name="dateFrom"
            label="From"
            onChange={dateChange}
          />
        </Box>

        <Box p={1} minWidth="175px" className="dropDownBox" style={{paddingLeft:"0"}}>
          <MaterialUIDatePickers
            name="dateTo"
            label="To"
            minDate={context.state.CriteriaSearchReprocess.dateFrom}
            onChange={dateChange}
          />
        </Box>
        {/* <Box p={1} flexGrow={1}>
          <CheckboxPlanType name="PLAN_TYPE_ID" onChange={filterdata} />
        </Box>
        <Box p={1} flexGrow={1} maxWidth={400}>
          <CheckboxPlanStatus name="PLAN_STATUS_ID" onChange={filterdata} />
        </Box> */}
      </Box>
    </>
  );
};
export default withLoadingContext(ReprocessPlan);
