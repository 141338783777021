import React, { useEffect, useState, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
//Material
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
//Component
import { BoxTemplate1 } from "stateless/Box";
import { Modal } from "stateless/modal";
import { ReactTable } from "stateless/react-table";
import CheckboxJobtype from "stateless/selection-control/checkbox/plantype";
import ComboDepDataDateType from "stateless/selection-control/combo/dep-date-type";
import FormInput from "stateless/hook-controls/input";
//Context
import { ExecutionPlanContext } from "../../../job-execution-plan-context";
import { LoadingContext } from "context/loading-context";
//Functions
import { getDependencyChainColumns } from "../../../columns/dependency-chain";
import { getExecutionPlanMaster } from "../../../columns/master-execution-dependency.js";
import { getExecutionDependencyColumns } from "../../../columns/execution-dependency";
import { buildParameter } from "../../../parameter";
import { setDataToFormHookV2, titleCase } from "utils/common-function";
import * as api from "../../../api";
import * as alertDialog from "utils/alert";
import {
  styleBookmarkRow,
  styleLoopDetectedRow,
  styleLoopDetectedBookmarkRow,
} from "utils/style";
// Util
import { common } from "utils";
function PlanSelfServiceDependencies() {
  //Context
  const { state, actions } = useContext(ExecutionPlanContext);
  const Loadingcontext = useContext(LoadingContext);
  //State
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [PlanDetail, setPlanDetail] = useState({});
  //Function

  //Event
  const onOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const onCloseAddModal = () => {
    setOpenAddModal(false);
  };
  const onCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const Edit = (plandetail) => {
    setOpenEditModal(true);
    setPlanDetail(plandetail);
  };
  const Delete = async (id) => {
    let res = await alertDialog.ConfirmDelete();
    if (!res) return;
    let param = buildParameter.RemovePlanDependencies(state.PlanDetail, id);
    let api_res = await Loadingcontext.actions.withLoading(() =>
      actions.RemoveDependency(param)
    );
    if (!api_res) return;
    alertDialog.del_success();

    let param2 = buildParameter.GetPlanDependencies(state.PlanDetail);
    await Loadingcontext.actions.withLoading(() =>
      actions.GetDependencyOfThisPlanSbx(param2)
    );
    await actions.GetSelfServiceDependencyChainOfThisPlanDisplay(
      buildParameter.GetPlanDependenciesChain(state.PlanDetail)
    );
  };
  return (
    <div>
      <BoxTemplate1
        title="Dependencies of This Plan"
        add
        onClickAdd={onOpenAddModal}
        height={600}
      >
        <ReactTable
          keyField="DEPENDS_ON_PLAN_ID"
          data={state?.DependenciesList}
          columns={getExecutionDependencyColumns(Edit, Delete)}
        />
      </BoxTemplate1>
      <AddPlanModal open={openAddModal} onClose={onCloseAddModal} />
      <EditPlanModal
        open={openEditModal}
        onClose={onCloseEditModal}
        plandetail={PlanDetail}
      />
    </div>
  );
}

function AddPlanModal(props) {
  const { state, actions } = useContext(ExecutionPlanContext);
  const Loadingcontext = useContext(LoadingContext);
  const TableMethods = useForm();
  const [SelectedPlan, setSelectedPlan] = useState([]);
  const [waiting, setWaiting] = useState(true);
  useEffect(() => {
    const load = async () => {
      if (!props.open) return;
      setSelectedPlan([]);
      setWaiting(true);
      await actions.GetSelfServiceDependenciesPlanList({});
      setWaiting(false);
    };
    load();
  }, [props.open, actions]);
  const AddPlan = async () => {
    let param = buildParameter.AddPlanDependencies(
      state.PlanDetail,
      SelectedPlan
    );
    await Loadingcontext.actions.withLoading(() =>
      actions.AddDependencyOfThisPlanSbx(param)
    );
    await actions.GetDependencyOfThisPlanSbx(
      buildParameter.GetPlanDependencies(state.PlanDetail)
    );
    await actions.GetSelfServiceDependencyChainOfThisPlanDisplay(
      buildParameter.GetPlanDependenciesChain(state.PlanDetail)
    );
    props.onClose();
  };

  const onFilterList = (e) => {
    let param = TableMethods.getValues();
    if (param?.EXECUTION_PLAN_TYPE_ID === false) {
      param.EXECUTION_PLAN_TYPE_ID = [];
    }
    common.reCheckbox(param, e);
    actions.FilteringPlanList(param);
  };
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedPlan((prev) => [...prev, row]);
    } else {
      setSelectedPlan((prev) => prev.filter((x) => x !== row));
    }
  };
  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelectedPlan(rows);
    } else {
      setSelectedPlan([]);
    }
  };
  return (
    <div>
      <Modal
        {...props}
        onSave={AddPlan}
        title="Add Plan Denpendencies"
        maxWidth="lg"
      >
        <FormProvider {...TableMethods}>
          <Box
            display="block"
            bgcolor="background.paper"
            style={{ margin: "0 5px" }}
          >
            <CheckboxJobtype
              name="EXECUTION_PLAN_TYPE_ID"
              onChange={onFilterList}
            />
          </Box>
          {waiting ? "Loading.." : null}
          <ReactTable
            clickToSelect
            keyField="PLAN_ID"
            columns={getExecutionPlanMaster()}
            data={state.FilterExecutionPlanMasterList}
            handleOnSelect={handleOnSelect}
            handleOnSelectAll={handleOnSelectAll}
          />
          <br></br>
        </FormProvider>
      </Modal>
    </div>
  );
}
function EditPlanModal(props) {
  const { plandetail, ...otherprops } = props;
  const { open } = otherprops;
  const Loadingcontext = useContext(LoadingContext);
  const method = useForm({ mode: "all" });
  const { setValue, getValues, setError, register, control, handleSubmit } =
    method;
  const { actions, state } = React.useContext(ExecutionPlanContext);
  const CurrentDataDateType = useWatch({
    control,
    name: "DEP_DATADATE_TYPE_ID", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: null, // default value before the render
  });

  React.useEffect(() => {
    if (!open) return;
    // let detail = { ...PlanDetail, DATADATE_VALUE: PlanDetail.DATADATE_VALUE + "" };
    let data = setDataToFormHookV2({
      ...plandetail,
      DEP_DATADATE_VALUE: plandetail.DEP_DATADATE_VALUE + "",
      DEP_DATADATE_TYPE_ID: plandetail.DEP_DATADATE_TYPE_ID || null,
    });
    setTimeout(() => data?.forEach(({ name, value }) => setValue(name, value)));
  }, [plandetail, setValue, open]);

  function validate(params) {
    let result = true;
    if (CurrentDataDateType === 1) {
      result =
        getValues().DEP_DATADATE_VALUE >= -31 &&
        getValues().DEP_DATADATE_VALUE <= 1;
      if (!result)
        setError("DEP_DATADATE_VALUE", {
          type: "manual",
          message: `value must between 1 and -31`,
        });
    }
    return result;
  }
  const Save = async (e) => {
    let param = buildParameter.SaveDataDate(plandetail, getValues());
    if (!validate()) return;
    if (!param.DEP_DATADATE_TYPE_ID) {
      setError("DEP_DATADATE_TYPE_ID", {
        type: "manual",
        message: `required`,
      });
      return;
    }

    await Loadingcontext.actions.withLoading(() =>
      api.UpdateDepencyDetail(param)
    );
    let paramPlanlist = buildParameter.GetPlanDependencies(state.PlanDetail);
    await actions.GetDependencyOfThisPlanSbx(paramPlanlist);
    await actions.GetSelfServiceDependencyChainOfThisPlanDisplay(
      buildParameter.GetPlanDependenciesChain(state.PlanDetail)
    );
    props.onClose();
  };
  const getMaxValue = () => {
    switch (getValues().DEP_DATADATE_TYPE_ID) {
      case 1:
        return -31;
      default:
        return 0;
    }
  };
  return (
    <>
      <Modal {...otherprops} onSave={handleSubmit(Save)} title="Data Date">
        <FormProvider {...method}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={2}>
              <Typography>Plan name</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>{plandetail.PLAN_NAME}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Data Date Type</Typography>
            </Grid>
            <Grid item xs={10}>
              <ComboDepDataDateType
                name="DEP_DATADATE_TYPE_ID"
                label={titleCase("DEP_DATADATE_TYPE")}
                onChange={(e) => {
                  setValue(e.target.name, e.target.value);
                }}
              />
            </Grid>

            <>
              <Grid item xs={2}>
                <Typography>{"Value"}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Box
                  display={
                    CurrentDataDateType === 1 || CurrentDataDateType === 5
                      ? "flex"
                      : "none"
                  }
                >
                  <FormInput
                    type="number"
                    name="DEP_DATADATE_VALUE"
                    label={titleCase("DEP_DATADATE_VALUE")}
                    InputProps={{ inputProps: { min: getMaxValue(), max: 1 } }}
                  />
                </Box>
              </Grid>
            </>
            <>
              <Grid item xs={2}>
                <Typography>{"Enabled"}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Box m={1} flexGrow={1}>
                  <Box pl={1}>
                    {/* <FormCheckbox name="ENABLED" label="Enable"></FormCheckbox> */}
                    <input
                      type="checkbox"
                      //ref={register}
                      {...register("ENABLED")}
                      name="ENABLED"
                      label="Enable"
                    />{" "}
                    Enable
                  </Box>{" "}
                </Box>
              </Grid>
            </>
          </Grid>
        </FormProvider>
      </Modal>
    </>
  );
}
const rowStyle = (row, rowIndex) => {
  if (row.POSITION === 0) {
    if (row.LOOP_DETECTED === true) return styleLoopDetectedBookmarkRow;
    else return styleBookmarkRow;
  } else if (row.LOOP_DETECTED === true) return styleLoopDetectedRow;
};
export function SelfServiceDependenciesChain() {
  //Context
  const { state } = useContext(ExecutionPlanContext);
  //State
  //Function
  //Event
  return (
    <div>
      <BoxTemplate1 title="Entire Dependency Chain">
        <ReactTable
          keyField="running_no"
          rowStyle={rowStyle}
          data={state?.DependenciesChainList}
          columns={getDependencyChainColumns(null)}
        />
      </BoxTemplate1>
    </div>
  );
}
export default PlanSelfServiceDependencies;
