import React,{useContext,useEffect} from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { BaseContainer } from "stateless/base-container";
import FormInput from "stateless/hook-controls/input";
import { Button } from "stateless/button";
import { SampleContext,withContext } from "./example-context";
function HookForm() {
  const methods = useForm();
  const { getValues,setValue } = methods;
  const context = useContext(SampleContext)
  const {state,actions} = context
  useEffect(() => {
    actions.SetExampleData({status:"init"})
  }, [actions])
  function Submit() {
  }
  function Load() {
    setValue("textbox","Load Complete")
  }
  return (
    <div>
      <BaseContainer>
        <FormProvider {...methods}>
          <FormInput  name="textbox" label={"label"} />
          <Button onClick={Submit}>Save</Button>
          <Button onClick={Load}>Load</Button>
        </FormProvider>
      </BaseContainer>
    </div>
  );
}

export default withContext(HookForm);
