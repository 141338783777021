import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";
import { useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";

import { Controller } from "react-hook-form";

const CheckboxThree = ({
  control,
  error,
  helperText,
  label,
  name,
  options,
}) => {
  return (
    <FormControl required error={error} component="fieldset" variant="standard">
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref, ...field } }) => (
          <FormGroup>
            {console.log(value)}
            {Object.keys(options).map((key) => {
              return (
                <FormControlLabel
                  label={key}
                  key={key}
                  control={
                    <Checkbox
                      {...field}
                      name={key}
                      checked={value.some((option) => option === key)}
                      onChange={(event, checked) => {
                        if (checked) {
                          onChange([...value, event.target.name]);
                        } else {
                          onChange(
                            value.filter((value) => value !== event.target.name)
                          );
                        }
                      }}
                      inputRef={ref}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        )}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
const defaultValues = {
  name: "",
  radio: "",
  checkboxOne: [],
  checkboxTwo: [],
  checkboxThree: ['Auto Loan1'],
};
const options = {
  "Auto Loan1": false,
  "Auto Loan2": false,
  "Auto Loan 3": false,
  "Auto Loan 4": false,
};
export default function App() {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues,
    shouldUnregister: true,
  });
  const onSubmit = (data) => {
    reset(defaultValues);
    console.log(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <CheckboxThree
          setValue={setValue}
          label="checkboxThree - (Controlled component, uses Controller and control)"
          options={options}
          name="checkboxThree"
          control={control}
        />
        <Box sx={{ mt: 2 }}>
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
}
