import React, { useState, useEffect } from "react";
//Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
//Component
import { ReactTable } from "../../../../stateless/react-table";
import { BoxTemplate1 } from "../../../../stateless/Box";
import { Modal } from "../../../../stateless/modal";
import FormInnput from "../../../../stateless/hook-controls/input";
import ModalJobHistory from "./modal-job-history";
import ModalPlanHistory from "./modal-plan-history";
import ModalDependencies from "./modal-dependencies";
import { getColumPlanDetail } from "../columns/popup-plan-detail";
import * as alertDialog from "utils/alert";
//Function
import * as api from "../api";
import { buildParameter } from "../parameter";
import { useSelectRowOpenModal } from "../../function-api/useSelectRowOpenModal.js";
import { isEmptyArray, titleCase } from "utils/common-function";
import { ToDateText, ToDateTimeText } from "utils/date";
const test_data = {
  PLAN_ID: 43,
  CUSTOM_PARAMS: [
    {
      ITEM_ID: "cc6bb302-6736-4da1-ba18-6f44db2b51b9",
      PARAM_PAIRS: [
        { KEY: "DATA_DT", VALUE: "2022-07-12" },
        { KEY: "LOOP", VALUE: "1" },
      ],
    },
    {
      ITEM_ID: "a6ec9485-02df-4402-a7f7-b78ddb5f7f3b",
      PARAM_PAIRS: [{ KEY: "DATA_DT", VALUE: "2022-07-12" }],
    },
  ],
};
let { log: c } = console;
function ModalPlanDetail(props) {
  const [resultData, setData] = useState([]);
  const { open, onClose, data } = props;
  const [Datatest, setDatatest] = useState([]);
  const [paramList, setparamList] = useState([]);
  useEffect(() => {
    if (!open) {
      setDatatest([]);
      setparamList([]);
      return;
    }
    (async () => {
      let res = await api.GetPlanDetail({
        PLAN_ID: data?.PLAN_ID,
        INSTANCE_ID: data.INSTANCE_ID,
      });
      if (!res) return;
      setData(res);
    })();
    (async () => {
      let res = await api.LoadPlanForExecute({
        PLAN_ID: data?.PLAN_ID,
      });
      if (!res) return;
      setparamList(res);
      setDatatest(res);
    })();
  }, [open, data]);

  const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
  }));
  const classes = useStyles();
  const captionText = () => {
    return (
      `Plan Detail` +
      (data?.INSTANCE_ID === undefined
        ? ""
        : " of Result Dates: " + ToDateText(data?.RESULT_DATE))
    );
  };
  async function Execute() {
    if ( ! await alertDialog.ConfirmCommon('Save data?')) return
    let final = {
      PLAN_ID: Datatest.PLAN_ID,
      CUSTOM_PARAMS: Datatest.JobItems.map((el) => ({
        ITEM_ID: el.ITEM_ID,
        PARAM_PAIRS: el.PARAM_PAIRS,
      })),
    };

    await api.ExecutePlan(final);
    alertDialog.inform_success()
    onClose()
    //console.log(JSON.stringify(final, null, 2));
  }
  return (
    <>
      <Modal
        {...props}
        onSave={Execute}
        title={'Execute Plan'}
        maxWidth="xl"
        className="headerModalPlanDetail"
      >
        <Box p={1} className="boxTableDetail">
          <Table className="tableDetail">
            <colgroup>
              <col style={{ width: "150px" }} />
              <col style={{ width: "auto" }} />
            </colgroup>
            <TableBody>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("Plan")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {resultData?.PLAN_TYPE_TEXT} - {resultData?.PLAN_ID} -{" "}
                    {resultData?.PLAN_NAME}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("OWNER")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {resultData?.OWNER}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("SCHEDULE")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {resultData?.SCHEDULE_TEXT}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("VALIDATION_KEY")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {resultData?.VALIDATION_KEY}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("Execution Status")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    :{" "}
                    {ToDateTimeText(resultData?.STATUS_DATETIME) +
                      ", " +
                      resultData?.EXECUTION_TYPE +
                      ", " +
                      resultData?.STATUS_TEXT}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" className="tdHeaderRow">
                  <Typography className={classes.heading}>
                    {titleCase("TAGS")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.secondaryHeading}>
                    : {resultData?.TAGS || ""}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box display={"flex"} flexDirection="column">
          {paramList?.JobItems?.map((job) => {
            return (
              <Box m={1}>
                <BoxTemplate1 title={job.JOB_NAME} key ={job.ITEM_ID}>
                  <Box display={"flex"} flexDirection={"row"}  p={2} flexWrap="wrap" >
                    {job.PARAM_PAIRS.map((pair) => {
                      return (
                        <>
                          <Box m={1} flexGrow={1} flexBasis={'300'}>
                            <TextField
                            fullWidth
                            InputProps={{
                              readOnly: pair.Key === "P_BU" ? true : false,
                            }}
                              label={pair.Key}
                              value={
                                Datatest?.JobItems?.find(
                                  (el) => el.ITEM_ID === job.ITEM_ID
                                )
                                  ?.PARAM_PAIRS?.find(
                                    (el) => el.Key === pair.Key
                                  )
                                  .Value.toString() || ""
                              }
                              variant={"outlined"}
                              size="small"
                              onChange={(e) => {
                                let val = e.target.value;
                                let temp = Datatest;
                                temp.JobItems.find(
                                  (el) => el.ITEM_ID === job.ITEM_ID
                                ).PARAM_PAIRS.find(
                                  (el) => el.Key === pair.Key
                                ).Value = val;
                                //console.log(Datatest.CUSTOM_PARAMS.find(el=>el.ITEM_ID === job.ITEM_ID).PARAM_PAIRS.find(el=>el.KEY===pair.KEY).VALUE)
                                setDatatest((prev) => ({
                                  ...prev,
                                  ...temp,
                                }));
                              }}
                            />
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </BoxTemplate1>
              </Box>
            );
          })}
        </Box>
      </Modal>
    </>
  );
}

export default ModalPlanDetail;
