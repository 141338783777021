import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
//Material
import Box from "@material-ui/core/Box";
//Component
import { BoxTemplate1 } from "stateless/Box";
import { Modal } from "stateless/modal";
import { ReactTable } from "stateless/react-table";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import FormInput from "stateless/hook-controls/input";
//Functions
import { setDataToFormHookV2, isEmptyStr } from "utils/common-function";
import { GetListOriginConfig } from "./api";
import { SaveOriginConfig } from "./api";
import { DeleteOriginConfig } from "./api";
import { GetServiceConfiguration } from "./api";
import { SetServiceConfiguration } from "./api";
import { getOriginColumns } from "./columns/origin-config";
import * as alertDialog from "utils/alert";
import { buildParameter } from "./parameter";
import { validateJSON } from "utils/common-function";
import { validateJSONArray } from "utils/common-function";
import { isEmptyObj } from "utils/common-function";
//Context

function ModalOriginConfig(props) {
  //Context

  //State
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [DataList, setDataList] = useState([]);
  const [OriginDetail, setDataDetail] = useState({});

  //Event
  const onOpenAddModal = () => {
    setOpenModal(true);
    setEditMode(false);
    setDataDetail(null);
  };
  const onOpenEditModal = () => {
    setOpenModal(true);
    setEditMode(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  //Function
  const Edit = (originDetail) => {
    onOpenEditModal();
    setDataDetail(originDetail);
  };

  const Delete = async (id) => {
    let res = await alertDialog.ConfirmDelete();
    if (!res) return;

    let param = {
      ORIGIN_ID: id,
    };
    let api_res = await DeleteOriginConfig(param);
    if (!api_res) return;
    fetch();
    alertDialog.del_success();
  };
  //--------
  const fetch = async () => {
    let res = await GetListOriginConfig({
      ORIGIN_NAME: null,
    });
    setDataList(res);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <BoxTemplate1
        {...props}
        title="Origin Map"
        add
        onClickAdd={onOpenAddModal}
      >
        <ReactTable
          keyField="ORIGIN_ID"
          data={DataList}
          columns={getOriginColumns(Edit, Delete)}
        />
      </BoxTemplate1>
      <OriginModal
        search={fetch}
        editmode={editMode ? 1 : 0}
        open={openModal}
        onClose={onCloseModal}
        origindetail={OriginDetail}
      />
    </>
  );
}

function OriginModal(props) {
  const { onClose } = props;
  const { open, editmode } = props;
  const { origindetail, search = () => {}, ...otherprops } = props;
  const method = useForm();
  const { setValue, getValues, setError, clearErrors, errors } = method;

  const [enableURL, setEnableURL] = useState(false);

  //-------------------------
  useEffect(() => {
    if (!open) return;

    if (editmode) {
      setEnableURL(false);
      let data = setDataToFormHookV2(origindetail);
      setTimeout(() =>
        data?.forEach(({ name, value }) => setValue(name, value))
      );

      const fetch = async () => {
        let res = await GetServiceConfiguration({
          EDGE_URL: origindetail.EDGE_URL,
        });
        setTimeout(() =>
          setValue(
            "CONFIGURATION",
            isEmptyObj(res) ? "" : JSON.stringify(res, undefined, 4)
          )
        );
      };
      fetch();
    }
  }, [origindetail, setValue, open, editmode]);

  // Event
  const handleClik = () => {
    if (enableURL) {
      setValue("EDGE_URL", getValues().EDGE_URL.trim());

      const fetch = async () => {
        const url = getValues().EDGE_URL;
        let res = await GetServiceConfiguration({
          EDGE_URL: url,
        });
        setTimeout(() =>
          setValue(
            "CONFIGURATION",
            isEmptyObj(res) ? "" : JSON.stringify(res, undefined, 4)
          )
        );
      };
      fetch();
    }

    setEnableURL(!enableURL);
  };

  // Function
  const SaveConfig = async () => {
    let param = buildParameter.SaveOriginConfig(getValues());
    param = {
      ...param,
      ORIGIN_ID: editmode ? origindetail.ORIGIN_ID : null,
      CONFIGURATION: !validateJSON(getValues().CONFIGURATION)
        ? null
        : JSON.parse(getValues().CONFIGURATION),
      JSON_CONFIG: getValues().CONFIGURATION,
    };

    clearErrors("ORIGIN_NAME");
    clearErrors("EDGE_URL");
    clearErrors("CONFIGURATION");

    if (isEmptyStr(param.ORIGIN_NAME.trim())) {
      setError("ORIGIN_NAME", {
        type: "manual",
        message: `Please input Origin Name`,
      });
      return;
    }

    if (isEmptyStr(param.EDGE_URL.trim())) {
      setError("EDGE_URL", {
        type: "manual",
        message: `Please input Edge URL`,
      });
      return;
    }

    if (isEmptyStr(param.JSON_CONFIG.trim())) {
      setError("CONFIGURATION", {
        type: "manual",
        message: `Please input Configuration`,
      });
      return;
    }

    if (
      !isNaN(param.JSON_CONFIG) ||
      validateJSONArray(param.JSON_CONFIG.trim()) ||
      !validateJSON(param.JSON_CONFIG.trim())
    ) {
      setError("CONFIGURATION", {
        type: "manual",
        message: `Configuration require a structures of JSON`,
      });
      return;
    }

    let res = await SaveOriginConfig(param);
    if (res) {
      if (editmode) await SetServiceConfiguration(param);
      search();
      onClose();
      alertDialog.inform_success();
    }
  };

  const useStyles = makeStyles(() => ({
    disable: { background: "whitesmoke" },
    enable: { background: "white" },
  }));

  const classes = useStyles();

  return (
    <div>
      <Modal
        {...otherprops}
        onSave={SaveConfig}
        title={editmode ? "Edit Origin Map" : "Add Origin Map"}
        maxWidth="sm"
        hiddenSave={enableURL}
      >
        <FormProvider {...method}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box m={3} maxWidth="500px" flexGrow={1} className="box-input">
                <FormInput
                  name="ORIGIN_NAME"
                  label="Origin name"
                  errorobj={errors}
                  disabled={enableURL}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                ml={3}
                flexGrow={1}
                maxWidth="500px"
                display="flex"
                className="update-field"
                style={{ backgroundColor: enableURL ? "white" : "whitesmoke" }}
              >
                <FormInput
                  name="EDGE_URL"
                  label="Edge URL"
                  errorobj={errors}
                  className={enableURL ? classes.enable : classes.disable}
                  InputProps={{
                    readOnly: !enableURL,
                  }}
                />
                <Button
                  variant="contained"
                  onClick={handleClik}
                  style={{ height: "45px" }}
                >
                  {!enableURL && <EditIcon />}
                  {enableURL && <SaveIcon />}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                m={3}
                maxWidth="500px"
                height="300px"
                flexGrow={1}
                className="box-input"
              >
                <FormInput
                  multiline
                  minRows={14}
                  name="CONFIGURATION"
                  label="Configuration"
                  errorobj={errors}
                  disabled={enableURL}
                />
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </Modal>
    </div>
  );
}
export default ModalOriginConfig;
