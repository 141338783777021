import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

function FormInput(props) {
  const { control, formState: { errors }, } = useFormContext();
  const { name, required, variant, fullWidth = true } = props;
  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }
  const v3 = (
    <>
      <Controller
        control={control}
        defaultValue=""
        {...props}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              {...props}
              variant={variant || "outlined"}
              error={isError}
              size="small"
              style={{  marginTop: "5px" }}
              fullWidth={fullWidth}
              helperText={errorMessage}
              InputLabelProps={{
                className: required ? "required-label" : "",
                required: required || false,
              }}
            />
          );
        }}
      />
    </>
  );

  return v3;
}

export default FormInput;
export { default as SearchTextBox } from "./SearchTextBox";
export { default as SearchTextBoxFull } from "./SearchTextBoxfull";
export { default as InputButtonBox } from "./InputButtonBox";
