import { styleTextLink } from "utils/style";
import { titleCase } from "utils/common-function";
import { ToDateText } from "utils/date";
export const getColumReprocessDetail = (viewDetail = () => {}) => {
  const onClickCol = {
    onClick: async (e, column, columnIndex, row, rowIndex) => {
      viewDetail(row);
    },
  };
  const columns = [
    {
      dataField: "PLAN_ID",
      text: titleCase("PLAN_ID"),
      sort: true,
    },
    {
      dataField: "PLAN_NAME",
      text: titleCase("PLAN_NAME"),
      sort: true,
      style: styleTextLink,
    },
    {
      dataField: "VALIDATION_KEY",
      text: titleCase("Process Name"),
      sort: true,
    },
    {
      dataField: "FORCE_RESULT_DATE",
      text: titleCase("FORCE_RESULT_DATE"),
      sort: true,
      // events: onClickCol,
      formatter: (cell, row) => {
        return <>{ToDateText(row.FORCE_RESULT_DATE)}</>;
      },
    },
    {
      dataField: "STATUS_TEXT",
      text: titleCase("CURRENT STATUS"),
      sort: true,
    },
  ];
  var foundIndex = columns.findIndex((x) => x.dataField === "PLAN_NAME");
  columns[foundIndex] = { ...columns[foundIndex], events: onClickCol };
  return columns;
};
